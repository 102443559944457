import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspacePermissionsRequest,
    GetWorkspacePermissionsResponse,
    SetWorkspacePermissionsRequest,
    SetWorkspacePermissionsResponse
} from "./types";

export { apiName };

const getWorkspacePermissions = getCallable<GetWorkspacePermissionsRequest, GetWorkspacePermissionsResponse>(
    apiName,
    "getWorkspacePermissions",
    {
        ...endpoints.getWorkspacePermissions,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.object) {
                searchParams.set("object", JSON.stringify(request.object));
            }

            return {
                url: `/workspaces/${request.workspaceId}/permissions?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    }
);

const setWorkspacePermissions = getCallable<SetWorkspacePermissionsRequest, SetWorkspacePermissionsResponse>(
    apiName,
    "setWorkspacePermissions",
    {
        ...endpoints.setWorkspacePermissions,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/permissions`,
                options: {
                    method: "PUT",
                    body: JSON.stringify(request)
                }
            };
        }
    }
);

export const callables = {
    getWorkspacePermissions,
    setWorkspacePermissions
};
