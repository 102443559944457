import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Icon } from "@material-ui/core";
import { EditOutlined, EmojiObjectsOutlined, GroupWorkOutlined, LaptopChromebookOutlined, StorefrontOutlined, SupervisorAccountOutlined } from "@material-ui/icons";

import { headerContainerStyles, headerTextStyles, optionImageStyles, optionNameStyles, optionsButtonStyles, optionsContainerStyles, subHeaderTextStyles } from "legacy-js/react/views/Onboarding/MarketingSurveyStyles";

import Container from "./Components/Container";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const HeaderContainer = styled.div`
    ${headerContainerStyles}
`;

const HeaderText = styled.div`
    ${headerTextStyles}
`;

const SubHeaderText = styled.h3`
    ${subHeaderTextStyles}
`;

const OptionsContainer = styled.div`
    ${optionsContainerStyles}
    grid-template-columns: repeat(3, 1fr);

    & > :nth-child(7) {
        grid-column: 1 / 3;
        grid-row: 3;
    }

    & > :nth-child(8) {
        grid-column: 2 / 4;
        grid-row: 3;
    }

    @media (max-width: 768px) {
        & > :nth-child(7),
        & > :nth-child(8) {
            grid-column: auto;
            grid-row: auto;
        }
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        width: 100%;
    }
`;

const OptionButton = styled(Button)`
    ${optionsButtonStyles}
`;

const OptionImage = styled(Icon)`
    ${optionImageStyles}
`;

const OptionName = styled.span`
    ${optionNameStyles}

    /* Safari-specific styles */
    ${isSafari && `
        margin-top: 5px;
    `}
`;

const UserRole = ({ onNext, onBack, prevProgress, currProgress, handleChange, value, paneIdx, prevPaneIdx }) => {
    const [selectedOption, setSelectedOption] = useState(value);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);

    const calculateDelay = index => {
        return 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 0);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (key, value) => {
        handleChange(key, value);
        setSelectedOption(value);
        setFadeOut(true);
        onNext();
    };

    const handleBackButtonClick = () => {
        setFadeOut(true);
        onBack();
    };

    return (
        <Container progress={progress} prevProgress={prevProgress} showBackButton={true} onBackButtonClick={handleBackButtonClick} paneIdx={paneIdx} prevPaneIdx={prevPaneIdx}>
            <HeaderContainer>
                <HeaderText data-test-id="user-role-header" fadeOut={fadeOut} delay={calculateDelay(0)}>What is your role?</HeaderText>
                <SubHeaderText fadeOut={fadeOut} delay={calculateDelay(1)}>This helps us show relevant content</SubHeaderText>
            </HeaderContainer>
            <OptionsContainer>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "individual-contributor")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "individual-contributor" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(2)}
                >
                    <OptionImage as={EditOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Individual contributor</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "people-manager")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "people-manager" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(3)}
                >
                    <OptionImage style={{ fontSize: "20px" }}>manager</OptionImage>
                    <OptionName>People manager</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "department-leader")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "department-leader" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(4)}
                >
                    <OptionImage as={GroupWorkOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Department leader</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "executive")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "executive" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(5)}
                >
                    <OptionImage as={SupervisorAccountOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Executive</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "startup-founder")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "startup-founder" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(6)}
                >
                    <OptionImage style={{ fontSize: "20px" }}>rocket_launch</OptionImage>
                    <OptionName>Startup founder</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "consultant")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "consultant" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(7)}
                >
                    <OptionImage as={EmojiObjectsOutlined} style={{ fontSize: "20px" }}>lightbulb</OptionImage>
                    <OptionName>Consultant</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "freelancer")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "freelancer" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(8)}
                >
                    <OptionImage as={LaptopChromebookOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Freelancer</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userRole", "solo-preneur")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "solo-preneur" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    delay={calculateDelay(9)}
                >
                    <OptionImage as={StorefrontOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Solo-preneur</OptionName>
                </OptionButton>
            </OptionsContainer>
        </Container>
    );
};

export default UserRole;
