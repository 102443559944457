import { Icon } from "@material-ui/core";
import React, { Component } from "reactn";
import styled from "styled-components";

import { CustomerType } from "common/constants";
import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import { FeatureType } from "js/core/models/features";
import { trackActivity, trackState } from "js/core/utilities/utilities";
import { app } from "js/namespaces";

import { ShowConfirmationDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import Loadable from "js/react/components/Loadable";
import {
    BlueButton,
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import TeamMembersList from "js/react/views/UserOptions/components/TeamMembersList";

import BillingDataService from "../dataservices/billingDataService";
import { InviteTeamMembersDialog } from "../dialogs";
import ManageAvailableTeamSeatsDialog from "../dialogs/ManageAvailableTeamSeatsDialog";
import { getUsedSeatCount } from "../UserOptionsContainer";
import { WorkspaceNameInput } from "../components/WorkspaceNameInput";

const SeatCount = styled.div`
  font-size: 14px;
  margin-top: 20px;       
  font-weight: 700;  
`;

const StyledFlexBox = styled(FlexBox)`
  font-weight: bold;
`;

const StyledLink = styled.a`
  color: #11A9E2;
  margin-left: 2px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #0D76A2;
  }
`;

class ManageTeamPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            org: {},
            isLoading: true,
            team: false,
            usedSeatCount: 0,
            userRole: "",
            transactionHistoryData: [],
            subscription: { items: { data: [{ quantity: 0 }] } },
            paymentMethod: null,
            canManageTeam: app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, props.organizationId),
        };

        this.contentsContainerRef = React.createRef();
    }

    get orgTotalSeatCount() {
        const { subscription } = this.state;
        return subscription?.items?.data[0].quantity ?? 0;
    }

    get isActiveSubscription() {
        const { subscription } = this.state;
        return subscription && !subscription.cancel_at_period_end;
    }

    get isValidSubscription() {
        const { subscription } = this.state;
        return (
            !!subscription &&
            (
                subscription?.status === "active" ||
                subscription?.status === "trialing"
            )
        );
    }

    async componentDidMount() {
        await this.getFreshState();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.organizationId !== prevProps.organizationId) {
            this.setState({ org: {}, isLoading: true });
            await this.getFreshState();
        }
    }

    fetchSubscription = async () => {
        const { organizationId } = this.props;
        const [paymentMethodsResponse, subscription, customer, isEnterprise] = await Promise.all([
            Api.paymentMethods.get({
                customer_type: CustomerType.TEAM,
                organization_id: organizationId
            }),
            BillingDataService.fetchSubscription(organizationId),
            BillingDataService.fetchCustomer(organizationId),
            Api.orgIsEnterprise.get({ orgId: organizationId })
        ]);

        this.setState({
            subscription,
            paymentMethod: paymentMethodsResponse.body[0],
            isEnterprise,
            team: {
                ...this.state.team,
                name: subscription?.customerName ?? customer.name
            }
        });
    }

    getFreshState = async () => {
        const { canManageTeam } = this.state;

        const org = await Api.organizations.get({ id: this.props.organizationId });
        this.setState({ org });

        await this.getTeam();
        if (canManageTeam) {
            await this.fetchSubscription();
        }

        this.setState({ isLoading: false });
    }

    getTeam = async () => {
        const { teamId } = this.props;
        const teamResponse = await Api.teams.get({ id: teamId });
        const teamInvitesResponse = await Api.teamInvites.get({
            type: "team",
            id: teamId
        });
        const allMembers = teamResponse.body.members.concat(teamInvitesResponse.body);
        const user = allMembers.find(member => member.id === app.user.id);
        this.setUsedSeatCount(allMembers);

        this.setState({
            originalName: teamResponse.body.name,
            team: {
                ...teamResponse.body,
                members: allMembers
            },
            userRole: user ? user.role : "",
        });
    }

    setUsedSeatCount = teamMembers => {
        const usedSeatCount = getUsedSeatCount(teamMembers);
        this.setState({ usedSeatCount });
    }

    handleShowAddMemberDialog = () => {
        const { team, usedSeatCount, org, isEnterprise } = this.state;
        const { organizationId } = this.props;

        const props = {
            usedSeatCount,
            org
        };

        ShowDialog(InviteTeamMembersDialog, {
            organizationId,
            team,
            callback: () => this.getFreshState(),
            isEnterprise,
            ...props
        });
    }

    handleRemoveAvailableSeats = async () => {
        const { usedSeatCount } = this.state;
        const { organizationId } = this.props;

        await BillingDataService.removeTeamSeats(organizationId, usedSeatCount);
        await this.getFreshState();
    }

    handleShowManageAvailableSeatsDialog = () => {
        const { usedSeatCount } = this.state;

        ShowDialog(ManageAvailableTeamSeatsDialog, {
            unusedSeatCount: this.orgTotalSeatCount - usedSeatCount,
            showAddMemberDialog: this.handleShowAddMemberDialog,
            removeAvailableSeats: this.handleRemoveAvailableSeats
        });
    }

    renderSeatCount = () => {
        const { usedSeatCount, canManageTeam, paymentMethod, isEnterprise } = this.state;

        // if this.isActiveSubscription is undefined, it means we do not have an active subscription
        // and we should not show the seat count
        if (usedSeatCount == this.orgTotalSeatCount || this.isActiveSubscription === undefined) {
            const canManage = canManageTeam && this.isActiveSubscription;

            let label = "Add Team Pro Seats";
            const linkProps = {};
            if (canManage) {
                if (isEnterprise || !paymentMethod) {
                    label = "Contact us to add more seats.";
                    const recipient = isEnterprise ? "accounts@beautiful.ai" : "support@beautiful.ai";
                    linkProps.href = `mailto:${recipient}`;
                } else if (paymentMethod) {
                    linkProps.onClick = this.handleShowAddMemberDialog;
                }
            }

            return (
                <StyledFlexBox left>
                    You have no available Team Pro seats.&nbsp;
                    {canManage && <StyledLink {...linkProps}>{label}</StyledLink>}
                </StyledFlexBox>
            );
        }

        const remainingSeats = this.orgTotalSeatCount - usedSeatCount;
        const hasAvailableSeats = remainingSeats > 0;

        return (<StyledFlexBox left>
            {remainingSeats} of {this.orgTotalSeatCount} Team Pro {"seat".pluralize(this.orgTotalSeatCount > 1)} available.
            {canManageTeam && this.isValidSubscription &&
                // if enterprise user DO NOT show the manage seats button, otherwise check if it has payment method or available seats
                (!isEnterprise && (paymentMethod || hasAvailableSeats)) &&
                <StyledLink onClick={this.handleShowManageAvailableSeatsDialog}>Manage seats</StyledLink>}
        </StyledFlexBox>);
    }

    handleUpgradeToV11 = async (uid = null) => {
        const { team } = this.state;

        if (await ShowConfirmationDialog({
            title: `Are you sure you want to upgrade ${uid ? "this member" : "all members"} to the new version of Beautiful.ai?`,
            message: `Once you upgrade, ${uid ? "they" : "all members"} will not be able to return to the current version. Presentations and slides created or edited with this version of Beautiful.ai will not be editable during collaboration with users still using the older version.`
        })) {
            this.setState({ isLoading: true });

            await Api.teams.put({
                teamId: team.id,
                type: "upgrade_members_to_v11",
                userIds: uid ? [uid] : null
            });

            trackActivity("VersionUpgrade", "Upgrade", null, null, {
                userIds: uid ? [uid] : team.members.map(member => member.id),
                version_number: 11
            }, { audit: true });

            this.getFreshState();
        }
    }

    render() {
        const { handleRemoveMember, paneClicked } = this.props;
        const { isLoading, team, canManageTeam, org, usedSeatCount, paymentMethod, isEnterprise } = this.state;

        const hasAvailableSeats = (this.orgTotalSeatCount - usedSeatCount) > 0;
        const canAddMoreMembers = isEnterprise || paymentMethod || hasAvailableSeats;
        const hasNonMigratedMembers = team?.members?.some(member => !member.pending && !member._migrated);

        return (
            <UIPane className="manage-team">
                <UIPaneHeader>
                    Manage Team
                </UIPaneHeader>
                <UIPaneContents containerRef={this.contentsContainerRef}>
                    <Loadable isLoading={isLoading}>
                        <Section>
                            <Gap10 />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <WorkspaceNameInput disabled={!canManageTeam} />
                                    {canManageTeam && <SeatCount>{this.renderSeatCount()}</SeatCount>}
                                </div>
                                {canManageTeam && canAddMoreMembers && this.isValidSubscription &&
                                    <BlueButton
                                        id="add-member-button"
                                        style={{ height: 44 }}
                                        onClick={this.handleShowAddMemberDialog}
                                    >
                                        <Icon>add</Icon>
                                        Add a member
                                    </BlueButton>
                                }
                            </div>
                        </Section>
                        {canManageTeam && hasNonMigratedMembers && <>
                            <Gap20 />
                            <Section title="Upgrade Beautiful.ai for all your team members">
                                <FlexBox left vertical>
                                    Some of your team members are still using the legacy version of Beautiful.ai. We are providing a 3-month grace period during which you can choose to upgrade at your convenience. After October 1, 2024, your account will automatically be upgraded to the new version.
                                    <Gap10 />
                                    <span>For more information on upgrading, please click <a href="https://www.beautiful.ai/upgrade" target="_blank">here</a>.</span>
                                    <Gap20 />
                                    <strong>Please note: Presentations and slides created or edited with the current version of Beautiful.ai will not be editable during collaboration with members still using the older version.</strong>
                                    <Gap20 />
                                    <BlueButton onClick={() => this.handleUpgradeToV11()}>Upgrade Beautiful.ai for all your team members...</BlueButton>
                                </FlexBox>
                            </Section>
                        </>}
                        <Gap20 />
                        <TeamMembersList
                            isValidSubscription={this.isValidSubscription}
                            organizationId={this.props.organizationId}
                            org={org}
                            team={team}
                            getTeam={this.getTeam}
                            handleRemoveMember={handleRemoveMember}
                            getFreshState={this.getFreshState}
                            totalSeatCount={this.orgTotalSeatCount}
                            isDefaultTeam={true}
                            canManageTeam={canManageTeam}
                            paneClicked={paneClicked}
                            scrollContainerEl={this.contentsContainerRef.current}
                            canAddSeats={paymentMethod || hasAvailableSeats}
                            upgradeMemberToV11={this.handleUpgradeToV11}
                        />
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default ManageTeamPane;
