import {
    DialogActions,
    DialogTitle
} from "@material-ui/core";
import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";

import { workspaces as workspacesApi } from "apis/callables";
import {
    IWorkspacePermission,
    IWorkspaceResourceFolder,
    IWorkspaceUserGroup,
    WorkspacePermissionObjectType,
    WorkspacePermissionSubjectType,
    WorkspaceUserGroupType,
    LegacyWorkspaceGroupIds
} from "common/interfaces";
import getObjectHash from "common/utils/getObjectHash";
import { Button } from "js/Components/Button";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger from "js/core/logger";
import { BeautifulDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

const logger = getLogger();

const DialogContent = styled.div`
    padding: 0 25px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

const SettingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;

    >span {
        margin-left: 60px;
    }
`;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
`;

type AllTeamMembersFolderSettingsDialogProps = WorkspaceControllerState & {
    folder: IWorkspaceResourceFolder;
    closeDialog: () => void;
}

function getRestrictAccess(permissions: IWorkspacePermission[], userGroups: IWorkspaceUserGroup[]) {
    const allMembersGroup = userGroups.find(group => group.type === WorkspaceUserGroupType.ALL_WORKSPACE_MEMBERS);

    if (permissions.some(({ subjectId, subjectType, write }) => subjectType === WorkspacePermissionSubjectType.USER_GROUP && subjectId === allMembersGroup?.id && write)) {
        return false;
    }

    return true;
}

function getHideFolder(permissions: IWorkspacePermission[], userGroups: IWorkspaceUserGroup[]) {
    const allOwnersGroup = userGroups.find(group => group.type === WorkspaceUserGroupType.ALL_WORKSPACE_OWNERS);

    if (permissions.length === 1 && permissions[0].subjectId === allOwnersGroup?.id) {
        return true;
    }

    return false;
}

export default WorkspaceController.withInitializedState(forwardRef<any, AllTeamMembersFolderSettingsDialogProps>(function AllTeamMembersFolderSettingsDialog({ folder, userGroups, closeDialog }, ref) {
    const [fetching, setFetching] = useState(true);
    const [permissions, setPermissions] = useState<IWorkspacePermission[]>([]);

    const [restrictAccess, setRestrictAccess] = useState(false);
    const [hideFolder, setHideFolder] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const permissions = await workspacesApi.getWorkspacePermissions({
                    workspaceId: folder.workspaceId,
                    object: {
                        type: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                        id: folder.id
                    }
                });
                setPermissions(permissions);
                setFetching(false);
            } catch (err) {
                logger.error(err, `Failed to fetch permissions for folder ${folder.id}`);
            }
        })();
    }, []);

    useEffect(() => {
        if (hideFolder) {
            setRestrictAccess(true);
        }
    }, [hideFolder]);

    useEffect(() => {
        setRestrictAccess(getRestrictAccess(permissions, userGroups));
        setHideFolder(getHideFolder(permissions, userGroups));
    }, [getObjectHash(permissions)]);

    const handleSave = async () => {
        setFetching(true);
        try {
            const permissions: Omit<IWorkspacePermission, "workspaceId" | "createdAt" | "modifiedAt" | "id">[] = [];

            permissions.push({
                subjectId: LegacyWorkspaceGroupIds.OWNERS,
                subjectType: WorkspacePermissionSubjectType.USER_GROUP,
                objectId: folder.id,
                objectType: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                read: true,
                write: true,
                owner: true
            });

            if (!hideFolder) {
                permissions.push({
                    subjectId: LegacyWorkspaceGroupIds.LIBRARIANS,
                    subjectType: WorkspacePermissionSubjectType.USER_GROUP,
                    objectId: folder.id,
                    objectType: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                    read: true,
                    write: true,
                    owner: false
                }, {
                    subjectId: LegacyWorkspaceGroupIds.MEMBERS,
                    subjectType: WorkspacePermissionSubjectType.USER_GROUP,
                    objectId: folder.id,
                    objectType: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                    read: true,
                    write: !restrictAccess,
                    owner: false
                });
            }

            const updatedPermissions = await workspacesApi.setWorkspacePermissions({
                workspaceId: folder.workspaceId,
                object: {
                    type: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                    id: folder.id
                },
                permissions
            });

            setPermissions(updatedPermissions);
        } catch (err) {
            logger.error(err, `Failed to save permissions for folder ${folder.id}`);

            ShowErrorDialog({
                error: "Error",
                message: "Failed to save settings"
            });
        } finally {
            setFetching(false);
        }
    };

    const dirty = restrictAccess !== getRestrictAccess(permissions, userGroups) || hideFolder !== getHideFolder(permissions, userGroups);

    return (
        <BeautifulDialog closeDialog={closeDialog} ref={ref}>
            <DialogTitle>All Team Members Folder Settings</DialogTitle>
            <DialogContent>
                <FetchingClickShield visible={fetching} backgroundColor="white" />
                <SettingContainer>
                    <SwitchContainer>
                        <ToggleSwitch
                            disabled={hideFolder}
                            value={restrictAccess}
                            onChange={() => setRestrictAccess(!restrictAccess)}
                        />
                        <span>Restrict Access</span>
                    </SwitchContainer>
                    <span>Only Librarians and Owners can add and remove presentations from this folder.</span>
                </SettingContainer>
                <SettingContainer>
                    <SwitchContainer>
                        <ToggleSwitch
                            value={hideFolder}
                            onChange={() => setHideFolder(!hideFolder)}
                        />
                        <span>Hide Folder</span>
                    </SwitchContainer>
                    <span>Hide folder from all team members. Presentations currently in this folder will not be deleted, but some members may lose access.</span>
                </SettingContainer>
            </DialogContent>
            <DialogActions>
                <Button unframed disabled={fetching} onClick={closeDialog}>Close</Button>
                <Button blue disabled={fetching || !dirty} onClick={handleSave}>Save changes</Button>
            </DialogActions>
        </BeautifulDialog>
    );
}));
