import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceUsers: {
        route: "/workspaces/:workspaceId/users",
        method: "GET"
    },
};
