import { FormatType, PositionType } from "common/constants";
import { $ } from "js/vendor";
import anime from "animejs";

export const ActionNumberAnimationStyles = [
    { id: "count", label: "Count" },
    { id: "zoom", label: "Zoom" },
    { id: "slide", label: "Slide" },
    { id: "scroll", label: "Scroll" },
    { id: "multi-zoom", label: "Multi-Zoom" },
];

export function playActionNumAnimation(el, animationStyle, onComplete) {
    if (el.timeline?.began && !el.timeline?.completed) {
        el.timeline.restart();
        return el.timeline;
    }

    let timeline = anime.timeline({
        easing: "easeOutQuad"
    });

    switch (animationStyle) {
        case "count":
            ActionNumAnimationStyle1(el, timeline);
            break;
        case "zoom":
            ActionNumAnimationStyle2(el, timeline);
            break;
        case "slide":
            ActionNumAnimationStyle3(el, timeline);
            break;
        case "scroll":
            ActionNumAnimationStyle4(el, timeline);
            break;
        case "multi-zoom":
            ActionNumAnimationStyle5(el, timeline);
            break;
    }

    timeline.finished.then(() => {
        el.timeline = null;
        onComplete && onComplete();
    });
    timeline.play();
    return timeline;
}

function getAnimationForLabel(el, timeline) {
    if (el.labelPosition == PositionType.RIGHT) {
        let labelContainerEl = el.label.blockContainerRef.current.ref.current;
        let labelFrameEl = $(labelContainerEl).find(".authoring-block-frame");
        let labelBlockEl = $(labelContainerEl).find(".text-block-container")[0];

        $(labelFrameEl).css({
            overflow: "hidden"
        });

        $(el.label.DOMNode).opacity(1);

        timeline.add({
            targets: el.number.DOMNode,
            translateX: el.number.calculatedProps.bounds.left - (el.calculatedProps.size.width / 2 - el.number.calculatedProps.bounds.width / 2),
            duration: 800,
        }, "+=200");

        timeline.add({
            targets: labelBlockEl,
            translateX: [-el.label.calculatedProps.size.width, 0],
            opacity: [.5, 1],
            duration: 600
        }, "-=500");

        if (el.showLine) {
            timeline.add({
                targets: el.line.DOMNode,
                scaleY: [0, 1],
                keyframes: [{
                    opacity: 0, duration: 1
                }, {
                    opacity: 1
                }],
                duration: 600
            }, "-=500");
        }
    } else {
        if (el.showLine) {
            timeline.add({
                targets: el.line.DOMNode,
                scaleX: [0, 1],
                keyframes: [{
                    opacity: 0, duration: 1
                }, {
                    opacity: 1
                }],
                duration: 600
            }, "-=100");
        }

        timeline.add({
            targets: el.label.DOMNode,
            duration: 400,
            opacity: [0, 1],
            translateY: [50, 0],
            // easing: "cubicBezier(0.170, 0.440, 0.205, 0.880)",
        }, "-=100");
    }
}

//counting
function ActionNumAnimationStyle1(el, timeline) {
    let num = {
        value: el.model.value * el.startValue
    };

    if (el.format == FormatType.PERCENT) {
        num.value /= 100;
    }

    let steps = 20;
    let startValue = el.model.value * el.startValue;

    let $numberText = $(el.number.DOMNode).find(".content-editable");
    timeline.add({
        targets: num,
        update: anim => {
            let progress = 1 - (1 - anim.progress / 100) * (1 - anim.progress / 100);
            let value = (startValue + (el.model.value - startValue) * progress);
            el.number.setValue(value);
        },
        duration: steps * 100,
        easing: "linear"
    }, 0);

    timeline.add({
        targets: el.number.DOMNode,
        opacity: [0, 1],
        duration: 1700
    }, 0);

    getAnimationForLabel(el, timeline);
}

function ActionNumAnimationStyle2(el, timeline) {
    let num = {
        value: el.model.value * el.startValue
    };

    if (el.format == FormatType.PERCENT) {
        num.value /= 100;
    }

    let steps = 20;
    let startValue = el.model.value * el.startValue;
    let $numberText = $(el.number.DOMNode).find(".content-editable");

    timeline.add({
        easing: "cubicBezier(0.170, 0.440, 0.205, 0.880)",
        targets: el.number.DOMNode,
        scale: [20, 1],
        opacity: [0, 1],
        duration: steps * 50,
    });

    timeline.add({
        targets: num,
        update: anim => {
            let progress = 1 - (1 - anim.progress / 100) * (1 - anim.progress / 100);
            progress = Math.round(15 * progress) / 15;
            let value = startValue + (el.model.value - startValue) * progress;
            el.number.setValue(value);
        },
        duration: steps * 100,
        easing: "linear"
    }, 0);

    getAnimationForLabel(el, timeline);
}

function ActionNumAnimationStyle3(el, timeline) {
    timeline.add({
        targets: el.number.DOMNode,
        scale: [2, 2],
        translateX: [-1000, 100],
        duration: 1000,
        easing: "cubicBezier(0.170, 0.440, 0.205, 0.880)",
        opacity: {
            value: [0, 1],
            duration: 1000,
            easing: "linear",
        }
    }, 0);

    timeline.add({
        targets: el.number.DOMNode,
        translateX: 0,
        scale: 1,
        duration: 700,
        easing: "easeOutQuad",
    });

    let num = {};
    num.value = el.model.value * el.startValue;

    timeline.add({
        targets: num,
        value: el.model.value,
        // round: 1,
        easing: "steps(15)",
        update: () => {
            el.number.setValue(num.value);
        },
        duration: 1700
    }, 0);

    getAnimationForLabel(el, timeline);
}

async function ActionNumAnimationStyle4(el, timeline) {
    el.numberDiv = document.createElement("div");
    el.DOMNode.appendChild(el.numberDiv);

    const STEPS = Math.min(el.model.value, 10);
    let valueDelta = 1;
    let value = el.model.value - valueDelta;

    let numbers = [];
    let stepOffset = el.number.calculatedProps.bounds.height + 30;
    for (let i = 0; i < STEPS - 1; i++) {
        await el.number.setValue(value);

        let step = el.number.DOMNode.cloneNode(true);
        el.numberDiv.appendChild(step);

        // if (i > 0) {
        numbers.push(step);
        // }

        $(step).css({
            transform: `translateY(${-stepOffset}px)`,
            opacity: 1
        });

        value -= valueDelta;
        stepOffset += el.number.calculatedProps.bounds.height + 30;
    }

    // reset the number to the final value
    await el.number.setValue(el.model.value);

    $(el.number.DOMNode).css({
        transform: `translateY(${-stepOffset}px)`,
        opacity: 1
    });

    timeline.add({
        targets: el.numberDiv,
        easing: "easeOutQuad",
        opacity: 1,
        translateY: [stepOffset + el.calculatedProps.size.height, 0],
        duration: 1500,
        // easing: "spring(1, 80, 10, 0)"
    });

    timeline.add({
        targets: el.number.DOMNode,
        easing: "easeOutQuad",
        opacity: 1,
        translateY: [stepOffset + el.calculatedProps.size.height, 0],
        duration: 1500,
        // easing: "spring(1, 80, 10, 0)"
    }, "-=1500");

    getAnimationForLabel(el, timeline, el.numberDiv);

    timeline.add({
        targets: numbers,
        easing: "easeOutQuad",
        opacity: 0,
        duration: 2000
    }, "-=2000");
}

async function ActionNumAnimationStyle5(el, timeline) {
    el.numberDiv = document.createElement("div");
    el.DOMNode.appendChild(el.numberDiv);

    let startValue = el.model.value;
    // if (el.format == FormatType.PERCENT) {
    //     startValue *= 100;
    // }

    const STEPS = 10;

    if (el.startValue == 1) {
        startValue = startValue - STEPS;
    } else {
        startValue *= el.startValue;
    }

    let valueDelta = Math.round((el.model.value - startValue) / STEPS);

    let numbers = [];
    let stepOffset = 0;
    for (let i = STEPS; i > 0; i--) {
        let value = el.model.value - (i * valueDelta);
        await el.number.setValue(value);

        let step = el.number.DOMNode.cloneNode(true);
        el.numberDiv.appendChild(step);

        if (i > 0) {
            numbers.push(step);
        }

        $(step).css({
            opacity: 0,
        });
    }

    // reset the number to the final value
    await el.number.setValue(el.model.value);

    anime.set(numbers, { opacity: 0 });
    anime.set(el.number.DOMNode, { opacity: 0 });

    timeline.add({
        targets: numbers,
        easing: "easeInQuad",
        keyframes: [{
            opacity: 0, duration: 1
        }, {
            opacity: 1, duration: 300
        }, {
            opacity: 0
        }],
        duration: 400,
        scale: [3, 0],
        delay: anime.stagger(150)
    });

    timeline.add({
        targets: el.number.DOMNode,
        duration: 400,
        scale: [3, 1],
        keyframes: [{
            opacity: 0, duration: 1
        }, {
            opacity: 1
        }],
    }, "-=300");

    // timeline.add({
    //     targets: el.label.DOMNode,
    //     duration: 400,
    //     opacity: [0, 1],
    //     translateY: [50, 0],
    //     easing: "cubicBezier(0.170, 0.440, 0.205, 0.880)",
    // }, "-=100");
    getAnimationForLabel(el, timeline);
}

