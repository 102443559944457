import _ from "lodash";

import { IGenericModelFields } from "../IGenericFields";

export enum WorkspaceUserGroupType {
    ALL_WORKSPACE_OWNERS = "all_workspace_owners",
    ALL_WORKSPACE_MEMBERS = "all_workspace_members",
    ALL_WORKSPACE_GUESTS = "all_workspace_guests",
    CUSTOM = "custom"
}

export enum ActionPermissionsObject {
    GENERATIVE_AI = "generativeAI",
    IMPORT_PPT = "importPPT",
    CONVERT_TO_CLASSIC = "convertToClassic",
    CLASSIC_SLIDES = "classicSlides",
    STOCK_LIBRARIES = "stockLibraries",
    WEB_IMAGES = "webImages",
    CUSTOM_THEMES = "customThemes",
    INSPIRATION_SLIDES = "inspirationSlides",
    EXTERNAL_WORKSPACES = "externalWorkspaces",
    BILLING = "billing",
    SSO = "sso",
    PLAYER_BRANDING = "playerBranding",
    PLAYER_SETTINGS = "playerSettings",
    USER_GROUPS = "userGroups",
    WORKSPACE_GUESTS = "workspaceGuests",
    RESOURCES = "resources",
    RESOURCE_FOLDERS = "resourceFolders",
}

export enum PermissionActionType {
    CREATE = "create",
    USE = "use",
    /**
     * Discover and edit all resources, even the ones that aren't owned
     */
    MANAGE = "manage",
    COLLABORATE_WITH = "collaborateWith",
    MOVE_RESOURCES_TO = "moveResourcesTo"
}

export interface IWorkspaceUserGroupActionPermissions {
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.EXTERNAL_WORKSPACES]: {
        [PermissionActionType.COLLABORATE_WITH]: boolean;
        [PermissionActionType.MOVE_RESOURCES_TO]: boolean;
    };
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
}

export interface IWorkspaceUserGroup extends IGenericModelFields {
    readonly workspaceId: string;
    name: string;
    readonly type: WorkspaceUserGroupType;
    actionPermissions: IWorkspaceUserGroupActionPermissions
}

export enum LegacyWorkspaceGroupIds {
    OWNERS = "owners",
    LIBRARIANS = "librarians",
    MEMBERS = "members",
    GUESTS = "guests"
}

////////////////////////////// DEFAULTS //////////////////////////////
const DEFAULT_WORKSPACE_OWNERS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceUserGroupActionPermissions = {
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES]: {
        [PermissionActionType.COLLABORATE_WITH]: true,
        [PermissionActionType.MOVE_RESOURCES_TO]: true
    },
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    }
};

const DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceUserGroupActionPermissions = {
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES]: {
        [PermissionActionType.COLLABORATE_WITH]: true,
        [PermissionActionType.MOVE_RESOURCES_TO]: true
    },
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    }
};

const DEFAULT_WORKSPACE_GUESTS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceUserGroupActionPermissions = {
    ...DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    }
};

const DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceUserGroupActionPermissions = {
    ...DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    }
};

export const GET_DEFAULT_WORKSPACE_OWNERS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_OWNERS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_OWNERS,
    name: "All Owners"
});

export const GET_DEFAULT_WORKSPACE_MEMBERS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_MEMBERS,
    name: "All Members"
});

export const GET_DEFAULT_WORKSPACE_GUESTS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_GUESTS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_GUESTS,
    name: "All Guests"
});

/**
 * Used for legacy/migrated librarian members
 */
export const GET_DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.CUSTOM,
    name: "Librarians"
});
