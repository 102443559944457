import React, { Component } from "react";
import styled from "styled-components";

import { Icon as MaterialIcon, MenuItem } from "@material-ui/core";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import AppController from "js/core/AppController";
import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";
import { ds } from "js/core/models/dataService";
import { sortTagsByPopularity } from "js/core/utilities/utilities";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { UserMenu } from "js/editor/components/UserMenu";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap15 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { PopupMenu } from "js/react/components/PopupMenu";
import { themeColors } from "js/react/sharedStyles";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { TeamResourceTypes } from "js/react/views/TeamResources/TeamResources";
import CreateTeamTemplateDialog from "js/react/views/TeamResources/dialogs/CreateTeamTemplateDialog";

import { Divider } from "js/Components/Divider";
import CollaborationActions from "./CollaborationActions";
import CollaborationBar from "./CollaborationBar";
import CommentsActions from "./CommentsActions";

const organizeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" id="organize-icon" width="30" height="22" viewBox="0 0 19 18">
        <g id="organize-squares" fill="#eee" fillRule="evenodd">
            <rect id="first-box" width="4" height="4" x="1.616" y=".719" />
            <rect id="second-box" width="4" height="4" x="7.788" y=".719" />
            <rect id="third-box" width="4" height="4" x="1.616" y="7.216" />
            <rect id="fourth-box" width="4" height="4" x="1.616" y="13.719" />
            <rect width="4" height="4" x="7.788" y="13.719" />
            <rect width="4" height="4" x="7.788" y="7.216" />
            <rect width="4" height="4" x="13.959" y="13.719" />
            <rect width="4" height="4" x="13.959" y="7.216" />
            <rect width="4" height="4" x="13.959" y=".719" />
        </g>
    </svg>);

const MenuBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #222;
    width: 100%;
    height: 55px;
    padding: 0px;
    color: white;
    flex-shrink: 0;
    gap: 0px;

    .MuiIcon-root {
        color: white;
    }

    #presentation-menu .MuiIcon-root {
        font-size: 20px;
    }
`;

const NavMenuContainer = styled.div`
    width: 52px;
    height: 100%;
    //padding-left: 15px;
    //background: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    //cursor: pointer;

    .material-icons, .bai-icon {
        // color: ${themeColors.ui_blue} !important;
        //font-size: 22px !important;
        font-weight: 800 !important;
    }
`;

const UserMenuContainer = styled.div`
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
`;

const IconMenuButton = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons, .bai-icon {
        font-size: 20px;
        color: #eee;
    }

    &:hover {
        .material-icons, .bai-icon {
            color: ${themeColors.ui_blue};
        }
    }
`;

export const MenuBarIconButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: #ccc;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    .bai-icon {
        color: white !important;
        font-size: 22px !important;
    }

    &:hover {
        color: ${themeColors.ui_blue};

        .bai-icon {
            color: ${themeColors.ui_blue} !important;
        }

        .hover-label {
            opacity: .75;
        }
    }

    .hover-label {
        color: white;
        font-size: 9px;
        position: absolute;
        bottom: -13px;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 300ms;
    }
`;

const PlayButton = styled.div`
    display: flex;
    align-items: center;

    .bai-icon {
        color: white;
        font-size: 40px !important;
        margin-right: 10px;

        &:hover {
            color: ${themeColors.ui_blue};
        }
    }

    button {
        padding: 0px;

        label {
            font-size: 11px;
        }

        label {
            font-weight: 600;
            color: white;
            font-size: 12px;
            text-transform: uppercase;
            white-space: nowrap;
            letter-spacing: 0.03em;
        }
    }
`;

const PresentationName = styled.div`
    color: white;
    font-size: 15px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;


    label {
        font-size: 16px;
    }

    &:hover {
        color: ${themeColors.ui_blue};
    }
`;

const SlideGridButton = styled(IconMenuButton)`
    label {
        font-weight: 600;
        color: white;
        font-size: 13px;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 0.03em;
        margin-left: 10px;
    }

    &:hover {
        label {
            color: ${themeColors.ui_blue};
        }

        rect {
            fill: ${themeColors.ui_blue};
        }

        #first-box {
            transition: all 0.4s ease;
            transform: translateX(6.1px);
        }

        #second-box {
            transition: all 0.4s ease;
            transform: translateX(-6.1px);
        }

        #third-box {
            transition: all 0.4s 0.2s ease;
            transform: translateY(6.5px);
        }

        #fourth-box {
            transition: all 0.4s 0.2s ease;
            transform: translateY(-6.5px);
        }
    }
`;

const Template = styled.div`
    background: #ff4500;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
`;

const LeftMenuBar = styled.div`
    display: flex;
    width: 240px;
    flex-shrink: 0;
`;

const MiddleMenuBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    //padding-left: 20%;
    flex-grow: 2;
    //position: relative;
    height: 100%;
`;

function PlayButtonComponent({ onClick, showSlideGrid }) {
    return (
        <PlayButton>
            <Icon fill color="white" onClick={onClick}>play_circle</Icon>
            <PopupMenu showArrow showHoverEffect={false} label="Present">
                <MenuItem
                    onClick={() => AppController.playPresentation({ presentationId: ds.selection.presentation.id, slideIndex: PresentationEditorController.getCurrentSlideIndex() }, { isPreviewFromEditor: true })}
                >
                    Present from Current Slide
                </MenuItem>
                <MenuItem divider
                    onClick={() => AppController.playPresentation({ presentationId: ds.selection.presentation.id, slideIndex: 0 }, { isPreviewFromEditor: true })}
                >
                    Present from Beginning (Cmd-Click)
                </MenuItem>
                {!showSlideGrid && (
                    <MenuItem
                        onClick={() => PresentationEditorController.playAnimation()}
                    >
                        Preview Animation (Shift-Click)
                    </MenuItem>
                )}
            </PopupMenu>
        </PlayButton>
    );
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;  
`;

class PresentationMenuBar extends Component {
    handleShare = () => {
        ShowDialog(ShareDialog, { presentation: ds.selection.presentation, selectedPanel: "collaborate" });
    }

    handlePlay = event => {
        const { presentation, currentSlide, slides } = this.props;

        if (event.shiftKey) {
            PresentationEditorController.playAnimation();
        } else if (event.metaKey) {
            AppController.playPresentation({ presentationId: presentation.id, slideIndex: 0 }, { isPreviewFromEditor: true });
        } else {
            AppController.playPresentation({ presentationId: presentation.id, slideIndex: slides.indexOf(currentSlide) }, { isPreviewFromEditor: true });
        }
    }

    render() {
        const { currentSlide, slides, presentation, showSlideGrid } = this.props;

        const isPresentationTemplate = presentation.get("isTemplate");

        return (
            <MenuBarContainer id="menubar">

                {!showSlideGrid &&
                    <NavMenuContainer>
                        <PresentationMenu presentation={presentation} icon="menu" />
                    </NavMenuContainer>
                }
                {showSlideGrid &&
                    <NavMenuContainer onClick={() => PresentationEditorController.toggleSlideGrid()}>
                        <Icon fill>arrow_back_ios</Icon>
                    </NavMenuContainer>
                }

                <Gap10 />
                {isPresentationTemplate && <Template>Template</Template>}

                <LeftMenuBar width={240} left>
                    <PresentationName id="presentation-name"
                        onClick={() => PresentationEditorController.renamePresentation()}>
                        {presentation.get("name")}
                    </PresentationName>
                </LeftMenuBar>

                <MiddleMenuBar>
                    {showSlideGrid &&
                        <SlideGridButton id="hide-slide-grid" onClick={() => PresentationEditorController.toggleSlideGrid()}>
                            {organizeIcon}<label>{slides.length} Slides</label>
                        </SlideGridButton>
                    }
                    {!showSlideGrid &&
                        <FlexBox gap={20} left>
                            <SlideGridButton id="show-slide-grid" onClick={() => PresentationEditorController.toggleSlideGrid()}>
                                {organizeIcon}<label>Slide {slides.indexOf(currentSlide) + 1} of {slides.length}</label>
                            </SlideGridButton>
                        </FlexBox>
                    }
                    <PlayButtonComponent onClick={this.handlePlay} />
                    <Button blue onClick={this.handleShare}><Icon>share</Icon>Share...</Button>
                </MiddleMenuBar>

                <StyledContainer>
                    {!showSlideGrid && <>
                        <CollaborationBar />
                        <CollaborationActions />
                        <CommentsActions />
                    </>}
                </StyledContainer>

                <Gap15 />
                <Divider vertical style={{
                    background: "#666"
                }} />

                <UserMenuContainer>
                    <Gap15 />
                    <UserMenu canSwitchWorkspace />
                    <Gap15 />
                </UserMenuContainer>
            </MenuBarContainer>
        );
    }
}

function PresentationMenu({ presentation, label, showArrow, icon = "more_vert" }) {
    let handleReturn = () => {
        if (presentation.get("isTemplate")) {
            OnCreateNewPresentationTemplate(presentation);
        } else {
            AppController.showLibrary();
        }
    };

    if (presentation.get("isTemplate")) {
        return (
            <PopupMenu id="presentation-menu" icon="menu" childrenAreMenuItems showHoverEffect={false}>
                <MenuItem divider onClick={handleReturn}><Icon>list</Icon>Exit Template Editor</MenuItem>
            </PopupMenu>
        );
    } else {
        return (
            <PopupMenu id="presentation-menu" icon={icon} label={label} showArrow={showArrow} childrenAreMenuItems showHoverEffect={false}>
                <MenuItem divider onClick={handleReturn}><MaterialIcon>list</MaterialIcon>Return to Library</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("share", presentation)}><MaterialIcon>share</MaterialIcon>Sharing
                    Settings</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("collaborate", presentation)}><MaterialIcon>person_add_alt_1</MaterialIcon>Collaboration</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("player-settings", presentation)}><MaterialIcon>play_circle_outline</MaterialIcon>Player
                    Settings</MenuItem>
                <MenuItem divider
                    onClick={() => AppController.showPresentationSettings("analytics", presentation)}><MaterialIcon>show_chart</MaterialIcon>View
                    Analytics</MenuItem>
                <MenuItem divider
                    onClick={() => AppController.showPresentationSettings("version-history", presentation)}><MaterialIcon>history</MaterialIcon>Recover
                    Deleted Slides</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("export", presentation)}><MaterialIcon>cloud_download</MaterialIcon>Export
                    Presentation</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("export", presentation)}><MaterialIcon>print</MaterialIcon>Print
                    Presentation</MenuItem>
            </PopupMenu>
        );
    }
}

async function OnCreateNewPresentationTemplate(presentation) {
    let templates = await PresentationTemplateController.getTeamTemplates(null, true);
    let existingTags = sortTagsByPopularity(templates.map(item => item.tags));

    let isTemplateConfirmed = presentation.get("isTemplateConfirmed");

    // Undefined tags means this is a new/legacy template.
    //   So we should prompt for the user to add details
    if (!isTemplateConfirmed) {
        let name = presentation.get("name") || "";
        let description = presentation.get("description") || "";
        let tags = presentation.get("tags") || [];

        PresentationEditorController.selectionLayerController.setSelectedElements([]);
        ShowDialog(CreateTeamTemplateDialog, {
            title: "Publish this template?",
            subTitle: "Publish this presentation now and make it available to your team to start from—or save it as a draft and publish it later.",
            name,
            description,
            tags,
            existingTags,
            callback: async ({
                isPublished,
                name,
                description,
                tags,
            }) => {
                await presentation.update({
                    isTemplateConfirmed: true,
                    isPublished,
                    name,
                    description,
                    tags,
                });

                AppController.showTeamResources({ pane: TeamResourceTypes.PRESENTATION_TEMPLATES });
            }
        });
    } else {
        AppController.showTeamResources({ pane: TeamResourceTypes.PRESENTATION_TEMPLATES });
    }
}

export default PresentationEditorController.withState(PresentationMenuBar);
