import React, { Component } from "react";
import styled from "styled-components";

import { AssetType, AuthoringElementType, AuthoringShapeType, CALLOUTS_MAPPING } from "common/constants";
import { Icon } from "js/Components/Icon";
import { IconButton } from "js/Components/IconButton";
import { ImageOption, ImageOptionList } from "js/Components/ImageOptionList";
import { ImagePopup } from "js/Components/ImagePopup";
import { NumericStepper } from "js/Components/NumericStepper";
import { Slider } from "js/Components/Slider";
import { StaticImage } from "js/Components/StaticImage";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { WithLabel } from "js/Components/WithLabel";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { FlexSpacer, Gap10, Gap20, Gap5 } from "js/react/components/Gap";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { themeColors } from "js/react/sharedStyles";

import { Button } from "js/Components/Button";
import { Divider } from "js/Components/Divider";
import { Popup, PopupContainer, PopupContent, PopupPreview } from "js/Components/Popup";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection, PropertySectionButtonContainer, PropertySectionHeader } from "js/EditorComponents/PropertyPanel";
import { ShowInputDialog } from "js/react/components/Dialogs/BaseDialog";
import { getShadowStyle, ShadowEditor } from "../../../../Components/legacy-components/AuthoringEditorComponents/ShadowEditor";
import { calloutStyles } from "../../../../core/utilities/calloutUtilities";
import { _ } from "../../../../vendor";
import AuthoringElementHeader from "../EditorComponents/AuthoringElementHeader";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ImageFrameMenu, ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";
import { MediaPropertyPanel } from "./MediaUI";

export function PropertyPopupButton({ label, labelIcon, description, icon = "add", onClick, children }) {
    let [open, setOpen] = React.useState(false);

    return (
        <Popup style={{ width: "100%" }}
            onShow={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <PopupPreview>
                <PropertySectionButtonContainer color="#e9e9e9" onClick={onClick} className={open && "isOpen"}>
                    <FlexBox vertical left top fillWidth>
                        <FlexBox left middle fillWidth>
                            <label className="section-label">{label}</label>
                            <FlexSpacer />
                            <Icon className="popupmenu-icon">{icon}</Icon>

                        </FlexBox>
                        {description &&
                            <div className="description">
                                {description}
                            </div>
                        }
                    </FlexBox>
                </PropertySectionButtonContainer>
            </PopupPreview>
            <PopupContent>
                {children}
            </PopupContent>
        </Popup>
    );
}

export class AuthoringPropertyPanel extends Component {
    render() {
        const { element } = this.props;

        if (element.isCallouts) {
            return null;
        }

        return (
            <PropertyPanelContainer>
                <PropertySection fullWidth>
                    <PropertySectionHeader label="Canvas Elements" />
                    <ScrollBox vertical fillWidth>
                        {element.itemElements.map((child, idx) => <AuthoringElementListItem element={child} key={idx} />).reverse()}
                    </ScrollBox>
                    <Gap10 />
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Snap while dragging">
                        <ToggleSwitch value={element.showSnapLines}
                            onChange={value => element.updateModel({ showSnapLines: value })} />
                    </WithLabel>
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}

const AuthoringElementListItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 36px;
    padding: 2px 10px 2px 10px;
    border-bottom: solid 1px #ddd;

    &:first-child {
        border-top: solid 1px #ddd;
    }

    .controls {
        .isHidden_false, .isLocked_false, .editName {
            opacity: 0;
        }
    }

    label {
        font-size: 12px;
        color: #111;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background: ${themeColors.selection};

        .controls {
            .isHidden_true, .isHidden_false, .isLocked_true, .isLocked_false, .editName {
                opacity: 1;
            }
        }
    }
`;

export function getAuthoringContainerElementIconAndName(containerElement) {
    const childElement = containerElement.childElement;

    let name = containerElement.model.name ?? containerElement.name;
    let IconJSX = null;

    switch (containerElement.model.type) {
        case AuthoringElementType.CALLOUT:
            name ||= containerElement.model.calloutType.replaceAll("-", " ").toTitleCase();
            IconJSX = <StaticImage src={CALLOUTS_MAPPING[childElement.model.calloutType].image} width={36} height={36} />;
            break;
        case AuthoringElementType.SHAPE:
            if (childElement.isTextBox) {
                IconJSX = <Icon>title</Icon>;
            } else {
                IconJSX = <Icon>shapes</Icon>;
            }
            break;
        case AuthoringElementType.PATH:
            IconJSX = <Icon>turn_right</Icon>;
            break;
        case AuthoringElementType.CONTENT:
            IconJSX = <Icon>photo_camera</Icon>;
            break;
        case AuthoringElementType.COMPONENT:
            switch (containerElement.model.componentType) {
                case "TableFrame":
                    IconJSX = <Icon>table</Icon>;
                    break;
                case "Dashboard":
                    IconJSX = <Icon>analytics</Icon>;
                    break;
            }
            break;
    }

    return { name, IconJSX };
}

export class AuthoringElementListItem extends Component {
    handleClick = () => {
        const { element } = this.props;
        element.canvas.selectionLayerController.setHilitedElements([]);
        element.canvas.selectionLayerController.setSelectedElements([element]);
    }

    handleEditName = async (event, currentName) => {
        event.stopPropagation();
        const { element } = this.props;
        const name = await ShowInputDialog({ title: "Edit Name", value: currentName });
        if (name) {
            element.updateModel({ name });
        }
    }

    handleToggleLock = event => {
        event.stopPropagation();
        const { element } = this.props;
        element.updateModel({ isLocked: !element.model.isLocked });
    }

    handleToggleVisibility = event => {
        event.stopPropagation();
        const { element } = this.props;
        element.updateModel({ isHidden: !element.model.isHidden });
    }

    handleMouseEnter = () => {
        const { element } = this.props;
        element.canvas.selectionLayerController.setHilitedElements([element]);
    }

    handleMouseLeave = () => {
        const { element } = this.props;
        element.canvas.selectionLayerController.setHilitedElements([]);
    }

    render() {
        const { element, canEditName = true, canLock = true, canHide = true } = this.props;

        if (!element) {
            return null;
        }

        const { IconJSX, name } = getAuthoringContainerElementIconAndName(element);

        return (
            <AuthoringElementListItemContainer onClick={this.handleClick} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                {IconJSX}
                <Gap5 />
                <label>{name}</label>
                <FlexSpacer />
                <FlexBox className="controls" left middle gap={5}>
                    {canEditName && <IconButton className="editName" small color="#333" icon="edit" onClick={event => this.handleEditName(event, name)} />}
                    {canLock && <IconButton className={`isLocked_${(element.model.isLocked ?? false).toString()}`} small color="#333" icon={element.model.isLocked ? "lock" : "lock_open"} onClick={this.handleToggleLock} />}
                    {canHide && <IconButton className={`isHidden_${(element.model.isHidden ?? false).toString()}`} small color="#333" icon={element.model.isHidden ? "visibility_off" : "visibility"} onClick={this.handleToggleVisibility} />}
                </FlexBox>
            </AuthoringElementListItemContainer>
        );
    }
}

function TransformProperties({ authoringCanvas, selectedElements }) {
    let fitToText = authoringCanvas.getChildValues("fitToText", selectedElements);
    let lockAspectRatio = selectedElements.some(element => element.childElement?.lockAspectRatio);
    let isCallout = authoringCanvas.getChildValues("type", selectedElements) == AuthoringElementType.CALLOUT;
    let shapeType = authoringCanvas.getChildValues("shape", selectedElements);

    return (
        <>
            <PropertySection>
                <FlexBox fillWidth left>
                    <WithLabel left small label="X" gap={3}>
                        <NumericStepper value={authoringCanvas.getChildValues("x", selectedElements)}
                            width={40}
                            onChange={value => authoringCanvas.updateChildModels({ x: value }, { selectedElements })}
                        />
                    </WithLabel>
                    <Gap10 />
                    <WithLabel left small label="Y" gap={3}>
                        <NumericStepper value={authoringCanvas.getChildValues("y", selectedElements)}
                            width={40}
                            onChange={value => authoringCanvas.updateChildModels({ y: value }, { selectedElements })}
                        />
                    </WithLabel>
                    <Gap20 />
                    <WithLabel left small label="W" gap={3}>
                        <NumericStepper value={Math.round(authoringCanvas.getChildValues("width", selectedElements))}
                            width={40}
                            onChange={value => {
                                if (lockAspectRatio) {
                                    authoringCanvas.updateChildModels({ width: value, height: value }, { selectedElements });
                                } else {
                                    authoringCanvas.updateChildModels({ width: value }, { selectedElements });
                                }
                                for (let element of selectedElements.filter(el => el.model.type == AuthoringElementType.PATH)) {
                                    element.onResize(element.bounds);
                                }
                            }}
                        />
                    </WithLabel>
                    <Gap10 />
                    <WithLabel left small label="H" gap={3}>
                        <NumericStepper value={Math.round(authoringCanvas.getChildValues("height", selectedElements))}
                            width={40}
                            onChange={value => {
                                if (lockAspectRatio) {
                                    authoringCanvas.updateChildModels({ width: value, height: value }, { selectedElements });
                                } else {
                                    authoringCanvas.updateChildModels({ height: value }, { selectedElements });
                                }
                                for (let element of selectedElements.filter(el => el.model.type == AuthoringElementType.PATH)) {
                                    element.onResize(element.bounds);
                                }
                            }}
                            commitOnEnter
                            disabled={fitToText}
                        />
                    </WithLabel>
                </FlexBox>
            </PropertySection>
            {!isCallout && shapeType == AuthoringShapeType.RECT && (
                <PropertySection>
                    <WithLabel label="Corner Radius">
                        <NumericStepper value={authoringCanvas.getChildValues("adj1", selectedElements) ?? 0}
                            min={0} max={100}
                            onChange={value => authoringCanvas.updateChildModels({ adj1: value }, { selectedElements })}
                        />
                    </WithLabel>
                </PropertySection>
            )}
        </>
    );
}

function StyleProperties({ authoringCanvas, selectedElements }) {
    let shadow = authoringCanvas.getChildValues("shadow", selectedElements);
    let shadowStyle = getShadowStyle(shadow);

    return (
        <>
            <PropertySection>
                <WithLabel label="Opacity">
                    <Slider value={authoringCanvas.getChildValues("opacity", selectedElements) / 100}
                        onChange={value => authoringCanvas.refreshChildModels({ opacity: value * 100 }, { selectedElements })}
                        onCommit={value => authoringCanvas.updateChildModels({ opacity: value * 100 }, { selectedElements })}
                        min={0} max={1} step={0.01}
                        showInput percent
                    />
                </WithLabel>
                <WithLabel label="Shadow">
                    <Popup>
                        <PopupPreview>
                            <ImageOption value={shadowStyle} size={30} round
                                url={`/images/ui/shadows/shadow-${shadowStyle}.svg`} />
                        </PopupPreview>
                        <PopupContent width={300}>
                            {closePopup =>
                                (<PropertyPanelContainer>
                                    <ShadowEditor
                                        closePopup={closePopup}
                                        shadow={shadow}
                                        onChange={value => authoringCanvas.updateChildModels({ shadow: value }, { selectedElements })} />
                                </PropertyPanelContainer>)
                            }
                        </PopupContent>
                    </Popup>

                </WithLabel>
            </PropertySection>
        </>
    );
}

function QuickStyles({ authoringCanvas, selectedElements }) {
    const theme = authoringCanvas.canvas.getTheme();

    let colors = Object.values(theme.palette.getSlideColors());
    let setQuickStyle = (style, color, closePopup) => {
        const selectedStyle = calloutStyles(color, style);
        authoringCanvas.updateChildModels(selectedStyle, { refreshStyles: true, selectedElements });
        closePopup();
    };

    let renderStyle = (style, color, onClick) => {
        switch (style) {
            case "outlined":
                return (
                    <div onClick={onClick} style={{ height: 24 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="22" height="22" stroke={color} strokeWidth="2" />
                        </svg>
                    </div>
                );
            case "filled":
                return (
                    <div onClick={onClick} style={{ height: 24 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill={color} />
                        </svg>
                    </div>
                );

            case "muted":
                return (
                    <div onClick={onClick} style={{ height: 24 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill={color} fillOpacity="0.35" />
                        </svg>
                    </div>
                );
            case "fillAndStroke":
                return (
                    <div onClick={onClick} style={{ height: 24 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="22" height="22" stroke={color} fill={color}
                                fillOpacity="0.35" strokeWidth="2" />
                        </svg>
                    </div>
                );
        }
    };

    let themeStyle = theme.get("styleElementStyle");
    let themeColor = theme.palette.getColor("theme").toRgbString();

    return (
        // <WithLabel label="Quick Styles">
        <Popup icon="palette" showArrow>
            <PopupContent>
                {closePopup => (
                    <PopupContainer width={200}>
                        <FlexBox left gap={5} style={{ paddingTop: 20 }}>
                            {colors.map(color => (
                                <FlexBox vertical gap={5}>
                                    {renderStyle("outlined", color, () => setQuickStyle("outlined", color, closePopup))}
                                    {renderStyle("filled", color, () => setQuickStyle("filled", color, closePopup))}
                                    {renderStyle("muted", color, () => setQuickStyle("muted", color, closePopup))}
                                    {renderStyle("fillAndStroke", color, () => setQuickStyle("fillAndStroke", color, closePopup))}
                                </FlexBox>
                            ))}
                        </FlexBox>
                        <Divider />
                        <Button onClick={() => setQuickStyle(themeStyle, themeColor, closePopup)} style={{ height: 42 }}>
                            <FlexBox>
                                {renderStyle(themeStyle, themeColor, () => {
                                })}
                                <Gap10 />
                                Use Theme Style
                            </FlexBox>
                        </Button>
                    </PopupContainer>
                )}
            </PopupContent>
        </Popup>
        // </WithLabel>
    );
}

function ShapeProperties({ authoringCanvas, selectedElements }) {
    const element = selectedElements[0];

    const theme = element.canvas.getTheme();
    const isCallout = authoringCanvas.getChildValues("type", selectedElements) == AuthoringElementType.CALLOUT;

    return (
        <>
            <PropertySection>
                <PropertySectionHeader label="Styles">
                    <QuickStyles authoringCanvas={authoringCanvas} selectedElements={selectedElements} />
                </PropertySectionHeader>

                <FlexBox left fillWidth>
                    <WithLabel label="Fill" gap={4}>
                        <ColorPicker value={authoringCanvas.getChildValues("fill", selectedElements)}
                            onChange={value => {
                                authoringCanvas.updateChildModels({
                                    fill: value ?? "none"
                                }, { refreshStyles: true, selectedElements });
                            }}
                            canvas={element.canvas} size={26}
                            showNone
                            showBackgroundColors
                            allowColorOnColor
                            showColorPicker={!isCallout}
                        />
                    </WithLabel>
                    <FlexSpacer />
                    <WithLabel label="Stroke" gap={4}>
                        <FlexBox left gap={10}>
                            <ColorPicker value={authoringCanvas.getChildValues("stroke", selectedElements)}
                                onChange={value => authoringCanvas.updateChildModels({ stroke: value }, { refreshStyles: true, selectedElements })}
                                canvas={element.canvas} size={26}
                                showNone
                                showBackgroundColors
                                allowColorOnColor
                                showColorPicker={!isCallout}
                            />
                            <NumericStepper value={authoringCanvas.getChildValues("strokeWidth", selectedElements)}
                                onChange={value => authoringCanvas.updateChildModels({ strokeWidth: value }, { selectedElements })}
                            />
                            <Popup icon="line_style">
                                <PopupContent>
                                    {closePopup => (
                                        <PopupContainer>
                                            <ImageOptionList value={authoringCanvas.getChildValues("strokeStyle", selectedElements)}
                                                size={30}
                                                onChange={value => {
                                                    authoringCanvas.updateChildModels({ strokeStyle: value }, { selectedElements });
                                                    closePopup();
                                                }}
                                            >
                                                <ImageOption value="solid" label="Solid" url="/images/ui/connectors/line-style-solid.svg" />
                                                <ImageOption value="dashed" label="Dashed" url="/images/ui/connectors/line-style-dashed.svg" />
                                                <ImageOption value="dotted" label="Dotted" url="/images/ui/connectors/line-style-dotted.svg" />
                                            </ImageOptionList>
                                        </PopupContainer>
                                    )}
                                </PopupContent>
                            </Popup>
                        </FlexBox>
                    </WithLabel>

                </FlexBox>
                {(authoringCanvas.getChildValues("type", selectedElements) === AuthoringElementType.SHAPE) &&
                    selectedElements.every(element => element.childElement?.canChangeDirection) &&
                    (<WithLabel label="Rotate">
                        <Icon small
                            color={"#333"}
                            onClick={() => authoringCanvas.invokeChildMethods("rotateRight", { selectedElements })}
                        >
                            rotate_right
                        </Icon>
                    </WithLabel>)}

                {element.model.type == AuthoringElementType.PATH && (
                    <WithLabel label="Decoration">
                        <ImagePopup value={element.model.startDecoration}
                            previewSize={30}
                            size={30}
                            onChange={startDecoration => element.updateModel({ startDecoration })}
                        >
                            <ImageOption label="None" value="none">
                                <StaticImage src="/images/ui/connectors/line-start-none.svg" />
                            </ImageOption>
                            <ImageOption label="Arrow" value="arrow">
                                <StaticImage src="/images/ui/connectors/line-start-arrow.svg" />
                            </ImageOption>
                            <ImageOption label="Circle" value="circle">
                                <StaticImage src="/images/ui/connectors/line-start-circle.svg" />
                            </ImageOption>
                        </ImagePopup>
                        <WithLabel label="to" tight>
                            <ImagePopup value={element.model.endDecoration}
                                previewSize={30}
                                size={30}
                                onChange={endDecoration => element.updateModel({ endDecoration })}
                            >
                                <ImageOption label="None" value="none">
                                    <StaticImage src="/images/ui/connectors/line-end-none.svg" />
                                </ImageOption>
                                <ImageOption label="Arrow" value="arrow">
                                    <StaticImage src="/images/ui/connectors/line-end-arrow.svg" />
                                </ImageOption>
                                <ImageOption label="Circle" value="circle">
                                    <StaticImage src="/images/ui/connectors/line-end-circle.svg" />
                                </ImageOption>
                            </ImagePopup>
                        </WithLabel>
                    </WithLabel>
                )}
            </PropertySection>
        </>
    );
}

function ContentProperties({ element }) {
    const mediaElement = element.content;

    const allowBackgroundColor =
        !mediaElement ||
        mediaElement.assetType == AssetType.ICON ||
        mediaElement.assetElement?.isScaledBelowFill ||
        mediaElement.assetElement?.hasAlpha;

    return (
        <>
            <MediaPropertyPanel element={element} />
            {element.model.frameType && element.model.frameType !== "none" &&
                <PropertySection>
                    <PropertySectionHeader label="Frame">
                        <IconButton icon="delete"
                            onClick={()=>element.deleteFrame()} />
                    </PropertySectionHeader>
                    <FlexBox fillWidth>
                        <ImageFramePopupMenu
                            size={60} showPreview
                            frameType={element.model.frameType}
                            allowedCategories={["shape", "device", "decorative"]}
                            onChange={frameType => element.changeFrame(frameType)}
                            showRemoveFrameButton
                        />
                        <FlexSpacer />
                        {allowBackgroundColor &&
                            <WithLabel label="Frame Color" right tight>
                                <ColorPicker showDecorationStyles={element.model.frameType} value={element.model.frameColor} canvas={element.canvas}
                                    onChange={value => element.updateModel({ frameColor: value }, { refreshStyles: true })}
                                    showBackgroundColors allowColorOnColor showColorPicker
                                    onChangeDecorationStyle={decorationStyle => element.updateModel({ decorationStyle }, { refreshStyles: true })
                                    } />
                            </WithLabel>
                        }
                    </FlexBox>
                </PropertySection>
            }
            {(!element.model.frameType || element.model.frameType == "none") &&
                <PropertyPopupButton label="Add Frame" icon="laptop_mac">
                    {closeMenu => (
                        <ImageFrameMenu
                            selectedFrame={element.model.frameType}
                            allowedCategories={["shape", "device", "decorative"]}
                            onSelect={frameType => {
                                element.addFrame(frameType);
                                closeMenu();
                            }}
                        />
                    )}
                </PropertyPopupButton>
            }
        </>
    );
}

function TextProperties({ authoringCanvas, selectedElements }) {
    let fontColor = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.text?.getFontColor()));
    fontColor = fontColor.length == 1 ? fontColor[0] : "mixed";

    let fontSize = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.text?.getFontSize()));
    fontSize = fontSize.length == 1 ? fontSize[0] : "mixed";

    let textInset = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.model.textInset));
    textInset = textInset.length == 1 ? textInset[0] : "mixed";

    let fitToText = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.model.fitToText));
    fitToText = fitToText.length == 1 ? fitToText[0] : "mixed";

    let isShape = false;

    const type = authoringCanvas.getChildValues("type", selectedElements);
    if (type == AuthoringElementType.SHAPE) {
        isShape = authoringCanvas.getChildValues("shape", selectedElements) == AuthoringShapeType.RECT;
    }

    return (
        <>
            <PropertySection>
                <PropertySectionHeader label="Text" />
                <HorizontalPropertyList>
                    <WithLabel label="Text Color" tight>
                        <ColorPicker value={fontColor}
                            canvas={authoringCanvas.canvas}
                            showPrimary showSecondary allowColorOnColor
                            onChange={value => {
                                for (let element of selectedElements) {
                                    element.childElement.text?.setFontColor(value);
                                }
                                authoringCanvas.saveModel();
                            }}
                        />
                    </WithLabel>
                    <WithLabel label="Font Size" tight>
                        <NumericStepper value={fontSize}
                            onChange={value => {
                                for (let element of selectedElements) {
                                    element.childElement.text?.setFontSize(value);
                                }
                                authoringCanvas.saveModel();
                            }}
                        />
                    </WithLabel>
                </HorizontalPropertyList>
                {isShape && (
                    <>
                        {!selectedElements[0].childElement.preserveAspectRatio && (
                            <WithLabel label="Fit Height to Text" toolTip={
                                <>
                                    <p>When this setting is on, the shape will resize automatically it's height to fit to the text.</p>
                                    <p>When this setting is off, the text in a shape will automatically shrink to fit within the shape.</p>
                                </>
                            }>
                                <ToggleSwitch value={fitToText}
                                    onChange={value => {
                                        for (let element of selectedElements) {
                                            element.childElement.model.fitToText = value;
                                        }
                                        authoringCanvas.saveModel();
                                    }} />
                            </WithLabel>
                        )}
                        <WithLabel label="Text Inset" toolTip="Sets the inset between the edges of the shape and any text inside the shape.">
                            <NumericStepper value={textInset}
                                onChange={value => {
                                    for (let element of selectedElements) {
                                        element.childElement.text.model.textInset = value;
                                    }
                                    authoringCanvas.saveModel();
                                }} />
                        </WithLabel>
                    </>
                )}
            </PropertySection>
        </>
    );
}

export class AuthoringElementContainerPropertyPanel extends Component {
    get containerElement() {
        const { element } = this.props;
        return element.isInstanceOf("AuthoringElementContainer") ? element : element.findClosestOfType("AuthoringElementContainer");
    }

    getContainerElement(element) {
        return element.findClosestOfType("AuthoringElementContainer");
    }

    handleEditName = async currentName => {
        const { selectionLayerController } = this.props;
        const containerElement = this.containerElement;

        await selectionLayerController.setSelectedElements([]);
        const name = await ShowInputDialog({ title: "Edit Name", value: currentName });
        if (name) {
            await containerElement.updateModel({ name });
            await selectionLayerController.setSelectedElements([containerElement]);
        }
    }

    handleToggleLock = isLocked =>
        () => {
            const { selectedElements, canvas } = this.props;

            for (const selectedElement of selectedElements) {
                const element = this.getContainerElement(selectedElement);
                element.model.isLocked = !isLocked;
            }

            canvas.updateCanvasModel();
        }

    handleToggleVisibility = isHidden =>
        ()=>{
            const { selectedElements, canvas } = this.props;

            for (const selectedElement of selectedElements) {
                const element = this.getContainerElement(selectedElement);
                element.model.isHidden = !isHidden;
            }

            canvas.updateCanvasModel();
        }

    render() {
        let { selectedElements, canvasController } = this.props;

        selectedElements = selectedElements.map(el => el.findClosestOfType("AuthoringElementContainer"));

        let selectedElement = selectedElements[0];

        const authoringCanvas = selectedElement.findClosestOfType("AuthoringCanvas");

        const containerElement = this.containerElement;
        const childElement = containerElement.childElement;

        if (containerElement.model.type === AuthoringElementType.COMPONENT && canvasController.selectionLayerController.selectedElements[0].isChildOf(containerElement)) {
            let selectedComponent = canvasController.selectionLayerController.selectedElements[0];
            const ChildElementPropertyPanel = childElement.getElementPropertyPanel();
            return (
                <>
                    <AuthoringElementHeader name={selectedComponent.name} />
                    <ChildElementPropertyPanel {...this.props} element={selectedComponent} />
                </>
            );
        }

        const elementType = authoringCanvas.getChildValues("type", selectedElements);

        const canHide = elementType !== AuthoringElementType.CALLOUT;

        let name = containerElement.name;
        let isLocked = containerElement.model.isLocked;
        let isHidden = containerElement.model.isHidden;

        if (selectedElements.length > 1) {
            name = `${selectedElements.length} Elements`;
            // For mixed states, show as if all elements are locked/hidden and default to unlocking/unhiding all
            isLocked = selectedElements.some(el =>{
                let element = this.getContainerElement(el);
                return element.model.isLocked;
            });
            isHidden = selectedElements.some(el =>{
                let element = this.getContainerElement(el);
                return element.model.isHidden;
            });
        }

        return (
            <PropertyPanelContainer>
                <AuthoringElementHeader name={name} control={
                    <FlexBox gap={10}>
                        {selectedElements.length == 1 &&
                            <IconButton small color={themeColors.ui_blue} icon="edit" onClick={stopPropagation(() => this.handleEditName(name))} />
                        }
                        <IconButton small color={isLocked ? "orangered" : themeColors.ui_blue} icon={isLocked ? "lock" : "lock_open"} onClick={stopPropagation(this.handleToggleLock(isLocked))} />
                        {canHide &&
                            <IconButton small color={isHidden ? "orangered" : themeColors.ui_blue} icon={isHidden ? "visibility_off" : "visibility"} onClick={stopPropagation(this.handleToggleVisibility(isHidden))} />
                        }
                    </FlexBox>
                } />
                {authoringCanvas.allowTransformProperties && <TransformProperties selectedElements={selectedElements} authoringCanvas={authoringCanvas} />}
                {elementType == AuthoringElementType.CONTENT && selectedElements.length == 1 &&
                    <ContentProperties element={childElement} />
                }
                {(elementType == AuthoringElementType.SHAPE || elementType == AuthoringElementType.PATH) &&
                    <ShapeProperties selectedElements={selectedElements} authoringCanvas={authoringCanvas} />
                }
                {(elementType == AuthoringElementType.SHAPE && childElement.hasText) &&
                    <TextProperties selectedElements={selectedElements} authoringCanvas={authoringCanvas} />
                }
                <StyleProperties element={selectedElements[0].childElement} selectedElements={selectedElements} authoringCanvas={authoringCanvas} />
            </PropertyPanelContainer>
        );
    }
}
