import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceUsersRequest,
    GetWorkspaceUsersResponse
} from "./types";

const getWorkspaceUsers = getCallable<GetWorkspaceUsersRequest, GetWorkspaceUsersResponse>(
    apiName,
    "getWorkspaceUsers",
    {
        ...endpoints.getWorkspaceUsers,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.uids) {
                searchParams.set("uids", JSON.stringify(request.uids));
            }

            return {
                url: `/workspaces/${request.workspaceId}/users?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });


export const callables = {
    getWorkspaceUsers,
};
