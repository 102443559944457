import { Button, Icon, IconButton } from "@material-ui/core";
import firebase from "firebase/compat/app";
import CalendarRange from "mdi-material-ui/CalendarRange";
import CloudSearch from "mdi-material-ui/CloudSearch";
import SortAlphabeticalVariant from "mdi-material-ui/SortAlphabeticalVariant";
import Update from "mdi-material-ui/Update";
import React, { Component } from "react";
import styled from "styled-components";

import {
    LibraryView,
    PresentationFilters,
    SearchThrottleWait
} from "common/constants";
import { FeatureType } from "common/features";
import { getStaticUrl } from "js/config";
import AppController from "js/core/AppController";
import { TeamFoldersController, TeamFoldersDataService } from "js/core/dataServices/TeamFoldersDataService";
import { ds } from "js/core/models/dataService";
import ShortcutPanel from "js/editor/PresentationEditor/Components/ShortCutPanel";
import { PresentationLibraryGrid } from "js/editor/PresentationLibrary/PresentationLibraryGrid";
import { PresentationLibraryList } from "js/editor/PresentationLibrary/PresentationLibraryList";
import { PresentationLibrarySideBar } from "js/editor/PresentationLibrary/PresentationLibrarySideBar";
import { PresentationLibraryViewSelect } from "js/editor/PresentationLibrary/PresentationLibraryViewSelect";
import { PresentationListHeader } from "js/editor/PresentationLibrary/PresentationListHeader";
import { app } from "js/namespaces";
import {
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap10, Gap20, Gap30, Gap5 } from "js/react/components/Gap";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { NoMatchNotice, Notice } from "js/react/components/Notice";
import Spinner from "js/react/components/Spinner";

import PresentationLibraryController, { PresentationFilterType, PresentationLibraryControllerState, PresentationLibraryPresentation } from "js/controllers/PresentationLibraryController";
import { User } from "js/core/models/user";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import {
    StyledWarningIcon,
    UIPane,
    UIPaneContents,
    UIPaneSideBar,
    UIPaneWithSideBar,
    WarningBox,
    WarningButton
} from "js/react/components/UiComponents";
import { themeColors } from "js/react/sharedStyles";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { _ } from "js/vendor";
import FetchingClickShield from "../../react/components/FetchingClickShield";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { OnMountedReporter } from "js/core/utilities/useMountedAt";

const CloseButtonContainer = styled.div`
    text-align: right;
    margin-bottom: -15px;
    margin-top: 10px;
`;

const RemoteControlBanner = styled.div`
    padding: 10px;
    text-align: center;
    background: orangered;
    color: white;
    font-size: 18px;
    width: 100%;
    font-weight: 600;
`;

const SubFolderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 108px;
    overflow-y: auto;
    margin-bottom: 10px;
    gap: 5px;
    row-gap: 5px;
`;

const SubFolder = styled.div<{ isSelected: boolean }>`
    background: ${props => props.isSelected ? "white" : "#e1e1e1"};
    padding: 10px 15px 10px 10px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        font-weight: 400;
    }

    .MuiIcon-root {
        opacity: 0.5;
    }

    &:hover {
        background: ${themeColors.selection};
    }
`;

const CreatePresentationButton = styled(Button)`
    flex-shrink: 0;
    height: 50px;
    background: ${themeColors.ui_blue} !important;
    padding: 0px 20px 0px 10px !important;

    .MuiButton-label {
        color: white;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
    }

    .MuiIcon-root {
        background: white;
        color: black;
        border-radius: 50%;
        padding: 3px;
        font-size: 17px;
        margin-right: 6px;
    }
`;

const CreatePresentationButtonIntegrator = styled(CreatePresentationButton)`
    border: 1px solid ${themeColors.ui_blue} !important;
    padding: 0 !important;
    margin-right: 14px !important;

    .MuiButton-label {
        color: #444;
        background: transparent;
        padding: 10px 20px;
    }

    .dark-mode & {
        .MuiButton-label {
            color: #ccc;
        }
    }
`;

export const Breadcrumb = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 10px;
    color: #444;
    flex-shrink: 0;

    .dark-mode & {
        color: #ccc;
    }
`;

type PresentationLibraryPaneProps = PresentationLibraryControllerState & {
    workspaceId: string;
    firebaseUser: firebase.User;
    readOnly?: boolean;
    pane?: PresentationFilterType;
    folderId?: string;
    subFolderId?: string;
    user: typeof User;
    onSelectedPresentation: (presentationId: string) => void;
    onCloseDialog: () => void;
    showCloseIcon: boolean;
    showSidebar: boolean;
    onHideSidebar: () => void;
    onSwitchType: () => void;
    sideBarWidth: number;
    onResizeSidebar: () => void;
    className: string;
    showLibraryViewSelect: boolean;
    isMyPresentationsOnly: boolean;
    onReady?: () => void;
    onDoubleClick?: ()=>void;
}

interface InnerPresentationLibraryPaneState {
    searchResults: any[];
    userFolders: any[];
    teamFolders: any[];
    draggedItems: any[];
    isEveryTeamFolderLoaded: boolean;
}

class InnerPresentationLibraryPane extends Component<PresentationLibraryPaneProps, InnerPresentationLibraryPaneState> {
    sideBarRef: React.RefObject<any>;
    scrollRef: React.RefObject<any>;

    teamFolderDS: TeamFoldersDataService;

    handleSearchThrottled: (query: string) => void;

    get sortOptions() {
        const { searchQuery } = this.props;

        const options = [{
            value: "name", label: "Name", reverse: false, icon: <SortAlphabeticalVariant />
        }, {
            value: "createdAt", label: "Recently Created", reverse: true, icon: <CalendarRange />
        }, {
            value: "modifiedAt", label: "Recently Edited", reverse: true, icon: <Update />
        }];
        if (searchQuery) {
            options.push({
                value: "relevance", label: "Relevance", reverse: false, icon: <CloudSearch />
            });
        }
        return options;
    }

    constructor(props) {
        super(props);

        this.sideBarRef = React.createRef();
        this.scrollRef = React.createRef();

        this.handleSearchThrottled = _.throttle(this.handleSearch, SearchThrottleWait, { leading: false });

        this.state = {
            draggedItems: [],
            userFolders: [],
            teamFolders: [],
            isEveryTeamFolderLoaded: false,
            searchResults: null
        };
    }

    componentDidMount() {
        ds.userFolders.on("change", this.onUserFoldersChanged);
        this.onUserFoldersChanged(ds.userFolders);

        this.initializeTeamFoldersDataService();
    }

    componentWillUnmount() {
        this.teamFolderDS.unsubscribe();
        PresentationLibraryController.setSearchQuery("");
        ds.userFolders.off("change", this.onUserFoldersChanged);
    }

    componentDidUpdate(prevProps: PresentationLibraryPaneProps) {
        const { workspaceId } = this.props;

        if (workspaceId !== prevProps.workspaceId) {
            this.initializeTeamFoldersDataService();
        }
    }

    initializeTeamFoldersDataService() {
        const { filter } = this.props;
        if (this.teamFolderDS) {
            this.teamFolderDS.unsubscribe();
        }

        this.teamFolderDS = new TeamFoldersDataService({
            onCollectionChanged: teamFolders => {
                // Remove the default folder if we aren't flagged to have access
                if (!app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)) {
                    teamFolders = teamFolders.filter(folder => !folder.isDefault);
                }

                // We need to set the filter to correct folder
                // because when returning from the editor, the filter is reset
                // to be the default folder url and not the folder we were in
                this.setState({ teamFolders, isEveryTeamFolderLoaded: true }, () => {
                    this.handleSetFilter(filter);
                });
            }
        });
    }

    onUserFoldersChanged = userFolders => {
        if (userFolders.collection) {
            userFolders = userFolders.collection;
        }
        this.setState({ userFolders });
    }

    handleSetViewType = viewType => {
        PresentationLibraryController.setViewType(viewType);
    }

    handleSearch = query => {
        PresentationLibraryController.setSearchQuery(query);
    }

    handleSort = sort => {
        PresentationLibraryController.setSort(sort);
    }

    handleSetFilter = filter => {
        const { readOnly } = this.props;

        // if we are in read-only (We're adding a slide from My Slide sectio ) mode
        // we should just update the filter state because we
        // don't want to navigate away from the current page
        if (readOnly) {
            PresentationLibraryController.setFilter(filter);
        } else {
            AppController.showLibrary({ filter });
        }
    }

    handleSelectedPresentations = async (selection: PresentationLibraryPresentation[]) => {
        const { onSelectedPresentation } = this.props;

        if (selection.length === 0) {
            return;
        }

        if (onSelectedPresentation) {
            onSelectedPresentation(selection[0].id);
            return;
        }

        if (app.isConstrained) {
            await this.handleDoubleClickedPresentation(selection[0]);
        }
    }

    handleDoubleClickedPresentation = async (presentation: PresentationLibraryPresentation) => {
        if (this.props.onDoubleClick) {
            return this.props.onDoubleClick();
        }

        if (presentation.trashedAt) {
            return ShowErrorDialog({
                title: "Can't edit presentations in the trash",
                message: "Please restore this presentation back into your library before editing."
            });
        }

        if (!presentation.permissions.write || app.isConstrained || window.roomID) {
            PresentationLibraryController.playPresentation(presentation.id);
        } else {
            AppController.showEditor({ presentationId: presentation.id });
        }
    }

    handleStartDragItems = selection => {
        this.setState({ draggedItems: selection });
    }

    handleDropDragItems = () => {
        this.setState({ draggedItems: [] });
    }

    handleSubFolderMouseUp = subFolderId => {
        const { filter } = this.props;
        const { draggedItems } = this.state;

        if (draggedItems?.length) {
            TeamFoldersController.addPresentationsToTeamSubFolder(
                draggedItems,
                filter.folderId,
                subFolderId,
                () => {
                    this.handleSetFilter({
                        type: PresentationFilters.TEAM_FOLDER,
                        folderId: filter.folderId,
                        subFolderId
                    });
                }
            );
        } else {
            this.handleSetFilter({
                type: PresentationFilters.TEAM_FOLDER,
                folderId: filter.folderId,
                subFolderId
            });
        }
    }

    handleEmptyTrash = () => {
        const { presentations, workspaceId } = this.props;

        const trashedPresentations = presentations.filter(p => p.trashedAt && p.workspaceId === workspaceId);
        if (trashedPresentations.length === 0) {
            return;
        }

        ShowConfirmationDialog({
            title: "Are you sure you want to permanently delete all of the presentations in the trash?",
            message: "You can't undo this action.",
            acceptCallback: () => {
                PresentationLibraryController.deletePresentations(trashedPresentations.map(p => p.id));
            }
        });
    }

    handleCreateNewPresentation = () => {
        const { filter } = this.props;

        AppController.showCreatePresentationDialog({
            pane: CreatePresentationDialogPaneType.BLANK_PRESENTATION,
            state: {
                libraryFilter: filter
            }
        });
    }

    render() {
        const {
            workspaceId,
            readOnly,
            onCloseDialog,
            showCloseIcon,
            showSidebar,
            onHideSidebar,
            onSwitchType,
            sideBarWidth,
            onResizeSidebar,
            className,
            showLibraryViewSelect = true,
            isMyPresentationsOnly = false,
            filter,
            isInitialized,
            sort,
            viewType,
            onReady = () => {}
        } = this.props;
        const {
            userFolders,
            teamFolders,
            isEveryTeamFolderLoaded,
            draggedItems,
        } = this.state;

        if (!isInitialized) {
            return (<UIPane>
                <Spinner />
            </UIPane>);
        }

        let subFolders = [];
        let breadcrumb;
        switch (filter.type) {
            case PresentationFilters.ALL_PRESENTATIONS:
                breadcrumb = "All Presentations";
                break;
            case PresentationFilters.RECENT:
                breadcrumb = "Recent Presentations";
                break;
            case PresentationFilters.OWNED_BY_ME:
                breadcrumb = "Created by me";
                break;
            case PresentationFilters.SHARED_WITH_ME:
                breadcrumb = "Shared With Me";
                break;
            case PresentationFilters.FOLDER:
                breadcrumb = userFolders.find(f => f.id == filter.folderId)?.get("name");
                break;
            case PresentationFilters.TEAM_FOLDER:
                const team = ds.teams.find(t => t.id == filter.folderId);
                if (team) {
                    breadcrumb = team.get("name");
                    subFolders = _.sortBy(team.get("subFolders") || [], subFolder => subFolder.name);
                    const subFolder = subFolders.find(subFolder => subFolder.id === filter.subFolderId);
                    if (subFolder) {
                        breadcrumb += " > " + subFolder.name;
                    }
                }
                break;
            case PresentationFilters.TRASH:
                breadcrumb = "Trash";
                break;
        }

        const canEditLibraryItems = !app.integrator && app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, AppController.orgId) && !readOnly;

        const TrashHeader = filter.type == PresentationFilters.TRASH ? (
            // @ts-ignore
            <FlexBox middle right fillWidth style={{ paddingRight: 40 }}>
                <StyledWarningIcon>warning</StyledWarningIcon>
                <Gap10 />
                <WarningBox>
                    Items in your trash will be permanently deleted after 30 days
                </WarningBox>
                <Gap20 />
                <WarningButton
                    // @ts-ignore
                    onClick={this.handleEmptyTrash}>
                    Empty Trash
                </WarningButton>
            </FlexBox>
        ) : null;

        return (
            // @ts-ignore
            <UIPaneWithSideBar className={className ?? ""}>
                <OnMountedReporter onMounted={onReady} />
                <UIPaneSideBar
                    // @ts-ignore
                    show={showSidebar}
                    onHide={onHideSidebar}
                    style={{ width: sideBarWidth }}
                    onResize={onResizeSidebar}
                >
                    {showLibraryViewSelect && <PresentationLibraryViewSelect libraryView={LibraryView.LIBRARY} onSwitchType={onSwitchType} isMyPresentationsOnly={isMyPresentationsOnly} />}
                    <PresentationLibrarySideBar
                        workspaceId={workspaceId}
                        filter={filter}
                        onSetFilter={this.handleSetFilter}
                        userFolders={userFolders}
                        teamFolders={teamFolders}
                        isEveryTeamFolderLoaded={isEveryTeamFolderLoaded}
                        readOnly={readOnly}
                        draggedItems={draggedItems}
                        canEditLibraryItems={canEditLibraryItems}
                    />
                </UIPaneSideBar>

                {
                    !app.isMobileOrTablet &&
                    // @ts-ignore
                    <UIPaneContents style={{
                        paddingLeft: 30,
                        paddingRight: 10,
                        paddingBottom: 0
                    }}>
                        {
                            showCloseIcon &&
                            <CloseButtonContainer>
                                <Icon onClick={onCloseDialog}>
                                    close
                                </Icon>
                            </CloseButtonContainer>
                        }

                        {
                            app.integrator &&
                            <>
                                <Gap20 />
                                <CreatePresentationButtonIntegrator
                                    id="new-presentation-button"
                                    variant="text"
                                    onClick={this.handleCreateNewPresentation}
                                >
                                    <Icon>add</Icon>Create New Presentation
                                </CreatePresentationButtonIntegrator>
                            </>
                        }
                        {window.roomID &&
                            <RemoteControlBanner>Select Presentation to Remote Play</RemoteControlBanner>
                        }
                        {/* @ts-ignore */}
                        <FlexBox left gap={20} middle style={{ height: 65 }}>
                            <Breadcrumb className="breadcrumb">{breadcrumb}</Breadcrumb>
                            {
                                filter.type != PresentationFilters.TRASH &&
                                <>
                                    <FlexSpacer />
                                    <PresentationListHeader
                                        // @ts-ignore
                                        onSearch={this.handleSearchThrottled}
                                        viewType={viewType}
                                        onSetViewType={this.handleSetViewType}
                                        sortOptions={this.sortOptions}
                                        onSetSort={this.handleSort}
                                        sort={sort}
                                        searchPlaceholder=""
                                    />
                                </>
                            }
                            {TrashHeader}
                        </FlexBox>
                        {subFolders.length > 0 &&
                            <SubFolderContainer>
                                {filter.subFolderId &&
                                    <IconButton size="small"
                                        onMouseUp={() => this.handleSubFolderMouseUp(null)}><Icon>arrow_upward</Icon></IconButton>
                                }
                                {subFolders.map(subFolder => (
                                    <SubFolder
                                        key={subFolder.id}
                                        onMouseUp={() => this.handleSubFolderMouseUp(subFolder.id)}
                                        isSelected={filter.subFolderId === subFolder.id}
                                    >
                                        <Icon>folder_open</Icon>
                                        <Gap10 />
                                        {subFolder.name}
                                        <Gap5 />
                                        <span>({subFolder.presentations?.length ?? 0})</span>
                                    </SubFolder>)
                                )}
                            </SubFolderContainer>
                        }
                        {/* @ts-ignore */}
                        <ScrollBox id="presentation_list" ref={this.scrollRef}
                            fillWidth
                            style={{ height: 0, flexGrow: 2, marginTop: 5, paddingRight: 10, position: "relative" }}
                        >
                            {this.renderItems()}
                        </ScrollBox>
                    </UIPaneContents>
                }
                {
                    app.isMobileOrTablet &&
                    // @ts-ignore
                    <UIPaneContents style={{ padding: 0 }}>
                        {
                            showCloseIcon &&
                            <CloseButtonContainer>
                                <Icon onClick={onCloseDialog}>
                                    close
                                </Icon>
                            </CloseButtonContainer>
                        }

                        {window.roomID &&
                            <RemoteControlBanner>Select Presentation to Remote Play</RemoteControlBanner>
                        }

                        {/* @ts-ignore */}
                        <FlexBox center middle>
                            {
                                filter.type != PresentationFilters.TRASH &&
                                <>
                                    <PresentationListHeader
                                        // @ts-ignore
                                        onSearch={this.handleSearchThrottled}
                                        viewType={viewType}
                                        onSetViewType={this.handleSetViewType}
                                        sortOptions={this.sortOptions}
                                        onSetSort={this.handleSort}
                                        sort={sort}
                                        searchPlaceholder=""
                                    />
                                </>
                            }
                            {TrashHeader}
                        </FlexBox>
                        {/* @ts-ignore */}
                        <FlexBox middle style={{
                            margin: 20,
                            marginTop: 0,
                            marginBottom: 0,
                        }}>
                            <Breadcrumb className="breadcrumb" style={{
                                width: "100%",
                                marginRight: 0,
                            }}>{breadcrumb}</Breadcrumb>
                        </FlexBox>
                        {/* @ts-ignore */}
                        <ScrollBox id="presentation_list" ref={this.scrollRef}
                            fillWidth
                            style={{ height: 0, flexGrow: 2, marginTop: 0, paddingRight: 10, paddingLeft: 10, position: "relative" }}
                        >
                            {this.renderItems()}
                            <Gap30 />
                        </ScrollBox>
                    </UIPaneContents>
                }
                <ShortcutPanel />
            </UIPaneWithSideBar>
        );
    }

    renderItems() {
        const { readOnly, filteredPresentations, filter, sort, viewType, searchQuery, isPerformingSearch } = this.props;

        const Content = (() => {
            if (filteredPresentations.length == 0) {
                switch (filter.type) {
                    case PresentationFilters.ALL_PRESENTATIONS:
                    case PresentationFilters.OWNED_BY_ME:
                    case PresentationFilters.RECENT:
                        //currently readOnly is set to true when viewing presentation library from CopySlidePane.
                        if (readOnly || searchQuery) {
                            return <NoMatchNotice />;
                        } else {
                            if (app.isConstrained) {
                                return (
                                    <Notice
                                        title="You have no presentations in this workspace."
                                        message="Open Beautiful.ai in your desktop browser to create one."
                                    />
                                );
                            } else {
                                return (
                                    <Notice
                                        title="Click Create Presentation to get started."
                                    />
                                );
                            }
                        }
                    case PresentationFilters.SHARED_WITH_ME:
                        return (
                            <Notice
                                title="No one has shared a presentation with you."
                                message={(
                                    <>
                                        <span>Maybe you should share one of yours? </span>
                                        <br />
                                        <span>
                                            <a target="_blank"
                                                href="https://support.beautiful.ai/hc/en-us/articles/360000771231-How-can-I-collaborate-with-someone-">Learn more</a> about sharing and collaboration.
                                        </span>
                                    </>
                                )}
                            />
                        );
                    case PresentationFilters.TRASH:
                        return (
                            <Notice
                                title="Trash is empty"
                            />
                        );
                    case PresentationFilters.FOLDER:
                        return (
                            <Notice
                                title="This folder is empty."
                                message={!app.isConstrained && "You can drag presentations into this folder to add them."}
                            />
                        );
                    case PresentationFilters.TEAM_FOLDER:
                        return (
                            <Notice
                                image={getStaticUrl(`/images/notice-banner/team-folders.png`)}
                                title="This shared Team Folder is empty."
                                message="Facilitate collaboration by creating shared Team Folders for teams or projects. Add presentations to collaborate with folder members instantly."
                            />
                        );
                    default:
                        return (
                            <Notice
                                title="No presentations found"
                            />
                        );
                }
            }

            if (viewType === "grid") {
                return (
                    <PresentationLibraryGrid
                        items={filteredPresentations}
                        readOnly={readOnly}
                        onSelectedPresentations={this.handleSelectedPresentations}
                        onDoubleClick={this.handleDoubleClickedPresentation}
                        onStartDragItems={this.handleStartDragItems}
                        onDropDragItems={this.handleDropDragItems}
                        filter={filter}
                    />
                );
            }

            return (
                <PresentationLibraryList
                    items={filteredPresentations}
                    readOnly={readOnly}
                    sort={sort}
                    onSort={this.handleSort}
                    onSelectedPresentations={this.handleSelectedPresentations}
                    onDoubleClick={this.handleDoubleClickedPresentation}
                    onStartDragItems={this.handleStartDragItems}
                    onDropDragItems={this.handleDropDragItems}
                    filter={filter}
                />
            );
        })();

        return (<>
            <FetchingClickShield backgroundColor="rgb(238 238 238)" visible={isPerformingSearch} />
            {Content}
        </>);
    }
}

export const PresentationLibraryPane =
    PresentationLibraryController.withState(
        withFirebaseUser(
            AppController.withState(InnerPresentationLibraryPane)
        )
    );
