export { default as Home } from "./Home";
export { default as General } from "./General";
export { default as Maintenance } from "./Maintenance";
export { default as Reports } from "./Reports";
export { default as QuickPlayer } from "./QuickPlayer";
export { default as Manage } from "./Manage";
export { default as Edu } from "./Edu";
export { default as Enterprise } from "./Enterprise";
export { default as ManageAI } from "./ManageAI";
export { default as ManageEmails } from "./ManageEmails";
export { default as Provisioning } from "./Provisioning";
