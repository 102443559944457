import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import type { User } from "firebase/auth";

import { Icon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { workspaces as workspacesApi } from "apis/callables";
import {
    IWorkspaceResourceFolder,
    IWorkspaceResourceFolderMetadata,
    WorkspaceResourceType,
    WorkspacePermissionObjectType,
    IWorkspacePermission,
    WorkspacePermissionSubjectType
} from "common/interfaces";
import { Breadcrumb } from "js/Components/Breadcrumb";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger from "js/core/logger";
import { ShowDialog, ShowErrorDialog, ShowInputDialog, ShowConfirmationDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { RoundIconButton, UIPane } from "js/react/components/UiComponents";
import { useIntersectionObserver } from "js/react/hooks/useIntersectionObserver";
import { SearchBarContainer, UIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import AllTeamMembersFolderSettingsDialog from "js/react/views/TeamResources/dialogs/AllTeamMembersFolderSettingsDialog";
import { IconButton } from "js/Components/IconButton";
import { Popup, PopupContent, PopupPreview } from "js/Components/Popup";
import { MenuItem } from "js/Components/Menu";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import withProgressDialog from "js/core/utilities/withProgressDialog";

//// LEGACY ////
import Api from "js/core/api";
import { ManageTeamFolderMembersDialog } from "../dialogs/TeamFolderDialogs";
////

const logger = getLogger();

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const GridContainerCSS = css`
    padding: 0px 20px;
    display: grid;
    grid-column-gap: 20px;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 50px;
`;

const HeaderContainer = styled.div`
    ${GridContainerCSS}

    font-size: 12px;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const FolderContainer = styled.div`
    ${GridContainerCSS}

    font-size: 16px;
    min-height: 60px;
    background: #fff;
    position: relative;
`;

const FolderNameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
`;

const StyledSkeleton = styled(Skeleton)`
    &&& {
        height: 34px;
    }
`;

const FolderIconButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
`;

const StyledIconButton = styled(IconButton)`
    &&& {
        transition: opacity 0.3s ease-in-out;
        opacity: ${props => props.visible ? 1 : 0};
        width: 40px;
        height: 40px;

        &:hover {
            background: rgba(0, 0, 0, 0.03);
            border-radius: 50%;
        }
    }
`;

const PopupMenuItem = styled(MenuItem)`
    &&& {
        > .MuiIcon-root {
            margin-right: 5px;
            color: #666;
        }
    }
`;

function FolderIconButton({ icon, visible, onClick }: { icon: string, visible: boolean, onClick?: () => void }) {
    return <StyledIconButton icon={icon} onClick={onClick} size={30} color="rgb(102,102,102)" visible={visible} />;
}

const Folder: React.FC<{ folder: IWorkspaceResourceFolder, ref?: React.Ref<HTMLDivElement> }> =
    withFirebaseUser(function Folder({ folder, firebaseUser }: { folder: IWorkspaceResourceFolder, firebaseUser: User }) {
        if (folder.parentFolderId) {
            return null;
        }

        const containerRef = useRef<HTMLDivElement>(null);
        const metadataLoadedAtRef = useRef<number>(0);

        const [isMouseOver, setIsMouseOver] = useState(false);
        const [fetching, setFetching] = useState(true);
        const [metadata, setMetadata] = useState<IWorkspaceResourceFolderMetadata | null>(null);
        const [permissions, setPermissions] = useState<IWorkspacePermission[]>([]);
        const isIntersecting = useIntersectionObserver(containerRef);

        const isDefault = folder.name === "All Team Members";
        const membersCount = metadata ? Object.values(metadata.counters.users).reduce((acc, curr) => acc + curr, 0) : 0;
        const presentationsCount = metadata ? metadata.counters.resources[WorkspaceResourceType.PRESENTATION] : 0;

        const canEdit =
            permissions.some(permission => permission.subjectId === firebaseUser.uid && permission.subjectType === WorkspacePermissionSubjectType.USER && permission.write) ||
            WorkspaceController.workspaceUserGroups.some(group => permissions?.some(permission => permission.subjectId === group.id && permission.subjectType === WorkspacePermissionSubjectType.USER_GROUP && permission.write));

        useEffect(() => {
            if (!isIntersecting) {
                return;
            }

            if (!metadata || folder.modifiedAt > metadataLoadedAtRef.current) {
                (async () => {
                    try {
                        const [metadata, permissions] = await Promise.all([
                            workspacesApi.getWorkspaceResourceFolderMetadata({
                                workspaceId: folder.workspaceId,
                                resourceFolderId: folder.id
                            }),
                            workspacesApi.getWorkspacePermissions({
                                workspaceId: folder.workspaceId,
                                object: {
                                    type: WorkspacePermissionObjectType.RESOURCE_FOLDER,
                                    id: folder.id
                                }
                            })
                        ]);
                        metadataLoadedAtRef.current = Date.now();
                        setMetadata(metadata);
                        setPermissions(permissions);
                        setFetching(false);
                    } catch (err) {
                        logger.error(err, `Failed to fetch data for folder ${folder.id}`);
                    }
                })();
            }
        }, [isIntersecting, folder.modifiedAt]);

        const handleMouseEnter = () => setIsMouseOver(true);
        const handleMouseLeave = () => setIsMouseOver(false);

        const handleOpenAllTeamMembersFolderSettings = () => {
            ShowDialog(AllTeamMembersFolderSettingsDialog, { folder });
        };

        const handleShareFolder = (closePopup: () => void) => async () => {
            closePopup();

            //// LEGACY ////
            try {
                const { body: legacyFolder } = await withProgressDialog({
                    action: () => Api.teams.get({ id: folder.id })
                });
                ShowDialog(ManageTeamFolderMembersDialog, {
                    folder: legacyFolder,
                    onClose: () => {
                        WorkspaceController.forceRefreshWorkspaceResourceFolders([folder.id]);
                    }
                });
            } catch (err) {
                logger.error(err, `Failed to fetch folder ${folder.id} from legacy api`);
            }
            ////
        };

        const handleRenameFolder = closePopup => async () => {
            closePopup();

            const name = await ShowInputDialog({
                title: "Rename Team Folder",
                maxChars: 50,
                value: folder.name
            });

            if (!name || name === folder.name) {
                return;
            }

            try {
                await withProgressDialog({
                    title: "Renaming folder...",
                    action: () => WorkspaceController.updateWorkspaceResourceFolder({ folderId: folder.id, name })
                });
            } catch (err) {
                logger.error(err, `Failed to rename folder ${folder.id}`);

                ShowErrorDialog({
                    title: "Failed to rename folder",
                    message: "An error occurred while renaming the folder. Please try again."
                });
            }
        };

        const handleDeleteFolder = closePopup => async () => {
            closePopup();

            const shouldDelete = await ShowConfirmationDialog({
                title: "Are you sure you want to delete this team folder?",
                message: "This action can not be undone."
            });

            if (!shouldDelete) {
                return;
            }

            try {
                await withProgressDialog({
                    title: "Deleting folder...",
                    action: () => WorkspaceController.deleteWorkspaceResourceFolder({ folderId: folder.id })
                });
            } catch (err) {
                logger.error(err, `Failed to delete folder ${folder.id}`);

                ShowErrorDialog({
                    title: "Failed to delete folder",
                    message: "An error occurred while deleting the folder. Please try again."
                });
            }
        };

        const handleDoubleClick = () => {
            if (fetching || !canEdit) {
                return;
            }

            if (isDefault) {
                handleOpenAllTeamMembersFolderSettings();
                return;
            }

            handleShareFolder(() => { })();
        };

        return (
            <FolderContainer
                ref={containerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onDoubleClick={handleDoubleClick}
                data-test-id={`team-folder-container-${folder.id}`}
                data-test-name={folder.name}
            >
                <FolderNameContainer data-test-id="team-folder-name-container">
                    <Icon>{isDefault ? "apartment" : "folder_open"}</Icon>
                    {folder.name}
                </FolderNameContainer>
                {fetching ? <StyledSkeleton /> : <div data-test-id="team-folder-members-count">{membersCount} {membersCount === 1 ? "person" : "people"}</div>}
                {fetching ? <StyledSkeleton /> : <div data-test-id="team-folder-presentations-count">{presentationsCount} presentation{presentationsCount === 1 ? "" : "s"}</div>}
                {!fetching && canEdit && <FolderIconButtonContainer data-test-id="team-folder-icon-button-container">
                    {isDefault &&
                        <FolderIconButton icon="settings" visible={isMouseOver} onClick={handleOpenAllTeamMembersFolderSettings} />
                    }
                    {!isDefault &&
                        <Popup>
                            <PopupPreview>
                                <FolderIconButton icon="more_vert" visible={isMouseOver} data-test-id="team-folder-more-vert-button" />
                            </PopupPreview>
                            <PopupContent>
                                {(closePopup: () => void) => (
                                    <>
                                        <PopupMenuItem divider onClick={handleShareFolder(closePopup)}>
                                            <Icon>edit</Icon>
                                            Share Folder...
                                        </PopupMenuItem>
                                        <PopupMenuItem divider onClick={handleRenameFolder(closePopup)}>
                                            <Icon>label</Icon>
                                            Rename...
                                        </PopupMenuItem>
                                        <PopupMenuItem onClick={handleDeleteFolder(closePopup)}>
                                            <Icon>delete</Icon>
                                            Delete Folder
                                        </PopupMenuItem>
                                    </>
                                )}
                            </PopupContent>
                        </Popup>
                    }
                </FolderIconButtonContainer>}
            </FolderContainer>
        );
    });

function TeamFoldersPane({ resourceFolders }: WorkspaceControllerState) {
    const handleCreateFolder = async () => {
        const name = await ShowInputDialog({
            title: "Create Team Folder",
            maxChars: 50
        });

        if (!name) {
            if (name === "") {
                // @ts-ignore
                ShowWarningDialog({
                    title: "Unable to create folder",
                    message: "Please enter a folder name.",
                });
            }
            return;
        }

        try {
            await withProgressDialog({
                title: "Creating folder...",
                action: async () => {
                    const { id } = await WorkspaceController.createWorkspaceResourceFolder({ name, resourcesType: WorkspaceResourceType.PRESENTATION });

                    //// LEGACY ////
                    const { body: legacyFolder } = await Api.teams.get({ id });
                    ShowDialog(ManageTeamFolderMembersDialog, {
                        folder: legacyFolder,
                        onClose: () => {
                            WorkspaceController.forceRefreshWorkspaceResourceFolders([id]);
                        }
                    });
                    ////
                }
            });
        } catch (err) {
            logger.error(err, `Failed to create team folder`);

            ShowErrorDialog({
                title: "Failed to create team folder",
                message: "An error occurred while creating the folder. Please try again."
            });
        }
    };

    const allTeamMembersFolder = resourceFolders.find(folder => folder.name === "All Team Members");
    const teamFolders = resourceFolders.filter(folder => folder.name !== "All Team Members").sort((a, b) => a.name.localeCompare(b.name));

    return (
        <UIPane>
            <SearchBarContainer className="search-container">
                <Breadcrumb>Team Folders</Breadcrumb>
                <RoundIconButton onClick={handleCreateFolder} data-test-id="team-folders-create-folder-button"><Icon>add</Icon></RoundIconButton>
            </SearchBarContainer>
            <UIPaneResultsContainer>
                <Container>
                    <HeaderContainer>
                        <div>Folder</div>
                        <div>Members</div>
                        <div>Presentations</div>
                    </HeaderContainer>
                    {[allTeamMembersFolder, ...teamFolders]
                        .map(folder => (
                            <Folder key={folder.id} folder={folder} />
                        ))}
                </Container>
            </UIPaneResultsContainer>
        </UIPane>
    );
}

export default WorkspaceController.withInitializedState(TeamFoldersPane);
