import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button, Icon } from "@material-ui/core";

import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { Theme } from "js/core/models/theme";
import { PREDEFINED_PALETTES } from "common/constants";
import { UIController } from "js/editor/dialogs/UIController";
import AppController from "js/core/AppController";

import { BeautifulDialog, DialogContent, ShowConfirmationDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";

import "css/style-guide.scss";
import { ThemeThumbnailList } from "js/editor/ThemeEditor/components/ThemeThumbnailList";
import { BlueButton } from "js/react/components/UiComponents";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { FeatureType } from "common/features";

import ThemeEditor from "./ThemeEditor";

const Subtitle = styled.div`
  margin-top: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.5px;
  color: #222222;
`;

export class ChooseThemeDialog extends Component {
    static defaultProps = {
        title: "Select Theme",
        subtitle: null,
        selectButtonLabel: "Select Theme",
        preventClose: false,
        hideUserThemes: false,
        hideBuiltInThemes: false,
        onlyTeamThemes: false,
        showCancelButton: true,
        closeDialogOnThemeChoose: true,
        callback: null
    }

    state = {
        selectedTheme: null,
        teamThemes: [],
        userThemes: [],
        pptTheme: null
    }

    componentDidMount() {
        this.loadThemes().then(() => {
            const { hideBuiltInThemes, hideUserThemes } = this.props;
            const { teamThemes, userThemes, pptTheme } = this.state;
            // Auto select ppt theme if there are no other options
            if (
                pptTheme &&
                hideBuiltInThemes &&
                !teamThemes.length &&
                (
                    !userThemes.length ||
                    hideUserThemes
                )
            ) {
                this.handleChooseTheme(pptTheme);
            }
        });
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    async loadThemes() {
        const { pptThemeModel } = this.props;

        const teamThemes = ds.sharedThemes.getThemesInWorkspace(UIController.getOrganizationId());
        const userThemes = ds.userThemes.getThemesInWorkspace(UIController.getOrganizationId());
        const pptTheme = pptThemeModel && new Theme(
            pptThemeModel,
            {
                disconnected: true,
                autoSync: false
            }
        );
        return new Promise(resolve => this.setState(
            {
                teamThemes,
                userThemes,
                pptTheme,
            },
            resolve
        ));
    }

    handleSelectTheme = selectedTheme => {
        this.setState({ selectedTheme });
    }

    handleChooseTheme = theme => {
        this.handleSelectTheme();

        const { callback, closeDialog, closeDialogOnThemeChoose } = this.props;

        if (!theme.has("colors")) {
            const defaultThemePalette = _.find(PREDEFINED_PALETTES, { id: theme.get("palette_name") || "colorful" });
            theme.set("colors", defaultThemePalette.colors);
        }

        if (callback) {
            callback(theme);
        }

        if (closeDialogOnThemeChoose) {
            closeDialog();
        }
    }

    handleEditCurrentTheme = async () => {
        const { closeDialog } = this.props;
        closeDialog();

        if (window.isPPTAddin) {
            const themeUpdated = await AppController.showThemeEditor(PresentationEditorController.presentation);
            if (themeUpdated) {
                await PresentationEditorController.updateTheme();
            }
            return;
        }

        AppController.navigateToThemeEditor(PresentationEditorController.presentation.id);
    }

    handleDeleteTheme = theme => {
        ShowConfirmationDialog({
            title: "Are you sure you want to delete this theme?",
            message: "No existing presentations will be affected by this change but you won't be able to use this theme in any future presentations."
        }).then(confirmed => {
            if (confirmed) {
                theme.collection.remove([theme]);
                theme.destroy();
            }
        });
    }

    render() {
        const {
            selectedTheme,
            pptTheme
        } = this.state;
        const {
            closeDialog,
            title,
            subtitle,
            selectButtonLabel,
            preventClose,
            onlyTeamThemes,
            showCancelButton,
            styles,
            showEditCurrentTheme = true
        } = this.props;

        // If we're creating a theme, we won't have a presentation
        const isTeamTheme = PresentationEditorController.presentation?.has("sharedThemeId");
        const orgId = PresentationEditorController.presentation?.get("orgId");
        const canEditLibrary = orgId ? AppController.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, orgId) : false;

        return (
            <BeautifulDialog
                maxWidth="lg"
                preventClose={preventClose}
                closeDialog={closeDialog}
            >
                <DialogTitle>
                    <FlexBox left>{title}
                        <FlexSpacer/>
                        {showEditCurrentTheme && (!isTeamTheme || canEditLibrary) &&
                        <Button onClick={this.handleEditCurrentTheme}>
                            <Icon color="primary">edit</Icon>
                            Edit Current Theme...
                        </Button>
                        }
                    </FlexBox>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </DialogTitle>
                <DialogContent style={{ ...styles, background: "#f1f1f1" }}>
                    <Gap10/>
                    <ThemeThumbnailList
                        requireTeamTheme={onlyTeamThemes}
                        pptTheme={pptTheme}
                        selectedTheme={selectedTheme}
                        onClick={this.handleSelectTheme}
                        onDoubleClick={this.handleChooseTheme}
                    />
                </DialogContent>
                <DialogActions>
                    <FlexBox fillWidth>
                        <FlexSpacer />
                        {showCancelButton && <Button onClick={closeDialog}>Cancel</Button>}
                        <Gap20/>
                        <BlueButton
                            color="primary"
                            onClick={() => this.handleChooseTheme(selectedTheme)}
                            disabled={!selectedTheme}
                        >
                            {selectButtonLabel}
                        </BlueButton>
                    </FlexBox>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
