import React from "react";
import { GlobalStateController } from "bai-react-global-state";
import { v4 as uuid } from "uuid";

import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { trackActivity } from "js/core/utilities/utilities";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog, ShowUpgradeDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import TeamSlideDialog from "js/react/views/TeamResources/dialogs/TeamSlideDialog";
import LockedSharedSlideDialog from "js/react/views/TeamResources/dialogs/LockedSharedSlideDialog";
import SwitchTemplateDialog from "js/react/views/SwitchTemplate/SwitchTemplateDialog";
import { app } from "js/namespaces";
import { appVersion, isPPTAddin } from "js/config";
import { FeatureType } from "common/features";
import { ds } from "js/core/models/dataService";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ExportToPPT } from "js/exporter/exportToPPT";
import { exportToGoogleSlides } from "js/exporter/exportToGoogleSlides";
import { getCanvasBundle } from "js/canvas";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { linkedSlides } from "js/core/utilities/conversions";
import { CreateSharedSlideDialog } from "js/react/views/UserOptions/dialogs/CreateSharedSlideDialog";
import { getPricingPageUrl } from "js/core/utilities/pricing";

export const CanvasExportType = {
    JPEG: "jpeg",
    PPTX: "pptx",
    GOOGLE: "google"
};

export class FailedToRenderCanvasError extends Error { }

export class CanvasController extends GlobalStateController {
    constructor(initialState, collaborationSlidesLockService) {
        super({
            ...initialState,
            selectionLayerController: null,
            isCanvasRendered: false,
            isCanvasGenerating: false,
            isCanvasTransitioning: false,
            isCanvasAnimating: false,
            isCanvasInErrorState: false,
            disableUserSelect: false,
            canvasRenderKey: null,
            wrapperRef: React.createRef()
        });
        this._state.canvasController = this;

        this.renderCanvasPromise = null;
        this.collaborationSlidesLockService = collaborationSlidesLockService;

        this._canvasScale = null;
        this._maxCanvasScale = null;

        this._stateDidUpdateCallbacks = [];

        this._canvasBundle = null;

        this.instanceId = uuid();
    }

    get canvas() {
        return this._state.canvas;
    }

    get maxCanvasScale() {
        return this._maxCanvasScale;
    }

    set maxCanvasScale(maxCanvasScale) {
        this._maxCanvasScale = maxCanvasScale;

        if (this.canvas) {
            this.canvas.maxCanvasScale = maxCanvasScale;
        }
    }

    get canvasScale() {
        return this._canvasScale;
    }

    set canvasScale(canvasScale) {
        this._canvasScale = canvasScale;

        if (!this.canvas) {
            // Not rendered/initialized yet
            return;
        }

        if (this.canvas.canvasScale === canvasScale) {
            // Already has correct scale, skip
            return;
        }

        this.canvas.canvasScale = canvasScale;
        if (this.isCanvasRendered && !this.isCanvasGenerating) {
            this.canvas.refreshRender();
        }
    }

    get slide() {
        return this._state.slide;
    }

    get slideId() {
        return this._state.slide.id;
    }

    get isTemplateObsolete() {
        return !!this.canvas?.slideTemplate?.constructor?.updateTemplateId;
    }

    get selectionLayerController() {
        return this._state.selectionLayerController;
    }

    set selectionLayerController(selectionLayerController) {
        // Set silently
        this._state.selectionLayerController = selectionLayerController;

        if (this.canvas) {
            this.canvas.selectionLayerController = selectionLayerController;
        }
    }

    get primaryElement() {
        return this.canvas?.layouter?.canvasElement?.elements?.primary ?? null;
    }

    get canvasScreenBounds() {
        const { canvas } = this._state;
        if (!canvas) {
            return null;
        }

        return geom.Rect.FromBoundingClientRect(canvas.$el[0].getBoundingClientRect());
    }

    get canvasBundle() {
        return this._canvasBundle;
    }

    get isCanvasGenerating() {
        return this._state.isCanvasGenerating;
    }

    get isCanvasRendered() {
        return this._state.isCanvasRendered;
    }

    _stateDidUpdate(prevState) {
        this._stateDidUpdateCallbacks.forEach(callback => callback(prevState, this._state));
    }

    onStateDidUpdate(callback) {
        this._stateDidUpdateCallbacks.push(callback);
    }

    offStateDidUpdate(callback) {
        this._stateDidUpdateCallbacks.remove(callback);
    }

    async setDisableUserSelect(disableUserSelect) {
        const { disableUserSelect: prevDisableUserSelect } = this._state;
        if (prevDisableUserSelect === disableUserSelect) {
            return;
        }

        await this._updateState({ disableUserSelect });
    }

    async reportCanvasState({ isGenerating, isTransitioning, isRendered, isAnimating, renderKey, isInErrorState }) {
        const stateUpdates = {};

        if (typeof isGenerating === "boolean" && this._state.isCanvasGenerating !== isGenerating) {
            stateUpdates.isCanvasGenerating = isGenerating;
        }
        if (typeof isTransitioning === "boolean" && this._state.isCanvasTransitioning !== isTransitioning) {
            stateUpdates.isCanvasTransitioning = isTransitioning;
        }
        if (typeof isAnimating === "boolean" && this._state.isCanvasAnimating !== isAnimating) {
            stateUpdates.isCanvasAnimating = isAnimating;
        }
        if (typeof renderKey === "string" && this._state.canvasRenderKey !== renderKey) {
            stateUpdates.canvasRenderKey = renderKey;
        }
        if (typeof isRendered === "boolean") {
            // Will always refresh when isRendered is passed
            stateUpdates.isCanvasRendered = isRendered;
        }
        if (typeof isInErrorState === "boolean" && this._state.isCanvasInErrorState !== isInErrorState) {
            stateUpdates.isCanvasInErrorState = isInErrorState;
        }

        if (Object.keys(stateUpdates).length > 0) {
            await this._updateState(stateUpdates);
        }
    }

    async renderCanvas(forceSlideVersion = null, reloadStylesCache = false) {
        const { slide, canvasWidth, canvasHeight, isEditable } = this._state;

        if (!this.renderCanvasPromise) {
            this.renderCanvasPromise = (async () => {
                this._canvasBundle = await getCanvasBundle(forceSlideVersion ?? appVersion);

                // create the slide canvas
                const canvas = new this._canvasBundle.SlideCanvas({
                    dataModel: slide,
                    canvasWidth,
                    canvasHeight,
                    editable: true
                });
                canvas.canvasController = this;
                canvas.isEditable = !!isEditable;

                await this._updateState({ canvas, canvasWidth, canvasHeight, isCanvasRendered: false });

                if (this.canvasScale) {
                    canvas.canvasScale = this.canvasScale;
                }
                if (this.maxCanvasScale) {
                    canvas.maxCanvasScale = this.maxCanvasScale;
                }
                if (this.selectionLayerController) {
                    canvas.selectionLayerController = this.selectionLayerController;
                }

                await canvas.renderSlide(reloadStylesCache).catch(err => {
                    throw new FailedToRenderCanvasError(err);
                });

                if (this.isTemplateObsolete) {
                    if (canvas.slideTemplate.constructor.updateMigration) {
                        // Migrate model
                        canvas.slideTemplate.constructor.updateMigration(canvas);
                    }

                    // New template id
                    canvas.model.template_id = canvas.slideTemplate.constructor.updateTemplateId;

                    await this.reloadCanvas(null, false, false);

                    return this.canvas;
                }

                return canvas;
            })();
        }

        return await this.renderCanvasPromise;
    }

    refreshRender() {
        this.canvas.layouter.refreshRender();
    }

    async setEditable(isEditable) {
        await this._updateState({ isEditable });

        if (!this.renderCanvasPromise) {
            return;
        }
        await this.renderCanvasPromise;

        this.canvas.isEditable = isEditable;

        await this.canvas.layouter?.generationPromiseChain;

        this.canvas.layouter?.refreshRender();
    }

    async reloadCanvas(forceSlideVersion = null, reloadStylesCache = false, waitForRenderCanvasPromise = true) {
        if (this.renderCanvasPromise && waitForRenderCanvasPromise) {
            await this.renderCanvasPromise.catch(() => { }); // Ignore failed render
        }

        const wasCurrentCanvas = this.canvas.isCurrentCanvas;
        if (wasCurrentCanvas) {
            await this.removeAsCurrentCanvas();
        }

        const previousCanvas = this.canvas;

        this.renderCanvasPromise = null;

        try {
            await this.renderCanvas(forceSlideVersion, reloadStylesCache);
        } finally {
            previousCanvas.remove();

            if (wasCurrentCanvas) {
                await this.setAsCurrentCanvas();
            }
        }
    }

    async setAsCurrentCanvas(canEditSharedSlide = false) {
        // Ensure canvas is rendered, ignore rendering errors
        await this.renderCanvas().catch(() => { });

        const { canvas, slide } = this._state;

        if (!slide.isLocked() || canEditSharedSlide) {
            app.currentCanvas = canvas;

            canvas.setAsCurrentCanvas();

            await canvas.prepareToShowElements();
        }
    }

    setOpacity(opacity) {
        return this._updateState({ opacity });
    }

    removeAsCurrentCanvas(awaitFinishedEditing = true) {
        const { canvas } = this._state;
        if (!canvas) {
            return;
        }

        if (app.currentCanvas === canvas) {
            app.currentCanvas = null;
        }

        return canvas.removeAsCurrentCanvas(awaitFinishedEditing);
    }

    /*
       Locks slide for collaborators for lockTimeSeconds
     */
    lockSlideForCollaborators(lockTimeSeconds = 5) {
        if (!this.collaborationSlidesLockService || this.freezeCollaboratorsLock) {
            return;
        }
        this.collaborationSlidesLockService.lock(this.slideId, lockTimeSeconds);
    }

    /*
       Explicitly unlocks slide for collaborators
     */
    unlockSlideForCollaborators() {
        if (!this.collaborationSlidesLockService || this.freezeCollaboratorsLock) {
            return;
        }
        this.collaborationSlidesLockService.unlock(this.slideId);
    }

    /*
      Is slide locked by user (locked for other collaborators)
     */
    isLockedForCollaborators() {
        if (!this.collaborationSlidesLockService) {
            return false;
        }
        const lockState = this.collaborationSlidesLockService.getLockState(this.slideId);
        return !!lockState?.isLockedByMe;
    }

    getTemplate() {
        const { canvas } = this._state;
        return canvas.getTemplate();
    }

    getTemplateName() {
        const { canvas } = this._state;

        const template = this.getTemplate();

        if (canvas.bundleVersion < appVersion) {
            return `${template.title} (v${canvas.bundleVersion})`;
        } else {
            return template.title;
        }
    }

    async editLibrarySlide() {
        const { presentation, slide } = this._state;

        if (isPPTAddin) {
            ShowWarningDialog({
                title: "Sorry, this shared slide cannot be edited.",
                message: "Shared slides cannot be edited from PowerPoint."
            });
            return;
        }

        if (app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, presentation.getWorkspaceId()) && slide.isLibrarySlideInCurrentUserOrg()) {
            if (await ShowConfirmationDialog({
                title: "This shared slide cannot be edited directly within a presentation.",
                message: <><p>As a librarian in your team, you have permission to edit shared slides.</p><p>Any changes made to this shared slide will automatically be updated in all presentations that use it.</p></>,
                okButtonLabel: "Edit Shared Slide..."
            })) {
                ShowDialog(TeamSlideDialog, {
                    libraryItemId: slide.get("libraryItemId")
                });
            }
        } else if (slide.isLibrarySlideInCurrentUserOrg()) {
            // if the user is a member and the team slide is in their org, let them know they need to contact an owner or librarian
            ShowDialog(LockedSharedSlideDialog, {
                organizationId: presentation.get("orgId")
            });
        } else {
            // otherwise let them know they cant
            ShowErrorDialog({
                title: "Sorry, this shared slide is not editable by you.",
                message: "This shared slide is not in your workspace and you don't have permissions to edit it."
            });
        }
    }

    switchTemplate = source => {
        const { canvas, slide } = this._state;

        if (slide.isLibrarySlide()) {
            this.editLibrarySlide();
        } else {
            this.lockSlideForCollaborators(30);

            PresentationEditorController.setPopupState(true);
            trackActivity("Slide", "ShowSwitchTemplate", null, null, { source }, { audit: false });
            ShowDialog(SwitchTemplateDialog, {
                canvas,
                onClose: () => {
                    PresentationEditorController.setPopupState(false);
                    this.unlockSlideForCollaborators();
                }
            });
        }
    }

    convertToClassic = async () => {
        const { presentation, canvas } = this._state;

        if (app.user.features.isFeatureEnabled(FeatureType.CONVERT_TO_CLASSIC, presentation.getWorkspaceId())) {
            this.lockSlideForCollaborators(10);

            await canvas.convertToClassic();
            await PresentationEditorController.setSelectedPropertyPanelTab("element");

            this.unlockSlideForCollaborators();
        } else {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "ConvertToAuthoring", ...presentation.getAnalytics() },
                workspaceId: presentation.getWorkspaceId()
            });
        }
    }

    exportCanvas = ({ type, slide }) => {
        const { canvas, presentation } = this._state;
        const workspaceId = presentation.getWorkspaceId();

        switch (type) {
            case CanvasExportType.JPEG:
                this.canvas.dataModel.exportSlideToJpeg();
                break;
            case CanvasExportType.PPTX:
                if (app.user.features.isFeatureEnabled(FeatureType.DESKTOP_APP, workspaceId)) {
                    const exporter = new ExportToPPT();
                    exporter.export({ slideCanvases: [canvas], includeSkippedSlides: true, slide });
                    trackActivity("Presentation", "SlideExport", null, null, { type: "pptx" }, { audit: true });
                } else {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                        analytics: { cta: "exportToPPT", ...presentation.getAnalytics() },
                        workspaceId,
                    });
                }
                break;
            case CanvasExportType.GOOGLE:
                if (app.user.features.isFeatureEnabled(FeatureType.DESKTOP_APP, workspaceId)) {
                    exportToGoogleSlides({ slideCanvases: [canvas], includeSkippedSlides: true, slide });
                } else {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                        analytics: { cta: "ExportToGoogleSlides", ...presentation.getAnalytics() },
                        workspaceId,
                    });
                }
                break;
        }
    }

    convertToSharedSlide = async () => {
        const { slide, presentation } = this._state;

        const canEditLibraryItems = app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, presentation.getWorkspaceId());

        if (!canEditLibraryItems) {
            const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, presentation.getWorkspaceId());
            const currentPlan = userIsBasicInWorkspace ? "basic" : app.user.analyticsPersonalPlan;
            window.open(getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
            return;
        }

        if (slide.isLibrarySlide()) {
            ShowWarningDialog({
                title: "Failed to add slide to library",
                message: "This slide is already part of another library",
            });
            return;
        }

        // check for linked data which may change the dialogs that
        // are shown for the user
        const containsLinkedData = linkedSlides.detect(ds.selection.slide);
        const onBeforeSaveSlide = containsLinkedData
            ? slide => {
                linkedSlides.remove(slide);
                slide.commit();
            }
            : null;

        // handles displaying the Share Dialog - possibly may be called
        // immediately or after showing a warning about linked slides
        const showShareSlideDialog = () => {
            ShowDialog(CreateSharedSlideDialog, {
                slide,
                presentation: slide.presentation,
                onBeforeSaveSlide
            });
        };

        // since there's linked data, show a dialog warning that
        // the linked slides will be unlinked
        if (containsLinkedData) {
            return ShowConfirmationDialog({
                title: "This slide is linked to other parts of your deck.",
                message: "Sharing it with your team will remove those links. Would you like to continue?",
                acceptCallback: showShareSlideDialog,
                cancelCallback: () => { }
            });
        }

        // there's nothing to warn about so immediately
        // display the share dialog
        showShareSlideDialog();
    }

    viewSharedSlideProperties = () => {
        const { slide } = this._state;

        ShowDialog(TeamSlideDialog, {
            libraryItemId: slide.get("libraryItemId")
        });
    }

    dispose() {
        this.canvas?.remove();
    }
}
