import React, { Component } from "react";

import { AssetType, AuthoringBlockType, PaletteColorType, PositionType, ResizeDirection, TextStyleType } from "../../../../../common/constants";

import { ImageOption } from "../../../../Components/ImageOptionList";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { StaticImage } from "../../../../Components/StaticImage";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { ShowAssetDialog } from "../../../../react/views/AddAssets/ShowAssetDialog";

import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { PropertyPanelButton } from "../../../../EditorComponents/PropertyPanelButton";
import { defaultDragResizeProps, DragHandleStyle } from "../../../../editor/PresentationEditor/DragElementManager";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ElementUIButton } from "../EditorComponents/ElementUIButton";
import { BaseElementSelection } from "../ElementSelections/BaseElementSelection";
import { MediaPropertyPanel } from "./MediaUI";
import { TextFrameBoxPropertyPanel, TextFrameBoxSelection } from "./TextFrameBoxUI";

export class PresentationTitlePropertyPanel extends Component {
    handleShowPresenters = event => {
        const { element } = this.props;

        if (event.target.checked) {
            if (element.model.presenters == null || element.model.presenters.items == null || element.model.presenters.items.length == 0) {
                element.model.presenters = {
                    items: [{ title: "" }]
                };
            }
            element.model.showPresenters = true;
        } else {
            element.model.showPresenters = false;
            element.model.presenters = null;
        }
        element.canvas.updateCanvasModel(true);
    }

    handleChangeImagePosition = async position => {
        const { element } = this.props;

        element.model.image.position = position;
        switch (position) {
            case PositionType.LEFT:
            case PositionType.RIGHT:
                element.model.image.traySize = 400;
                break;
            case PositionType.TOP:
            case PositionType.BOTTOM:
                element.model.image.traySize = 360;
                break;
        }

        await element.canvas.updateCanvasModel(true);
        element.canvas.selectionLayerController.setSelectedElements([element.image]);
    }

    handleChangeDecorationType = decoration => {
        const { element } = this.props;
        let update = { decoration };
        if (decoration === "underline" || decoration === "block") {
            update.backdropStyle = "none";
            update.backdropColor = null;
            update.backdropOpacity = null;
            update.backdropPadding = null;
            update.backdropCornerRadius = null;
        }

        element.updateModel(update);
    }

    render() {
        const { element } = this.props;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <WithLabel label="Accent Color" toolTip={
                        <>
                            <p>The <strong>accent color</strong> is used for:</p>
                            <ul>
                                <li>Any title decorations defined by the theme.</li>
                                <li>The title label text block if present.</li>
                            </ul>
                            <p><strong>Note:</strong> In some cases, the accent color may not be visible when none of those conditions are present.</p>
                        </>
                    }>
                        <ColorPicker value={element.accentColor} canvas={element.canvas}
                            onChange={value => element.updateModel({ accentColor: value })}
                            showBackgroundColors
                        />
                    </WithLabel>

                    <WithLabel label="Title Decoration">
                        <ImagePopup value={element.titleDecoration}
                            cols={3} size={120}
                            onChange={this.handleChangeDecorationType}
                        >
                            <ImageOption value="none" label="Plain" url="/images/template-icons/title.svg" />
                            <ImageOption value="left_border" label="Left Bar" url="/images/template-icons/title_bar_left.svg" />
                            <ImageOption value="top_border" label="Top Bar" url="/images/template-icons/title_bar_top.svg" />
                            <ImageOption value="frame" label="Border" url="/images/template-icons/title_border.svg" />
                            <ImageOption value="block" label="Block" url="/images/template-icons/title_block.svg" />
                            <ImageOption value="underline" label="Underline" url="/images/template-icons/title_underline.svg" />
                        </ImagePopup>
                    </WithLabel>

                    <WithLabel label="Show Footer">
                        <ToggleSwitch value={element.showFooter}
                            onChange={value => element.updateModel({ showFooter: value })}
                        />
                    </WithLabel>
                </PropertySection>

                <TextFrameBoxPropertyPanel
                    title="Title Text"
                    element={element.textFrame}
                    showDeleteButton={false}
                    showInsetWhenNotOverImage={false}
                />

                {element.showImage && element.image && (
                    <MediaPropertyPanel element={element.image} showAnimationEffect onDelete={() => {
                        element.updateModel({
                            image: null,
                            backdropStyle: null,
                            backdropColor: null,
                            backdropOpacity: null,
                            backdropPadding: null,
                            shadow: null,
                        }, { refreshStyles: true });
                    }}>
                        <WithLabel label="Image Position" flex>
                            <ImagePopup value={element.model.image.position}
                                size={100}
                                onChange={value => this.handleChangeImagePosition(value)}
                            >
                                <ImageOption value="background" label="Background">
                                    <StaticImage src="/images/ui/trays/background_full.png" />
                                </ImageOption>
                                <ImageOption value="left" label="left">
                                    <StaticImage src="/images/ui/trays/left_tray_full.png" />
                                </ImageOption>
                                <ImageOption value="right" label="right">
                                    <StaticImage src="/images/ui/trays/right_tray_full.png" />
                                </ImageOption>
                                <ImageOption value="top" label="top">
                                    <StaticImage src="/images/ui/trays/top_tray_full.png" />
                                </ImageOption>
                                <ImageOption value="bottom" label="bottom">
                                    <StaticImage src="/images/ui/trays/bottom_tray_full.png" />
                                </ImageOption>
                            </ImagePopup>
                        </WithLabel>
                    </MediaPropertyPanel>
                )}

                {!element.showImage &&
                    <PropertyPanelButton icon="add_a_photo" title="Add Image..." description="Add an image to the background or side of the title slide."
                        onClick={async () => {
                            let asset = await ShowAssetDialog();
                            element.model.image = asset;
                            element.model.backdropStyle = "white_box";
                            element.model.backdropColor = PaletteColorType.BACKGROUND_LIGHT;
                            element.model.backdropOpacity = 1;
                            element.model.backdropPadding = 30;
                            element.model.backdropCornerRadius = 0;
                            element.model.image.position = "background";
                            element.saveModel(true);
                        }}
                    />
                }

                {!element.showPresenters &&
                    <PropertyPanelButton icon="add_circle" title="Add Presenter Names" description="List the names and roles of the presenters for this presentation."
                        onClick={async () => {
                            if (element.model.presenters == null || element.model.presenters.items == null || element.model.presenters.items.length == 0) {
                                element.model.presenters = {
                                    textStyle: element.showImage ? "white_box" : null,
                                    items: [{ title: "" }]
                                };
                            }
                            element.model.showPresenters = true;
                            await element.saveModel();
                            element.canvas.selectionLayerController.selectTextElementBlock(element.presenters.itemElements[0].text);
                        }}
                    />
                }

                {!element.showLogos &&
                    <PropertyPanelButton icon="add_circle" title="Add Logos..." description="Add one or more logos to the title slide."
                        onClick={async () => {
                            const assetModel = await ShowAssetDialog(AssetType.LOGO);
                            if (!assetModel) {
                                return;
                            }

                            if (!element.model.logos) {
                                element.model.logos = {
                                    position: PositionType.TOP_LEFT
                                };
                            }
                            if (!element.model.logos.items) {
                                element.model.logos.items = [];
                            }

                            element.model.logos.items.push({
                                ...assetModel,
                                showBackdrop: true
                            });

                            await element.canvas.updateCanvasModel(true);
                            element.canvas.selectionLayerController.setSelectedElements([element.logos]);
                        }} />
                }

            </PropertyPanelContainer>
        );
    }
}

export class PresentationTitleTextSelection extends TextFrameBoxSelection {
    get canResize() {
        return true;
    }

    handleAddLabel = async () => {
        const { element, selectionLayerController } = this.props;

        const block = element.text.addBlock({
            type: AuthoringBlockType.TEXT,
            index: 0,
            textStyle: TextStyleType.LABEL
        });

        await element.canvas.refreshCanvas();
        await selectionLayerController.selectTextElementBlock(element.text, block.id);
    }

    handleAddSubHeading = async () => {
        const { element, selectionLayerController } = this.props;

        const block = element.text.addBlock({
            type: AuthoringBlockType.TEXT,
            textStyle: TextStyleType.BODY
        });

        await element.canvas.refreshCanvas();
        await selectionLayerController.selectTextElementBlock(element.text, block.id);
    }

    renderCustomChildren() {
        const { element } = this.props;

        const labelBlock = element.text?.getBlockByTextStyle(TextStyleType.LABEL);
        const descriptionBlock = element.text?.getBlockByTextStyle(TextStyleType.BODY);

        return (
            <>
                {super.renderCustomChildren()}

                {element.text && !labelBlock &&
                    <ElementUIButton
                        style={{ top: -20, left: 20 }}
                        onClick={this.handleAddLabel}
                    >
                        Add Label
                    </ElementUIButton>
                }
                {element.text && !descriptionBlock &&
                    <ElementUIButton
                        style={{ bottom: -20, left: 20 }}
                        onClick={this.handleAddSubHeading}
                    >
                        Add Sub Heading
                    </ElementUIButton>
                }
            </>
        );
    }
}

export class PresentationTitleImageSelection extends BaseElementSelection {
    get canResize() {
        return true;
    }

    get dragResizeProps() {
        const { element } = this.props;

        const resizeDirections = [];
        switch (element.parentElement.imagePosition) {
            case "left":
                resizeDirections.push(ResizeDirection.RIGHT);
                break;
            case "right":
                resizeDirections.push(ResizeDirection.LEFT);
                break;
            case "top":
                resizeDirections.push(ResizeDirection.BOTTOM);
                break;
            case "bottom":
                resizeDirections.push(ResizeDirection.TOP);
                break;
        }

        const minSize = 50;
        const maxSize = 640;

        let initialSize;
        return {
            ...defaultDragResizeProps,
            resizeDirections,
            handleProps: {
                style: DragHandleStyle.GRABBER,
            },
            onDragStart: async () => {
                initialSize = element.parentElement.imageTraySize;
            },
            onDrag: async ({ dragOffset, resizeDirection }) => {
                if (resizeDirection === ResizeDirection.LEFT) {
                    element.parentElement.imageTraySize = Math.clamp(initialSize - dragOffset.x, minSize, maxSize);
                } else if (resizeDirection === ResizeDirection.RIGHT) {
                    element.parentElement.imageTraySize = Math.clamp(initialSize + dragOffset.x, minSize, maxSize);
                } else if (resizeDirection === ResizeDirection.TOP) {
                    element.parentElement.imageTraySize = Math.clamp(initialSize - dragOffset.y, minSize, maxSize);
                } else if (resizeDirection === ResizeDirection.BOTTOM) {
                    element.parentElement.imageTraySize = Math.clamp(initialSize + dragOffset.y, minSize, maxSize);
                }

                await element.canvas.refreshCanvas();
            }
        };
    }
}

