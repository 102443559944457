import React from "react";

import {
    Button,
    Typography,
    Paper,
    Link,
    LinearProgress,
    TextField,
    Grid,
    Hidden
} from "@material-ui/core";
import { withStyles, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import * as analytics from "legacy-js/analytics";
import { _ } from "legacy-js/vendor";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { Key } from "js/core/utilities/keys";
import { getStaticUrl, isPPTAddin } from "legacy-js/config";
import BeautifulLogo from "legacy-js/react/components/BeautifulLogo";
import LabelledDivider from "legacy-js/react/components/LabelledDivider";
import { dialogTheme, darkTheme } from "legacy-js/react/materialThemeOverrides";
import { app } from "js/namespaces";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.AUTH);

const googleIcon = getStaticUrl("/images/auth/google.svg");
const emailIcon = getStaticUrl("/images/auth/email.svg");
const genericImage = getStaticUrl("/images/auth/generic.png");
const teamImage = getStaticUrl("/images/auth/team.png");
const beautifulBrandingIcon = getStaticUrl("/images/auth/beautiful-branding.svg");
const spacing = 16;

const styles = theme => {
    let style = {
        // sso
        ssoNotice: {
            borderRadius: 4,
            padding: 6,
            textAlign: "center",
            color: "#46555a",
            fontSize: 13
        },
        ssoIcon: {
            fontFamily: "Material Icons",
            fontSize: 20,
            fontStyle: "normal",
            verticalAlign: "bottom"
        },
        authPage: {
            display: "grid",
            gridTemplateColumns: "repeat(18, 1fr)",
            [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(22, 1fr)",
            },
            [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(30, 1fr)",
            },
            "& > div": {
                gridColumn: "2 / span 16",
                [theme.breakpoints.up("sm")]: {
                    gridColumn: "3 / span 18",
                },
                [theme.breakpoints.up("md")]: {
                    gridColumn: "9 / span 14",
                },
            },
        },
        authTopLogo: {
            position: "absolute",
            top: 23,
            left: 128,
            "@media (max-width: 768px)": {
                left: 10
            }
        },
        headerLogo: {
            width: "55px",
            height: "55px",
            marginBottom: "24px"
        },
        // google sign-in
        googleButton: {
            display: "flex",
            justifyContent: "start",
            borderRadius: 2,
            height: 40,
            background: "#FFFFFF",
            border: "none",
            padding: "11px",
            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.17)",
            "& .MuiButton-root:hover": {
                background: "#EEEEEE"
            },
            "& .MuiButton-label": {
                display: "flex",
                columnGap: 24,
                lineHeight: "normal",

                "& :hover": {
                    cursor: "pointer"
                }
            },
            "& label": {
                textTransform: "capitalize",
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "normal",
            },
            "& img": {
                width: 18,
                height: 18
            }
        },
        // rows
        smallRow: {
            marginTop: spacing,
            marginBottom: spacing,
            "&:first-child": {
                marginTop: 0
            }
        },
        largeRow: {
            marginTop: spacing * 2,
            marginBottom: spacing * 2,
            "&:first-child": {
                marginTop: spacing
            }
        },
        topPaddedRow: {
            marginTop: spacing * 2,
        },
        // error message
        error: {
            background: "rgb(247, 181, 174)",
            color: "rgb(97, 26, 21)",
            paddingTop: spacing * 1,
            paddingBottom: spacing * 1,
            paddingLeft: spacing * 3,
            paddingRight: spacing * 3,
        },
        errorB: {
            color: "#D43A00",
            fontFamily: "Source Sans Pro",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "145%",
            letterSpacing: "-0.28px"
        },
        errorBorder: {
            borderBottom: "1px solid #D43A00"
        },
        // info message
        info: {
            background: "#d1eeff",
            fontSize: 14,
            paddingTop: spacing * 1,
            paddingBottom: spacing * 1,
            paddingLeft: spacing * 2,
            paddingRight: spacing * 2,
        },
        // AuthPage
        logoContainer: {
            textAlign: "center",
            padding: spacing * 4,
        },
        authContainer: {
            margin: "auto",
            maxWidth: 450,
            [theme.breakpoints.up("md")]: {
                width: 450,
            },
        },
        authContainerB: {
            backgroundColor: "transparent",
            margin: "auto",
            width: "80%",
            [theme.breakpoints.up("sm")]: {
                width: 400
            },
            [theme.breakpoints.up("md")]: {
                width: 450
            },
            [theme.breakpoints.up("lg")]: {
                width: 530
            },
        },
        // AuthForm
        authFormContent: {
            padding: spacing * 2
        },
        authFormContentB: {
            padding: 0,
            width: "100%",
            border: "none",
            backgroundColor: "transparent"
        },
        // text
        muted: {
            color: "#333"
        },
        wideSpace: {
            paddingLeft: ".2em"
        },
        // text field
        disabledField: {
            "& input": {
                background: "#f7f7f7",
                color: "#525252 !important"
            }
        },
        headerText: {
            marginBottom: spacing * 2
        },
        subheadingText: {
            marginTop: 11,
            fontSize: 19,
            opacity: 0.75,
            maxWidth: 450
        },
        subheadingTextB: {
            marginTop: 11,
            color: "#222",
            fontFamily: "Source Sans Pro",
            fontSize: 26,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "145%",
            letterSpacing: -0.26,
        },
        captionText: {
            marginTop: 15,
            fontSize: 19,
            lineHeight: "1.5em",
            opacity: 0.75,
        },
        // sign-in/up form toggle
        toggleHoisted: {
            marginTop: 16
        },
        toggleBelow: {
            marginTop: 24,
            marginBottom: -64
        },
        fineText: {
            marginTop: 40,
            color: "gray",
            fontFamily: "Source Sans Pro",
            fontSize: "0.875rem",
            letterSpacing: "-0.02em",
            lineHeight: 1.45,
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                fontSize: "0.875rem",
                lineHeight: 1.55,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "0.875rem",
                lineHeight: 1.6,
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.125rem",
                lineHeight: 1.6,
            },
        },
        verificationCodeInput: {
            fontFamily: "Source Sans Pro",
            width: "40px",
            height: "40px",
            textAlign: "center",
            border: "none",
            borderBottom: "1px solid gray",
            background: "transparent",
            outline: "none",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "120%",
            letterSpacing: -0.48,
            borderRadius: 0,
            "@media (max-width: 768px)": {
                width: "20px"
            }
        },
        verificationCodeContainer: {
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            gap: "4px",
            marginTop: "64px",
        }
    };

    if (app.isDarkMode) {
        style = _.merge(
            style,
            {
                // text
                muted: {
                    color: "#ddd",
                },
                subheadingText: {
                    color: "white",
                },
            },
        );
    }

    return style;
};

function blockHotkeys(event) {
    // Prevent window.keydown events from firing.
    event.stopPropagation();
}

function makeHero(hero) {
    if (hero) {
        if (hero.image) {
            switch (hero.image) {
                case "generic": return <ImageWithCaption imageSrc={genericImage} imageWidth={500} />;
                case "team-invite": return <ImageWithCaption imageSrc={teamImage} imageWidth={500} />;
                case "presentation-invite": return <ImageWithCaption imageSrc={hero.thumbnailUrl} imageWidth={500} imageHeight={281} caption={hero.caption} isSlide />;
            }
        } else if (hero.imageUrl) {
            return <ImageWithCaption imageSrc={hero.imageUrl} imageWidth={500} caption={hero.caption} isSlide={hero.isSlide} />;
        }
    }
    return <ImageWithCaption imageSrc={genericImage} imageWidth={500} />;
}

export const AuthPage = withStyles(styles)(
    ({ classes, subheading, hero = null, noLogo = false, children }) => {
        const content = (
            <div data-cy="auth-page" style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", flexGrow: 1, maxWidth: 1280 }}>
                        <Grid container spacing={3}>
                            {hero && (
                                <Grid item container xs={12} lg={6}>
                                    <Hidden mdDown>
                                        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                            {makeHero(hero)}
                                        </div>
                                    </Hidden>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={hero ? 6 : 12}>
                                <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                                        {!noLogo && (
                                            <BeautifulLogo darkOnLight={!app.isDarkMode} forceMarketing />
                                        )}
                                        {subheading && (
                                            <Typography variant="h5" component="h2" align="center" className={classes.subheadingText}>
                                                {subheading}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.authContainer}>
                                    {children}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
        return (
            <MuiThemeProvider theme={dialogTheme}>
                {
                    app.isDarkMode &&
                    <MuiThemeProvider theme={darkTheme}>
                        {content}
                    </MuiThemeProvider>
                }
                {
                    !app.isDarkMode &&
                    content
                }
            </MuiThemeProvider>
        );
    }
);

export const AuthPageB = withStyles(styles)(
    ({ classes, subheading, children, headerLogo, showHeader = true }) => {
        const content = (
            <div data-cy="auth-page" className={classes.authPage} style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
                {showHeader && (
                    <>
                        <div className={classes.authTopLogo}>
                            <BeautifulLogo darkOnLight={!app.isDarkMode} forceMarketing width={132.855} />
                        </div>
                        <div style={{ textAlign: "center", marginBottom: 60, marginTop: 128 }}>
                            <img src={headerLogo || beautifulBrandingIcon} className={classes.headerLogo} />
                            {subheading && (
                                <Typography align="center" className={classes.subheadingTextB}>
                                    {subheading}
                                </Typography>
                            )}
                        </div>
                    </>
                )}
                <div className={classes.authContainerB} style={{ marginTop: 0 }}>
                    {children}
                </div>
            </div>
        );
        return (
            <MuiThemeProvider theme={dialogTheme}>
                {
                    app.isDarkMode &&
                    <MuiThemeProvider theme={darkTheme}>
                        {content}
                    </MuiThemeProvider>
                }
                {
                    !app.isDarkMode &&
                    content
                }
            </MuiThemeProvider>
        );
    }
);

export const ImageWithCaption = withStyles(styles)(({ classes, caption, imageSrc, imageWidth, imageHeight, isSlide }) => {
    return (
        <div style={{ textAlign: "center", maxWidth: imageWidth, isSlide }}>
            <img
                width={imageWidth}
                height={imageHeight}
                src={imageSrc}
                style={{
                    background: isSlide ? "#f1f1f1" : undefined,
                    boxShadow: isSlide ? "2px 2px 8px rgba(0,0,0,0.3)" : undefined
                }}
            ></img>
            {caption && (
                <Typography variant="h5" component="h2" align="center" className={classes.captionText}>
                    {caption}
                </Typography>
            )}
        </div>
    );
});

export const AuthForm = withStyles(styles)(
    ({ classes, title, children, error, loading, info }) => (
        <Paper style={{ overflow: "hidden" }}>
            {loading && <LinearProgress />}
            {error && <div data-cy="error" className={classes.error}>{error}</div>}
            {info && <div data-cy="info" className={classes.info}>{info}</div>}
            <div className={classes.authFormContent}>
                {/* TODO: what should noValidate and autoComplete be set to? */}
                <form noValidate autoComplete="off">
                    {title && (
                        <Typography variant="h6" component="h1" align="center" className={classes.headerText}>
                            {title}
                        </Typography>
                    )}
                    <div>
                        {React.Children.map(children, child => (
                            <div className={classes.smallRow}>
                                {child}
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        </Paper>
    )
);

export const AuthFormB = withStyles(styles)(
    ({ classes, children, title }) => {
        const Container = title ? Paper : "div";
        return (
            <Container style={{ overflow: "hidden", width: "100%", transform: title && "translateY(+75%)" }}>
                <div className={classes.authFormContentB} style={{ width: "100%" }}>
                    <form noValidate autoComplete="off" style={{ width: "100%" }}>
                        {title && (
                            <Typography variant="h6" component="h1" align="center" className={classes.headerText} style={{ marginTop: 32 }}>
                                {title}
                            </Typography>
                        )}
                        <div style={{ width: "100%" }}>
                            {React.Children.map(children, child => (
                                <div className={classes.smallRow} style={{ width: "100%" }}>
                                    {child}
                                </div>
                            ))}
                        </div>
                    </form>
                </div>
            </Container>
        );
    }
);

export const GoogleButton = withStyles(styles)(
    ({ classes, children, label, disabled, onClick, fullWidth = true }) => (
        <Button
            id="google"
            className={classes.googleButton}
            onClick={onClick}
            disabled={disabled}
            fullWidth={fullWidth}
        >
            <img src={googleIcon} />
            {children}
            <label>{label}</label>
        </Button>
    )
);

const SignInOrCreateAccount = withStyles(styles)(
    class SignInOrCreateAccount extends React.Component {
        handleClickSubmit = e => {
            e.preventDefault();
            this.props.onClickSubmit();
        }

        handleClickSwitchLink = e => {
            e.preventDefault();
            this.props.onClickSwitchLink({ signIn: !!this.props.createAccount });
        }

        handleClickForgot = e => {
            e.preventDefault();
            this.props.onClickForgot();
        }

        handleClickSSOLink = e => {
            e.preventDefault();
            this.props.onClickSSOLink();
        }

        render = () => {
            const {
                classes,
                email, defaultEmail, password, error, loading, createAccount, emailError, passwordError,
                onClickSSOLink,
                showOnlyGoogle,
                noToggle,
                children,
                info,
                withCompany,
                hero,
                subheading,
                noLogo,
                hoistTerms,
                noTitle,
                ssoState
            } = this.props;

            const isEmailReadonly = !!defaultEmail;
            const signInLabel = (ssoState == "strict" || ssoState == "optional") ? "Continue" : "Sign in";

            const onEmailChange = event => {
                if (this.props.onEmailChange) {
                    this.props.onEmailChange(event.target.value);
                }
            };

            const onPasswordChange = event => {
                if (this.props.onPasswordChange) {
                    this.props.onPasswordChange(event.target.value);
                }
            };

            const onFirstNameChange = event => {
                if (this.props.onFirstNameChange) {
                    this.props.onFirstNameChange(event.target.value);
                }
            };

            const onLastNameChange = event => {
                if (this.props.onLastNameChange) {
                    this.props.onLastNameChange(event.target.value);
                }
            };

            const onCompanyChange = event => {
                if (this.props.onCompanyChange) {
                    this.props.onCompanyChange(event.target.value);
                }
            };

            let title;
            if (!noTitle) {
                title = createAccount
                    ? "Create an account"
                    : "Sign in to your account";
            }

            const isSafari = (
                navigator.userAgent.indexOf("Safari") > -1 &&
                navigator.userAgent.indexOf("Chrome") < 0
            );
            const hideGoogleSso = app.integrator && isSafari;

            return (
                <AuthPage
                    subheading={subheading ?? (createAccount ? "Start creating beautiful presentations, faster." : "")}
                    hero={hero}
                    noLogo={noLogo}
                >
                    <AuthForm
                        title={title}
                        error={error}
                        info={info}
                        loading={loading}
                    >
                        {ssoState !== "strict" && !hideGoogleSso && (
                            <GoogleButton
                                label="Continue with Google"
                                onClick={this.props.onClickGoogleButton}
                                disabled={loading}
                            />
                        )}
                        {children}
                        {!showOnlyGoogle && (
                            <>
                                {ssoState !== "strict" && !hideGoogleSso && (
                                    <div style={{ marginBottom: -spacing }}>
                                        <LabelledDivider label="OR" />
                                    </div>
                                )}
                                {createAccount && (
                                    <>
                                        {withCompany && (
                                            <div className={classes.smallRow}>
                                                <TextField
                                                    id="company"
                                                    variant="outlined"
                                                    type="text"
                                                    placeholder="(Company Name)"
                                                    fullWidth
                                                    onChange={onCompanyChange}
                                                    onKeyDown={blockHotkeys}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className={classes.smallRow}>
                                    <TextField
                                        id="email"
                                        className={isEmailReadonly ? classes.disabledField : ""}
                                        variant="outlined"
                                        type="email"
                                        placeholder={createAccount ? "Work Email" : "Email"}
                                        value={email || defaultEmail || ""}
                                        autoFocus={!createAccount && !isEmailReadonly}
                                        disabled={isEmailReadonly}
                                        fullWidth
                                        helperText={emailError}
                                        error={!!emailError}
                                        onChange={onEmailChange}
                                        onKeyDown={blockHotkeys}
                                    />
                                </div>
                                {ssoState !== "strict" && (
                                    <div className={classes.smallRow}>
                                        <TextField
                                            variant="outlined"
                                            type="password"
                                            placeholder={createAccount
                                                ? "Create Password"
                                                : "Password"
                                            }
                                            autoFocus={!createAccount && isEmailReadonly}
                                            value={password || ""}
                                            fullWidth
                                            helperText={passwordError}
                                            error={!!passwordError}
                                            onChange={onPasswordChange}
                                            onKeyDown={blockHotkeys}
                                        />
                                    </div>
                                )}
                                {(ssoState === "strict" || ssoState === "optional") && (
                                    <div className={classes.smallRow}>
                                        <div className={classes.ssoNotice}>
                                            <i className={classes.ssoIcon}>lock</i>
                                            Single sign-on enabled
                                        </div>
                                    </div>
                                )}
                                <div className={classes.smallRow}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={this.handleClickSubmit}
                                        // TODO: handle enter key press -> submit
                                        disabled={loading}
                                        fullWidth
                                    >{createAccount ? "Create account" : signInLabel}
                                    </Button>
                                </div>
                                {!createAccount && ssoState !== "strict" && (
                                    <Typography variant="body2">
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={this.handleClickForgot}
                                        >Forgot your password?</Link>
                                    </Typography>
                                )}
                                {hoistTerms && (
                                    <Toggle
                                        createAccount={createAccount}
                                        noToggle={noToggle}
                                        onClickSSOLink={onClickSSOLink}
                                        handleClickSwitchLink={this.handleClickSwitchLink}
                                        handleClickSSOLink={this.handleClickSSOLink}
                                        hoistTerms={true}
                                    />
                                )}
                                {createAccount && hoistTerms && (
                                    <Terms align="left" />
                                )}
                            </>
                        )}
                    </AuthForm>
                    {!hoistTerms && (
                        <Toggle
                            createAccount={createAccount}
                            noToggle={noToggle}
                            onClickSSOLink={onClickSSOLink}
                            handleClickSwitchLink={this.handleClickSwitchLink}
                            handleClickSSOLink={this.handleClickSSOLink}
                            hoistTerms={false}
                        />
                    )}
                    {createAccount && !hoistTerms && (
                        <Terms align="center" />
                    )}
                </AuthPage>
            );
        }
    }
);

const Toggle = withStyles(styles)(
    ({ classes, createAccount, noToggle, onClickSSOLink, handleClickSwitchLink, handleClickSSOLink, hoistTerms }) => (
        <div className={classes.smallRow + " " + hoistTerms ? classes.toggleHoisted : classes.toggleBelow}>
            <Typography
                variant="body1"
                align="center"
                className={classes.muted}
                style={{
                    marginBottom: hoistTerms
                        ? (createAccount ? 0 : -16)
                        : (createAccount ? 0 : -48)
                }}>
                {!noToggle && <>
                    {createAccount
                        ? "Already have an account?"
                        : "New to Beautiful.ai?"}
                    <Link
                        id="toggle"
                        href="#"
                        className={classes.wideSpace}
                        onClick={handleClickSwitchLink}
                    >{createAccount ? "Sign in" : "Create an account"}
                    </Link>
                </>}
                {!createAccount && onClickSSOLink && (
                    <span style={{ float: "right" }}>
                        <Link
                            className={classes.wideSpace}
                            onClick={handleClickSSOLink}
                        >Sign in with SSO
                        </Link>
                    </span>
                )}
            </Typography>
        </div>
    )
);

const Terms = withStyles(styles)(
    ({ classes, align }) => (
        <div className={classes.smallRow}>
            <Typography
                variant="body2"
                align={align}
                className={classes.muted}
                style={{
                    marginBottom: -spacing
                }}
            >
                By creating an account, you agree to the { }
                <Link
                    href="/terms-of-service"
                    target="_blank"
                >Terms of Service
                </Link> and { }
                <Link
                    href="/privacy-policy"
                    target="_blank"
                >Privacy Policy
                </Link>.
            </Typography>
        </div>
    )
);

export const SignIn = props => (
    <SignInOrCreateAccount {...props} />
);

export const CreateAccount = props => (
    <SignInOrCreateAccount {...props} createAccount />
);

export class ResetPassword extends React.Component {
    render = () => {
        const { loading, error, email, emailError, hero } = this.props;

        const onEmailChange = event => {
            if (this.props.onEmailChange) {
                this.props.onEmailChange(event.target.value);
            }
        };

        return (
            <AuthPage
                hero={hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm
                    title="Forgot your password?"
                    loading={loading}
                    error={error}
                >
                    <Typography variant="body1">
                        Enter your email address to reset your password.
                    </Typography>
                    <TextField
                        variant="outlined"
                        type="email"
                        placeholder="Email"
                        value={email || ""}
                        fullWidth
                        autoFocus
                        helperText={emailError}
                        error={!!emailError}
                        onChange={onEmailChange}
                        onKeyDown={blockHotkeys}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.props.onClickReset}
                        fullWidth
                    >Reset Password
                    </Button>
                </AuthForm>
            </AuthPage>
        );
    }
}

export const ResetDone = withStyles(styles)(class ResetDone extends React.Component {
    render = () => {
        return (
            <AuthPage
                hero={this.props.hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm>
                    <div style={{ textAlign: "center" }}>
                        <img src={emailIcon} />
                    </div>
                    <Typography variant="h6" component="h1" align="center" className={this.props.classes.headerText}>
                        Check your email
                    </Typography>
                    <Typography variant="body1" align="center">
                        We've sent you instructions to reset your password.
                    </Typography>
                </AuthForm>
            </AuthPage>
        );
    }
});

export class WaitForGoogle extends React.Component {
    render = () => {
        return (
            <AuthPage
                hero={this.props.hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm
                    loading={false}
                    error={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img width="40" src={googleIcon} />
                    </div>
                    <Typography variant="h6" component="h1" align="center">
                        Waiting for Google...
                    </Typography>
                </AuthForm>
            </AuthPage>
        );
    }
}

export const VerifyEmail = withStyles(styles)(class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sent: false,
            errorMessage: null,
            verificationCodes: Array(6).fill([]),
            verified: false,
            verificationTimeout: null,
            hasPresentationsInURL: window.location.href.includes("/copydeck")
        };
        this.firstInputRef = React.createRef();
    }

    componentDidMount() {
        if (this.firstInputRef.current) {
            this.firstInputRef.current.focus();
        }
    }

    handleClickResend = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            await Api.verifyEmail.post({ isPPT: isPPTAddin });
            this.setState({ sent: true, errorMessage: null });
        } catch (err) {
            logger.error(err, "[VerifyEmail] Api.verifyEmail.post() failed");
            this.setState({ errorMessage: err.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    handleVerificationCodeChange = (index, value) => {
        value = value.slice(0, 1).replace(/[^0-9]/g, "");

        if (this.state.errorMessage) {
            this.setState({ errorMessage: null });
        }

        this.onCodeChange(index, value);
    }

    onCodeChange = (index, value) => {
        const newCodes = [...this.state.verificationCodes];
        newCodes[index] = value.split("");
        this.setState({ verificationCodes: newCodes }, () => {
            this.focusAppropriateField(index, value);
        });

        // Move focus to the next input field if the current one is filled
        if (value && index < 5) {
            document.getElementById(`verification-code-input-${index + 1}`).focus();
        }

        // Clear any existing timeout and set a new one to verify the code
        if (this.state.verificationTimeout) {
            clearTimeout(this.state.verificationTimeout);
        }
        if (newCodes.every(code => code.length > 0)) {
            const timeout = setTimeout(this.verifyCode, 500);
            this.setState({ verificationTimeout: timeout });
        }
    }

    focusAppropriateField = (currentIndex, value) => {
        const nextIndex = value ? Math.min(currentIndex + 1, 5) : Math.max(currentIndex, 0);
        const nextInput = document.getElementById(`verification-code-input-${nextIndex}`);
        if (nextInput) {
            nextInput.focus();
            // Move cursor to the end of the input
            const length = nextInput.value.length;
            nextInput.setSelectionRange(length, length);
        }
    }

    handlePaste = event => {
        event.preventDefault();
        const paste = event.clipboardData.getData("text");
        if (paste.length === 6) {
            const newCodes = paste.split("").map(char => [char]);
            this.setState({ verificationCodes: newCodes });

            // Clear any existing timeout and set a new one to verify the code
            if (this.state.verificationTimeout) {
                clearTimeout(this.state.verificationTimeout);
            }
            const timeout = setTimeout(this.verifyCode, 0);
            this.setState({ verificationTimeout: timeout });
        }
    }

    verifyCode = async () => {
        this.setState({ loading: true, sent: false });
        try {
            const { verificationCodes } = this.state;
            const code = verificationCodes.flat().join("");
            const res = await Api.verifyEmail.get({ code });

            if (res.verified) {
                await this.waitForEmailVerification();
                await auth().currentUser.getIdToken(true);

                trackActivity("SignupFlow", "NavForward", null, null, {
                    step_number: 2,
                    step_name: "Verification",
                    verification_type: "email",
                    object: "field",
                    object_label: "",
                    action: "typed",
                    workspace_id: "all"
                }, { audit: true });

                analytics.trackState({
                    "is_signing_up": true,
                });

                this.setState({ verified: true, errorMessage: null });
            } else {
                if (res.reason === "expired") {
                    this.setState({ verified: false, errorMessage: "code expired. Resend a new code", verificationCodes: Array(6).fill([]) });
                } else {
                    this.setState({ verified: false, errorMessage: "invalid code. Try again", verificationCodes: Array(6).fill([]) });
                }
                this.firstInputRef.current.focus();
            }
        } catch (err) {
            logger.error(err, "[VerifyEmail] Api.verifyEmail.get() failed");
            this.setState({ errorMessage: err.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    waitForEmailVerification = () => {
        return new Promise((resolve, reject) => {
            const checkInterval = setInterval(async () => {
                try {
                    await auth().currentUser.reload();
                    if (auth().currentUser.emailVerified) {
                        clearInterval(checkInterval);
                        resolve();
                    }
                } catch (error) {
                    logger.error(error, "Error checking email verification status");
                    clearInterval(checkInterval);
                    reject(error);
                }
            }, 1000);
        });
    }

    handleKeyDown = (index, event) => {
        if (event.which === Key.BACKSPACE && index > 0 && !this.state.verificationCodes[index].length) {
            this.focusAppropriateField(index - 1, "");
        }
    }

    handleGoBackToSignup = () => {
        trackActivity("SignupFlow", "NavBack", null, null, {
            step_number: 2,
            step_name: "Verification",
            verification_type: "email",
            object: "link",
            object_label: "go back to signup",
            action: "clicked",
            workspace_id: "all"
        }, { audit: true });
    }

    render = () => {
        const { children } = this.props;
        const { sent, loading, errorMessage, verificationCodes, verified, hasPresentationsInURL } = this.state;

        if (verified) {
            return children;
        }

        return (
            <AuthPageB
                subheading={"Verify your email"}
                showHeader={!hasPresentationsInURL}
            >
                <AuthFormB
                    loading={false}
                    title={hasPresentationsInURL ? "Verify your email" : null}
                >
                    <div className={this.props.classes.verificationCodeContainer}>
                        {Array(6).fill(0).map((_, index) => (
                            <input
                                key={index}
                                id={`verification-code-input-${index}`}
                                type="text"
                                pattern="[0-9]*"
                                maxLength="6"
                                className={`${this.props.classes.verificationCodeInput} ${!sent && errorMessage ? this.props.classes.errorBorder : ""}`}
                                value={verificationCodes[index].join("") || ""}
                                onChange={event => this.handleVerificationCodeChange(index, event.target.value)}
                                onKeyDown={event => this.handleKeyDown(index, event)}
                                onPaste={this.handlePaste}
                                ref={index === 0 ? this.firstInputRef : null}
                                inputMode="numeric"
                            />
                        ))}
                    </div>
                    {!sent && errorMessage && <div className={this.props.classes.errorB} style={{ textAlign: "center" }}>Error: {errorMessage}</div>}
                    <div className={this.props.classes.emailInfo}>
                        <Typography align="center" style={{ color: "#222" }}>
                            <span>An email with a verification code has been sent</span>
                            <br />
                            <span>to your email address. Enter the code to</span>
                            <br />
                            <span>continue.</span>
                        </Typography>
                    </div>
                    <div className={this.props.classes.fineText} style={{ marginTop: "12px" }}>
                        <Typography align="center" >
                            {sent && !errorMessage && <div>Sent! Check your inbox.</div>}
                            {!sent &&
                                <>
                                    Didn't receive a code?
                                    <Link
                                        href=""
                                        onClick={this.handleClickResend}
                                        disabled={loading}
                                    >
                                        &nbsp;Resend.
                                    </Link>
                                </>
                            }
                        </Typography>
                        <Typography align="center" style={{ marginTop: "12px" }}>
                            Having trouble? <Link
                                href="mailto:support@beautiful.ai"
                            >Contact support</Link> or <Link
                                href="/logout?continue=/signup"
                            >go back to signup</Link>.
                        </Typography>
                    </div>
                </AuthFormB>
            </AuthPageB>
        );
    }
});
