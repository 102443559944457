import React, { Component } from "react";
import styled from "styled-components";
import { Icon, MenuItem, Button } from "@material-ui/core";
import SortAlphabeticalVariant from "mdi-material-ui/SortAlphabeticalVariant";
import Update from "mdi-material-ui/Update";
import CalendarRange from "mdi-material-ui/CalendarRange";
import { v4 as uuid } from "uuid";
import { toLower, trim, uniqBy } from "lodash";

import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import { UIPane, RoundIconButton, IconMenu } from "legacy-js/react/components/UiComponents";
import { FilterDropDown } from "legacy-js/react/views/AddSlide/Panes/Components/FilterDropDown";
import { Breadcrumb } from "legacy-js/Components/Breadcrumb";
import {
    SearchBarContainer,
    SearchBarInnerContainer,
    UIPaneResultsContainer,
    SlideSearchInput,
} from "legacy-js/react/views/AddSlide/Panes/Components/SearchBox";
import { SortMenu } from "legacy-js/react/views/AddSlide/Panes/Components/SortMenu";
import { ds } from "js/core/models/dataService";
import { ASSET_FILETYPE, ASSET_RESOLUTION, AssetType, PERMISSION_RESOURCE_TYPE } from "legacy-common/constants";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { ShowDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import AddAssetsContainer from "legacy-js/react/views/AddAssets/AddAssetsContainer";
import { ShowConfirmationDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { sortTagsByPopularity, trackActivity } from "js/core/utilities/utilities";
import { NoMatchNotice, NoTeamAssetNotice } from "legacy-js/react/components/Notice";
import { Gap20, Gap30 } from "legacy-js/react/components/Gap";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import AppController from "legacy-js/core/AppController";

import CreateTeamAssetDialog from "../dialogs/CreateTeamAssetDialog";
import TeamAssetSettingsDialog from "../dialogs/TeamAssetSettingsDialog";
import { BulkAssetUploadDialog, BulkSetTagsDialog } from "../dialogs/BulkAssetUploadDialog";
import ProgressDialog from "../../../components/Dialogs/ProgressDialog";
import { MultiSelectThumbnailGrid, Thumbnail, ThumbnailContainer } from "../../AddSlide/Panes/Components/ThumbnailGrid";

const logger = getLogger(LogGroup.TEAMS);

const TEAM_ASSET = PERMISSION_RESOURCE_TYPE.TEAM_ASSET;

const StyledUIPaneResultsContainer = styled(UIPaneResultsContainer)`
    ${props => props.editable ? "" : "height: 50px"}
`;

const StyledSearchBarContainer = styled(SearchBarContainer)`
    background: #eee;
    ${props => props.editable ? "" : "height: 50px"}
`;

const StyledSearchBarInnerContainer = styled(SearchBarInnerContainer)`
    background: ${props => props.editable ? "white" : "#eee"};
`;

const ThumbnailGridContainer = styled.div`
    //background: white;
    padding: 20px;
    margin: 0px 0px 20px;
    overflow: hidden;

    .dark-mode & {
        background: #333;
    }
`;

const UploadFileInput = styled.input`
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;

`;
const mediaFilterOptions = [
    {
        id: "all",
        name: "All Media"
    },
    {
        id: "image",
        name: "Photos"
    },
    {
        id: "logo",
        name: "Logos"
    },
];

const sortOptions = [
    {
        value: "assetName",
        label: "Name",
        icon: <SortAlphabeticalVariant />
    },
    {
        value: "createdAt",
        label: "Created At",
        reverse: true,
        icon: <CalendarRange />
    },
    {
        value: "modifiedAt",
        label: "Modified At",
        reverse: true,
        icon: <Update />
    },
];

export default class TeamAssetsPane extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadedAssets: {},
            isLoading: true,
            muted: true,
            selectedMediaFilter: "all",
            selectedTagFilter: "  all  ", // Spaces needed to prevent conflict with other tags
            sortBy: "createdAt",
            sortDir: 1,
            searchTerm: props.prefilledSearchQuery?.trim().toLowerCase() || "",
            existingTags: [],
        };
    }

    componentDidMount() {
        this.reloadAssets()
            .then(() => {
                const workspace = app.user.workspaces[AppController.workspaceId];
                const team = workspace.getDefaultTeam();
                team?.on("change:sharedResources", this.onChangeSharedResources);

                this.updateExistingTags();
            })
            .catch(err => logger.error(err, "[TeamAssetsPane] this.reloadAssets() failed"));
    }

    componentDidUpdate(prevProps, prevState) {
        const { loadedAssets } = this.state;
        if (prevState.loadedAssets !== loadedAssets) {
            this.updateExistingTags();
        }
    }

    updateExistingTags = () => {
        //get all tags from user's teams
        let existingTags = this.getSortedTags() ?? [];
        const defaultTeam = AppController.currentTeam;
        defaultTeam?.libraryItems.forEach(libraryItem => {
            existingTags.push(Object.keys(libraryItem.get("tags") || {}));
        });

        // Remove duplicates
        existingTags = uniqBy(existingTags.flat().map(trim), toLower);
        this.setState({ existingTags });
    }

    onChangeSharedResources = () => {
        this.setState({ loadedAssets: {} }, () => {
            this.reloadAssets();
        });
    }

    componentWillUnmount() {
        const workspace = app.user.workspaces[AppController.workspaceId];
        const team = workspace.getDefaultTeam();
        team?.off("change:sharedResources", this.onChangeSharedResources);
    }

    handleSearch = async searchTerm => {
        this.setState({
            searchTerm: searchTerm?.trim().toLowerCase(),
        });
    };

    async getAssetsToLoad(teamAssets) {
        const workspace = app.user.workspaces[AppController.workspaceId];
        const team = workspace.getDefaultTeam();
        teamAssets = teamAssets ?? { ...(team?.get("sharedResources") ?? {})[TEAM_ASSET] };

        const assetIds = Object.keys(teamAssets);
        const type = AssetType.IMAGE; // HACK
        const assets = await Promise.all(assetIds.map(id => ds.assets.getAssetById(id, type)));

        return assets;
    }

    async reloadAssets() {
        this.setState({ isLoading: true });

        const loadedAssets = await this.getAssetsToLoad();
        await new Promise(resolve => this.setState({ loadedAssets }, resolve));

        // Hide spinner after the loaded assets are rendered, this
        // will also force the grid to reflow if needed
        this.setState({ isLoading: false });

        return loadedAssets;
    }

    async createOrUpdateTeamAsset(fromAssetId, attributesOverrides = {}, originalTeamAsset = null, isBulkUpload = false) {
        const updatedAttributes = {
            // Force org id and remove user id
            orgId: UIController.getOrganizationId(),
            userId: null,
            // This prop is used for pulling files
            actualId: fromAssetId,
            // Marking correct update data
            modifiedAt: Date.now(),
            modifiedById: app.user.id,
            ...attributesOverrides
        };

        let asset;
        if (originalTeamAsset) {
            // If original team asset is defined - then update it with the source
            // asset attributes and the new values

            const fromAsset = await ds.assets.getAssetById(fromAssetId);
            const newAttributes = {
                ...fromAsset.attributes,
                // Attributes of team asset we need to preserve
                assetName: originalTeamAsset.get("assetName"),
                tags: originalTeamAsset.get("tags") ?? [],
                hidden: originalTeamAsset.get("hidden") ?? false,
                content_type: originalTeamAsset.get("type"),
                createdById: originalTeamAsset.get("createdById"),
                ...updatedAttributes
            };
            delete newAttributes.id;

            asset = originalTeamAsset;
            asset.replace(newAttributes);
            await ds.assets.updatePromise;
        } else {
            // If creating team asset from a non-team asset that already exists - then clone it
            // NOTE: we won't be cloning files because we set the actualId field
            asset = await ds.assets.cloneAsset(fromAssetId, {
                ...updatedAttributes,
                // Weird field duplicating asset.userId, but we already rely on it...
                createdById: app.user.id,
                // Force id of the correct format
                id: `${UIController.getOrganizationId()}:${uuid()}`
            }, false);
        }

        // Ensure asset is in team's sharedResources
        const workspace = app.user.workspaces[AppController.workspaceId];
        const team = workspace.getDefaultTeam();
        team.update({ sharedResources: { [TEAM_ASSET]: { [asset.id]: true } } });

        return asset;
    }

    handleAddItem = async existingTags => {
        let createdAssetModel, isNewAsset;
        await ShowDialogAsync(AddAssetsContainer, {
            assetType: AssetType.IMAGE,
            hideTeamAssets: true,
            hideIcons: true,
            hideVideos: true,
            // We have to use callback props to catch the results because we don't want to
            // change the internal flow of the AddAssetsContainer
            callback: (model, isNew) => {
                createdAssetModel = model;
                isNewAsset = isNew;
            }
        });

        if (!createdAssetModel) {
            return;
        }

        ShowDialog(CreateTeamAssetDialog, {
            type: createdAssetModel.content_type,
            name: createdAssetModel.assetName,
            tags: createdAssetModel.tags,
            existingTags,
            callback: async ({ type, name, tags }) => {
                const attributesOverrides = {
                    type,
                    content_type: type,
                    assetName: name,
                    tags
                };

                let asset = await this.createOrUpdateTeamAsset(createdAssetModel.content_value, attributesOverrides, null);
                trackActivity("TeamAsset", "Added", null, null, {
                    id: asset.id,
                    orgId: asset.get("orgId"),
                    type: asset.get("type"),
                    isNewAsset,
                });
                this.reloadAssets();
            }
        });
    }

    handleEditSettings = async asset => {
        const { existingTags } = this.state;
        ShowDialog(TeamAssetSettingsDialog, {
            asset,
            existingTags,
            onUpdateAsset: this.handleUpdateAsset,
            callback: async () => {
                await this.reloadAssets();
            }
        });
    }

    handleUpdateAsset = async (teamAsset, assetValueOverrides = {}) => {
        return new Promise(resolve => {
            const assetType = teamAsset.get("type");
            const showAssetsDialog = () => {
                ShowDialog(AddAssetsContainer, {
                    assetType,
                    hideTeamAssets: true,
                    hideIcons: true,
                    hideVideos: true,
                    hidePhotos: assetType !== AssetType.IMAGE,
                    hideLogos: assetType !== AssetType.LOGO,
                    onCancel: () => resolve(false),
                    callback: async (model, isNew) => {
                        const asset = await this.createOrUpdateTeamAsset(model.content_value, assetValueOverrides, teamAsset);

                        trackActivity("TeamAsset", "Replaced", null, null, {
                            id: asset.id,
                            orgId: asset.get("orgId"),
                            type: asset.get("type"),
                            isNew
                        });

                        await this.reloadAssets();

                        resolve(asset);
                    }
                });
            };

            ShowConfirmationDialog({
                title: "Update Asset",
                message: (
                    <>
                        <p>You can update this asset with a new version and any slides that currently use this asset will be automatically updated.</p>
                        <p>This can be useful if you need to update a team logo or headshot or other asset that you'd like to easily replace with a new version across all existing slides that use it.</p>
                    </>
                ),
                acceptCallback: showAssetsDialog,
                cancelCallback: () => resolve(false),
            });
        });
    }

    handleDeleteAssets = async selection => {
        if (await ShowConfirmationDialog({
            title: `Are you sure you want to delete ${selection.length == 1 ? "this asset" : selection.length + " selected selection"}?`,
            message: "Slides that already use this asset will not be changed. This action can not be undone.",
            buttonOptions: { cancelButtonVariant: "outlined", acceptButtonColor: "red" },
            okButtonLabel: "Delete",
        })) {
            const workspace = app.user.workspaces[AppController.workspaceId];
            const team = workspace.getDefaultTeam();

            if (team) {
                for (let asset of selection) {
                    // Remove entry from team's sharedResources
                    await team.update({ sharedResources: { [TEAM_ASSET]: { [asset.id]: null } } }, { silent: true });

                    trackActivity("TeamAsset", "Deleted", null, null, {
                        id: asset.id,
                        orgId: asset.get("orgId"),
                        type: asset.get("type")
                    });
                }

                this.reloadAssets();
            }
        }
    }

    handleHideItem = async asset => {
        // flag as hidden
        asset.update({ hidden: true });
        this.forceUpdate();

        const assetValues = asset.attributes;

        trackActivity("TeamAsset", "Hidden", null, null, {
            id: assetValues.id,
            orgId: assetValues.orgId,
            type: assetValues.type,
        });
    }

    handleShowItem = async asset => {
        // flag as not hidden
        asset.update({ hidden: false });
        this.forceUpdate();

        const assetValues = asset.attributes;

        trackActivity("TeamAsset", "Shown", null, null, {
            id: assetValues.id,
            orgId: assetValues.orgId,
            type: assetValues.type,
        });
    }

    handleMediaFilterChange = event => {
        const selectedMediaFilter = event.target.value;
        this.setState({ selectedMediaFilter });
    }

    handleTagFilterChange = event => {
        const selectedTagFilter = event.target.value;
        this.setState({ selectedTagFilter });
    }

    handleSort = ({ field, reverse }) => {
        this.setState({
            sortBy: field,
            sortDir: reverse ? 1 : -1,
        });
    }

    getSortedFilteredAssets() {
        const {
            sortBy,
            sortDir,
            loadedAssets,
            searchTerm,
            selectedMediaFilter,
            selectedTagFilter,
        } = this.state;
        const {
            editable
        } = this.props;

        // filtering
        const assetEntries = Object.values(loadedAssets).filter(asset => {
            if (
                !editable &&
                asset.get("hidden")
            ) {
                return false;
            }

            const tags = asset.get("tags") ?? [];

            const searchMatch = (
                !searchTerm?.length ||
                asset.get("assetName")?.toLowerCase().includes(searchTerm) ||
                tags.some(tag => tag.toLowerCase().includes(searchTerm))
            );
            const mediaMatch = (
                selectedMediaFilter === "all" ||
                asset.get("type") === selectedMediaFilter
            );
            const tagMatch = (
                selectedTagFilter === "  all  " ||
                tags.includes(selectedTagFilter)
            );

            return searchMatch && mediaMatch && tagMatch;
        });

        // sorting
        assetEntries.sort((a, b) => {
            const valueA = a.get(sortBy);
            const valueB = b.get(sortBy);
            return valueA < valueB ? sortDir : -sortDir;
        });

        return assetEntries;
    }

    getSortedTags() {
        const { editable } = this.props;
        const { loadedAssets } = this.state;

        const assetEntries = Object.values(loadedAssets).filter(asset => (editable || !asset.get("hidden")));
        const sortedTags = sortTagsByPopularity(assetEntries.map(asset => asset.get("tags")));

        return sortedTags;
    }

    handleSetTags = selection => {
        const { existingTags } = this.state;
        ShowDialog(BulkSetTagsDialog, {
            selection,
            existingTags,
            onAccept: async tags => {
                for (let asset of selection) {
                    asset.update({
                        tags: [...tags]
                    });
                }
                await this.reloadAssets();
            }
        });
    }

    handleBulkUpload = event => {
        const { existingTags } = this.state;
        const files = event.target.files;

        if (files.length == 0) return;

        ShowDialog(BulkAssetUploadDialog, {
            files,
            existingTags,
            types: AssetType.IMAGE,
            onAccept: async ({ tags, types }) => {
                const progressDialog = ShowDialog(ProgressDialog, {
                    title: "Uploading Assets...",
                    progress: 0
                });

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    progressDialog.setProgress(i / files.length * 100);
                    progressDialog.setMessage("Uploading " + file.name);

                    let fileType;
                    if (file.type == "image/jpeg") {
                        fileType = ASSET_FILETYPE.JPG;
                    } else if (file.type == "image/png") {
                        fileType = ASSET_FILETYPE.PNG;
                    } else if (file.type == "image/gif") {
                        fileType = ASSET_FILETYPE.GIF;
                    } else if (file.type == "image/svg+xml") {
                        fileType = ASSET_FILETYPE.SVG;
                    }
                    let currentType = AssetType.IMAGE;
                    if (types === "logo") {
                        currentType = AssetType.LOGO;
                    }
                    let asset = await ds.assets.getOrCreateImage({
                        file,
                        name: file.name,
                        fileType,
                        assetType: currentType,
                        metadata: {
                            source: "bulk-upload"
                        }
                    });

                    await this.createOrUpdateTeamAsset(asset.id, { tags, assetName: file.name }, null, true);
                }

                this.reloadAssets();
                progressDialog.props.closeDialog();
            }
        });
    }

    renderContextMenu = selection => {
        let menuItems = [];
        if (selection.length == 1) {
            menuItems.push(<MenuItem divider key="edit" onClick={() => this.handleEditSettings(selection[0])}>
                <Icon>edit</Icon>Edit Asset Properties...
            </MenuItem>);

            menuItems.push(<MenuItem divider key="replace" onClick={() => this.handleUpdateAsset(selection[0])}>
                <Icon>sync</Icon>Update Asset...
            </MenuItem>);

            menuItems.push(<MenuItem key="trash" onClick={() => this.handleDeleteAssets(selection)}>
                <Icon>delete</Icon>Delete Asset
            </MenuItem>);
        } else {
            menuItems.push(<MenuItem divider key="tags" onClick={() => this.handleSetTags(selection)}>
                <Icon>label</Icon>Set Tags...
            </MenuItem>);
            menuItems.push(<MenuItem key="trash" onClick={() => this.handleDeleteAssets(selection)}>
                <Icon>delete</Icon>Delete {selection.length} Selected Assets
            </MenuItem>);
        }

        return menuItems;
    }

    countTags = () => {
        const { editable } = this.props;
        const { loadedAssets, existingTags } = this.state;
        const assetEntries = Object.values(loadedAssets).filter(asset => (editable || !asset.get("hidden")));

        const tagsMap = {};

        assetEntries.map(asset => asset.get("tags")).forEach(tags => {
            tags?.forEach(tag => {
                if (!tagsMap[tag]) {
                    tagsMap[tag] = 1;
                } else {
                    tagsMap[tag]++;
                }
            });
        });

        return existingTags
            // Filter out tags that are not used
            .filter(tag => tagsMap[tag])
            .map(tag => {
                return {
                    id: tag,
                    name: tag,
                    count: tagsMap[tag]
                };
            });
    }

    render() {
        const {
            editable,
            addAssetCallback,
            handleConfirm,
        } = this.props;
        const {
            selectedMediaFilter,
            selectedTagFilter,
            sortBy,
            searchTerm,
            isLoading,
            existingTags
        } = this.state;

        const countTags = this.countTags();

        const tagOptions = [
            {
                id: "  all  ", // Spaces needed to prevent conflict with other tags
                name: "All Tags",
            },
            ...countTags.map(({ id, name, count }) => {
                return {
                    id,
                    name: `${name} (${count})`,
                };
            }),
        ];

        const assets = this.getSortedFilteredAssets();

        return (
            <UIPane>
                <StyledSearchBarContainer
                    className="search-container"
                    editable={editable}
                >
                    {editable && <>
                        <Breadcrumb>Team Assets</Breadcrumb>
                        <RoundIconButton
                            onClick={() => this.handleAddItem(existingTags)}
                        >
                            <Icon>add</Icon>
                        </RoundIconButton>
                        <Gap20 />
                        <Button small color="primary">
                            <Icon>drive_folder_upload</Icon>
                            Bulk Upload...
                            <UploadFileInput
                                type="file"
                                multiple
                                accept={"image/png,image/jpeg,image/gif,image/svg+xml"}
                                onChange={this.handleBulkUpload}
                            />
                        </Button>

                        <Gap30 />
                    </>}
                    <StyledSearchBarInnerContainer editable={editable}>
                        <SlideSearchInput
                            query={searchTerm}
                            onSearch={this.handleSearch}
                            placeholder="Search team assets..."
                        />

                        <FilterDropDown
                            onChange={this.handleMediaFilterChange}
                            selectedFilter={selectedMediaFilter}
                            filterOptions={mediaFilterOptions}
                        />
                        <FilterDropDown
                            onChange={this.handleTagFilterChange}
                            selectedFilter={selectedTagFilter}
                            filterOptions={tagOptions}
                        />
                        <SortMenu
                            selectedOption={sortBy}
                            disabled={searchTerm.length > 0}
                            sortOptions={sortOptions}
                            onSetSort={this.handleSort}
                        />
                    </StyledSearchBarInnerContainer>
                </StyledSearchBarContainer>
                <StyledUIPaneResultsContainer editable={editable}>
                    <FetchingClickShield visible={isLoading} backgroundColor="#eee" />
                    {assets.length > 0 &&
                    <ThumbnailGridContainer>
                        <MultiSelectThumbnailGrid
                            items={assets}
                            columns={3}
                            colGap={30}
                            rowGap={30}
                            readOnly={!editable}
                            allowDragging={false}
                            allowMultiSelect={editable}
                            thumbnailClass={AssetThumbnail}
                            onItemSelected={selection => {
                                if (addAssetCallback) {
                                    addAssetCallback(selection[0]);
                                    handleConfirm();
                                }
                            }}
                            onDoubleClick={asset => this.handleEditSettings(asset)}
                            renderContextMenuItems={selection => this.renderContextMenu(selection)}
                        />
                    </ThumbnailGridContainer>
                    }
                    {assets.length === 0 && (
                        (
                            !searchTerm &&
                            selectedMediaFilter === "all" &&
                            selectedTagFilter === "  all  "
                        )
                            ? <NoTeamAssetNotice />
                            : <NoMatchNotice />
                    )}
                </StyledUIPaneResultsContainer>
            </UIPane>
        );
    }
}

const ThumbnailBar = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% + 10px);

    label {
        font-size: 14px;
        letter-spacing: 0px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        flex-grow: 2;
        text-transform: none;
        margin: 0px;
    }
`;

class AssetThumbnail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            thumbnailUrl: null,
            visible: false,
        };

        this.ref = React.createRef();
    }

    async getThumbnailUrl() {
        return await this.props.item.getURL(ASSET_RESOLUTION.XSMALL);
    }

    fetchThumbnailUrl() {
        const { thumbnailUrl: currentThumbnailUrl } = this.state;

        this.setState({ thumbnailUrl: null });

        this.getThumbnailUrl()
            .then(thumbnailUrl => this.setState({ thumbnailUrl }))
            .catch(err => {
                logger.error(err, "[TeamAssetsPane] [AssetThumbnail] getThumbnailUrl() failed");

                if (currentThumbnailUrl) {
                    // Restore previous thumbnail
                    this.setState({ thumbnailUrl: currentThumbnailUrl });
                }
            });
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                if (_.isEmpty(this.state.thumbnailUrl)) {
                    this.fetchThumbnailUrl();
                }
                if (!this.state.visible) {
                    this.setState({ visible: true });
                }
            } else {
                if (this.state.visible) {
                    this.setState({ visible: false });
                }
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item.changed?.hash) {
            this.fetchThumbnailUrl();
        }
    }

    render() {
        const { item: asset, onMouseDown, onDoubleClick, onContextMenu, selected, readOnly, allSelected, renderContextMenuItems } = this.props;
        const { thumbnailUrl } = this.state;

        return (
            <ThumbnailContainer
                onMouseDown={onMouseDown}
                onDoubleClick={!readOnly && onDoubleClick}
                onContextMenu={!readOnly && onContextMenu}
                ref={this.ref}
            >
                <Thumbnail url={thumbnailUrl} selected={selected} />
                <ThumbnailBar>
                    <label className="presentation_item_name">{asset.get("assetName")}</label>
                    {asset.get("hidden") && (
                        <Icon>visibility_off</Icon>
                    )}
                    {!readOnly && (
                        <IconMenu>{renderContextMenuItems(allSelected)}</IconMenu>
                    )}
                </ThumbnailBar>
            </ThumbnailContainer>
        );
    }
}
