import * as geom from "js/core/utilities/geom";
import getLogger, { LogGroup } from "js/core/logger";
import { getBase64Image } from "js/core/utilities/utilities";
import { _, tinycolor } from "js/vendor";
import {
    AssetType,
    CellChangeStyle,
    AuthoringShapeType,
    FormatType,
    AuthoringBlockType,
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
    HorizontalAlignType,
    ListStyleType
} from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { ds } from "js/core/models/dataService";
import Logos from "js/core/models/logos";
import { ELEMENT_IDS } from "common/constants";
import { getSelectionState } from "js/core/utilities/htmlTextHelpers";
import { DecorationType } from "common/constants";

import PolyLineExporter from "./PolyLineExporter";
import { reactUnmount } from "js/react/renderReactRoot";
import { svgImageWithFilterToUrl } from "common/utils/svgImageWithFilterToUrl";
import { exportGradientConfig } from "common/utils/exportGradientConfig";

// issues
// ---------------------------------------------------------
// color alpha is not supported (text fontColor opacity, etc.)

const SLIDE_WIDTH = 13.33;
const MULT = SLIDE_WIDTH / CANVAS_WIDTH;

const logger = getLogger(LogGroup.EXPORT);

export class CanvasExporter {
    convertCoordinate(val) {
        return val * MULT;
    }

    convertRectToSlide(rect) {
        let width = this.convertCoordinate(rect.width);
        let height = this.convertCoordinate(rect.height);
        if (width < 0) {
            width = 0.1;
        }
        if (height < 0) {
            height = 0.1;
        }
        return {
            x: this.convertCoordinate(rect.left),
            y: this.convertCoordinate(rect.top),
            w: width,
            h: height
        };
    }

    convertPointToSlide(point) {
        return {
            x: this.convertCoordinate(point.x),
            y: this.convertCoordinate(point.y)
        };
    }

    getColorFromRGB(rgb) {
        if (rgb.contains("rgba")) {
            return {
                type: "solid",
                color: tinycolor(rgb)
                    .toHexString()
                    .substr(1),
                alpha: parseInt(100 - tinycolor(rgb)._a * 100)
            };
        } else if (rgb == "transparent" || rgb == "none") {
            return null;
        } else {
            return tinycolor(rgb)
                .toHexString()
                .substr(1);
        }
    }

    getSVGElementFillColor(svg) {
        let fill = svg.style.fill || svg.getAttribute("fill");
        let fillOpacity = svg.style.fillOpacity || svg.getAttribute("fill-opacity") || 1;

        if (fillOpacity == 1) {
            return this.getColorFromRGB(fill);
        } else {
            return {
                type: "solid",
                color: tinycolor(fill)
                    .toHexString()
                    .substr(1),
                alpha: parseInt(100 - fillOpacity * 100)
            };
        }
    }

    constructor(pptx, canvas) {
        this.pptx = pptx;
        this.canvas = canvas;
    }

    addWarning(warning) {
        if (!this.exportWarnings.contains(warning)) {
            this.exportWarnings.push(warning);
        }
    }

    async export(slide, exportWarnings, debug = false) {
        logger.info(`CanvasExporter export() slide id: ${this.canvas.dataModel.id}`);

        this.slide = slide;
        this.debug = debug;
        this.exportWarnings = exportWarnings;
        this.slideNotes = [];

        this.canvasBounds = geom.Rect.FromBoundingClientRect(this.canvas.el.getBoundingClientRect());

        this.slide.back = this.canvas
            .getBackgroundColor()
            .toHexString()
            .substr(1);

        if (this.canvas.slideTemplate.constructor.id == "slidePlaceholder") {
            this.slide.back = "ffffff";
        }

        if (this.canvas.hasSlideNotes()) {
            this.slideNotes.push(this.canvas.getNormalizedSlideNotes());
        }

        // Sorting elements by layer
        for (const element of _.sortBy(this.canvas.layouter.elements, element => element.calculatedProps?.layer)) {
            await this.exportElement(element, slide);
        }

        if (this.slideNotes.length > 0) {
            this.slide.addNotes(this.slideNotes.join("\n"));
        }

        // the exporter modifies some node attributes so re-render the canvas as quickly as possible
        reactUnmount(this.canvas.el);
        await this.canvas.refreshCanvas();
    }

    async exportElement(element, slide) {
        if (element.options.preventExport) return;
        if (!element.DOMNode || element.DOMNode.style.display === "none") return;
        if (element.calculatedProps?.opacity === 0) return;

        if (element?.isInstanceOf("ListDecoration")) {
            return;
        }

        if (element.decoration && element.decoration.hasBackground) {
            await this.exportDecoration(element.decoration, "background");
        }
        if (element.type === "CycleDiagram") {
            for (const childNode of element.DOMNode.querySelectorAll("svg")) {
                await this.exportSVGNodeAsImage(element, childNode, element.canvasBounds);
            }
        } else if (element.type === "StatisticChangeInValueIcon") {
            await this.exportStatisticChangeInValueIcon(element);
        } else if (element.type === "VignetteOverlayElement") {
            return this.exportVignetteOverlay(element);
        } else if (element.type === "BorderOverlayElement") {
            return this.exportBorderOverlay(element);
        } else if (element.type === "CanvasBackground") {
            return this.exportCanvasBackground(element);
        } else if (element?.isInstanceOf("DecorationElement")) {
            // Do nothing
        } else if (element?.isInstanceOf("TextElement")) {
            await this.exportText(element);
        } else if (element?.isInstanceOf("ContentElement")) {
            await this.exportContent(element);
        } else if (element?.isInstanceOf("SVGElement") && !(element?.isInstanceOf("SVGGroupElement"))) {
            switch (element.type) {
                case "SVGPolylineElement":
                case "AuthoringPathElement":
                    await this.exportSVGPolyline(element);
                    break;
                case "SVGCircleElement":
                    this.exportSVGShape(element, this.pptx.shapes.OVAL);
                    break;
                case "SVGRectElement":
                    this.exportSVGShape(element, this.pptx.shapes.RECTANGLE);
                    break;
                default:
                    if (element.exportAsImage || element.options.exportAsImage) {
                        await this.exportSVGNodeAsImage(element, element.DOMNode, element.canvasBounds);
                    } else {
                        await this.exportSVGNode(element);
                    }
            }
            return;
        } else if (element.type === "ConnectorItem") {
            const isConnectorLineWeightBold = element.model?.lineWeight === "bold";
            await this.exportSVGNode(element, element.pathNode, {
                reduceStrokeWidth: !isConnectorLineWeightBold
            });
            if (element.startDecorationNode) {
                await this.exportSVGNode(element, element.startDecorationNode);
            }
            if (element.endDecorationNode) {
                await this.exportSVGNode(element, element.endDecorationNode);
            }
        } else if (element.type === "ImageFrame") {
            this.slide.addImage({
                path: element.frameURL,
                ...this.convertRectToSlide(element.canvasBounds)
            });
        } else if (element.type === "WordCloudElement") {
            return this.exportWordCloud(element);
        } else if (element.type === "Footer") {
            await this.exportFooter(element);
        } else if (element.type === "StreetMap") {
            return this.exportStreetMap(element);
        } else if (element.type === "OrgChartConnectors") {
            return this.exportOrgChartConnectors(element);
        } else if (element.type === "Icon") {
            // Not exporting Icon elements w/o icons
            if (element.iconPath) {
                await this.exportSVGNode(element, element.DOMNode.firstElementChild.querySelector("path"));
            }
        } else if (element.type === "Video") {
            this.hasVideo = true;
            return this.exportVideo(element);
        } else if (element.type === "TableFrame") {
            return this.exportTable(element);
        } else if (element.type === "Chart") {
            await this.exportSVGNodeAsImage(element, element.DOMNode.querySelector(".highcharts-root"), element.canvasBounds);
        } else if (element.type === "Picture") {
            // skip - handled by contentelement
        } else if (element.type === "Logo") {
            // skip - handled by contentelement
        } else if (element.type === "AuthoringShapeElement" || element.type === "ShapeCallout") {
            await this.exportAuthoringShape(element);
        } else if (element.type == "WebView") {
            this.addWarning("WebView is not supported");
            return;
        } else if (Object.values(element.elements).filter(element => !element.options.preventExport).length === 0 && element.DOMNode.childElementCount === 1 && element.DOMNode.firstElementChild.nodeName === "svg") {
            // catch for custom svg elements
            await this.exportSVGNode(element);
        }

        // special case for text and image elements
        if (element.parentElement.type === "TextAndImage" && element.parentElement.decoration?.hasForeground) {
            await this.exportDecoration(element.parentElement.decoration, "foreground");
        }

        for (let childElement of _.sortBy(element.elements, element => element.calculatedProps?.layer)) {
            if (childElement === element.decoration && element.decoration?.hasForeground) {
                await this.exportDecoration(element.decoration, "foreground");
            } else {
                await this.exportElement(childElement, slide);
            }
        }

        // If iamge has a frame, export it last in order for it to be added on top of the stack
        const frameElement = element.parentElement.DOMNode.querySelector(".frame");
        if (frameElement) {
            this.exportFrame(element, frameElement);
        }

        // NOTE: Moved commented block below into childElement loop above
        //   so that decorations are rendered in the proper order.
        //
        //   Leaving this here in case this change breaks something.
        //   Delete this commented code if it's been several months without issue.

        // if (element.decoration && element.decoration.hasForeground) {
        //     await this.exportDecoration(element.decoration, "foreground");
        // }
    }

    exportFrame(element, svgElement) {
        if (!svgElement) {
            return;
        }

        const bounds = geom.Rect.FromBBox(svgElement.getBoundingClientRect())
            .offset(-this.canvasBounds.left, -this.canvasBounds.top)
            .multiply(1 / this.canvas.canvasScale);

        this.slide.addImage({
            path: element.frameURL,
            ...this.convertRectToSlide(bounds)
        });
    }

    async exportDecoration(decorationElement, position) {
        const ref = position === "background" ? decorationElement.backgroundRef : decorationElement.foregroundRef;
        const domNode = ref.current;

        // if we do not have any decoration elements, return
        if (!domNode) {
            return;
        }

        const computedStyle = window.getComputedStyle(domNode);
        const borderRadius = parseFloat(computedStyle.getPropertyValue("border-radius") || 0);

        const { type, shape, cornerRadius = borderRadius } = decorationElement.styles;

        if (type === "svg" || shape === DecorationType.OCTAGON) {
            await this.exportSVGNode(decorationElement, domNode);
        } else if (shape === DecorationType.RECT || shape === DecorationType.ROUNDED_RECT || shape === DecorationType.CIRCLE) {
            let bounds = decorationElement.bounds;
            let pptShape;
            if (shape === DecorationType.RECT || shape === DecorationType.ROUNDED_RECT) {
                pptShape = cornerRadius ? this.pptx.shapes.ROUNDED_RECTANGLE : this.pptx.shapes.RECTANGLE;
            } else if (shape === DecorationType.CIRCLE) {
                pptShape = this.pptx.shapes.OVAL;
                bounds = decorationElement.bounds.square().centerInRect(decorationElement.bounds);
            }

            const shapeOptions = {
                ...this.convertRectToSlide(
                    bounds
                        .offset(decorationElement.canvasBounds.position)
                        .offset(-decorationElement.parentElement.styles.paddingLeft || 0, -decorationElement.parentElement.styles.paddingTop || 0)
                )
            };

            if (domNode.style.backgroundColor) {
                shapeOptions.fill = this.getColorFromRGB(domNode.style.backgroundColor);
            }

            if (domNode.style.borderColor && domNode.style.borderColor !== "none" && parseFloat(domNode.style.borderWidth) > 0) {
                shapeOptions.line = this.getColorFromRGB(domNode.style.borderColor);
                shapeOptions.lineSize = parseFloat(domNode.style.borderWidth);
                switch (domNode.style.borderStyle) {
                    case "dashed":
                        shapeOptions.lineDash = "dash";
                        break;
                    default:
                        shapeOptions.lineDash = "solid";
                        break;
                }
            }

            if (cornerRadius && shape === DecorationType.RECT) {
                shapeOptions.rectRadius = cornerRadius / 100;
            }

            this.slide.addShape(pptShape, shapeOptions);
        }
    }

    async exportAuthoringShape(authoringShapeElement) {
        if (
            authoringShapeElement.model.fill !== "none" ||
            authoringShapeElement.model.stroke !== "none" ||
            authoringShapeElement.model.shape === "raw_svg"
        ) {
            const shapeType = authoringShapeElement.model.shape;
            const shapeNode = authoringShapeElement.DOMNode.firstElementChild.firstElementChild;
            if (shapeType === AuthoringShapeType.RECT) {
                this.exportSVGShape(authoringShapeElement, this.pptx.shapes.RECTANGLE, shapeNode);
            } else if (shapeType === AuthoringShapeType.ELLIPSE) {
                this.exportSVGShape(authoringShapeElement, this.pptx.shapes.OVAL, shapeNode);
            } else if (shapeType === AuthoringShapeType.CAPSULE) {
                this.exportSVGShape(authoringShapeElement, this.pptx.shapes.ROUNDED_RECTANGLE, shapeNode);
            } else if (shapeType === AuthoringShapeType.RAW_SVG) {
                await this.exportSVGNode(authoringShapeElement, shapeNode.firstElementChild.firstElementChild);
            } else {
                await this.exportSVGNode(authoringShapeElement, shapeNode);
            }
        }
    }

    async exportText(textElement) {
        if (textElement.model && textElement.model.formatOptions && (textElement.model.formatOptions.accountingStyle || textElement.model.formatOptions.changeStyle === CellChangeStyle.ARROWS)) {
            this.addWarning("Some formatting may be missing from the table slide.");
        }

        let textRuns = [];
        let currentTextBounds = null;

        const writeTextRuns = () => {
            if (textRuns.length > 0) {
                const textProps = {
                    ...this.convertRectToSlide(currentTextBounds),
                    valign: textElement.verticalAlign,
                    margin: 0
                };
                if (textElement.id === ELEMENT_IDS.SLIDE_NUM) {
                    const logoPosition = textElement.parentElement.logoPosition;
                    textProps.isSlideNumber = true;
                    textProps.slideNumberPosition = logoPosition === "left" ? "right" : "left";
                }

                this.slide.addText(textRuns, textProps);
                textRuns = [];
            }
            currentTextBounds = null;
        };

        for (const blockRef of Object.values(textElement.blockContainerRef.current.blockRefs)) {
            if (!blockRef?.current) {
                continue;
            }

            const block = blockRef.current;
            const blockProps = block.props;

            if (blockProps.type === AuthoringBlockType.TEXT) {
                let blockBounds = blockProps.containerBounds;

                const padding = Math.clamp(Math.max(blockBounds.width / 10, blockProps.textStyles.fontSize / 10), 3, 100);
                const textAlign = block.ref.current.style.getPropertyValue("text-align");
                if (textAlign === HorizontalAlignType.LEFT) {
                    blockBounds = blockBounds.inflate({ right: padding });
                } else if (textAlign === HorizontalAlignType.RIGHT) {
                    blockBounds = blockBounds.inflate({ left: padding });
                } else {
                    blockBounds = blockBounds.inflate({ left: padding / 2, right: padding / 2 });
                }

                blockBounds = blockBounds.offset(textElement.calculatedProps.textBounds.position);
                blockBounds = blockBounds.offset(textElement.canvasBounds.position);

                if (!blockProps.model.listStyle) {
                    // Write what we already have
                    writeTextRuns();
                }

                currentTextBounds = currentTextBounds?.union(blockBounds) ?? blockBounds;

                const blockTextRuns = this.getTextRunsForAuthoringTextBlock(block, textElement);
                textRuns.push(...blockTextRuns);

                if (!blockProps.model.listStyle) {
                    // Write current block
                    writeTextRuns();
                }
            } else {
                writeTextRuns();
            }

            switch (blockProps.type) {
                case AuthoringBlockType.CODE:
                    this.exportAuthoringCodeBlock(block);
                    break;
                case AuthoringBlockType.EQUATION:
                    const backgroundOptions = {
                        ...this.convertRectToSlide(geom.Rect.FromBBox(block.ref.current.getBoundingClientRect()).offset(-this.canvasBounds.left, -this.canvasBounds.top).multiply(1 / this.canvas.canvasScale)),
                        fill: "ffffff"
                    };
                    this.slide.addShape(this.pptx.shapes.RECTANGLE, backgroundOptions);

                    await this.exportRawSVGNode(block.ref.current.firstElementChild);
                    break;
                case AuthoringBlockType.DIVIDER:
                    if (block.model.style != "spacer") {
                        const lineOptions = {
                            ...this.convertRectToSlide(geom.Rect.FromBBox(block.ref.current.firstChild.getBoundingClientRect()).offset(-this.canvasBounds.left, -this.canvasBounds.top).multiply(1 / this.canvas.canvasScale)),
                            h: 0,
                            line: this.getColorFromRGB(block.ref.current.querySelector("line").getAttribute("stroke")),
                            lineSize: 1
                        };
                        this.slide.addShape(this.pptx.shapes.LINE, lineOptions);

                        if (block.model.style != "line") {
                            this.addWarning("Divider line style was exported as a plain line.");
                        }
                    }
                    break;
            }
        }

        // any unwritten text runs?
        writeTextRuns();
    }

    async exportStatisticChangeInValueIcon(statisticChangeInValueIcon) {
        const textProps = {
            ...this.convertRectToSlide(
                geom.Rect.FromBBox(statisticChangeInValueIcon.DOMNode.getBoundingClientRect())
                    .offset(-this.canvasBounds.left, -this.canvasBounds.top)
                    .multiply(1 / this.canvas.canvasScale)),
            valign: statisticChangeInValueIcon.verticalAlign,
            margin: 0,
            fontSize: 10,
            color: "ffffff"
        };

        const cellValue = statisticChangeInValueIcon.model.positive ? "▲" : "▼";

        this.slide.addText(cellValue, textProps);
    }

    getTextRunsForAuthoringTextBlock(block, textElement) {
        const PPT_ADJ = 0.75;

        const blockProps = block.props;

        const contentEditableNode = textElement.blockContainerRef.current.blockRefs[blockProps.id].current.ref.current;

        const textContent = contentEditableNode.textContent;
        if (!textContent) {
            return [];
        }

        // Explicitly enabling user-select on the contenteditable to allow text selection in Safari
        const userSelect = contentEditableNode.style.getPropertyValue("user-select");
        contentEditableNode.style.setProperty("user-select", "text");
        contentEditableNode.style.setProperty("-webkit-user-select", "text");

        const selection = window.getSelection();
        selection.selectAllChildren(contentEditableNode);
        selection.collapseToStart();

        const textStyles = textElement.calculatedProps.blockProps.findById(blockProps.id).textStyles;

        const textRuns = [];
        let stepsPerformed = 0;
        let currentTextOptions = {};
        let currentText = "";
        while (true) {
            selection.modify("extend", "forward", "character");
            const range = selection.getRangeAt(0);
            let selectedNode = range.commonAncestorContainer;
            while (selectedNode.nodeName === "#text") {
                selectedNode = selectedNode.parentElement;
            }
            const nodeStyles = window.getComputedStyle(selectedNode);

            const color = this.getColorFromRGB(document.queryCommandValue("foreColor"));
            const bold = !!document.queryCommandState("bold");
            const italic = !!document.queryCommandState("italic");
            const underline = !!document.queryCommandState("underline");
            const strike = !!document.queryCommandState("strikeThrough");
            const subscript = !!document.queryCommandState("subscript");
            const superscript = !!document.queryCommandState("superscript");
            const fontSize = nodeStyles.getPropertyValue("font-size").replace("px", "");
            const charSpacing = parseFloat(nodeStyles.getPropertyValue("letter-spacing").replace("px", "")).toFixed(2);
            const lineSpacing = parseFloat(textStyles.lineHeight * fontSize).toFixed(2);
            const align = window.getComputedStyle(contentEditableNode).getPropertyValue("text-align");
            const isLink = selectedNode.nodeName === "A";

            const fontFamily = textStyles.fontFamily;
            const fontFace = textElement.fonts[fontFamily].label;

            const textOptions = {
                color,
                bold,
                italic,
                underline,
                strike,
                subscript,
                superscript,
                fontSize: fontSize * PPT_ADJ,
                fontFace,
                align,
                charSpacing: charSpacing * PPT_ADJ,
                lineSpacing: lineSpacing * PPT_ADJ,
                break: false
            };

            if (isLink) {
                let url = selectedNode.href;
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                    url = "https://" + url;
                }

                // This is mainly done for exporting to Google Slides
                // for if we have a link like "https://www.google.com/search?q=hello&world"
                // it will be converted to "https://www.google.com/search?q=hello&amp;world"
                // so that it can be properly displayed in the exported PowerPoint
                // if the expoport will break the link and the export will not work properly
                textOptions.hyperlink = { url: _.escape(url) };
            }

            // NOTE: non-printable unicode chars are sanitized by selection.toString()
            if (_.isEqual(textOptions, currentTextOptions)) {
                currentText += selection.toString();
            } else {
                if (currentText) {
                    textRuns.push({ text: currentText, options: currentTextOptions });
                }
                currentTextOptions = textOptions;
                currentText = selection.toString();
            }

            selection.collapseToEnd();

            stepsPerformed++;

            const { isAtEnd } = getSelectionState(contentEditableNode);
            // The second condition is a rough estimation to make sure we never end up in an endless cycle
            if (isAtEnd || stepsPerformed > blockProps.model.html.length) {
                textRuns.push({ text: currentText, options: currentTextOptions });
                break;
            }
        }

        if (textRuns.length > 0) {
            if (blockProps.model.listStyle) {
                if (blockProps.model.listStyle !== ListStyleType.TEXT) {
                    textRuns[0].options.bullet = blockProps.model.listStyle === ListStyleType.NUMBERED ? { type: "number" } : true;
                }
                textRuns[0].options.indentLevel = blockProps.indent;
            }

            textRuns[0].options.break = true;
            textRuns[0].options.paraSpaceBefore = (blockProps.blockMargin.top) * .735;
        }

        // Restoring user-select to its intial state
        if (userSelect) {
            contentEditableNode.style.setProperty("user-select", userSelect);
            contentEditableNode.style.setProperty("-webkit-user-select", userSelect);
        } else {
            contentEditableNode.style.removeProperty("user-select");
            contentEditableNode.style.removeProperty("-webkit-user-select");
        }

        return textRuns;
    }

    exportAuthoringCodeBlock(block) {
        const preNode = block.codeRef.current.firstElementChild;
        const userSelectAttrs = ["user-select", "-moz-user-select", "-webkit-user-select", "-ms-user-select"];
        userSelectAttrs.forEach(userSelectAttr => preNode.style.setProperty(userSelectAttr, "text"));

        const containerNode = block.codeRef.current.parentElement;
        const containerNodeStyles = window.getComputedStyle(containerNode);

        const textRuns = [];

        const exportText = () => {
            const fill = this.getColorFromRGB(containerNodeStyles.getPropertyValue("background"));

            const textOptions = {
                ...this.convertRectToSlide(
                    geom.Rect.FromBBox(containerNode.getBoundingClientRect())
                        .offset(-this.canvasBounds.left, -this.canvasBounds.top)
                        .multiply(1 / this.canvas.canvasScale)
                ),
                valign: "middle",
                fill
            };

            this.slide.addText(textRuns, textOptions);
        };

        if (!block.model.html || !preNode.textContent) {
            // Export empty text box
            exportText();
            return;
        }

        const selection = window.getSelection();
        selection.selectAllChildren(preNode);
        selection.collapseToStart();

        let stepsPerformed = 0;
        let currentTextOptions = {};
        let currentText = "";
        while (true) {
            selection.modify("extend", "forward", "character");
            const range = selection.getRangeAt(0);
            let selectedNode = range.commonAncestorContainer;
            while (selectedNode.nodeName === "#text") {
                selectedNode = selectedNode.parentElement;
            }
            const nodeStyles = window.getComputedStyle(selectedNode);

            const color = this.getColorFromRGB(document.queryCommandValue("foreColor"));
            const bold = !!document.queryCommandState("bold");
            const italic = !!document.queryCommandState("italic");
            const underline = !!document.queryCommandState("underline");
            const strike = !!document.queryCommandState("strikeThrough");
            const subscript = !!document.queryCommandState("subscript");
            const superscript = !!document.queryCommandState("superscript");
            const fontSize = Math.round(parseInt(nodeStyles.getPropertyValue("font-size").replace("px", "")) * 0.75);
            // Code block uses monospace font family, which is usually mapped to Courier font by the browser
            const fontFace = "Courier";
            const lineSpacing = parseFloat(parseInt(nodeStyles.getPropertyValue("line-height")) * 0.75).toFixed(2);

            const textOptions = {
                color,
                bold,
                italic,
                underline,
                strike,
                subscript,
                superscript,
                fontSize,
                fontFace,
                lineSpacing,
                align: "left",
                break: false
            };

            // NOTE: non-printable unicode chars are sanitized by selection.toString()
            if (_.isEqual(textOptions, currentTextOptions)) {
                currentText += selection.toString();
            } else {
                if (currentText) {
                    textRuns.push({ text: currentText, options: currentTextOptions });
                }
                currentTextOptions = textOptions;
                currentText = selection.toString();
            }

            stepsPerformed++;

            selection.collapseToEnd();
            const { isAtEnd } = getSelectionState(preNode);
            // The second condition is a rough estimation to make sure we never end up in an endless cycle
            if (isAtEnd || stepsPerformed > (block.html ?? "").length) {
                textRuns.push({ text: currentText, options: currentTextOptions });
                break;
            }
        }

        if (textRuns.length > 0) {
            textRuns[0].options.break = true;

            textRuns[0].options.paraSpaceBefore = Math.round(block.blockMargin.top * 1.2);
            textRuns[textRuns.length - 1].options.paraSpaceAfter = Math.round(block.blockMargin.bottom * 1.2);
        }

        userSelectAttrs.forEach(userSelectAttr => preNode.style.setProperty(userSelectAttr, "none"));

        exportText();
    }

    exportSVGPolyline(element) {
        if (element.styles.fill && element.styles.fill !== "none") {
            // We can't export lines with fill, so we export as a baked shape instead
            return this.exportSVGNode(element, element.DOMNode.firstElementChild.firstElementChild);
        }

        const polyline = new PolyLineExporter(this, element);
        return polyline.generate();
    }

    exportSVGShape(element, shapeType, svgNode = element.DOMNode.firstElementChild) {
        const shapeOptions = {
            ...this.convertRectToSlide(geom.Rect.FromBBox(svgNode.getBoundingClientRect()).offset(-this.canvasBounds.left, -this.canvasBounds.top).multiply(1 / this.canvas.canvasScale))
        };

        if (
            svgNode.style.fill &&
            svgNode.style.fill !== "transparent" &&
            svgNode.style.fill !== "none"
        ) {
            shapeOptions.fill = this.getSVGElementFillColor(svgNode);
        }
        if (
            svgNode.style.strokeWidth &&
            parseFloat(svgNode.style.strokeWidth) > 0 &&
            svgNode.style.stroke !== "none"
        ) {
            shapeOptions.line = this.getColorFromRGB(svgNode.style.stroke || "black");
            shapeOptions.lineSize = parseFloat(svgNode.style.strokeWidth);
        }

        if (element.styles && element.styles.shadow) {
            shapeOptions.shadow = {
                type: "outer",
                angle: 0,
                offset: 0.1,
                blur: parseFloat(element.styles.shadow.blur),
                color: "000000",
                opacity: parseFloat(element.styles.shadow.opacity)
            };
        }
        if ((element.model.adj1 || 0) > 0) {
            shapeType = this.pptx.shapes.ROUNDED_RECTANGLE;
            shapeOptions.rectRadius = (element.model.adj1 || 0) / 100;
        }

        this.slide.addShape(shapeType, shapeOptions);
    }

    async exportVideo(element) {
        this.exportSVGShape(element.background, this.pptx.shapes.RECTANGLE);

        if (element.videoType === "upload") {
            const videoAsset = await ds.assets.getAssetById(element.model.videoAssetId, AssetType.VIDEO);
            const previewAssetId = videoAsset.get("previewAssetId");
            if (previewAssetId) {
                const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                const previewUrl = await previewAsset.getURL("original", true);
                const imageData = await this.loadBase64Image(previewUrl);

                let imageBounds = new geom.Rect(0, 0, previewAsset.get("w"), previewAsset.get("h"));
                imageBounds = imageBounds.fitToSize(element.canvasBounds.size);
                imageBounds = imageBounds.centerInRect(element.canvasBounds);

                this.slide.addImage({
                    data: imageData,
                    ...this.convertRectToSlide(imageBounds)
                });

                this.addWarning("Uploaded videos are not currently supported and will be exported as static images.");
            } else {
                this.addWarning("Uploaded videos are not currently supported.");
            }
            return;
        }

        if (element.publicVideoUrl) {
            this.slide.addMedia({
                type: "online",
                link: element.publicVideoUrl,
                ...this.convertRectToSlide(element.canvasBounds)
            });

            this.slideNotes.push(`Video URL: ${element.publicVideoUrl}`);
        }
    }

    async getSvgFontsDef(element) {
        let svg = "";

        const fontFaces = [];
        await Promise.all(Object.entries(element.fonts ?? {}).map(([fontId, font]) => Promise.all(font.styles.map(async style => {
            const url = await style.getFontUrl();
            const fontData = await fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise(resolve => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }));

            fontFaces.push({
                "font-family": fontId,
                "font-weight": style.weight,
                "font-style": style.italic ? "italic" : "normal",
                src: `url('${fontData}')`
            });
        }))));

        if (fontFaces.length > 0) {
            svg += `<defs><style type="text/css">`;
            svg += fontFaces.map(fontFace => `@font-face {\n${Object.entries(fontFace).reduce((css, [key, value]) => `${css}\n${key}: ${value};`, "")}\n}`).join("\n");
            svg += `</style></defs>`;
        }

        return svg;
    }

    async exportSVGNodeAsImage(element, svgNode, bounds, padding = 20) {
        // SVG elements can exceed their bounds if they have stroked paths so we need to add padding to avoid clipping when converting to image

        if (!bounds) {
            bounds = geom.Rect.FromBBox(svgNode.getBoundingClientRect())
                .offset(-this.canvasBounds.left, -this.canvasBounds.top)
                .multiply(1 / this.canvas.canvasScale);
        }

        const loadImage = src => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve(getBase64Image(img));
                };
                img.onerror = err => {
                    reject(err);
                };
                img.src = src;
            });
        };

        let svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${bounds.width + padding * 2}' height='${bounds.height + padding * 2}'>`;
        // if the node has a clip path, add it
        if (svgNode.instance && svgNode.instance.clipper) {
            const clipPath = new XMLSerializer().serializeToString(svgNode.instance.clipper.node);
            svg += `<defs>${clipPath}</defs>`;
        }

        if (svgNode.instance && svgNode.instance.track && svgNode.instance.track()) {
            const serializedTrackPath = new XMLSerializer().serializeToString(svgNode.instance.track().node);
            svg += `<defs>${serializedTrackPath}</defs>`;
        }

        svg += `<g style="transform: translateX(${padding}px) translateY(${padding}px)">`;
        svg += new XMLSerializer().serializeToString(svgNode);
        if (svg.includes("font-family")) {
            svg += await this.getSvgFontsDef(element);
        }
        svg += new XMLSerializer().serializeToString(document.getElementById("elements-filters"));
        svg += "</g></svg>";

        const imageData = await loadImage("data:image/svg+xml; charset=utf8, " + encodeURIComponent(svg));

        this.slide.addImage({
            data: imageData,
            ...this.convertRectToSlide(bounds.addPadding(padding))
        });
    }

    async exportRawSVGNode(svgNode) {
        const svg = new XMLSerializer().serializeToString(svgNode);
        const dataURL = "data:image/svg+xml;base64," + window.btoa(svg);

        const bounds = geom.Rect.FromBBox(svgNode.getBoundingClientRect())
            .offset(-this.canvasBounds.left, -this.canvasBounds.top)
            .multiply(1 / this.canvas.canvasScale);

        const imageOptions = {
            data: dataURL,
            preview: await this.loadBase64Image(dataURL),
            ...this.convertRectToSlide(bounds)
        };
        this.slide.addImage(imageOptions);
    }

    async exportSVGNode(element, svgNode = element.DOMNode.firstElementChild, options = {
        reduceStrokeWidth: true
    }) {
        let padding = 0;

        // svg bbox doesn't account for stroke so we need to pad the bbox by half the stroke width
        if (svgNode.style.strokeWidth && (svgNode.style.stroke && svgNode.style.stroke !== "none")) {
            padding = parseFloat(svgNode.style.strokeWidth) / 2;
        } else if (svgNode.getAttribute("stroke-width") && svgNode.getAttribute("stroke") && svgNode.getAttribute("stroke") !== "none") {
            padding = parseFloat(svgNode.getAttribute("stroke-width")) / 2;
        }

        for (let styledNode of svgNode.querySelectorAll("[style]")) {
            if (!_.isEmpty(styledNode.style.strokeWidth)) {
                padding = parseFloat(styledNode.style.strokeWidth) / 2;
                break;
            }
        }

        // svg export doesn't support filters
        if (svgNode.style.filter) {
            svgNode.style.filter = null;
            this.addWarning("Drop shadow shape effects are not currently supported and were removed.");
        }

        let bounds = geom.Rect.FromBBox(svgNode.getBoundingClientRect())
            .offset(-this.canvasBounds.left, -this.canvasBounds.top)
            .multiply(1 / this.canvas.canvasScale);

        if (options.reduceStrokeWidth) {
            bounds = bounds.inflate(padding);
        }

        // if the node is an SVGElement, it may have a position set which we need to take into account
        if (element instanceof SVGElement && element.position) {
            bounds = bounds.offset(element.position);
        }

        if (element.styles && element.styles.padding) {
            bounds = bounds.offset(-element.styles.paddingLeft, -element.styles.paddingTop);
        }

        // fix svg node attributes to make sure we don't export anything that's not supported
        const restoreSvgNodesFunctions = [];
        const fixSvgNodes = (svgNode, colorAttributes, ignoreTransform = false) => {
            if (!svgNode || !svgNode.style || !svgNode.getAttribute) {
                return;
            }

            const originalAttributes = Array.from(svgNode.attributes).map(({ nodeValue, nodeName }) => ({ nodeValue, nodeName }));
            restoreSvgNodesFunctions.push(() => {
                Array.from(svgNode.attributes).forEach(({ nodeName }) => svgNode.removeAttribute(nodeName));
                originalAttributes.forEach(({ nodeValue, nodeName }) => svgNode.setAttribute(nodeName, nodeValue));
            });

            colorAttributes.forEach(colorAttribute => {
                const attributeOpacity = parseFloat(svgNode.getAttribute(`${colorAttribute}-opacity`) || "1");
                const attributeCssOpacity = parseFloat(svgNode.style[`${colorAttribute}-opacity`] || "1");
                let opacity = attributeCssOpacity * attributeOpacity;

                // colors can be in styles
                {
                    const color = tinycolor(svgNode.style[colorAttribute]);
                    const alpha = color.getAlpha();
                    if (alpha !== 1) {
                        opacity *= alpha;
                        svgNode.style.setProperty(colorAttribute, color.setAlpha(1).toHexString());
                    }
                }

                // or colors can be in attributes
                {
                    const color = tinycolor(svgNode.getAttribute(colorAttribute));
                    const alpha = color.getAlpha();
                    if (alpha !== 1) {
                        opacity *= alpha;
                        svgNode.setAttribute(colorAttribute, color.setAlpha(1).toHexString());
                    }
                }

                // always set opacity via node attribute for better compatibility
                svgNode.style.removeProperty(`${colorAttribute}-opacity`);
                if (opacity !== 1) {
                    svgNode.setAttribute(`${colorAttribute}-opacity`, opacity);
                }
            });

            // remove transition
            svgNode.style.removeProperty("transition");

            if (ignoreTransform) {
                svgNode.removeAttribute("transform");
                svgNode.style.removeProperty("transform");
            } else {
                let transform = svgNode.style.transform;
                if (transform) {
                    const hasTranslate = /translate\(([^\)]+)\)/.test(transform);
                    if (!hasTranslate) {
                        let translateX = transform.match(/translateX\(([^\)]+)\)/);
                        if (translateX) {
                            translateX = translateX[1].replace("px", "");
                        }
                        let translateY = transform.match(/translateY\(([^\)]+)\)/);
                        if (translateY) {
                            translateY = translateY[1].replace("px", "");
                        }

                        // remove translate z because it's not supported anyway
                        transform = transform.replace(/translateZ\(([^\)]+)\)/, "");

                        if (translateX || translateY) {
                            const translate = `translate(${translateX} ${translateY})`;
                            if (translateX) {
                                transform = transform.replace(/translateX\(([^\)]+)\)/, translate);
                                transform = transform.replace(/translateY\(([^\)]+)\)/, "");
                            } else {
                                transform = transform.replace(/translateY\(([^\)]+)\)/, translate);
                                transform = transform.replace(/translateX\(([^\)]+)\)/, "");
                            }
                        }
                    }

                    svgNode.setAttribute("transform", transform);
                    svgNode.style.removeProperty("transform");
                }
            }

            // by some reason Powerpoint on mac hangs when there are some svg elements with the stroke-dasharray
            // parameter set to zero, so we have to remove it from the node and all its children
            //
            // stroke-dasharray can be a style property
            if (svgNode.style.getPropertyValue("stroke-dasharray") == "0" || svgNode.style.getPropertyValue("stroke-dasharray") === "0px") {
                svgNode.style.removeProperty("stroke-dasharray");
                svgNode.style.removeProperty("stroke-dashoffset");
            }
            // or an svg node attribute
            if (svgNode.getAttribute("stroke-dasharray") == "0" || svgNode.getAttribute("stroke-dasharray") === "0px") {
                svgNode.removeAttribute("stroke-dasharray");
                svgNode.removeAttribute("stroke-dashoffset");
            }

            // recurse through child nodes
            if (svgNode.childNodes) {
                // transoform will always be respected on child nodes
                svgNode.childNodes.forEach(node => fixSvgNodes(node, colorAttributes));
            }
        };

        // transform will be ignored on the parent node because it's positioned using it's actual
        // bounds
        fixSvgNodes(svgNode, ["fill", "stroke"], true);

        // Special case for connectors with bold line weight
        // In order to avoid wrong place, we are keeping the stroke width for the arrow head but
        // reducing the stroke width for the connector line
        if (element.isInstanceOf("ConnectorItem") && element.model?.lineWeight === "bold") {
            const strokeWidth = svgNode.getAttribute("stroke-width") / 2;
            bounds = bounds.inflate(strokeWidth);
            if (options.reduceStrokeWidth) {
                svgNode.setAttribute("stroke-width", strokeWidth);
            }
        }

        // Rounding w and h because Safari sometimes fails to load svg
        // images with non-integer w or h
        bounds.width = Math.ceil(bounds.width);
        bounds.height = Math.ceil(bounds.height);
        // Hack to avoid errors when exporting vertical or horizontal lines
        bounds.width = bounds.width === 0 ? 1 : bounds.width;
        bounds.height = bounds.height === 0 ? 1 : bounds.height;

        const bbox = geom.Rect.FromBBox(svgNode.getBBox()).inflate(padding);
        const viewBox = `${bbox.x} ${bbox.y} ${bbox.width === 0 ? 1 : bbox.width} ${bbox.height === 0 ? 1 : bbox.height}`;

        const svg = `<svg viewBox='${viewBox}' xmlns='http://www.w3.org/2000/svg' width='${bounds.width}' height='${bounds.height}'>
            ${new XMLSerializer().serializeToString(svgNode)}
        </svg>`;

        const dataURL = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svg)));
        const imageData = await this.loadBase64Image(dataURL);
        const slideImageBounds = this.convertRectToSlide(bounds);
        if (element.exportAsImage || element.options.exportAsImage) {
            this.slide.addImage({
                data: imageData,
                ...slideImageBounds
            });
        } else {
            this.slide.addImage({
                data: dataURL,
                preview: imageData,
                ...slideImageBounds
            });
        }

        restoreSvgNodesFunctions.forEach(restoreSvgNode => restoreSvgNode());
    }

    async exportFooter(footerElement) {
        const { logoBounds, logoFrameBounds } = footerElement.calculatedProps;

        if (logoFrameBounds) {
            const logoFrameCanvasBounds = logoFrameBounds.offset(footerElement.canvasBounds.position);

            const shapeOptions = {
                ...this.convertRectToSlide(logoFrameCanvasBounds),
                fill: "ffffff"
            };

            this.slide.addShape(this.pptx.shapes.RECTANGLE, shapeOptions);
        }

        if (logoBounds) {
            const logoUrl = await Logos.getSignedUrlAndLoad(footerElement.lastLogoPath, true);
            const logoImageData = await this.loadBase64Image(logoUrl);
            const logoCanvasBounds = logoBounds.offset(footerElement.canvasBounds.position);

            this.slide.addImage({
                data: logoImageData,
                rounding: false,
                x: this.convertCoordinate(logoCanvasBounds.left),
                y: this.convertCoordinate(logoCanvasBounds.top),
                w: this.convertCoordinate(logoCanvasBounds.width),
                h: this.convertCoordinate(logoCanvasBounds.height),
                flipH: false
            });
        }
    }

    async exportWordCloud(wordCloudElement) {
        const parentSvgNode = wordCloudElement.DOMNode.firstElementChild;
        for (const wordSvgNode of parentSvgNode.childNodes) {
            await this.exportSVGNode(wordCloudElement, wordSvgNode);
        }
    }

    async exportOrgChartConnectors(orgChartConnectorsElement) {
        const parentSvgNode = orgChartConnectorsElement.DOMNode.firstElementChild;
        for (const connectorSvgNode of parentSvgNode.childNodes) {
            await this.exportSVGNode(orgChartConnectorsElement, connectorSvgNode);
        }
    }

    async exportCanvasBackground(element) {
        const {
            background,
        } = element;

        if (background.isInstanceOf("BackgroundImage") ||
            background.isInstanceOf("CustomBackgroundImage") ||
            background.isInstanceOf("ThemeBackgroundImage")) {
            const imageUrl = await background.getBackgroundImageUrl(true);
            if (!imageUrl) {
                return;
            }

            const imageData = await this.loadBase64Image(imageUrl);
            this.slide.addImage({
                data: imageData,
                x: 0,
                y: 0,
                w: this.convertCoordinate(CANVAS_WIDTH),
                h: this.convertCoordinate(CANVAS_HEIGHT),
            });
        } else if (background.isInstanceOf("CustomBackgroundGradient")) {
            const {
                width,
                height,
            } = background.calculatedProps.bounds;

            const gradient = background.options.gradient;
            let data = exportGradientConfig(width, height, gradient);
            this.slide.addImage({
                data,
                x: 0,
                y: 0,
                w: this.convertCoordinate(CANVAS_WIDTH),
                h: this.convertCoordinate(CANVAS_HEIGHT),
            });
        } else if (background.gradientConfig) {
            const {
                width,
                height,
            } = background.bounds;

            let data = exportGradientConfig(width, height, background.gradientConfig);

            this.slide.addImage({
                data,
                x: 0,
                y: 0,
                w: this.convertCoordinate(CANVAS_WIDTH),
                h: this.convertCoordinate(CANVAS_HEIGHT),
            });
        }
    }

    async exportStreetMap(streetMapElement) {
        // Make sure map is loaded
        await streetMapElement.map.loadMap();

        const mapCanvasBounds = streetMapElement.map.canvasBounds;
        const mapSize = streetMapElement.map.mapSize + 30;

        const mapImageUrl = await streetMapElement.map.getAssetUrl(true);
        const imageData = await this.loadBase64Image(mapImageUrl);

        this.slide.addImage({
            data: imageData,
            rounding: false,
            x: this.convertCoordinate(mapCanvasBounds.left),
            y: this.convertCoordinate(mapCanvasBounds.top),
            w: this.convertCoordinate(mapSize),
            h: this.convertCoordinate(mapSize),
            sizing: {
                type: "crop",
                x: this.convertCoordinate(Math.abs(mapCanvasBounds.width - mapSize) / 2),
                y: this.convertCoordinate(Math.abs(mapCanvasBounds.height - mapSize) / 2),
                w: this.convertCoordinate(mapCanvasBounds.width),
                h: this.convertCoordinate(mapCanvasBounds.height)
            },
            flipH: false
        });

        await this.exportSVGNodeAsImage(streetMapElement, streetMapElement.copyright.DOMNode, streetMapElement.copyright.canvasBounds);
    }

    async exportTable(tableElement) {
        const table = tableElement.table;

        await this.exportSVGNode(table.tableBackground);

        for (let element of Object.values(table.elements)) {
            if (element.type == "TableCellIcon" && element.assetElement) {
                await this.exportElement(element);
            }
        }

        await this.exportSVGNode(table.tableGridLines);

        const font = table.fonts[table.styles.TableCell.fontId];
        const fontStyle = font.getStyle(table.styles.TableCell.fontWeight, false);

        let fontFace = fontStyle.label;
        if (fontFace == "Carlito") {
            fontFace = "Calibri";
        }

        for (let cell of table.cells) {
            if (cell.model.isHidden) continue;

            if (cell.format !== FormatType.ICON) {
                let props = {
                    x: this.convertCoordinate(table.canvasBounds.left + cell.bounds.left),
                    y: this.convertCoordinate(table.canvasBounds.top + cell.bounds.top),
                    w: this.convertCoordinate(cell.bounds.width),
                    h: this.convertCoordinate(cell.bounds.height),
                    align: cell.styles.textAlign,
                    bold: cell.bold,
                    italic: cell.italic,
                    fontSize: cell.fontSize,
                    fontFace: fontFace,
                    strike: cell.strikeThrough ? "dblStrike" : "",
                    shrinkText: true,
                    color: this.getColorFromRGB(cell.colorSet.textColor.toRgbString())
                };

                let format = cell.model.format || FormatType.TEXT;
                let formatOptions;
                if (cell.model.formatOptions && typeof (cell.model.formatOptions) == "object") {
                    formatOptions = cell.model.formatOptions;
                } else {
                    formatOptions = formatter.getDefaultFormatOptions();
                }

                let cellValue = (cell.value && cell.value != "") ? formatter.formatValue(cell.value, format, formatOptions) : "";

                // some slides had tables with cells that contained a single Backspace char that blew up PPT export
                // it's not clear how to reproduce this state but i am special-case stripping them
                cellValue = cellValue.replace(/[\b]/g, "");

                if ((cell.format == FormatType.NUMBER || cell.format == FormatType.CURRENCY || cell.format == FormatType.PERCENT) && (cell.formatOptions && cell.formatOptions.changeStyle != CellChangeStyle.NONE)) {
                    if (parseFloat(cell.value) > 0) {
                        cellValue = "+" + cellValue;
                    }
                }

                if (
                    (cell.format == FormatType.NUMBER || cell.format == FormatType.CURRENCY || cell.format == FormatType.PERCENT) &&
                    (cell.model.formatOptions && cell.model.formatOptions.changeStyle === CellChangeStyle.ARROWS)
                ) {
                    if (parseFloat(cell.value) > 0) {
                        cellValue = "▲ " + cellValue;
                    } else if (parseFloat(cell.value) < 0) {
                        cellValue = "▼ " + cellValue;
                    }
                }

                if (cell.formatOptions && cell.formatOptions.accountingStyle) {
                    this.addWarning("Some table formatting options can not be exported.");
                }

                this.slide.addText(cellValue, props);

                if (cell.cellStyle === "flag") {
                    this.slide.addShape(this.pptx.shapes.RIGHT_TRIANGLE, {
                        x: this.convertCoordinate(table.canvasBounds.left + cell.bounds.left + cell.bounds.width - cell.flagDecorationSize),
                        y: this.convertCoordinate(table.canvasBounds.top + cell.bounds.top),
                        w: this.convertCoordinate(cell.flagDecorationSize),
                        h: this.convertCoordinate(cell.flagDecorationSize),
                        fill: this.getColorFromRGB(cell.colorSet.flagColor.toRgbString()),
                        line: { color: this.getColorFromRGB(cell.colorSet.flagColor.toRgbString()), width: 2 },
                        flipV: true,
                        flipH: true
                    });
                }
            }
        }
    }

    async exportContent(contentElement) {
        if (contentElement.model.content_type == AssetType.ICON) {
            // let the iconElement export handle this
            return;
        }

        if (!contentElement.hasValidAsset) {
            return;
        }

        // if the image is flipped, we need to use the unflipped matrix or the image will be offset in PPT
        // so we temporarily switch image to unflipped and _calcSize to generate unflipped matrix
        const flipHorizontal = contentElement.model.flipHorizontal;
        if (flipHorizontal) {
            contentElement.model.flipHorizontal = false;
            contentElement.canvas.refreshElement(contentElement);
        }

        try {
            const primaryAsset = contentElement.assetElement.asset;
            let url;
            if (contentElement.contentType === AssetType.STOCK_VIDEO || contentElement.contentType === AssetType.VIDEO) {
                this.addWarning("Content videos are not currently supported and were exported as static images.");

                // upload
                if (primaryAsset) {
                    const previewAssetId = primaryAsset.get("previewAssetId");
                    if (previewAssetId) {
                        const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                        url = await previewAsset.getURL("original", true);
                    }
                    // stock video
                } else {
                    url = contentElement.model.previewUrl;
                }

                if (contentElement.assetElement.publicVideoUrl) {
                    this.slideNotes.push(`Video URL: ${contentElement.assetElement.publicVideoUrl}`);
                }
            } else {
                if (primaryAsset.get("fileType") == "gif") {
                    this.addWarning("GIFs are not currently supported and were exported as static images.");
                }

                url = await primaryAsset.getURL("xlarge", true);
            }

            if (!url) {
                return;
            }

            let filteredUrl = await svgImageWithFilterToUrl(url, contentElement.assetElement.adjustmentFilterId);
            filteredUrl = await svgImageWithFilterToUrl(filteredUrl, contentElement.assetElement.imageFilterId);

            const imageData = await this.loadBase64Image(filteredUrl);

            const {
                mediaSize,
                calculatedProps: {
                    transformProps,
                },
            } = contentElement.assetElement;

            const isCircle = contentElement.model.frameType === "circle" || contentElement.model.frameType === "octagon" ||
            (contentElement.decoration && (
                contentElement.decoration.styles.shape == "circle" || contentElement.decoration.styles.shape == "octagon"));
            let canvasBounds = contentElement.canvasBounds.deflate(contentElement.styles.padding);
            let offsetX = 0;
            let offsetY = 0;
            if (isCircle) {
                canvasBounds = canvasBounds.square();
                offsetX = (contentElement.canvasBounds.width - canvasBounds.width) / 2;
                offsetY = (contentElement.canvasBounds.height - canvasBounds.height) / 2;
            }

            this.slide.addImage({
                data: imageData,
                altText: contentElement.assetElement.getAltText(),
                rounding: isCircle,
                x: this.convertCoordinate(canvasBounds.left + offsetX),
                y: this.convertCoordinate(canvasBounds.top + offsetY),
                w: this.convertCoordinate(mediaSize.width * transformProps.scaleX),
                h: this.convertCoordinate(mediaSize.height * transformProps.scaleX),
                sizing: {
                    type: "crop",
                    w: this.convertCoordinate(canvasBounds.width),
                    h: this.convertCoordinate(canvasBounds.height),
                    x: this.convertCoordinate(transformProps.translateX * -1 + offsetX),
                    y: this.convertCoordinate(transformProps.translateY * -1)
                },
                flipH: flipHorizontal
            });
        } finally {
            if (flipHorizontal) {
                contentElement.model.flipHorizontal = true;
                contentElement.canvas.refreshElement(contentElement);
            }
        }
    }

    exportVignetteOverlay(element) {
        const {
            width,
            height,
        } = element.bounds;

        let data = exportGradientConfig(width, height, element.gradientConfig);

        this.slide.addImage({
            data,
            x: 0,
            y: 0,
            w: this.convertCoordinate(CANVAS_WIDTH),
            h: this.convertCoordinate(CANVAS_HEIGHT),
        });
    }

    exportBorderOverlay(borderOverlayElement) {
        const shapeOptions = {
            ...this.convertRectToSlide((borderOverlayElement.bounds).deflate(borderOverlayElement.calculatedBorderWidth / 4)),
            line: this.getColorFromRGB(borderOverlayElement.calculatedBorderColor),
            lineSize: borderOverlayElement.calculatedBorderWidth / 2
        };
        this.slide.addShape(this.pptx.shapes.RECTANGLE, shapeOptions);
    }

    async loadBase64Image(imageUrl) {
        const imageData = await new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = "anonymous";
            image.onload = () => resolve(getBase64Image(image));
            image.onerror = reject;
            image.src = imageUrl;
        });

        return imageData;
    }
}
