import React from "react";
import anime from "animejs";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import * as geom from "js/core/utilities/geom";
import { FormatType, HorizontalAlignType, PositionType, TextStyleEnum } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { delay } from "js/core/utilities/promiseHelper";
import { Path } from "js/core/utilities/shapes";
import { $, _ } from "js/vendor";
import { fontManager } from "js/core/services/fonts";

import { ActionNumberPropertyPanel } from "../../../Editor/ElementPropertyPanels/ActionNumberUI";
import { ValueLabelSelection } from "../../../Editor/ElementSelections/ValueLabelSelection";
import { BaseElement } from "../../base/BaseElement";
import { SVGPathElement } from "../../base/SVGElement";
import { TextElement } from "../../base/Text/TextElement";
import { playActionNumAnimation } from "./animations/ActionNumberAnimations";

const StyledSvg = styled.svg`
    overflow: visible;
`;

class ActionNumber extends BaseElement {
    static get schema() {
        return {
            value: 50000,
            animationStyle: "count",
            showLine: true,
            playOutroAnimation: true,
            numberColor: "theme",
            startValue: 0.9
        };
    }

    get name() {
        return "Action Number";
    }

    getElementPropertyPanel() {
        return ActionNumberPropertyPanel;
    }

    get animationStyle() {
        return this.model.animationStyle ?? 1;
    }

    get startValue() {
        return this.model.startValue ?? 0.9;
    }

    get format() {
        return this.model.format || FormatType.CURRENCY;
    }

    get formatOptions() {
        return this.model.formatOptions || formatter.getDefaultFormatOptions();
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    formatHtml(html) {
        html = html.replace("$", `<span class="text-superset">$</span>`);
        html = html.replace("%", `<span style="font-weight: 200">%</span>`);
        return html;
    }

    get labelPosition() {
        return this.model.labelPosition || PositionType.BOTTOM_RIGHT;
    }

    get showLine() {
        return this.model.showLine ?? false;
    }

    get playOutroAnimation() {
        return this.model.playOutroAnimation ?? false;
    }

    get labelTextAlign() {
        switch (this.labelPosition) {
            case PositionType.BOTTOM_RIGHT:
                return HorizontalAlignType.RIGHT;
            case PositionType.BOTTOM:
            case PositionType.TOP:
                return HorizontalAlignType.CENTER;
            case PositionType.RIGHT:
            case PositionType.BOTTOM_LEFT:
            default:
                return HorizontalAlignType.LEFT;
        }
    }

    _build() {
        this.number = this.addElement("number", () => ActionNumberNumber, {
            html: this.formatHtml(this.formatValue(this.model.value)),
            color: this.model.numberColor,
            bindTo: "value",
            autoWidth: true,
            autoHeight: true,
            scaleTextToFit: true,
            minTextScale: 0.1,
            allowStyling: false,
            canEdit: false,
            textAlign: HorizontalAlignType.RIGHT,
            fontColor: this.model.numberColor ?? "theme"
        });

        this.label = this.addElement("label", () => TextElement, {
            autoWidth: true,
            autoHeight: true,
            allowNewLines: true
        });

        if (this.showLine) {
            this.line = this.addElement("line", () => SVGPathElement);
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let availableNumberWidth;

        if (this.labelPosition == PositionType.RIGHT) {
            availableNumberWidth = 900;
        } else {
            availableNumberWidth = 1100;
        }

        const numberProps = this.number.calcProps(new geom.Size(availableNumberWidth, size.height));

        // update the textAlign style as needed
        this.label.updateStyles({ textAlign: this.labelTextAlign });

        let labelProps;

        if (this.labelPosition == PositionType.RIGHT) {
            let gap = 20;
            if (this.showLine) {
                gap += 30;
            }

            labelProps = this.label.calcProps(new geom.Size(size.width - numberProps.size.width - gap, size.height));

            numberProps.bounds = new geom.Rect(
                size.width / 2 - (numberProps.size.width + gap + labelProps.size.width) / 2,
                size.height / 2 - numberProps.size.height / 2,
                numberProps.size
            );

            labelProps.bounds = new geom.Rect(
                numberProps.bounds.right + gap,
                numberProps.bounds.centerV - labelProps.size.height / 2,
                labelProps.size
            );
        } else if (this.labelPosition == PositionType.TOP) {
            let gap = 40;
            if (this.showLine) {
                gap += 40;
            }

            labelProps = this.label.calcProps(new geom.Size(size.width * .75, size.height - numberProps.size.height - gap));
            labelProps.bounds = new geom.Rect(
                size.width / 2 - labelProps.size.width / 2,
                size.height / 2 - (numberProps.size.height + gap + labelProps.size.height) / 2,
                labelProps.size
            );

            numberProps.bounds = new geom.Rect(
                size.width / 2 - numberProps.size.width / 2,
                labelProps.bounds.bottom + gap,
                numberProps.size
            );
        } else if (this.labelPosition == PositionType.BOTTOM) {
            let gap = 30;
            if (this.showLine) {
                gap += 30;
            }

            labelProps = this.label.calcProps(new geom.Size(size.width * .75, size.height - numberProps.size.height - gap));

            numberProps.bounds = new geom.Rect(
                size.width / 2 - numberProps.size.width / 2,
                size.height / 2 - (numberProps.size.height + labelProps.size.height + gap) / 2,
                numberProps.size
            );

            if (this.formatValue(this.model.value).includes(",")) {
                gap += numberProps.commaDescender;
            }

            labelProps.bounds = new geom.Rect(
                size.width / 2 - labelProps.size.width / 2,
                numberProps.bounds.bottom + gap,
                labelProps.size
            );
        } else {   // bottom right
            let gap = 30;
            if (this.showLine) {
                gap += 30;
            }

            let availableLabelWidth = numberProps.size.width;
            if (numberProps.lastCommaX) {
                availableLabelWidth = numberProps.size.width - numberProps.lastCommaX;
            }

            labelProps = this.label.calcProps(new geom.Size(availableLabelWidth, size.height - numberProps.size.height));

            numberProps.bounds = new geom.Rect(
                size.width / 2 - numberProps.size.width / 2,
                size.height / 2 - (numberProps.size.height + gap + labelProps.size.height) / 2,
                numberProps.size
            );

            labelProps.bounds = new geom.Rect(
                numberProps.bounds.right - labelProps.size.width,
                numberProps.bounds.bottom + gap,
                labelProps.size
            );
        }

        if (this.showLine) {
            const path = new Path();

            switch (this.labelPosition) {
                case PositionType.BOTTOM: {
                    let lineWidth = labelProps.size.width + 100;
                    let x = labelProps.bounds.left - (lineWidth - labelProps.bounds.width) / 2;
                    let y = labelProps.bounds.top - 25;
                    path.moveTo(x, y);
                    path.lineTo(x + lineWidth, y);
                    break;
                }
                case PositionType.RIGHT: {
                    let x = labelProps.bounds.left - 30;
                    let y = numberProps.bounds.top;
                    path.moveTo(x, y);
                    path.lineTo(x, y + numberProps.bounds.height);
                    break;
                }
                case PositionType.BOTTOM_RIGHT: {
                    let x = labelProps.bounds.left;
                    let y = labelProps.bounds.top - 25;
                    path.moveTo(x, y);
                    path.lineTo(x + labelProps.bounds.width, y);
                    break;
                }
                case PositionType.TOP: {
                    let lineWidth = labelProps.size.width + 100;
                    let x = labelProps.bounds.left - (lineWidth - labelProps.bounds.width) / 2;
                    let y = labelProps.bounds.bottom + 30;
                    path.moveTo(x, y);
                    path.lineTo(x + lineWidth, y);
                    break;
                }
            }

            let lineProps = this.line.createProps({
                path: path.toPathData()
            });
        }

        return {
            size,
        };
    }

    _applyColors() {
        if (this.showLine) {
            this.line.colorSet.strokeColor = this.palette.getColor("primary", this.getBackgroundColor()).setAlpha(0.5);
        }
    }

    async previewAnimation() {
        if (this.isPreviewing) return;

        this.canvas.selectionLayerController?.setSelectedElements([]);

        return new Promise(resolve => {
            this.isPreviewing = true;
            this.playAnimationIn(() => {
                $(this.number.DOMNode).opacity(1).css("transform", "scale(1)");
                $(this.label.DOMNode).opacity(1);
                if (this.showLine) {
                    $(this.line.DOMNode).opacity(1);
                }

                // reset the number from being in animation state
                this.number.countingValue = null;

                this.isPreviewing = false;
                resolve();
            });
        });
    }

    get animateChildren() {
        return false; // turn off the built-in text animations
    }

    _prepareToShowElement() {
        $(this.number.DOMNode).opacity(1).css("transform", "scale(1)");
        $(this.label.DOMNode).opacity(1);
        if (this.showLine) {
            $(this.line.DOMNode).opacity(1);
        }
    }

    async _beforeShowElement() {
        this.prepareForAnimation();
        await delay(300);
        this.playAnimationIn(() => {
            $(this.number.DOMNode).opacity(1).css("transform", "scale(1)");
            $(this.label.DOMNode).opacity(1);
            if (this.showLine) {
                $(this.line.DOMNode).opacity(1);
            }

            // reset the number from being in animation state
            this.number.countingValue = null;
        });
        this.canvas.registerOutroAnimation(this);
    }

    prepareForAnimation() {
        $(this.number.DOMNode).opacity(0);
        $(this.label.DOMNode).opacity(0);
        if (this.showLine) {
            $(this.line.DOMNode).opacity(0);
        }
    }

    playAnimationIn(onComplete) {
        this.timeline = playActionNumAnimation(this, this.animationStyle, onComplete);
    }

    async playAnimationOut() {
        if (this.playOutroAnimation) {
            return new Promise(resolve => {
                const timeline = anime.timeline({
                    easing: "easeInQuad"
                });

                timeline.add({
                    targets: this.number.DOMNode,
                    scale: 3,
                    opacity: 0,
                    duration: 600
                });

                timeline.add({
                    targets: this.label.DOMNode,
                    opacity: 0,
                    duration: 300
                }, 0);

                if (this.showLine) {
                    timeline.add({
                        targets: this.line.DOMNode,
                        opacity: 0,
                        duration: 300
                    }, 0);
                }

                timeline.finished.then(() => {
                    resolve();
                });
                timeline.play();
            });
        }
    }
}

class ActionNumberNumber extends BaseElement {
    getElementSelection() {
        return ValueLabelSelection;
    }

    get _canSelect() {
        return true;
    }

    async setValue(value) {
        this.countingValue = value;
        if (this.calculatedProps?.bounds) {
            await this.canvas.refreshElement(this);
        }
    }

    // International currency symbols are not universally supported
    async loadCurrencyFont(font, fontStyle) {
        const formattedValue = formatter.formatValue(this.model.value, this.parentElement.format, this.parentElement.formatOptions);
        let textStyle = TextStyleEnum.REGULAR;
        if (fontStyle.weight >= 700) {
            textStyle = TextStyleEnum.BOLD;
        }
        const fallbackFont = await fontManager.getFallbackFontIfNeeded(font, formattedValue.charAt(0), textStyle, true);
        return fallbackFont || font;
    }

    async _load() {
        const font = await fontManager.loadFont(this.styles.fontId);
        const fontStyle = font.getStyle(this.styles.fontWeight, false);
        await fontStyle.loadOpentypeFont();
        this.font = fontStyle.font;

        const thinFontStyle = font.getStyle(200, false);
        await thinFontStyle.loadOpentypeFont();
        this.thinFont = thinFontStyle.font;
        this.currencyFont = await this.loadCurrencyFont(this.font, fontStyle);
    }

    _calcProps(props) {
        const { size } = props;

        const isCounting = this.countingValue != null;
        const value = this.countingValue ?? this.model.value;

        const digitsWidth = this.font.getAdvanceWidth("0", this.styles.fontSize);
        const commaWidth = this.font.getAdvanceWidth(",", this.styles.fontSize);
        const percentWidth = this.thinFont.getAdvanceWidth("%", this.styles.fontSize);

        const spacingBounds = this.font.charToGlyph("0").getBoundingBox();
        const glyphHeight = (spacingBounds.y2 - spacingBounds.y1) / this.font.unitsPerEm * this.styles.fontSize;

        let displayValue = value;
        if (this.parentElement.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }
        const formatOptions = { ...this.parentElement.formatOptions };

        if (isCounting) {
            if (formatOptions.decimal == "auto") {
                const defaultFormattedValue = formatter.formatValue(this.model.value, this.parentElement.format, formatOptions);
                const decimals = defaultFormattedValue.contains(".") ? defaultFormattedValue.split(".")[1].replace(/\D/g, "").length : 0;
                formatOptions.decimal = decimals;
            }
        }

        const formattedValue = formatter.formatValue(displayValue, this.parentElement.format, formatOptions);
        const currencyWidth = this.currencyFont.getAdvanceWidth(formattedValue.charAt(0), this.styles.fontSize * 0.5);
        const letterSpacing = 0.9;

        // calculate the total width of the number
        const numberWidth = _.sumBy(formattedValue.split(""), ch => {
            if (formatter.getCurrencies().includes(ch)) {
                return currencyWidth;
            } else if (ch == "," || ch == ".") {
                return commaWidth;
            } else if (ch == "%") {
                return percentWidth;
            } else if (isNaN(parseInt(ch))) {
                return this.font.getAdvanceWidth(ch, this.styles.fontSize) * letterSpacing;
            } else {
                return digitsWidth * letterSpacing;
            }
        });

        let scale;
        if (isCounting) {
            scale = this.lastCalculatedScale;
        } else {
            scale = Math.min(1, size.width / numberWidth);
        }

        const commaGlyph = this.font.charToGlyph(",");
        const commaDescender = (commaGlyph.getBoundingBox().y1 ?? 0) / this.font.unitsPerEm * this.styles.fontSize * -1 * scale;

        if (!isCounting) {
            // store some state for the counting animation
            this.lastCalculatedScale = scale;
            this.numberWidth = numberWidth;
        }

        const glyphPaths = [];

        const spacing = digitsWidth * letterSpacing * scale;

        let x = 0;
        if (isCounting) {
            // right align counting numbers
            if (numberWidth < this.numberWidth) {
                x = this.numberWidth - numberWidth;
            } else {
                x = -(numberWidth - this.numberWidth) / 2;
            }
        }

        let lastCommaX;
        for (const ch of formattedValue.split("")) {
            if (formatter.getCurrencies().includes(ch)) {
                glyphPaths.push(this.currencyFont.getPath(ch, x, glyphHeight * scale / 2, this.styles.fontSize * 0.5 * scale));
                x += currencyWidth * scale;
            } else if (ch == "." || ch == ",") {
                x += commaWidth * scale / 2;
                glyphPaths.push(this.font.getPath(ch, x - commaWidth * scale / 2, glyphHeight * scale, this.styles.fontSize * scale));
                x += commaWidth * scale / 2;
                lastCommaX = x;
            } else if (ch == "%") {
                x += percentWidth * scale / 2;
                glyphPaths.push(this.thinFont.getPath(ch, x - percentWidth * scale / 2, glyphHeight * scale, this.styles.fontSize * scale));
                x += percentWidth * scale / 2;
            } else if (isNaN(parseInt(ch))) {
                // center this glyph within the space
                glyphPaths.push(this.font.getPath(ch, x, glyphHeight * scale, this.styles.fontSize * scale));
                x += this.font.getAdvanceWidth(ch, this.styles.fontSize) * scale;
            } else {
                x += spacing / 2;
                // center this glyph within the space
                let glyphWidth = this.font.getAdvanceWidth(ch, this.styles.fontSize);
                glyphPaths.push(this.font.getPath(ch, x - glyphWidth * scale / 2, glyphHeight * scale, this.styles.fontSize * scale));
                x += spacing / 2;
            }
        }

        return {
            size: new geom.Size(numberWidth * scale, glyphHeight * scale),
            glyphPaths, commaDescender, lastCommaX
        };
    }

    _applyColors() {
        this.colorSet.numberColor = this.palette.getColor(this.model.numberColor, this.getBackgroundColor(), { allowColorOnColor: true });

        if (this.colorSet.numberColor.name == this.getBackgroundColor().name) {
            this.colorSet.numberColor = this.palette.getColor("primary", this.getBackgroundColor());
        }
    }

    renderChildren() {
        const { glyphPaths } = this.calculatedProps;
        const containerSize = this.calculatedProps.size;
        const numberBounds = this.calculatedProps.bounds;

        // Calculate the translation to align the <g> element to the right side of the parent
        const translateX = containerSize.width - numberBounds.width - numberBounds.left;
        const translateY = (containerSize.height - numberBounds.height) / 2;

        return (
            <StyledSvg width="100%" height="100%" key={uuid()}>
                <g fill={this.colorSet.numberColor} transform={`translate(${translateX}, ${translateY})`}>
                    {glyphPaths.map((path, i) => {
                        return <path key={i} d={path.toPathData()} />;
                    })}
                </g>
            </StyledSvg>
        );
    }
}

export const elements = {
    ActionNumber
};

