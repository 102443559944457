import React, { Component } from "react";
import { Icon, MenuItem } from "@material-ui/core";

import { _ } from "legacy-js/vendor";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import AppController from "legacy-js/core/AppController";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { ThemeThumbnail } from "legacy-js/editor/ThemeEditor/components/ThemeThumbnail";
import styled from "styled-components";
import { ThumbnailGrid } from "legacy-js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { IconMenu, RoundIconButton, UIPane } from "legacy-js/react/components/UiComponents";
import InputConfirmationDialog from "legacy-js/react/components/Dialogs/InputConfirmationDialog";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { SearchBarContainer, UIPaneResultsContainer } from "legacy-js/react/views/AddSlide/Panes/Components/SearchBox";
import { Breadcrumb } from "legacy-js/Components/Breadcrumb";
import { NoMyResourcesThemeNotice } from "legacy-js/react/components/Notice";

const ThumbnailGridContainer = styled.div`
    background: white;
    padding: 20px;
`;

export class ManageSavedThemes extends Component {
    state = {
        userThemes: []
    }

    componentDidMount() {
        this.loadUserThemes();
    }

    loadUserThemes = () => {
        const userThemes = _.sortBy(ds.userThemes.getThemesInWorkspace(AppController.orgId), theme => theme.get("name").toLowerCase());

        this.setState({
            userThemes,
        });
    }

    handleCreate = () => {
        AppController.navigateToThemeEditor("new-saved-theme", "my-resources");
    }

    handleEdit = userTheme => {
        AppController.navigateToThemeEditor(userTheme.id, "my-resources");
    }

    handleDuplicate = async userTheme => {
        const { userThemes } = this.state;

        let theme = await ds.userThemes.saveUserTheme(userTheme.attributes, `${userTheme.get("name")} (Copy)`, AppController.orgId);

        this.setState({
            userThemes: [...userThemes, theme]
        });
    }

    handleRename = theme => {
        ShowDialog(InputConfirmationDialog, {
            title: "Rename Theme",
            message: null,
            input: {
                value: theme.get("name")
            },
            acceptCallback: value => {
                try {
                    theme.update({ name: value.trim() });
                    // this.loadData();
                    this.forceUpdate();
                } catch (err) {
                    ShowErrorDialog({
                        title: "Unable to rename theme",
                        message: err.message
                    });
                }
            },
        });
    };

    handleEditCurrentTheme = () => {
        AppController.navigateToThemeEditor(PresentationEditorController.presentation.id);
    }

    handleDeleteTheme = theme => {
        ShowConfirmationDialog({
            title: "Are you sure you want to delete this theme?",
            message: "No existing presentations will be affected by this change but you won't be able to use this theme in any future presentations."
        }).then(confirmed => {
            if (confirmed) {
                theme.collection.remove([theme]);
                theme.destroy();
                this.loadUserThemes();
            }
        });
    }

    render() {
        const { userThemes } = this.state;

        return (
            <UIPane>
                <SearchBarContainer className="search-container">
                    <Breadcrumb>My Saved Themes</Breadcrumb>
                    <RoundIconButton onClick={this.handleCreate}><Icon>add</Icon></RoundIconButton>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    {userThemes.length > 0 && (
                        <ThumbnailGridContainer>
                            <ThumbnailGrid
                                items={userThemes}
                                columns={4}
                                selectedItem={false}
                                renderThumbnail={child => (
                                    <ThemeThumbnail
                                        item={child.item}
                                        menu={(
                                            <IconMenu>
                                                <MenuItem onClick={() => this.handleEdit(child.item)}><Icon>edit</Icon>Edit
                                                    Theme...</MenuItem>
                                                <MenuItem onClick={() => this.handleRename(child.item)}><Icon>label</Icon>Rename</MenuItem>
                                                <MenuItem
                                                    divider
                                                    onClick={() => this.handleDuplicate(child.item)}><Icon>content_copy</Icon>Duplicate</MenuItem>
                                                <MenuItem
                                                    onClick={() => this.handleDeleteTheme(child.item)}><Icon>delete</Icon>Delete</MenuItem>
                                            </IconMenu>
                                        )}
                                        onDoubleClick={() => this.handleEdit(child.item)}
                                    />
                                )}
                            />
                        </ThumbnailGridContainer>
                    )}
                    {userThemes.length === 0 && (
                        <NoMyResourcesThemeNotice />
                    )}
                </UIPaneResultsContainer>
            </UIPane>
        );
    }
}

function UserTheme({ userTheme }) {
    return (
        <FlexBox fillWidth left>
            <ThemeThumbnail item={userTheme} showBar={false} />
        </FlexBox>
    );
}

