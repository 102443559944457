import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Menu, MenuItem, Popover } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import {
    AuthoringBlockType,
    TextStyleType,
    HorizontalAlignType,
    BlockStructureType, TextFocusType, BlockStyleType, ListStyleType, CanvasEventType
} from "../../../../../../../common/constants";
import { $, _ } from "../../../../../../vendor";
import { themeColors } from "../../../../../../react/sharedStyles";
import * as geom from "../../../../../../core/utilities/geom";
import { Key } from "../../../../../../core/utilities/keys";
import { getStaticUrl } from "../../../../../../config";
import { v4 as uuid } from "uuid";
import { app } from "../../../../../../namespaces";
import { NestedMenuItem } from "../../../../../../react/components/NestedMenuItem";
import { dialogTheme } from "../../../../../../react/materialThemeOverrides";
import { sanitizeHtml } from "../../../../../../core/utilities/dompurify";
import { ClipboardType, clipboardRead, clipboardWrite } from "../../../../../../core/utilities/clipboard";
import { stopPropagation } from "../../../../../../core/utilities/stopPropagation";

import { slideTemplates } from "../../../../slideTemplates/slideTemplates";
import { RegionWidget } from "../../../../elements/elements/authoring/AuthoringBlocks/RegionBlock";
import { TextFormatWidgetBar } from "./BlockEditors/TextFormatBar";
import { ElementBlockEditor } from "./BlockEditors/ElementBlockEditor";
import { CodeBlockEditor } from "./BlockEditors/CodeBlockEditor";
import { EquationBlockEditor } from "./BlockEditors/EquationBlockEditor";
import { TextBlockEditor } from "./BlockEditors/TextBlockEditor";
import { stopEventPropagation } from "./AuthoringHelpers";
import { DividerBlockEditor } from "./BlockEditors/DividerBlockEditor";
import { ElementBlockPicker } from "./BlockEditors/ElementBlockPicker";
import { CardsBlockEditor } from "./BlockEditors/CardsBlockEditor";
import { ShowWarningDialog } from "../../../../../../react/components/Dialogs/BaseDialog";
import { StartRegionBlockEditor } from "./BlockEditors/RegionBlockEditor";
import { MediaBlockEditor } from "./BlockEditors/MediaBlockEditor";

const BlockEditorsContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

const RolloverBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: none;
    border: dotted 1px ${themeColors.ui_blue50percent};
    cursor: default;
`;

const FocusedBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: none;
    border: dotted 1px ${themeColors.ui_blue50percent};
    cursor: default;
`;

const SelectedBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: none;
    background: ${themeColors.textSelection};
    cursor: default;
`;

const DeleteButton = styled.div`
    position: absolute;
    top: -1px;
    right: -21px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${themeColors.ui_blue};
    cursor: pointer;
    pointer-events: auto;

    &:after {
        content: "\\E5CD";
        font-family: "Material Icons";
        font-size: 12px;
        position: relative;
        left: 0.5px;
    }
`;

const AddBlockWidget = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
    position: absolute;
    pointer-events: auto;
    border-bottom: dotted 1px #c3e9fb;
    cursor: default;
`;

const AddBlockButton = styled.div.attrs(({ top, bottom }) => ({
    style: { top, bottom }
}))`
    position: absolute;
    z-index: 100;
    pointer-events: auto;
    background: #c3e9fb;
    color: #333;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 38px);
    cursor: pointer;
    opacity: ${props => props.hidden ? 0 : 1};

    &:after {
        content: "Add Block";
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;
    }
`;

const DragBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
    position: absolute;
    background: rgba(17, 169, 226, 0.1);
`;

const MenuWidgetBox = styled.div`
    color: ${themeColors.ui_blue};
    font-size: 11px;
    text-transform: uppercase;
    padding: 6px 10px 6px 4px;
    padding: 0px 10px 0px 4px;
    position: absolute;
    left: -25px;
    top: -1px;
    width: 24px;
    height: calc(100% + 2px);
    pointer-events: auto;
    cursor: grab;
    display: flex;
    align-items: center;
    background: ${themeColors.contextBlue};

    .material-icons {
        font-size: 16px;
        margin-right: 10px;
        pointer-events: none;
    }
`;

const Popup = styled(Popover)`
    .MuiMenuItem-root {
        padding: 10px 15px 10px 10px;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: 600;
    }

    .MuiIcon-root {
        font-size: 1.3rem;
        margin-right: 10px;
        color: #666;
    }
`;

class BlockMenuWidget extends Component {
    constructor() {
        super();

        this.state = {
            anchorEl: null
        };

        this.ref = React.createRef();
    }

    handleMouseDown = event => {
        const { onDrag, canReorder } = this.props;

        if (canReorder) {
            const startPt = new geom.Point(event.pageX, event.pageY);
            $(document).on("mousemove.blockmenuwidget", event => {
                if (new geom.Point(event.pageX, event.pageY).distance(startPt) > 5) {
                    onDrag(event);
                    $(document).off(".blockmenuwidget");
                }
            });
        }

        $(document).on("mouseup.blockmenuwidget", event => {
            $(document).off(".blockmenuwidget");
            this.setState({
                anchorEl: this.ref.current
            });
        });
    }

    closePopup = () => {
        this.setState({ anchorEl: null });
    }

    handleMoveBlock = direction => {
        this.props.onMoveBlock(direction);
        this.closePopup();
    }

    handleAddBlock = async (event, direction) => {
        this.setState({ anchorEl: null }, () => {
            _.delay(() => {
                this.props.onAddBlock(direction);
            }, 333);
        });
    }

    handleDeleteBlock = () => {
        this.props.onDeleteBlock();
        this.closePopup();
    }

    handleSetBlockType = type => {
        this.props.onSelectBlockType(type, "replace");
        this.closePopup();
    }

    handleSetBlockStyle = style => {
        this.props.onSelectBlockStyle(style);
        this.closePopup();
    }

    render() {
        const { editorConfig, block, isLocked, canReorder, showBlockStyles, showBlockTypes } = this.props;

        let showMenu = false;
        return (
            <MenuWidgetBox
                ref={this.ref}
                className="editor-control"
                {...stopEventPropagation}
                onMouseDown={this.handleMouseDown}
            >
                <Icon>drag_indicator</Icon>

                {showMenu &&
                    <Popup
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        disableEnforceFocus
                        onClose={this.closePopup}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                        transitionDuration={{
                            enter: 100,
                            exit: 0
                        }}
                    >
                        {showBlockTypes &&
                            <NestedMenuItem divider label="Block Type" icon={<Icon>apps</Icon>}>
                                {getBlockPopupMenuItems(editorConfig.allowedBlockTypes, type => this.handleSetBlockType(type))}
                            </NestedMenuItem>
                        }

                        {block.type == AuthoringBlockType.ELEMENT &&
                            <NestedMenuItem label="Element Type">
                                <ElementBlockPicker onSelect={
                                    ({ id: templateId }) => {
                                        const template = _.find(slideTemplates, { id: templateId });
                                        if (template) {
                                            let slideTemplate = new template();

                                            let element = block.props.blockElement;

                                            for (let key of Object.keys(element.model)) {
                                                delete element.model[key];
                                            }

                                            element.model.elementType = slideTemplate.elementType;
                                            _.merge(element.model, slideTemplate.defaultData.primary);

                                            element.canvas.updateCanvasModel();
                                        }
                                        this.closePopup();
                                    }
                                } />
                            </NestedMenuItem>
                        }

                        {showBlockStyles && editorConfig.allowedBlockStyles.length > 0 &&
                            <NestedMenuItem disabled={isLocked} divider label="Block Style" icon={<Icon>style</Icon>}
                                nestedMenuClassName="small">
                                <MenuItem
                                    onClick={() => this.handleSetBlockStyle(BlockStyleType.NONE)}><Icon>disabled_by_default</Icon>None</MenuItem>
                                <MenuItem
                                    onClick={() => this.handleSetBlockStyle(BlockStyleType.INSET)}><Icon>crop_16_9</Icon>Inset</MenuItem>
                                <MenuItem
                                    onClick={() => this.handleSetBlockStyle(BlockStyleType.COLOR)}><Icon>palette</Icon>Color</MenuItem>
                            </NestedMenuItem>
                        }

                        {canReorder &&
                            <NestedMenuItem disabled={isLocked} label="Move Block" icon={<Icon>swap_vert</Icon>}
                                nestedMenuClassName="small">
                                <MenuItem onClick={() => this.handleMoveBlock(-1)}><Icon>arrow_upward</Icon>Move
                                    Up</MenuItem>
                                <MenuItem onClick={() => this.handleMoveBlock(1)}><Icon>arrow_downward</Icon>Move
                                    Down</MenuItem>
                            </NestedMenuItem>
                        }

                        {editorConfig.canAddBlocks &&
                            <NestedMenuItem divider label="Add Block" icon={<Icon>add</Icon>}
                                nestedMenuClassName="small">
                                <MenuItem onClick={event => this.handleAddBlock(event, 0)}><Icon>arrow_upward</Icon>Insert
                                    Above</MenuItem>
                                <MenuItem onClick={event => this.handleAddBlock(event, 1)}><Icon>arrow_downward</Icon>Insert
                                    Below</MenuItem>
                            </NestedMenuItem>
                        }

                        <MenuItem disabled={isLocked}
                            onClick={() => this.handleDeleteBlock()}><Icon>delete</Icon>Delete</MenuItem>
                    </Popup>
                }
            </MenuWidgetBox>
        );
    }
}

const BlockResizeWidgetContainer = styled.div`
    background: ${themeColors.contextBlue};
    pointer-events: auto;
    color: ${themeColors.ui_blue};
    cursor: ns-resize;
    width: 20px;
    height: 20px;
    //border-radius: 50%;
    position: absolute;
    right: -21px;
    bottom: -1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        font-size: 16px;
    }
`;

class BlockResizeWidget extends Component {
    handleMouseDown = event => {
        const { block, refreshCanvasAndSaveChanges, refreshElement, element } = this.props;

        event.stopPropagation();

        const startY = event.pageY;
        const startBlockHeight = block.model.blockHeight;

        app.isDragging = true;

        $(document).on("mousemove.resize", event => {
            event.stopPropagation();

            let frameHandledAt = 0;
            window.requestAnimationFrame(ts => {
                if (frameHandledAt === ts) {
                    return;
                }
                frameHandledAt = ts;

                if (element.canvas.layouter.isGenerating) {
                    return;
                }

                const diff = (event.pageY - startY) / element.canvas.getScale() * 2;
                block.model.blockHeight = Math.clamp(startBlockHeight + diff, 20, 1000);
                refreshElement();
            });
        });

        $(document).on("mouseup.resize", event => {
            app.isDragging = false;
            event.stopPropagation();
            $(document).off(".resize");
            refreshCanvasAndSaveChanges();
        });
    }

    render() {
        return (
            <BlockResizeWidgetContainer onMouseDown={this.handleMouseDown}>
                <Icon>height</Icon>
            </BlockResizeWidgetContainer>
        );
    }
}

const blockContainerMock = { blocks: [] };

export class AuthoringBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            rolloverBlock: null,
            focusedBlock: null,
            selectedBlocks: [],
            isDragging: false,
            isShowingPopup: false,
            popupAnchor: null,
            insertionBounds: null,
            insertionIndex: null,
            mouseDownPoint: null,
            blocksSignature: null,
            rolloverRegionId: null
        };

        this.state = { ...this.defaultState };

        const event = window.event;
        if (event && event.type === "mousedown") {
            const blocks = Object.values(this.blockContainer.blockRefs ?? {})
                .map(ref => ref.current)
                .filter(block => block.containerRef?.current);

            const targetBlock = blocks.find(block => $(block.containerRef.current).has($(event?.target)).length > 0);
            if (targetBlock) {
                this.state.focusedBlock = targetBlock;
                this.state.selectedBlocks = [targetBlock];
                this.state.mouseDownPoint = new geom.Point(event.pageX, event.pageY);
            }
        }

        this.unwireBlockEvents = () => { };
    }

    static defaultEditorConfig = {
        showMatchSizes: false,
        showTextAlign: true,
        showVerticalAlign: false,
        showColumns: false,
        showBlockStyles: false,
        syncTextAlignAcrossBlocks: true,
        showRolloverBlock: true,
        showFullSpectrumColorPicker: true,
        positionWidgetBarWithTextBlock: false,
        hyphenation: false,
        showAdvancedStylesMenu: false
    }

    get blockContainer() {
        const { element } = this.props;

        // Fallback to blockContainerMock in cases where React has a null ref, likely a race condition with canvas
        if (element.childElement) {
            // this is for the AuthoringContainerElements
            return element.childElement.blockContainerRef?.current || blockContainerMock;
        } else {
            // this is for the TextElements
            return element.blockContainerRef?.current || blockContainerMock;
        }
    }

    // region Utilities
    convertToSelectionBounds = (bounds, padding = 0) => {
        const { element } = this.props;

        const canvasBounds = element.canvas.$el[0].getBoundingClientRect();
        return bounds.offset(-canvasBounds.left, -canvasBounds.top).offset(-this.props.bounds.left, -this.props.bounds.top).inflate(padding);
    }

    getBlocksSelectionBounds = blocks => {
        let bounds;
        blocks.forEach(block => {
            if (!bounds) {
                bounds = block.bounds;
            } else {
                bounds = bounds.union(block.bounds);
            }
        });
        return this.convertToSelectionBounds(bounds);
    }

    //endregion

    // region mount/unmount functions
    wireBlockEvents() {
        if (this.isUnmounted) return;
        if (!this.blockContainer?.ref?.current) return;

        this.unwireBlockEvents();

        const unwireBlockFunctions = [];

        if (this.blockContainer.blocks.length) {
            for (const blockProps of this.blockContainer.props.blockProps) {
                const blockRef = this.blockContainer.blockRefs[blockProps.id];
                const block = blockRef?.current;
                if (block) {
                    if (block.setEditor) {
                        block.setEditor();
                    }

                    const blockElement = block.ref.current;
                    const containerElement = block.props.type === AuthoringBlockType.TEXT ? block.containerRef.current : block.ref.current;
                    unwireBlockFunctions.push(() => {
                        $(blockElement).off(".autheditor");
                        $(containerElement).off("mouseup.autheditor");
                    });

                    $(blockElement).on("mousedown.autheditor", event => {
                        if (block.type !== AuthoringBlockType.MEDIA) {
                            event.stopPropagation();
                        }

                        this.setState({
                            focusedBlock: block,
                            selectedBlocks: [block],
                            rolloverBlock: block,
                            mouseDownPoint: new geom.Point(event.pageX, event.pageY)
                        });
                    });

                    $(blockElement).on("focus.autheditor", event => {
                        if (app.isDragging || app.isDraggingItem) return;

                        if (this.state.isDragSelecting) return;

                        app.isEditingText = true;

                        if (this.state.focusedBlock !== block) {
                            this.setState({
                                focusedBlock: block,
                                rolloverBlock: block
                            });
                        }
                    });

                    $(blockElement).on("blur.autheditor", event => {
                        if (app.isDragging || app.isDraggingItem) return;

                        app.isEditingText = false;

                        if (blockProps.removeBlockWhenEmpty && block.model.html == "") {
                            this.deleteBlock(block);
                        }
                    });

                    $(blockElement).on("paste.autheditor", event => {
                        event.stopPropagation();

                        this.handlePaste(event);
                    });
                }
            }
        }

        const container = this.blockContainer.ref.current;
        this.unwireBlockEvents = () => {
            unwireBlockFunctions.forEach(fn => fn());
            $(container).off(".autheditor");
            this.unwireBlockEvents = () => { };
        };
    }

    componentDidMount() {
        const { element, onReady } = this.props;

        this.wireBlockEvents();

        document.addEventListener("paste", this.handlePaste);
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("mousedown", this.handleDocumentMouseDown);
        document.addEventListener("mouseup", this.handleDocumentMouseUp);
        document.addEventListener("mousemove", this.handleMouseMove);

        element.canvas.on(CanvasEventType.RENDER, this.onCanvasRender);

        onReady();
    }

    componentWillUnmount() {
        const { element } = this.props;

        this.isUnmounted = true;
        app.isEditingText = false;

        document.removeEventListener("paste", this.handlePaste);
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("mousedown", this.handleDocumentMouseDown);
        document.removeEventListener("mouseup", this.handleDocumentMouseUp);

        element.canvas.off(CanvasEventType.RENDER, this.onCanvasRender);

        this.unwireBlockEvents();

        if (this.blockContainer.props) {
            for (let blockProps of this.blockContainer.props.blockProps) {
                let blockRef = this.blockContainer.blockRefs[blockProps.id];
                let block = blockRef?.current;
                if (block) {
                    if (block.removeEditor) {
                        block.removeEditor();
                    }
                }

                if (blockProps.removeBlockWhenEmpty && blockProps.model.html == "") {
                    this.deleteBlock(block, false);
                }
            }
        }

        this.removeEmptyBlockFromShape();

        const selection = window.getSelection();

        if (selection.anchorNode && this.blockContainer.blocks.some(block => block.ref.current?.contains(selection.anchorNode))) {
            selection.removeAllRanges();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { onSelectedBlocksChanged, element, lockSlideForCollaborators = () => { }, canvasController } = this.props;
        const { focusedBlock, rolloverBlock, selectedBlocks, blocksSignature } = this.state;

        if (prevState.focusedBlock !== this.state.focusedBlock || !_.isEqual(prevState.selectedBlocks, this.state.selectedBlocks)) {
            if (onSelectedBlocksChanged) {
                onSelectedBlocksChanged(this.state.focusedBlock, this.state.selectedBlocks);
            }

            lockSlideForCollaborators();
        }

        if (selectedBlocks.length > 0 && !focusedBlock) {
            canvasController.setDisableUserSelect(true);
        } else {
            canvasController.setDisableUserSelect(false);
        }

        if (prevProps.element !== element) {
            const prevBlockContainer = prevProps.element.childElement.blockContainerRef.current;
            prevProps.element.canvas.off(CanvasEventType.RENDER, this.onCanvasRender);
            this.removeEmptyBlockFromShape(prevBlockContainer)
                .then(() => {
                    element.canvas.on(CanvasEventType.RENDER, this.onCanvasRender);
                    this.wireBlockEvents();
                    this.setState({ ...this.defaultState });
                });
        }

        if (prevState.blocksSignature !== blocksSignature) {
            this.wireBlockEvents();

            const stateUpdates = {};
            if (focusedBlock && !this.blockContainer.blocks.includes(focusedBlock)) {
                stateUpdates.focusedBlock = null;
            }
            if (rolloverBlock && !this.blockContainer.blocks.includes(rolloverBlock)) {
                stateUpdates.rolloverBlock = null;
            }
            if (selectedBlocks.some(block => !this.blockContainer.blocks.includes(block))) {
                stateUpdates.selectedBlocks = selectedBlocks.filter(block => this.blockContainer.blocks.includes(block));
            }
            if (Object.keys(stateUpdates).length > 0) {
                this.setState(stateUpdates);
            }
        }
    }

    setStateAsync = stateUpdates => new Promise(resolve => this.setState(stateUpdates, resolve))

    // endregion

    // region EventHandlers

    onCanvasRender = () => {
        const { element } = this.props;

        if (element.isDeleted) {
            return;
        }

        const blocksSignature = this.blockContainer.blocks
            .map(({ type, props: { id, textStyle } }) => `${id}:${type}:${textStyle ?? ""}`)
            .join("|");
        if (this.state.blocksSignature !== blocksSignature) {
            this.setState({
                blocksSignature
            });
        }
    }

    handleDocumentMouseDown = event => {
        this.setState({
            mouseDownPoint: null
        });

        if ($(event.target).hasClass("slide_canvas")) {
            this.clearSelection();
        }
    }

    handleDocumentMouseUp = event => {
        this.setState({
            mouseDownPoint: null,
            isDragSelecting: false
        });
    }

    handleMouseMove = event => {
        const {
            element
        } = this.props;
        const {
            focusedBlock,
            rolloverBlock,
            isShowingPopup,
            isDragging,
            mouseDownPoint,
            rolloverRegionId
        } = this.state;

        if ($(".MuiPopover-root").length > 0) return;
        if (app.isDragging) return;
        if (app.isDraggingItem) return;
        if (app.dialogManager.openDialogs.length) return;
        if (isDragging) return;
        if (isShowingPopup) return;
        if (element.isDeleted) return;

        let block;
        for (const childBlock of this.blockContainer.blocks) {
            if (childBlock.bounds.inflate({ left: 30, right: 30, top: 10, bottom: 10 }).contains(event.pageX, event.pageY)) {
                block = childBlock;
                break;
            }
        }
        if (block && block !== rolloverBlock) {
            this.setState({ rolloverBlock: block });
        } else if (!block && focusedBlock && focusedBlock !== rolloverBlock) {
            this.setState({ rolloverBlock: focusedBlock });
        } else if (!block && !focusedBlock && rolloverBlock) {
            this.setState({ rolloverBlock: null });
        }

        let newRolloverRegionId = null;
        for (let el of $(this.blockContainer.ref.current).find(".region-frame")) {
            let regionBounds = geom.Rect.FromBoundingClientRect(el.getBoundingClientRect()).inflate({ top: 50 });
            if (regionBounds.contains(event.pageX, event.pageY)) {
                newRolloverRegionId = el.getAttribute("data-region-id");
            }
        }
        if (newRolloverRegionId !== rolloverRegionId) {
            this.setState({ rolloverRegionId });
        }

        if (event.buttons == 1 && block && block != focusedBlock && mouseDownPoint) {
            const selectedBlocks = [];
            const selectionBox = new geom.Rect(mouseDownPoint, new geom.Point(event.pageX, event.pageY));
            for (const childBlock of this.blockContainer.blocks) {
                if (selectionBox.intersects(childBlock.bounds)) {
                    selectedBlocks.push(childBlock);
                }
            }
            this.setState({
                focusedBlock: null,
                selectedBlocks,
                isDragSelecting: true
            });
        }
    }

    handleKeyDown = async (event, selectionState) => {
        if (app.dialogManager.openDialogs.length) return;

        const { focusedBlock, selectedBlocks } = this.state;
        const { editorConfig, blockStructure, onKeyDownInBlock, refreshCanvasAndSaveChanges, element, selectionLayerController } = this.props;

        // prevent keyboard handling when coming from other ui (like equation editor textarea)
        if (event.target.nodeName == "TEXTAREA" || event.target.nodeName == "INPUT") return;

        switch (event.which) {
            case Key.KEY_A:
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();
                    this.selectAll();
                    return true;
                }
        }

        if (focusedBlock && selectionState) {
            if (onKeyDownInBlock) {
                // give the editor a chance to handle this keydown event and prevent the default behavior (ie. TextListEditor will add a new block on ENTER key in empty block)
                if (onKeyDownInBlock(event, focusedBlock, selectionState)) {
                    return;
                }
            }

            // prevent all keyboard events while focused selection from propagating
            event.stopPropagation();

            switch (event.which) {
                case Key.UP_ARROW:
                    if (event.shiftKey) {
                        event.stopPropagation();
                        event.preventDefault();
                        const newSelectedBlocks = [];
                        if (focusedBlock.index > 0) {
                            newSelectedBlocks.push(this.blockContainer.blocks[focusedBlock.index - 1]);
                        }
                        newSelectedBlocks.push(focusedBlock);
                        this.setState({ selectedBlocks: newSelectedBlocks, focusedBlock: null });
                        return true;
                    }
                case Key.DOWN_ARROW:
                    if (event.shiftKey) {
                        event.stopPropagation();
                        event.preventDefault();
                        const newSelectedBlocks = [focusedBlock];
                        if (focusedBlock.index > this.blockContainer.blocks.length - 1) {
                            newSelectedBlocks.push(this.blockContainer.blocks[focusedBlock.index + 1]);
                        }
                        this.setState({ selectedBlocks: newSelectedBlocks, focusedBlock: null });
                        return true;
                    }
                case Key.LEFT_ARROW:
                case Key.RIGHT_ARROW:
                    // move block focus
                    const newIndex = focusedBlock.index + ((event.which === Key.UP_ARROW || event.which === Key.LEFT_ARROW) ? -1 : 1);
                    if (newIndex in this.blockContainer.blocks) {
                        event.preventDefault();
                        this.focusBlock(
                            this.blockContainer.blocks[newIndex],
                            (event.which === Key.UP_ARROW || event.which === Key.LEFT_ARROW) ? TextFocusType.END : TextFocusType.START
                        );
                    }
                    return true;
                case Key.ENTER:
                    if (blockStructure == BlockStructureType.SINGLE_BLOCK) {
                        event.preventDefault();
                        return true;
                    } else {
                        event.preventDefault();
                        if (focusedBlock.type == AuthoringBlockType.TEXT) {
                            if (selectionState.isAtEnd) {
                                let model;
                                if (focusedBlock.model.listStyle) {
                                    if (focusedBlock.model.html == "" && focusedBlock.model.indent > 0) {
                                        // create another first-level bullet
                                        focusedBlock.model.indent = 0;
                                        let prevBulletBlock = _.last(this.blockContainer.blocks.filter(block => block.index < focusedBlock.index && block.model.listStyle && block.model.indent == 0));
                                        focusedBlock.model.listStyle = prevBulletBlock?.model.listStyle ?? element.defaultBlockListStyle ?? ListStyleType.BULLET;

                                        refreshCanvasAndSaveChanges();

                                        return;
                                    } else {
                                        // create sub-bullet text
                                        model = {
                                            ...focusedBlock.model,
                                            id: uuid(),
                                            html: ""
                                        };
                                        if (editorConfig.createSubBulletOnEnter && (focusedBlock.model.indent == 0 || !focusedBlock.model.indent)) {
                                            model.indent = 1;
                                            model.listStyle = ListStyleType.TEXT;
                                        }
                                    }
                                } else {
                                    model = {
                                        type: AuthoringBlockType.TEXT,
                                        textStyle: TextStyleType.BODY
                                    };

                                    // special case for hitting enter on an agenda section - this should really be handled by adding a new option to editorConfig
                                    if (focusedBlock.model.textStyle === TextStyleType.SECTION) {
                                        model.textStyle = TextStyleType.BULLET_LIST;
                                        model.listStyle = ListStyleType.NUMBERED;
                                    }

                                    if (focusedBlock.model.textStyle === TextStyleType.BODY) {
                                        // Preserve color and font size
                                        model.fontColor = focusedBlock.model.fontColor;
                                        model.fontSize = focusedBlock.model.fontSize;
                                    }
                                }
                                this.handleAddBlock({ model, replaceCurrentBlock: false });
                            } else if (selectionState.start == selectionState.end) {
                                this.splitBlock(focusedBlock, selectionState.start);
                            }
                        } else {
                            this.handleAddBlock({
                                model: {
                                    type: AuthoringBlockType.TEXT,
                                    textStyle: TextStyleType.BODY
                                }, replaceCurrentBlock: false
                            });
                        }
                        return true;
                    }
                case Key.DELETE:
                    if (focusedBlock.type != AuthoringBlockType.TEXT) {
                        event.stopPropagation();
                        event.preventDefault();
                        this.clearSelection();
                        this.deleteBlocks([focusedBlock]);
                        return true;
                    } else {
                        if (blockStructure == BlockStructureType.SINGLE_BLOCK) {
                            event.stopPropagation();
                            event.preventDefault();
                            return true;
                        } else {
                            if (selectionState.isAtEnd && this.blockContainer.blocks[focusedBlock.index + 1]?.type === AuthoringBlockType.TEXT) {
                                event.stopPropagation();
                                event.preventDefault();
                                this.mergeBlocks(focusedBlock, this.blockContainer.blocks[focusedBlock.index + 1]);
                                return true;
                            } else {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }
                    }
                    break;
                case Key.BACKSPACE:
                    if (focusedBlock.type !== AuthoringBlockType.TEXT) {
                        event.stopPropagation();
                        event.preventDefault();
                        this.clearSelection();
                        this.deleteBlocks([focusedBlock]);
                        return true;
                    } else {
                        if (blockStructure == BlockStructureType.SINGLE_BLOCK) {
                            event.stopPropagation();
                            event.preventDefault();
                            return true;
                        } else {
                            if (selectionState.isAtStart) {
                                if (focusedBlock.model.html == "") {
                                    event.preventDefault();
                                    let prevBlock = (focusedBlock.index > 0) ? this.blockContainer.blocks[focusedBlock.index - 1] : null;
                                    if (!prevBlock && !this.props.editorConfig.canDeleteLastBlock) {
                                        return;
                                    }

                                    await this.deleteBlocks([focusedBlock]);
                                    if (prevBlock) {
                                        if (element.options.autoDistributeBullets) {
                                            let column = element.getRootElement().itemElements.find(column => column.text?.calculatedProps.blockProps.findById(prevBlock.props.id));
                                            selectionLayerController.selectTextElementBlock(column.text, prevBlock.props.id);
                                        } else {
                                            this.setState({
                                                focusedBlock: prevBlock,
                                                rolloverBlock: prevBlock,
                                                selectedBlocks: prevBlock ? [prevBlock] : []
                                            });
                                            this.focusBlock(prevBlock, TextFocusType.END);
                                        }
                                    }

                                    return true;
                                } else {
                                    if (focusedBlock.index > 0 && this.blockContainer.blocks[focusedBlock.index - 1].type == AuthoringBlockType.TEXT) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.mergeBlocks(this.blockContainer.blocks[focusedBlock.index - 1], focusedBlock);
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        } else if (selectedBlocks.length > 0) {
            // prevent all keyboard events while blocks are selected from propagating to editor
            event.stopPropagation();

            if (event.metaKey || event.ctrlKey) {
                switch (event.which) {
                    case Key.KEY_C:
                    case Key.KEY_X:
                        clipboardWrite({
                            [ClipboardType.BLOCKS]: selectedBlocks.map(block => block.model),
                        });

                        if (event.which == Key.KEY_X) {
                            this.deleteBlocks(selectedBlocks);
                        }
                        return true;
                    case Key.KEY_Z:
                        event.preventDefault();

                        if (event.shiftKey) {
                            app.undoManager.redo();
                        } else {
                            app.undoManager.undo();
                        }
                        return true;
                }
            }

            switch (event.which) {
                case Key.TAB:
                case Key.ENTER:
                    event.preventDefault();
                    return true;
                case Key.DELETE:
                case Key.BACKSPACE: {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        focusedBlock: null,
                        rolloverBlock: null,
                        selectedBlocks: []
                    });

                    if (selectedBlocks.length == this.blockContainer.blocks.length) {
                        let firstBlock = selectedBlocks.shift();
                        firstBlock.model.html = "";
                        this.deleteBlocks(selectedBlocks);

                        this.focusBlock(firstBlock, TextFocusType.START);
                        return true;
                    } else {
                        this.deleteBlocks(selectedBlocks);
                        return true;
                    }
                }

                case Key.UP_ARROW:
                    if (event.shiftKey) {
                        event.stopPropagation();
                        event.preventDefault();
                        let newSelectedBlocks;
                        const firstSelectedBlockIndex = _.minBy(selectedBlocks, ({ index }) => index).index;
                        if (firstSelectedBlockIndex > 0) {
                            newSelectedBlocks = [this.blockContainer.blocks[firstSelectedBlockIndex - 1], ...selectedBlocks];
                        } else if (selectedBlocks.length > 1) {
                            newSelectedBlocks = [...selectedBlocks];
                            newSelectedBlocks.pop();
                        } else {
                            newSelectedBlocks = [...selectedBlocks];
                        }
                        this.setState({ selectedBlocks: newSelectedBlocks });
                        return true;
                    }
                    break;
                case Key.DOWN_ARROW:
                    if (event.shiftKey) {
                        event.stopPropagation();
                        event.preventDefault();
                        const newSelectedBlocks = [...selectedBlocks];
                        const firstSelectedBlockIndex = _.maxBy(selectedBlocks, ({ index }) => index).index;
                        if (firstSelectedBlockIndex < this.blockContainer.blocks.length - 1) {
                            newSelectedBlocks.push(this.blockContainer.blocks[firstSelectedBlockIndex + 1]);
                        } else if (newSelectedBlocks.length > 1) {
                            newSelectedBlocks.shift();
                        }
                        this.setState({ selectedBlocks: newSelectedBlocks });
                        return true;
                    }
                    break;
                case Key.LEFT_ARROW:
                case Key.RIGHT_ARROW:
                    event.stopPropagation();
                    return true;
            }

            // no special keys were handled so merge the selected blocks into one with the pressed keyt
            if (!event.metaKey && !event.ctrlKey && !event.altKey && !event.shiftKey) {
                let firstBlock = selectedBlocks.shift();

                firstBlock.model.html = "";
                this.setState({
                    focusedBlock: firstBlock,
                    rolloverBlock: firstBlock,
                    selectedBlocks: [firstBlock]
                });
                await this.deleteBlocks(selectedBlocks);
                this.focusBlock(firstBlock, TextFocusType.END);
                return true;
            }
        }
    }

    handlePaste = async event => {
        const { element, selectionLayerController } = this.props;
        if (!selectionLayerController.selectedElements.includes(element)) {
            return;
        }

        let blocks = await clipboardRead([ClipboardType.BLOCKS], event);
        if (blocks) {
            this.handlePasteBlocks(blocks);
        }
    }

    handlePasteBlocks = async blocks => {
        const { refreshCanvasAndSaveChanges, element } = this.props;
        const { focusedBlock, selectedBlocks } = this.state;

        const myBlocks = element.textModel.blocks;

        if (focusedBlock) {
            if (blocks[0].textStyle == focusedBlock.props.textStyle) {
                focusedBlock.model.html = blocks[0].html;
            } else {
                focusedBlock.model.html = `${focusedBlock.model.html ?? ""}${blocks[0].html}`;
            }

            for (let i = 1; i < blocks.length; i++) {
                myBlocks.insert({ ...blocks[i], id: uuid() }, focusedBlock.index + i);
            }
        } else {
            for (let block of selectedBlocks) {
                myBlocks.remove(block.model);
            }
            for (let i = 0; i < blocks.length; i++) {
                myBlocks.push({ ...blocks[i], id: uuid() });
            }
        }

        await refreshCanvasAndSaveChanges();
        this.setState({
            selectedBlocks: []
        });
    }

    handleAddBlock = async ({ model, insertAtIndex = null, replaceCurrentBlock = false }) => {
        const { focusedBlock } = this.state;

        const targetBlock = focusedBlock;

        let index = 0;
        if (insertAtIndex == null && targetBlock) {
            if (replaceCurrentBlock) {
                index = targetBlock.index;
            } else {
                index = targetBlock.index + 1;

                const nextBlocks = this.blockContainer.blocks.filter((block, index) => index >= targetBlock.index + 1);
                const nextBlock = nextBlocks[0];

                if (targetBlock.model.listStyle && nextBlock && nextBlock.model.listStyle && nextBlock.indent > targetBlock.indent) {
                    for (const block of nextBlocks) {
                        if (block.indent > targetBlock.indent) {
                            index++;
                        } else {
                            break;
                        }
                    }
                }
            }
        } else {
            index = insertAtIndex;
        }

        if (Object.values(TextStyleType).includes(model.type)) {
            model.textStyle = model.type;
            model.type = AuthoringBlockType.TEXT;
        }

        if (targetBlock) {
            model.blockStyle = targetBlock.model.blockStyle;
            model.blockColor = targetBlock.model.blockColor;
            model.fontColor = targetBlock.model.fontColor;
            model.spaceAbove = targetBlock.model.spaceAbove;
        }

        if (replaceCurrentBlock) {
            this.blockContainer.model.blocks.remove(targetBlock.model);
        }

        await this.addBlock(model, index);
    }

    handleDeleteBlock = async blocks => {
        this.clearSelection();

        let blocksToDelete = [...blocks];

        for (let deletedBlock of blocks) {
            if (deletedBlock.props.textStyle == TextStyleType.BULLET_LIST) {
                const nextBlocks = this.blockContainer.blocks.filter((block, index) => index > deletedBlock.props.index);
                for (const nextBlock of nextBlocks) {
                    if (nextBlock.indent > deletedBlock.props.indent) {
                        blocksToDelete.push(nextBlock);
                    } else {
                        break;
                    }
                }
            }
        }

        await this.deleteBlocks(blocksToDelete);
    }

    // endregion

    // region Action functions

    selectAll = () => {
        this.setState({
            focusedBlock: null,
            selectedBlocks: this.blockContainer.blocks
        });
    }

    clearSelection = () => {
        this.setState({
            focusedBlock: null,
            rolloverBlock: null,
            selectedBlocks: [],
        });
        this.clearTextSelection();
    }

    clearTextSelection = () => {
        const selection = window.getSelection();
        selection.removeAllRanges();
    }

    mergeBlocks = async (startBlock, endBlock) => {
        if (startBlock.type == AuthoringBlockType.TEXT && endBlock.type == AuthoringBlockType.TEXT) {
            const startBlockTextLength = startBlock.ref.current.textContent.length;
            startBlock.model.html = (startBlock.model.html ?? "") + (endBlock.model.html ?? "");
            await this.deleteBlock(endBlock);
            this.focusBlock(startBlock, TextFocusType.POSITION, startBlockTextLength);
        }
    }

    splitBlock = async (block, position) => {
        if (block.type == AuthoringBlockType.TEXT) {
            const range = window.getSelection().getRangeAt(0);
            range.deleteContents();

            const startRange = range.cloneRange();
            startRange.setStart(block.ref.current, 0);
            const startDiv = document.createElement("div");
            startDiv.appendChild(startRange.cloneContents());
            const startHtml = sanitizeHtml(startDiv.innerHTML);

            range.setEndAfter(block.ref.current);
            const endDiv = document.createElement("div");
            endDiv.appendChild(range.cloneContents());
            const endHtml = sanitizeHtml(endDiv.firstElementChild.innerHTML);

            block.model.html = startHtml;
            this.handleAddBlock({
                model: {
                    ...block.model,
                    id: uuid(),
                    html: endHtml
                },
                insertAtIndex: block.index + 1,
                replaceCurrentBlock: false
            });
        }
    }

    focusBlock = (block, focusType = TextFocusType.START, focusPosition = null, selectionSize = 0) => {
        const selection = window.getSelection();
        selection.selectAllChildren(block.ref.current);
        if (focusType === TextFocusType.POSITION) {
            selection.collapseToStart();
            while (focusPosition > 0) {
                selection.modify("move", "forward", "character");
                focusPosition--;
            }
            while (selectionSize > 0) {
                selection.modify("extend", "forward", "character");
                selectionSize--;
            }
        } else if (focusType === TextFocusType.START) {
            selection.collapseToStart();
        } else if (focusType === TextFocusType.END) {
            selection.collapseToEnd();
        }

        return this.setStateAsync({
            focusedBlock: block,
            selectedBlocks: [block],
            rolloverBlock: block
        });
    }

    focusElementBlock = blockId => {
        this.setState({ focusedBlock: this.blockContainer.blockRefs[blockId].current });
    }

    addBlock = async (blockModel, index) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        blockModel.id = uuid();
        this.blockContainer.model.blocks.insert(blockModel, index);

        try {
            await refreshCanvasAndSaveChanges();
        } catch (err) {
            ShowWarningDialog({
                title: "Unable to add another block",
                message: err
            });
            return;
        }

        const newBlock = this.blockContainer.blocks.find(block => block.model.id === blockModel.id);
        this.focusBlock(newBlock, TextFocusType.START);

        return newBlock;
    }

    deleteBlock = block => this.deleteBlocks([block])

    deleteBlocks = async (blocks, saveChanges = true) => {
        const { refreshCanvasAndSaveChanges, element, selectionLayerController } = this.props;

        for (const block of blocks) {
            this.blockContainer.model.blocks.remove(block.model);
        }

        if (!selectionLayerController.selectedElements.includes(element)) {
            await selectionLayerController.setSelectedElements([element]);

            if (saveChanges) {
                // Wait until selection layer refreshes and then refresh canvas
                return new Promise((resolve, reject) => {
                    _.defer(() => {
                        refreshCanvasAndSaveChanges()
                            .then(() => resolve())
                            .catch(err => reject(err));
                    });
                });
            }
        }

        if (saveChanges) {
            element.parentElement.onBlockRemoved && element.parentElement.onBlockRemoved();
            await refreshCanvasAndSaveChanges();
        }
    }

    moveBlocks = async (blocks, newIndex) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        const lastBlock = blocks[blocks.length - 1];
        const targetBlock = this.blockContainer.blocks.find(block => block.index === newIndex);
        const nextBlocks = this.blockContainer.blocks.filter((block, index) => index > lastBlock.index);

        const nextBlocksToMove = [];
        if (lastBlock.model.listStyle && nextBlocks.length > 0 && nextBlocks[0].model.listStyle && nextBlocks[0].indent >= lastBlock.indent) {
            for (const nextBlock of nextBlocks) {
                if (nextBlock.index === newIndex) {
                    break;
                }

                if (nextBlock.indent >= lastBlock.indent) {
                    nextBlocksToMove.push(nextBlock);
                } else {
                    break;
                }
            }
        }

        const firstBlock = blocks[0];
        if (newIndex > firstBlock.index) {
            newIndex -= nextBlocksToMove.length + blocks.length - 1;
        }

        if (targetBlock) {
            for (let block of blocks) {
                block.model.blockStyle = targetBlock.model.blockStyle;
                block.model.blockColor = targetBlock.model.blockColor;
            }
        }

        this.blockContainer.model.blocks.splice(firstBlock.index, nextBlocksToMove.length + blocks.length);
        this.blockContainer.model.blocks.splice(newIndex, 0, ...blocks.map(block => block.model), ...nextBlocksToMove.map(block => block.model));

        await refreshCanvasAndSaveChanges();

        return this.blockContainer.blocks.filter(block => block.index >= newIndex && block.index < (newIndex + blocks.length));
    }

    /**
     * Deletes single empty block from a shape (but not a textbox) when losing focus
     */
    removeEmptyBlockFromShape = async blockContainer => {
        if (blockContainer && !blockContainer.isTextBox && blockContainer.blocks.length == 1 && blockContainer.blocks[0].model.html == "") {
            blockContainer.model.fitToText = false;
            blockContainer.model.textAlign = HorizontalAlignType.CENTER;
            blockContainer.model.verticalAlign = null;
            blockContainer.model.textInset = 20;
            await this.deleteBlock(blockContainer.blocks[0]);
        }
    }

    //endregion

    // region PopupMenu

    handleMoveBlocks = (blocks, direction) => {
        let index = _.min(blocks.map(b => b.props.index)) + direction;
        this.moveBlocks(blocks, index);
    }

    handleInsertBlock = (blocks, direction) => {
        let index = _.min(blocks.map(b => b.props.index)) + direction;
        this.handlePopupSelect({ type: TextStyleType.BODY }, "add", index);
    }

    handleShowPopup = (event, actionType) => {
        this.setState({
            popupAnchor: event.currentTarget,
            isShowingPopup: true,
            popupActionType: actionType
        });
    }

    handlePopupSelect = async (blockType, actionType, insertIndex) => {
        let options = {
            model: {
                id: uuid(),
                type: blockType.type,
            },
            insertAtIndex: actionType == "add" ? (insertIndex ?? this.blockContainer.blocks.length) : null,
            replaceCurrentBlock: actionType == "replace"
        };

        switch (blockType.type) {
            case AuthoringBlockType.ELEMENT:
                options.model.elementModel = {};
                break;
            case TextStyleType.BULLET_LIST:
                options.model.listStyle = ListStyleType.BULLET;
                break;
            case TextStyleType.NUMBERED_LIST:
                options.model.listStyle = ListStyleType.NUMBERED;
                break;
        }

        this.handleClosePopup()
            .then(() => this.handleAddBlock(options));
    }

    handleSetBlockType = async (blocks, type) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        for (let block of blocks) {
            if (Object.values(TextStyleType).includes(type)) {
                block.model.textStyle = type;
                block.model.type = AuthoringBlockType.TEXT;
            } else {
                block.model.type = type;
            }
        }

        this.setState({
            rolloverBlock: null,
            selectedBlocks: [],
            focusedBlock: null
        });

        await refreshCanvasAndSaveChanges();
    }

    handleSetBlockStyle = (blocks, style) => {
        const { refreshCanvasAndSaveChanges } = this.props;
        for (let block of blocks) {
            block.model.blockStyle = style;
        }
        refreshCanvasAndSaveChanges();
    }

    handleClosePopup = () => this.setStateAsync({
        popupAnchor: null,
        isShowingPopup: false
    })

    //endregion

    handleDragBlocks = (dragBlocks, rolloverBlock) => {
        const { element, selectionLayerController } = this.props;

        if (dragBlocks.length === 1) {
            const dragBlock = dragBlocks[0];
            if (dragBlock.type === AuthoringBlockType.TEXT && dragBlock.model.listStyle) {
                // Collect new blocks
                const dragBlockIndex = this.blockContainer.blocks.indexOf(dragBlock);
                for (const nextBlock of this.blockContainer.blocks.slice(dragBlockIndex + 1)) {
                    if (
                        nextBlock.type !== AuthoringBlockType.TEXT ||
                        !nextBlock.model.listStyle ||
                        nextBlock.indent <= dragBlock.indent
                    ) {
                        break;
                    }
                    dragBlocks.push(nextBlock);
                }
            }
        }

        if (selectionLayerController) {
            selectionLayerController.registerBlockDrag(this, element, dragBlocks, rolloverBlock);
        } else if (element.canvas.selectionLayer) {
            element.canvas.selectionLayer.dragBlockManager.registerDrag(this, element, dragBlocks, rolloverBlock);
        }
    }

    render() {
        const {
            element,
            bounds,
            scale,
            blockStructure = BlockStructureType.FREEFORM,
            refreshCanvasAndSaveChanges,
            refreshCanvas,
            refreshElement,
            saveChanges,
            editorConfig = AuthoringBlockEditor.defaultEditorConfig,
            selectionLayerController,
            canvasController
        } = this.props;
        const {
            popupAnchor,
            popupActionType,
            rolloverBlock,
            focusedBlock,
            selectedBlocks,
            isDragging,
            rolloverRegionId
        } = this.state;

        if (!this.blockContainer || this.blockContainer.blocks.length == 0) {
            return null;
        }

        const Editors = [];
        for (const block of this.blockContainer.blocks) {
            // Block may not have been rendered
            if (!block) {
                continue;
            }

            const blockBounds = this.convertToSelectionBounds(block.bounds);

            // Always rendering all text editors
            if (block.type === AuthoringBlockType.TEXT) {
                Editors.push(
                    <TextBlockEditor
                        key={block.index}
                        isFocused={block === focusedBlock}
                        element={element}
                        scale={scale}
                        container={this.blockContainer}
                        block={block}
                        bounds={blockBounds}
                        refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                        refreshCanvas={refreshCanvas}
                        refreshElement={refreshElement}
                        saveChanges={saveChanges}
                        onShowMenu={stopPropagation(event => this.handleShowPopup(event, "replace"))}
                        onKeyDown={this.handleKeyDown}
                        editorConfig={editorConfig}
                        selectionLayerController={selectionLayerController}
                        canvasController={canvasController}
                    />
                );
            } else if (block === focusedBlock) {
                const editorProps = {
                    key: block.index,
                    element,
                    container: this.blockContainer,
                    block: block,
                    bounds: blockBounds,
                    containerBounds: bounds,
                    refreshCanvasAndSaveChanges: refreshCanvasAndSaveChanges,
                    refreshCanvas: refreshCanvas,
                    refreshElement: refreshElement,
                    editorConfig,
                    selectionLayerController,
                    canvasController
                };

                // Will render other editors only for selected block
                switch (block.type) {
                    case AuthoringBlockType.EQUATION:
                        Editors.push(
                            <EquationBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.CODE:
                        Editors.push(
                            <CodeBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.DIVIDER:
                        Editors.push(
                            <DividerBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.ELEMENT:
                        Editors.push(
                            <ElementBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.CARDS:
                        Editors.push(
                            <CardsBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.REGION_START:
                        Editors.push(
                            <StartRegionBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.MEDIA:
                        Editors.push(
                            <MediaBlockEditor {...editorProps} />
                        );
                        break;
                }
            }
        }

        const textBlocks = selectedBlocks.filter(block => block.type === AuthoringBlockType.TEXT);

        const addBlockWidgetBounds = new geom.Rect(0, this.convertToSelectionBounds(_.last(this.blockContainer.blocks).bounds).bottom + 20, bounds.width, 10);

        const blockSelectionPadding = { left: 7, right: 7, top: 5, bottom: 5 };

        let targetBlocks;
        if (selectedBlocks.length > 0 && selectedBlocks.contains(rolloverBlock)) {
            targetBlocks = selectedBlocks;
        } else {
            targetBlocks = [rolloverBlock];
        }

        const showRolloverBlock = editorConfig.showRolloverBlock;

        let rolloveRegion;
        if (rolloverRegionId) {
            rolloveRegion = this.blockContainer.blockRefs[rolloverRegionId].current;
        }

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BlockEditorsContainer className="block-editors-container">
                    {!isDragging && <>
                        {!focusedBlock && selectedBlocks.filter(block => block.bounds).map((block, index) => {
                            if (index === 0 && editorConfig.canReorderBlocks) {
                                return (
                                    <SelectedBlock
                                        key={index}
                                        className={`SelectedBlock_${block.id}`}
                                        bounds={this.convertToSelectionBounds(block.bounds, blockSelectionPadding)}
                                    >
                                        {
                                            editorConfig.positionWidgetBarWithTextBlock &&
                                            textBlocks.length > 0 &&
                                            <TextFormatWidgetBar
                                                offset={16}
                                                bounds={block.bounds}
                                                containers={[element]}
                                                selectedBlocks={textBlocks}
                                                isMultiSelectMode={true}
                                                refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                                                refreshElement={refreshElement}
                                                editorConfig={{
                                                    ...editorConfig,
                                                    showMargins: editorConfig.showMargins && textBlocks.length === selectedBlocks.length
                                                }}
                                            />
                                        }
                                    </SelectedBlock>
                                );
                            }

                            return (<SelectedBlock key={index} className={`SelectedBlock_${block.id}`}
                                bounds={this.convertToSelectionBounds(block.bounds, blockSelectionPadding)}
                            />);
                        })}
                        {focusedBlock && focusedBlock.bounds && editorConfig.showFocusedBlock &&
                            <FocusedBlock
                                className={`FocusedBlock_${focusedBlock.id}`}
                                bounds={this.convertToSelectionBounds(focusedBlock.bounds, blockSelectionPadding)}
                            >
                                {this.blockContainer.blocks.length === 1 && focusedBlock.props.canResize && !focusedBlock.props.isLocked &&
                                    <BlockResizeWidget
                                        block={focusedBlock}
                                        element={element}
                                        refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                                        refreshElement={refreshElement}
                                    />
                                }
                            </FocusedBlock>
                        }
                        {showRolloverBlock && rolloverBlock && rolloverBlock.bounds && !isDragging &&
                            <RolloverBlock
                                className={`RolloverBlock_${rolloverBlock.id}`}
                                bounds={this.convertToSelectionBounds(rolloverBlock.bounds, blockSelectionPadding)}>
                                {!rolloverBlock.props.isLocked &&
                                    editorConfig.canReorderBlocks &&
                                    !rolloverBlock.props.isLocked &&
                                    (Math.max(selectedBlocks.length, 1) < this.blockContainer.blocks.length || editorConfig.canDeleteLastBlock) &&
                                    <BlockMenuWidget
                                        editorConfig={editorConfig}
                                        isLocked={rolloverBlock.props.isLocked}
                                        canReorder={true}
                                        canAddBlocks={blockStructure != BlockStructureType.SINGLE_BLOCK}
                                        showBlockTypes={editorConfig.showBlockTypesInMenu}
                                        showBlockStyles={editorConfig.showBlockStylesInMenu}
                                        block={rolloverBlock}
                                        selectedBlocks={selectedBlocks}
                                        onDrag={() => this.handleDragBlocks(targetBlocks, rolloverBlock)}
                                        onMoveBlock={direction => this.handleMoveBlocks(targetBlocks, direction)}
                                        onAddBlock={direction => this.handleInsertBlock(targetBlocks, direction)}
                                        onDeleteBlock={() => this.handleDeleteBlock(targetBlocks)}
                                        onSelectBlockType={type => this.handleSetBlockType(targetBlocks, type)}
                                        onSelectBlockStyle={style => this.handleSetBlockStyle(targetBlocks, style)}
                                    />
                                }
                                {!rolloverBlock.props.isLocked && (this.blockContainer.blocks.length > 1 || editorConfig.canDeleteLastBlock) &&
                                    <DeleteButton className="editor-control"
                                        onClick={() => this.handleDeleteBlock(targetBlocks)}
                                        style={{ top: (rolloverBlock.model.blockHeight < 40) ? -10 : -1 }} />
                                }
                                {rolloverBlock.props.canResize && !rolloverBlock.props.isLocked &&
                                    <BlockResizeWidget
                                        className="editor-control"
                                        block={rolloverBlock}
                                        element={element}
                                        refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                                        refreshElement={refreshElement}
                                    />
                                }

                            </RolloverBlock>
                        }
                        {rolloveRegion &&
                            <RegionWidget
                                region={rolloveRegion}
                                blockContainer={this.blockContainer}
                                bounds={this.convertToSelectionBounds(rolloveRegion.bounds)}
                                refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                                element={element}
                                canReorder={true}
                            />
                        }

                        {Editors}

                        {editorConfig.canAddBlocks &&
                            <AddBlockWidget bounds={addBlockWidgetBounds} className="add-block-widget">
                                <AddBlockButton className="editor-control"
                                    onMouseDown={stopPropagation(event => this.handleShowPopup(event, "add"))} />
                            </AddBlockWidget>
                        }

                        {!editorConfig.positionWidgetBarWithTextBlock && !focusedBlock && textBlocks.length > 0 &&
                            <TextFormatWidgetBar
                                offset={20}
                                bounds={textBlocks[0].bounds}
                                containers={[element]}
                                selectedBlocks={textBlocks}
                                isMultiSelectMode={true}
                                refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                                refreshElement={refreshElement}
                                editorConfig={{ ...editorConfig, showMargins: editorConfig.showMargins && textBlocks.length === selectedBlocks.length }}
                            />
                        }

                        <BlockPopupMenu
                            editorConfig={editorConfig}
                            popupAnchor={popupAnchor}
                            popupActionType={popupActionType}
                            onPopupSelect={this.handlePopupSelect}
                            onClose={this.handleClosePopup}
                        />
                    </>}
                </BlockEditorsContainer>
            </MuiThemeProvider>
        );
    }
}

const BlockPopupMenuItem = styled.div`
    display: flex;
    padding: 5px 15px;
    align-items: center;

    img {
        width: 28px;
        height: 28px;
        margin-right: 15px;
    }
`;

class BlockPopupMenu extends Component {
    render() {
        const { editorConfig, popupAnchor, popupActionType, onPopupSelect, onClose } = this.props;

        return (
            <Menu
                open={!!popupAnchor}
                anchorEl={popupAnchor}
                disableEnforceFocus
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={onClose}
                {...stopEventPropagation}
            >
                {getBlockPopupMenuItems(editorConfig.allowedBlockTypes, type => onPopupSelect(type, popupActionType, null))}
            </Menu>
        );
    }
}

export function getBlockPopupMenuItems(blockTypes, callback) {
    return blockTypes.map(type => {
        return (
            <MenuItem
                key={type}
                value={type}
                onMouseDown={stopPropagation()}
                onClick={stopPropagation(() => callback({ type }))}
                style={{ padding: 5 }}
            >
                <BlockPopupMenuItem>
                    <img src={getStaticUrl(`/images/ui/blocks/${type}.svg`)} />
                    {type.toTitleCase()}
                </BlockPopupMenuItem>
            </MenuItem>
        );
    });
}

