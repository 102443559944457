import React, { Component } from "react";
import styled from "styled-components";
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, DialogProps } from "@material-ui/core";
import { getStaticUrl } from "../../../config";

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        padding: 40px 50px 40px 50px;
        max-width: 800px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &.MuiDialogTitle-root {
        padding: 0px;
    }

    h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #333;
        font-family: "Source Sans Pro";
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        text-wrap: nowrap;
    }
`;

const StyledDialogLogo = styled.img<{ width?: string, height?: string }>`
    width: ${props => props.width || "50px"};
    height: ${props => props.height || "50px"};
    margin-bottom: 15px;
`;

const StyledDialogContent = styled(DialogContent)`
    &.MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
        margin: 0px;
    }
`;

const Message = styled.div`
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
    &&&{
        color: #666;
    }
`;

const StyledDialogActions = styled(DialogActions)<{ firstButtonLabel?: string }>`
    &.MuiDialogActions-root {
        padding: 15px 0px 0px 0px;
        margin: 0px;
        justify-content: ${props => props.firstButtonLabel ? "space-between" : "flex-end"};
    }
`;

interface VersionConflictDialogProps extends DialogProps {
    title: string;
    message: string;
    submessage?: React.ReactNode;
    firstButtonLabel?: string;
    secondButtonLabel?: string;
    onFirstButtonClick?: () => void;
    onSecondButtonClick?: () => void;
    closeDialog?: () => void;
    onSubMessageClick?: () => void;
    onMessageClick?: () => void;
    preventClose?: boolean;
    customIcon?: string;
    secondButtonVariant?: "contained" | "outlined";
    logoWidth?: string;
    logoHeight?: string;
}

export default class VersionConflictDialog extends Component<VersionConflictDialogProps> {
    handleClose = () => {
        const { preventClose, closeDialog } = this.props;

        if (!preventClose && closeDialog) {
            closeDialog();
        }
    }

    render() {
        const {
            title,
            message,
            submessage,
            firstButtonLabel,
            secondButtonLabel,
            onFirstButtonClick,
            onSecondButtonClick,
            onSubMessageClick,
            onMessageClick,
            closeDialog,
            customIcon,
            secondButtonVariant,
            open = true,
            logoWidth,
            logoHeight,
            ...rest
        } = this.props;

        return (
            <StyledDialog
                onClose={(event, reason) => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        if (rest.hideBackdrop) {
                            return;
                        }
                        (event as Event).stopPropagation();
                    }
                    this.handleClose();
                }}
                disableEnforceFocus
                disableRestoreFocus
                open={open}
            >
                <StyledDialogTitle>
                    <StyledDialogLogo
                        src={customIcon || getStaticUrl(`/images/bai-bot/bai-bot-neutral.svg`)}
                        width={logoWidth}
                        height={logoHeight}
                    />
                    {title}
                </StyledDialogTitle>
                <StyledDialogContent>
                    {message && (
                        <Message>
                            {React.isValidElement(message)
                                ? React.cloneElement(message as React.ReactElement, {
                                    children: React.Children.map((message as React.ReactElement).props.children, child => {
                                        if (React.isValidElement(child) && child.type === "a" && (child as React.ReactElement).props.children === "Manage your account.") {
                                            return React.cloneElement(child as React.ReactElement<any>, {
                                                onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                    e.preventDefault();
                                                    onMessageClick && onMessageClick();
                                                    closeDialog && closeDialog();
                                                }
                                            });
                                        }
                                        return child;
                                    })
                                })
                                : message
                            }
                        </Message>
                    )}
                    {submessage && (
                        <Message>
                            {React.isValidElement(submessage)
                                ? React.cloneElement(submessage as React.ReactElement, {
                                    children: React.Children.map((submessage as React.ReactElement).props.children, child => {
                                        if (React.isValidElement(child) && child.type === "a" && (child as React.ReactElement).props.children === "team Owner") {
                                            return React.cloneElement(child as React.ReactElement<any>, {
                                                onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                    e.preventDefault();
                                                    onSubMessageClick && onSubMessageClick();
                                                    closeDialog && closeDialog();
                                                }
                                            });
                                        }
                                        return child;
                                    })
                                })
                                : submessage
                            }
                        </Message>
                    )}
                </StyledDialogContent>
                <StyledDialogActions firstButtonLabel={firstButtonLabel}>
                    {onFirstButtonClick && (
                        <StyledButton
                            onClick={() => {
                                onFirstButtonClick();
                                closeDialog && closeDialog();
                            }}
                        >
                            {firstButtonLabel}
                        </StyledButton>
                    )}
                    {onSecondButtonClick && (
                        <Button
                            onClick={() => {
                                onSecondButtonClick();
                                closeDialog && closeDialog();
                            }}
                            color="primary"
                            variant={secondButtonVariant || "text"}
                        >
                            {secondButtonLabel}
                        </Button>
                    )}
                </StyledDialogActions>
            </StyledDialog>
        );
    }
}
