import React, { Component } from "reactn";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import styled from "styled-components";

const ErrorText = styled.div`
& {
    margin-top: 10px;
    text-align: center;
    color: red;
    font-weight: bold;
}
`;

export default class InputDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || ""
        };
    }

    handleKeyDown = event => {
        event.stopPropagation();
        if (event.key == "Enter") {
            this.handleOk();
        }
    }

    handleChange = event => {
        let { maxChars } = this.props;

        let str = event.target.value;
        if (maxChars) {
            str = str.substring(0, maxChars);
        }

        this.setState({ value: str });
    }

    handleCancel = () => {
        if (this.props.onClose) {
            this.props.onClose(false);
        }
        this.props.closeDialog();
    }

    handleOk = () => {
        let {
            allowBlank = false,
            trimInput = true,
            onParse = text => text,
            onCheckForBadInput = text => false,
        } = this.props;
        let { value } = this.state;

        if (trimInput) {
            value = value.trim();
        }

        if (!allowBlank && value == "") {
            this.setState({ errorText: "Input can not be blank." });
        }

        value = onParse(value);
        const errorText = onCheckForBadInput(value);
        if (errorText) {
            this.setState({ errorText });
        } else {
            this.props.onClose(value);
            this.props.closeDialog();
        }
    }

    render() {
        const {
            cancelLabel = "Cancel",
            okLabel = "Ok",
            placeholder,
        } = this.props;
        const {
            value,
            errorText,
        } = this.state;

        return (
            <BeautifulDialog preventClose closeDialog={this.props.closeDialog}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={this.props.label}
                        id="dialog-input"
                        value={value}
                        placeholder={placeholder}
                        fullWidth
                        autoFocus
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        onFocus={evt => evt.target.select()}
                    />
                    {
                        !!errorText &&
                        <ErrorText>{errorText}</ErrorText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} data-test-id="input-dialog-cancel-button">{cancelLabel}</Button>
                    <Button onClick={this.handleOk} data-test-id="input-dialog-ok-button">{okLabel}</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
