import { ISlide } from "./models";

/**
 * Fake puppeteer options so we don't have to depend on puppeteer
 */
export declare interface BrowserOptions {
    executablePath?: string;
    ignoreDefaultArgs?: boolean | string[];
    handleSIGINT?: boolean;
    handleSIGTERM?: boolean;
    handleSIGHUP?: boolean;
    timeout?: number;
    dumpio?: boolean;
    env?: Record<string, string | undefined>;
    pipe?: boolean;
    waitForInitialPage?: boolean;
    headless?: boolean | "shell";
    userDataDir?: string;
    devtools?: boolean;
    debuggingPort?: number;
    args?: string[];
    ignoreHTTPSErrors?: boolean;
    defaultViewport?: any;
    slowMo?: number;
    targetFilter?: any;
    protocol?: any;
    protocolTimeout?: number;
}

export type IScreenshotRequest = {
    slideIndex?: number;
    theme: any;
    themeHash: string;
    assetType: "pptx" | "pdf" | "thumbnail" | "test";
    hideBaiBranding: boolean;
    hideSmartSlideWatermark: boolean;
    isTeamUser: boolean;
    userUid: string;
    browserOptions?: BrowserOptions;
    fileName?: string;
    rendererWidth?: number;
    debugInfo?: {
        [key: string]: any;
    };
    forceMigrationVersion?: number;
    stage?: number;
} & ({ slideId: string; } | { slideModel: ISlide; });

export interface IScreenshotDryRunRequest {
    dryRun: true;
}

export interface IScreenshotSuccessfulResponse {
    slideModifiedAt: string;
    slideNotes: string;
    stagesCount: number;
    exportFileId: string;
}

export interface IScreenshotErrorResponse {
    error: string;
}

export type IScreenshotResponse = IScreenshotSuccessfulResponse | IScreenshotErrorResponse;
