import React, { Component } from "react";

import { HorizontalAlignType, ListStyleType, TextStyleType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger/index";
import {
    getSelectionState,
    sanitizeHtmlText,
    sanitizePastedHtmlText,
    setSelection,
} from "js/core/utilities/htmlTextHelpers";
import { Key } from "js/core/utilities/keys";
import LoremIpsum from "js/core/utilities/loremIpsum";
import { app } from "js/namespaces";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { $ } from "js/vendor";
import { ClipboardType, clipboardRead } from "js/core/utilities/clipboard";

import { BoundsBox } from "../../SelectionBox";
import { addLink } from "./AddLink";
import { TextFormatWidgetBar } from "./TextFormatBar";

const logger = getLogger(LogGroup.EDITOR);

export class TextBlockEditor extends Component {
    state = {
        hasFocus: false
    }

    handleChanges = true;
    hasPendingTextChanges = false;
    saveTextChangesTimeout = null;
    isHandlingPaste = false;
    renderedScale;

    postUpdateCallbacks = [];

    constructor(props) {
        super(props);
        this.widgetBarRef = React.createRef();
        this.wiredBlockSignature = null;
    }

    wireBlock() {
        const { block } = this.props;

        this.wiredBlockSignature = this.getBlockSignature(block);

        this.textRef = block.ref;

        // bind listeners to events on the canvas TextBlock contentEditable ref
        $(this.textRef.current).on("input.textblockeditor", event => this.handleChange(event));
        $(this.textRef.current).on("keydown.textblockeditor", event => this.handleKeyDown(event));
        $(this.textRef.current).on("paste.textblockeditor", event => this.handlePaste(event));
    }

    unwireBlock() {
        this.wiredBlockSignature = null;

        if (this.textRef?.current) {
            $(this.textRef.current).off(".textblockeditor");
            if (document.activeElement === this.textRef.current) {
                window.getSelection().removeAllRanges();
            }
        }

        this.textRef = null;
    }

    componentDidMount() {
        this.wireBlock();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { block } = this.props;

        // Running requested post-update tasks
        this.postUpdateCallbacks.forEach(callback => callback());
        this.postUpdateCallbacks = [];

        if (prevProps.block !== block) {
            this.savePendingTextChangesIfNeeded();
        }

        if (this.wiredBlockSignature !== this.getBlockSignature(block)) {
            this.unwireBlock();
            this.wireBlock();
        }
    }

    componentWillUnmount() {
        this.unwireBlock();

        this.savePendingTextChangesIfNeeded();
    }

    getBlockSignature = block => {
        return `${block.props.id}:${block.props.textStyle ?? ""}`;
    }

    runPostUpdate = callback => {
        this.postUpdateCallbacks.push(callback);
    }

    savePendingTextChangesIfNeeded = async () => {
        const { saveChanges } = this.props;

        if (this.hasPendingTextChanges) {
            this.hasPendingTextChanges = false;
            await saveChanges();
        }
    }

    /**
     * WARNING: use this instead of props.refreshCanvasAndSaveChanges()
     */
    refreshCanvasAndSaveChanges = async () => {
        const { refreshCanvasAndSaveChanges } = this.props;

        this.hasPendingTextChanges = false;
        return refreshCanvasAndSaveChanges();
    }

    refreshCanvas = async () => {
        const { refreshCanvas } = this.props;

        return refreshCanvas();
    }

    setSelection = selectionState => {
        setSelection(selectionState, this.textRef.current);
    }

    updateHtml = (html, forceUpdate = false, forceSave = false) => {
        return this.handleChange({ target: { innerHTML: html } }, forceUpdate, forceSave);
    }

    handleChange = async (event, forceUpdate = false, forceSave = false) => {
        const { block, refreshElement } = this.props;

        const isHandlingPaste = this.isHandlingPaste;
        this.isHandlingPaste = false;

        if (!this.handleChanges) {
            return;
        }

        const html = sanitizeHtmlText(event.target.innerHTML, { font: true });

        if (!forceUpdate && !isHandlingPaste && block.model.html === html) {
            return;
        }

        block.model.html = html;

        const isFormatEvent = event.originalEvent?.inputType?.startsWith("format");

        if (isHandlingPaste || forceSave || isFormatEvent) {
            try {
                await this.refreshCanvasAndSaveChanges();
            } catch (err) {
                if (isHandlingPaste) {
                    ShowDialog(BadFitDialog, {
                        title: "Sorry, the pasted text won't fit the slide"
                    });
                } else if (isFormatEvent) {
                    ShowDialog(BadFitDialog, {
                        title: "Sorry, the formatted text won't fit the slide"
                    });
                }
                logger.error(err, "[TextBlockEditor] Error refreshing canvas and saving changes");
            }

            return;
        }

        try {
            this.hasPendingTextChanges = true;

            try {
                await refreshElement();
            } catch {
                this.hasPendingTextChanges = false;
                await this.refreshCanvasAndSaveChanges();
                return;
            }
        } catch (err) {
            logger.error(err, "[TextBlockEditor] Error refreshing canvas and saving changes");
        }

        clearTimeout(this.saveTextChangesTimeout);
        this.saveTextChangesTimeout = setTimeout(this.savePendingTextChangesIfNeeded, 5000);
    }

    handleKeyDown = async event => {
        const { isFocused, block, element, onShowMenu, onKeyDown, editorConfig, saveChanges } = this.props;

        if (!isFocused) {
            return;
        }

        const contentEditableElement = this.textRef.current;
        const textContentLength = contentEditableElement.textContent.length;
        const selectionState = getSelectionState(contentEditableElement);
        const { isAtStart, isAtEnd, isAllSelected, start: selectionStart, end: selectionEnd, textLength: selectionTextLength } = selectionState;

        event.stopPropagation();

        switch (event.which) {
            case Key.SPACE:
                if (block.model.html == "#") {
                    event.preventDefault();
                    block.model.textStyle = TextStyleType.BULLET_LIST;
                    block.model.listStyle = ListStyleType.NUMBERED;
                    block.model.html = "";
                    this.refreshCanvasAndSaveChanges();
                } else if (block.model.html == "-" || block.model.html == ".") {
                    event.preventDefault();
                    block.model.textStyle = TextStyleType.BULLET_LIST;
                    block.model.listStyle = ListStyleType.BULLET;
                    block.model.html = "";
                    this.refreshCanvasAndSaveChanges();
                }
                // }
                break;
            case Key.TAB:
                event.preventDefault();
                let blockIndex = element.textModel.blocks.indexOf(block.model);
                if (blockIndex > 0 && isAtStart && block.model.listStyle || (isAtStart && block.model.textStyle == TextStyleType.BODY && block.props.textAlign == HorizontalAlignType.LEFT)) {
                    // tab indent is supported by BulletLists and Body blocks only
                    if (event.shiftKey) {
                        block.model.indent = Math.max(0, (block.model.indent || 0) - 1);
                    } else {
                        block.model.indent = Math.min(5, (block.model.indent || 0) + 1);
                    }
                    await this.refreshCanvasAndSaveChanges();
                    setSelection({ start: 0, end: 0 }, this.textRef.current);
                } else {
                    // document.execCommand("insertText", false, "        ");
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.KEY_B:
                if (event.metaKey || event.ctrlKey) {
                    document.execCommand("bold");

                    // try to prevent default behavior
                    event.preventDefault();
                }
                break;

            case Key.KEY_I:
                if (event.metaKey || event.ctrlKey) {
                    document.execCommand("italic");

                    // try to prevent default behavior
                    // not possible in Safari
                    event.preventDefault();
                }
                break;
            case Key.KEY_K:
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();
                    if (this.widgetBarRef.current) {
                        this.widgetBarRef.current.updateSelectionStylesOnSelectionChange = false;
                    }
                    addLink({
                        block,
                        refreshCanvasAndSaveChanges: this.refreshCanvasAndSaveChanges,
                        updateHtml: this.updateHtml,
                        onClose: () => {
                            if (this.widgetBarRef.current) {
                                this.widgetBarRef.current.updateSelectionStylesOnSelectionChange = true;
                            }
                        }
                    });
                }
                break;

            case Key.ENTER:
                if (editorConfig.allowNewLines) {
                    event.preventDefault();
                    document.execCommand("insertLineBreak");
                } else if (!event.shiftKey && !event.altKey) {
                    onKeyDown(event, selectionState);
                    return;
                }
                break;
            case Key.BACKSPACE:
                if (isAtStart) {
                    if (block.model.indent > 0) {
                        block.model.indent = block.model.indent - 1;
                        await this.refreshCanvasAndSaveChanges();
                        setSelection({ start: 0, end: 0 }, this.textRef.current);
                    } else {
                        onKeyDown(event, selectionState);
                    }
                    return;
                }
                break;
            case Key.DELETE:
                if (isAtEnd) {
                    onKeyDown(event, selectionState);
                    return;
                }
                break;
            case Key.LEFT_ARROW:
                if (isAtStart) {
                    event.preventDefault();
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.RIGHT_ARROW:
                if (isAtEnd) {
                    event.preventDefault();
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.UP_ARROW:
                if (isAtStart || (event.shiftKey && selectionStart === 0)) {
                    event.preventDefault();
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.DOWN_ARROW:
                if (isAtEnd || (event.shiftKey && selectionEnd === selectionTextLength)) {
                    event.preventDefault();
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.FORWARD_SLASH:
                if (block.props.allowAdvancedTextStyling && textContentLength === 0) {
                    event.preventDefault();
                    onShowMenu(event, "below", true);
                }
                break;
            case Key.KEY_A:
                if (isAllSelected && (event.metaKey || event.ctrlKey)) {
                    event.preventDefault();
                    onKeyDown(event, selectionState);
                }
                break;
            case Key.KEY_E:
                if (window.isDevelopment) {
                    if (event.ctrlKey || event.metaKey || event.altKey) {
                        let loremText;
                        if (block.props.textStyle == TextStyleType.BODY) {
                            if (event.shiftKey) {
                                loremText = LoremIpsum.getTitle();
                            } else {
                                loremText = LoremIpsum.getParagraph(1);
                            }
                        } else {
                            if (event.shiftKey) {
                                loremText = LoremIpsum.getParagraph(1);
                            } else {
                                loremText = LoremIpsum.getTitle();
                            }
                        }
                        document.execCommand("insertText", false, loremText);
                    }
                }
                break;
            case Key.KEY_Z:
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();

                    const handleUndo = () => {
                        if (event.shiftKey) {
                            app.undoManager.redo();
                        } else {
                            app.undoManager.undo();
                        }
                    };

                    if (this.hasPendingTextChanges) {
                        this.hasPendingTextChanges = false;
                        saveChanges(false)
                            .then(() => handleUndo(event, selectionState));
                    } else {
                        handleUndo(event, selectionState);
                    }
                }
                break;
        }
    }

    handlePaste = async event => {
        const { block } = this.props;

        event.preventDefault();
        event.stopPropagation();

        let text = await clipboardRead(
            [
                ClipboardType.HTML,
                ClipboardType.TEXT,
            ],
            event
        );

        if (!text?.length) {
            return;
        }

        text = sanitizePastedHtmlText(text);

        const domParser = new DOMParser();
        const domDocument = domParser.parseFromString(text, "text/html");
        const fontNodesWithDataBlockAccentColor = domDocument.querySelectorAll("font[data-block-accent-color]");
        fontNodesWithDataBlockAccentColor.forEach(fontNode => {
            // All should have the same value
            if (block.props.canSetEmphasizedColor) {
                const accentColor = fontNode.getAttribute("data-block-accent-color");
                block.model.accentColor = accentColor;
            }
            fontNode.removeAttribute("data-block-accent-color");
        });
        text = domDocument.body.innerHTML;

        this.isHandlingPaste = true;
        document.execCommand("insertHTML", false, text);
    }

    handleMouseDown = event => {
        if (event.button === 2) {
            // Prevent default context menu from opening
            event.preventDefault();
        }
    }

    render() {
        const { block, bounds, refreshElement, element, isFocused, editorConfig } = this.props;

        if (!isFocused) {
            return null;
        }

        if (editorConfig.allowStyling === false) {
            return null;
        }

        const WidgetBar = (<TextFormatWidgetBar
            offset={editorConfig.textFormatBarOffset ?? 20}
            bounds={bounds}
            isMultiSelectMode={false}
            selectedBlocks={[block]}
            containers={[element]}
            refreshCanvasAndSaveChanges={this.refreshCanvasAndSaveChanges}
            refreshCanvas={this.refreshCanvas}
            refreshElement={refreshElement}
            updateHtml={this.updateHtml}
            runPostUpdate={this.runPostUpdate}
            stopHandlingChanges={() => this.handleChanges = false}
            startHandlingChanges={() => this.handleChanges = true}
            setSelection={this.setSelection}
            editorConfig={editorConfig}
            ref = {this.widgetBarRef}
        />);

        if (editorConfig.positionWidgetBarWithTextBlock) {
            return (
                <BoundsBox bounds={bounds}>{WidgetBar}</BoundsBox>
            );
        }

        return WidgetBar;
    }
}

