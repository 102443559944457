import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";

async function withProgressDialog<T = any>({
    title,
    message,
    action,
}: {
    title?: string;
    message?: string;
    action: () => Promise<T>;
}) {
    const dialog = ShowDialog(ProgressDialog, {
        title,
        message,
    });
    try {
        return await action();
    } finally {
        dialog.props.closeDialog();
    }
}

export default withProgressDialog;
