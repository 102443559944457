import React, { Component } from "react";
import { FeatureType } from "../../../../common/features";
import AppController from "../../../core/AppController";
import PresentationEditorController from "../../../editor/PresentationEditor/PresentationEditorController";
import { stopPropagation } from "../../../core/utilities/stopPropagation";
import { PropertyPanelContainer } from "../../../EditorComponents/PropertyPanel";
import { PropertyPanelButton } from "../../../EditorComponents/PropertyPanelButton";
import { app } from "../../../namespaces";
import { ShowDialog, ShowDialogAsync } from "../../../react/components/Dialogs/BaseDialog";
import VersionConflictDialog from "../../../react/components/Dialogs/VersionConflictDialog";
import { PropertyPanelHeader } from "../../ElementEditor/PropertyPanelHeader";

export const StylePanel = AppController.withState(class StylePanel extends Component {
    handleEditTheme = async () => {
        const { presentation, user } = this.props;

        const isTeamTheme = presentation.has("sharedThemeId");
        const canEditTheme = isTeamTheme
            ? user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, presentation.get("orgId"))
            : true;

        if (!canEditTheme && isTeamTheme) {
            await ShowDialogAsync(VersionConflictDialog, {
                title: "Sorry, you don’t have permission to edit this Team Theme.",
                message: "Team Themes are shared and only editable by team Owners and Librarians.",
                secondButtonLabel: "Got it",
                onSecondButtonClick: () => { }
            });
            return;
        }

        if (window.isPPTAddin) {
            const themeUpdated = await AppController.showThemeEditor(presentation);
            if (themeUpdated) {
                await PresentationEditorController.updateTheme();
            }
            return;
        }

        AppController.navigateToThemeEditor(presentation.id);
    }

    handleSwitchTheme = () => {
        const { currentCanvasController } = this.props;

        currentCanvasController.lockSlideForCollaborators(30);
        app.themeManager.showSwitchThemeDialog({
            onClose: () => {
                currentCanvasController.unlockSlideForCollaborators();
            }
        });
    }

    render() {
        const { presentation, user } = this.props;

        const isTeamTheme = presentation.has("sharedThemeId");
        const canEditTheme = isTeamTheme
            ? user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, AppController.workspaceId)
            : true;

        return (
            <PropertyPanelContainer fullHeight>
                <PropertyPanelHeader>Styles</PropertyPanelHeader>
                {!isTeamTheme &&
                    <PropertyPanelButton
                        title="Edit Theme..."
                        icon="palette"
                        description="Edit the current theme for this presentation."
                        onClick={this.handleEditTheme}
                        onMouseDown={stopPropagation()}
                    />
                }
                {isTeamTheme &&
                    <PropertyPanelButton
                        title="Edit Team Theme..."
                        icon="link"
                        description={canEditTheme ? "Edit team theme." : "Only Team Librarians and Members can edit shared team themes."}
                        iconBackdropColor={!canEditTheme ? "red" : "transparent"}
                        onClick={this.handleEditTheme}
                        onMouseDown={stopPropagation()}
                    />
                }
                <PropertyPanelButton
                    title="Switch Theme..."
                    icon="cycle"
                    description="Switch this presentation to another theme."
                    onClick={this.handleSwitchTheme}
                    onMouseDown={stopPropagation()}
                />
            </PropertyPanelContainer>
        );
    }
});
