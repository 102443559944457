import React, { Fragment } from "react";
import { GlobalStateController } from "bai-react-global-state";
import firebase from "firebase/compat/app";

import Api from "js/core/api";

import { getStaticUrl, isPPTAddin } from "legacy-js/config";
import { app } from "js/namespaces";
import { User } from "legacy-js/core/models/user";
import { Presentation } from "js/core/models/presentation";
import { ds } from "js/core/models/dataService";
import * as analytics from "legacy-js/analytics";
import NotificationsService from "js/core/services/notifications";
import { _ } from "legacy-js/vendor";
import { ShowDialog, ShowMessageDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import MemberAddedToOrgDialog from "legacy-js/react/views/UserOptions/dialogs/MemberAddedToOrgDialog";
import { ShowNotificationAlert } from "legacy-js/react/components/NotificationAlert";
import {
    BEAUTIFUL_WORKSPACE_ACTION,
    BEAUTIFUL_WORKSPACE_ID,
    TEAM_USER_ROLES,
    AppView,
    WorkspaceAction,
    SubscriptionStatus
} from "legacy-common/constants";
import { ForcedDowngradeDialog } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import UpdateAppDialog from "js/react/components/Dialogs/UpdateAppDialog";
import VersionConflictDialog from "js/react/components/Dialogs/VersionConflictDialog";
import MandatoryUpdateDialog from "legacy-js/react/components/Dialogs/MandatoryUpdateDialog";
import { initializeForceReloadDialog } from "legacy-js/editor/forceReload";
import isConnected from "js/core/utilities/isConnected";
import { browserHistory } from "js/react/history";
import { trackActivity, trackState } from "js/core/utilities/utilities";
import checkPastDue from "js/core/billing/checkPastDue";
import getLocalStorage from "js/core/utilities/localStorage";
import { getExperiments } from "js/core/services/experiments";
import getLogger, { LogGroup } from "js/core/logger";
import UndoManager from "legacy-js/core/services/undoManager";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { isOwnerOrLibrarian } from "legacy-common/utils/roles";
import PresentationLibraryController from "legacy-js/controllers/PresentationLibraryController";
import { IStorageModel, IPresentation } from "common/interfaces";

// Preloads needed for the editor
import "legacy-js/editor/preload";

declare global {
    interface Window {
        roomID: string;
        Appcues: any;
        baiSource: string;
        isPPTAddin: boolean;
    }
}

const localStorage = getLocalStorage();

const logger = getLogger(LogGroup.APP);

export interface AppControllerState {
    isConnected: boolean;
    isConstrained: boolean;
    view: typeof AppView[keyof typeof AppView];
    isTour: boolean;
    externalActivePanel: string;
    user: typeof User;
    userAvatarUrl: string;
    isAccountVerified: boolean;
    workspaceId: string;
    workspaceIsPastDue: boolean;
    firebaseUser: firebase.User;
    isNewWorkspace: boolean;
}

const initialState: AppControllerState = {
    // App is running in a iframe
    isConstrained: false,
    // Browser is connected to the internet
    isConnected: true,
    workspaceId: null,
    userAvatarUrl: null,
    isTour: false,
    view: null,
    user: null,
    isAccountVerified: true,
    externalActivePanel: null,
    workspaceIsPastDue: false,
    firebaseUser: null,
    isNewWorkspace: false,
};

class AppController extends GlobalStateController<AppControllerState> {
    private isSubscribedToConnectionChange: boolean = false;
    public notificationsService: NotificationsService = null;
    public isInitialized: boolean = false;

    get orgId() {
        return (this._state.workspaceId === "personal") ? null : this._state.workspaceId;
    }

    get workspaceId() {
        return this._state.workspaceId;
    }

    get view() {
        return this._state.view;
    }

    get externalActivePanel() {
        return this._state.externalActivePanel;
    }

    get currentTeam() {
        if (!this.orgId) {
            return null;
        }

        return ds.teams.defaultTeamForOrg(this.orgId);
    }

    get userAvatarUrl() {
        return this._state.userAvatarUrl;
    }

    get ds() {
        return ds;
    }

    get firebaseUser() {
        return this._state.firebaseUser;
    }

    get user() {
        return this._state.user;
    }

    get workspaceIsPastDue() {
        return this._state.workspaceIsPastDue;
    }

    get allowTours() {
        return (
            !app.isConstrained &&
            !window.isPPTAddin &&
            this.workspaceId === "personal"
        );
    }

    initializePromise = Promise.resolve();

    initialize(firebaseUser, initialWorkspaceId?: string) {
        return new Promise((resolve, reject) => {
            this.initializePromise = this.initializePromise
                .then(async () => {
                    window.roomID = new URLSearchParams(window.location.search).get("roomID");

                    // Force set initial state but keep the view
                    await this._updateState(() => ({ ...initialState, view: this.view }));

                    await this.initializeUser(firebaseUser);

                    await this.initializeWorkspaces(initialWorkspaceId);

                    await analytics.identify(this.user.getAuthUser());

                    await this._updateState({
                        isConstrained: app.isConstrained,
                        isConnected: isConnected.connected,
                        userAvatarUrl: this.user.getAvatarUrl(),
                        isTour: false
                    });

                    this.initializeNotificationsService();

                    // poll for forced reload
                    initializeForceReloadDialog();

                    if (!this.isSubscribedToConnectionChange) {
                        this.isSubscribedToConnectionChange = true;

                        // update the state if we're offline
                        isConnected.on("change", () => {
                            this._updateState({ isConnected: isConnected.connected });
                        });
                    }

                    app.undoManager = new UndoManager();

                    // if user came from signup, signup is now complete,
                    // but first wait for analytics events to fire
                    setTimeout(() => {
                        if (analytics.getState("is_signing_up")) {
                            analytics.trackState({
                                is_signing_up: false
                            });
                        }
                    }, 2000);

                    this.isInitialized = true;
                })
                .then(resolve)
                .catch(reject);
        });
    }

    async reset() {
        logger.info("[AppController] reset");
        this.isInitialized = false;

        if (this.notificationsService) {
            this.notificationsService.dispose();
        }

        if (this.user) {
            this.user.off();
        }
        if (this.isSubscribedToConnectionChange) {
            isConnected.off("change");
            this.isSubscribedToConnectionChange = false;
        }
        PresentationLibraryController.reset();

        await this._updateState(() => ({ ...initialState }));
    }

    setExternalActivePanel(panel: string) {
        // De-select any selected element when openinng a panel
        if (panel) ds.selection.elment = null;
        this._updateState({ externalActivePanel: panel });
    }

    async setView(view: typeof AppView[keyof typeof AppView]) {
        await this.initializePromise;

        await this._updateState({ view });

        if (view === AppView.PLAYER) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const withAppcue = urlParams.get("with-appcue");
        const isUserNew = !this.user.get("hasTakenTour");

        if (withAppcue && this.allowTours && isUserNew) {
            const flowId = withAppcue;
            window.Appcues.show(flowId);
        }

        const {
            template_recommendations: { enabled: hasTemplateRecommendations }
        } = await getExperiments(["template_recommendations"]);

        if (!this.isInitialized) {
            return;
        }

        let hasEditablePresentations = false;
        if (this.user.get("presentations")) {
            hasEditablePresentations = window.baiSource !== "collab:view" && window.baiSource !== "secureshare";
        }

        const isEligibleForTour = isUserNew &&
            this.allowTours &&
            this.view == AppView.LIBRARY &&
            (this.user.get("subscriptionStatus") === SubscriptionStatus.ACTIVE || this.user.get("subscriptionStatus") === SubscriptionStatus.TRIALING);

        if (isEligibleForTour && (!hasEditablePresentations || (hasEditablePresentations && hasTemplateRecommendations))) {
            trackActivity("ExperimentGroup", "Assigned", hasTemplateRecommendations ? "variant-a" : "control", null, {
                experiment_id: "5FC18A79E182FE7C764425D4F852F501",
                experiment_group_assignment: hasTemplateRecommendations ? "variant-a" : "control"
            });
        }

        const showGenAITour = !hasTemplateRecommendations && !hasEditablePresentations && isEligibleForTour;
        if (showGenAITour) {
            this.user.update({ hasTakenTour: true });
            _.delay(async () => {
                // @ts-ignore
                const { GeneratePresentationDialog } = await import(/* webpackMode: "eager" */"js/react/components/Dialogs/GeneratePresentationDialog");
                ShowDialog(GeneratePresentationDialog);
            }, 1000);
            return;
        }
    }

    async handleGetPersonalUpdate() {
        await ShowDialogAsync(UpdateAppDialog, {
            title: "Update to the new version of Beautiful.ai",
            message: "We’ve made the editing experience more powerful, flexible and easier to navigate.",
            submessage: "You have until March 3 to update at your convenience. After this, you’ll be updated automatically. Once updated, you’ll no longer have access to the old UI. Don’t worry—you won’t lose any of your work. You may notice slight visual differences in your existing slides due to enhanced features and functionality.",
            firstButtonLabel: "Learn more",
            onFirstButtonClick: () => {
                window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank");
            },
            secondButtonLabel: "Update now",
            onSecondButtonClick: async () => {
                await ShowDialogAsync(VersionConflictDialog, {
                    title: "Are you sure?",
                    message: "Once updated, you will not be able to go back to the previous version. If you are currently working on urgent presentations, we recommend you defer updating until that work is complete.",
                    submessage: "You have until March 3 to update at your convenience. After this, you’ll be updated automatically.",
                    customIcon: getStaticUrl("/images/dialogs/exclamation-warning.gif"),
                    logoWidth: "32px",
                    logoHeight: "32px",
                    firstButtonLabel: "Not now",
                    onFirstButtonClick: () => {
                        return;
                    },
                    secondButtonLabel: "Update now",
                    onSecondButtonClick: async () => {
                        trackActivity("VersionUpgrade", "Upgrade", null, null, {
                            workspace_id: "personal",
                            userId: this.user.id,
                            version_number: 11,
                            timestamp: Date.now()
                        }, { audit: true });

                        localStorage.setItem("migration_success_user_id", this.user.id);
                        this.showLibrary();

                        this.user.update({ _migrated: true });
                        await this.user.updatePromise;
                    },
                    secondButtonVariant: "contained"
                });
            }
        });
    }

    async handleGetTeamUpdate() {
        const defaultTeams = ds.teams.getDefaultTeams();
        const ownedTeam = defaultTeams.find(team => team.getUserRole() === "owner");

        const workspaceId = ownedTeam.get("orgId");
        if (workspaceId !== this.workspaceId) {
            await this.switchWorkspace(workspaceId);
        }

        await ShowDialogAsync(UpdateAppDialog, {
            title: "Give your team the new version of Beautiful.ai!",
            message: "We’ve made the presentation editor more powerful, flexible and easier to navigate.",
            submessage: "As Team Owner, updating your workspace will update everyone in your organization. You have until March 3 to update at your convenience. After this, your team will be updated automatically. Once updated, you’ll no longer have access to the old UI. Don’t worry—you won’t lose any of your work. You may notice slight visual differences in your existing slides due to enhanced features and functionality.",
            firstButtonLabel: "Learn more",
            onFirstButtonClick: () => {
                window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank");
            },
            secondButtonLabel: "Update your team",
            onSecondButtonClick: async () => {
                await ShowDialogAsync(VersionConflictDialog, {
                    title: "Are you sure you want to update everyone on your team?",
                    message: "Once updated, you will not be able to go back to the previous version. If you or any of your team members are currently working on urgent presentations, we recommend you defer updating until that work is complete.",
                    submessage: "You have until March 3 to update at your convenience. After this, your team will be updated automatically.",
                    customIcon: getStaticUrl("/images/dialogs/exclamation-warning.gif"),
                    logoWidth: "32px",
                    logoHeight: "32px",
                    firstButtonLabel: "Not now",
                    onFirstButtonClick: () => {
                        return;
                    },
                    secondButtonLabel: "Update your team",
                    onSecondButtonClick: async () => {
                        trackActivity("VersionUpgrade", "Upgrade", null, null, {
                            workspace_id: ownedTeam.id,
                            userIds: Object.keys(ownedTeam.get("members")),
                            version_number: 11,
                            timestamp: Date.now()
                        }, { audit: true });

                        localStorage.setItem("migration_success_user_id", this.user.id);
                        this.showLibrary();

                        await Api.teams.put({
                            teamId: ownedTeam.id,
                            type: "upgrade_members_to_v11"
                        });
                    },
                    secondButtonVariant: "contained"
                });
            }
        });
    }

    showLibrary({ filter }: { filter?: { type: string; folderId: string; subFolderId?: string; } } = {}) {
        let path = "/library";
        if (filter) {
            if (filter.type == "folder") {
                path += `/folder/${filter.folderId}`;
            } else if (filter.type == "team_folder") {
                path += `/team_folder/${filter.folderId}`;
                if (filter.subFolderId) {
                    path += `/${filter.subFolderId}`;
                }
            } else {
                path += "/" + filter.type;
            }
        }

        browserHistory.push(path);
    }

    isPersonalWorkspace() {
        return this.workspaceId === "personal";
    }

    isUserTeamMember() {
        const currentTeam = this.currentTeam;
        if (!currentTeam) {
            return false; // User is not in a team
        }
        const userRole = currentTeam.getUserRole(this.user.id);
        return userRole !== null && !isOwnerOrLibrarian(userRole);
    }

    showTeamResources({ pane }: { pane?: string } = {}) {
        browserHistory.push(`/team-resources/${pane || ""}`);
    }

    showMyResources({ pane }: { pane?: string } = {}) {
        browserHistory.push(`/my-resources/${pane || ""}`);
    }

    showAccountPane({ pane }: { pane?: string } = {}) {
        let url = `/account/${pane || ""}`;
        let presentationId = PresentationEditorController.presentation?.id;
        if (presentationId) {
            let slideIndex = PresentationEditorController.getCurrentSlideIndex() + 1;
            url += `?returnId=${presentationId}&slideIndex=${slideIndex}`;
        }
        browserHistory.push(url);
    }

    async showPresentationSettings(startPane = "share", presentation: typeof Presentation) {
        // @ts-ignore
        const { default: PresentationSettingsContainer } = await import("legacy-js/react/views/PresentationSettings/PresentationSettingsContainer");

        await ShowDialogAsync(PresentationSettingsContainer, {
            startPane,
            presentation
        });
    }

    showEditor({
        presentationId,
        slideIndex = 1,
        openInNewTab = false,
        withAppcue = null,
    }) {
        let url = `/${presentationId}/${slideIndex}`;

        if (withAppcue) {
            url = `${url}?with-appcue=${withAppcue}`;
        }

        if (openInNewTab) {
            window.open(url, "_blank");
        } else {
            browserHistory.push(url);
        }
    }

    showSlideEditor({ slideId, resourceType }) {
        if (slideId) {
            browserHistory.push(`/slide/${resourceType}/${slideId}`);
        } else {
            browserHistory.push(`/slide/${resourceType}`);
        }
    }

    goToRoute(path) {
        browserHistory.push(path);
    }

    showCreatePresentationDialog({ pane, state }) {
        browserHistory.push({
            pathname: `/create-presentation/${pane || ""}`,
            state
        });
    }

    async showThemeEditor(presentation: IStorageModel<IPresentation>) {
        // @ts-ignore
        const { default: ThemeEditor } = await import(/* webpackMode: "eager" */ "js/editor/ThemeEditor/ThemeEditor");
        return ShowDialogAsync(ThemeEditor, { presentation });
    }

    navigateToThemeEditor(themeOrPresentationId: string, returnPath = window.location.pathname) {
        browserHistory.push(`/theme/${themeOrPresentationId}?return=${encodeURIComponent(returnPath)}`);
    }

    playPresentation({ presentationId, slideIndex = 0 }, state = {}) {
        browserHistory.push(`/player/${presentationId}/${slideIndex + 1}`, state);
    }

    showDocumentEditor({ presentationId, openInNewTab }) {
        const url = `/document/${presentationId}`;

        if (openInNewTab) {
            window.open(url, "_blank");
        } else {
            browserHistory.push(url);
        }
    }

    async initializeUser(firebaseUser) {
        if (this.user) {
            // Dispose all existing subscriptions
            this.user.off();
        }

        await this._updateState({ firebaseUser });

        // create the user
        const user = new User({ id: this.firebaseUser.uid }, { autoLoad: false });

        // legacy
        app.user = user;

        // initialize datatservices
        await ds.setup();

        // load the User model
        await user.load();

        const isAccountVerified = this.firebaseUser.emailVerified;
        // check for email verification
        if (!isAccountVerified) {
            // wait for verification
            user.waitForVerifiedEmail().then(() => {
                this._updateState({ isAccountVerified: true });
            });
        }

        await this._updateState({
            user,
            isAccountVerified
        });

        return user;
    }

    initializeNotificationsService() {
        if (this.notificationsService) {
            this.notificationsService.dispose();
        }

        this.notificationsService = new NotificationsService(this.user.get("id"));

        // Store the user's modifiedAt value before any modifications happen
        //   so we have a benchmark for the last time they were logged in
        const userModifiedAt = this.user.get("modifiedAt");
        // Subscribe to old notifications
        // This function gets called every time the `unread` array is changed. Since the array is always
        //   updated one item at a time, we debounce so we only process the complete result.
        const onUnreadLoaded = _.debounce(() => {
            // don't interrupt presentations
            if (this.view === AppView.PLAYER) {
                return;
            }

            // Notify the user of any unread team invites
            const unread = this.notificationsService.unread
                .filter(x => x.event.createdAt > userModifiedAt)
                .sort((a, b) => b.event.createdAt - a.event.createdAt);

            if (unread.length) {
                unread.forEach(notification => {
                    if (notification.eventType === "team" && notification.event.action === "invitedToTeam" && !app.isConstrained) {
                        ShowDialog(MemberAddedToOrgDialog, { notification });
                    }
                });
                // Update the user's `modifiedAt` field so we don't spam the user on next login
                this.user.update({ modifiedAt: Date.now() });
            }

            // Unsubscribe now that we've processed the loaded array of unread notifications
            this.notificationsService.offUnreadChanged(onUnreadLoaded);
        }, 500);
        this.notificationsService.onUnreadChanged(onUnreadLoaded);

        // Subscribe to new notifications
        this.notificationsService.onNewUnread(notification => {
            // Don't interrupt presentations
            if (this.view === AppView.PLAYER) {
                return;
            }
            // Ensuring backwards compatibility, assuming notifyWithPopup == true unless it's
            // explicitly set to false
            const notifyWithPopup = this.user.get("notifyWithPopup") === false ? false : true;
            if (notification.eventType === "team" && notification.event.action === "invitedToTeam" && !app.isConstrained) {
                ShowDialog(MemberAddedToOrgDialog, { notification });
            } else if (notifyWithPopup && !app.isConstrained) {
                ShowNotificationAlert(notification);
            }
        });
    }

    async initializeWorkspaces(initialWorkspaceId?: string) {
        const workspaceIds = Object.values(this.user.workspaces).map(({ id }) => id);

        const workspaceAction = localStorage.getItem(BEAUTIFUL_WORKSPACE_ACTION);
        localStorage.removeItem(BEAUTIFUL_WORKSPACE_ACTION);

        const requestedWorkspaceId = initialWorkspaceId ?? localStorage.getItem(BEAUTIFUL_WORKSPACE_ID);

        if (
            (workspaceAction === WorkspaceAction.CREATED || workspaceAction === WorkspaceAction.JOINED) &&
            workspaceIds.includes(requestedWorkspaceId)
        ) {
            await this._updateState({ isNewWorkspace: true });
        }

        let workspaceId = "personal";
        if (workspaceIds.includes(requestedWorkspaceId)) {
            // Show requested workspace
            workspaceId = requestedWorkspaceId;
        } else if (workspaceIds.length === 2) {
            // If only in one team, then show that team
            workspaceId = workspaceIds.find(id => id !== "personal");
        } else if (isPPTAddin && workspaceIds.length > 1) {
            // Special case for PowerPoint add-in: if in multiple teams, then show the first team
            workspaceId = workspaceIds.find(id => id !== "personal");
        }

        await this._updateState({ workspaceId });

        // show dialog if the user has been downgraded since last login
        if (this.user.has("forcedDowngrade")) {
            ShowDialog(ForcedDowngradeDialog);
            if (this.user.has("forcedDowngrade")) {
                this.user.update({ forcedDowngrade: null });
            }
        }
        // subscribe to downgrade event so that at the end of the user's pro trial,
        // they get downgraded immediately
        this.user.on("change:forcedDowngrade", model => {
            if (model && model.changed && model.changed.forcedDowngrade === true) {
                ShowDialog(MandatoryUpdateDialog, {
                    message: "This browser tab will need to be refreshed since your account was downgraded."
                });
            }
        });

        // show dialog if the user's role has changed during page load
        if (this.user.has("workspaceRoleChange")) {
            this.handleWorkspaceRoleChanged(this.user.get("workspaceRoleChange"));
        }
        // subscribe to changes in workspaceRole
        this.user.on("change:workspaceRoleChange", (model: typeof User) => {
            if (model && model.changed && model.changed.workspaceRoleChange) {
                this.handleWorkspaceRoleChanged(model.changed.workspaceRoleChange);
            }
        });

        const createForcedRemovalDialogOptions = (teamName: string) => ({
            title: (
                <Fragment>
                    You've been removed from <span style={{ color: "#159bcd", fontWeight: 900 }}>{teamName}</span>
                </ Fragment >
            ),
            message: "You no longer have access to any presentations or shared resources within that workspace",
            buttonText: "Got It",
            buttonOptions: { color: "blue" },
            onClick: () => { }
        });

        if (this.user.has("forcedWorkspaceRemoval")) {
            const options = createForcedRemovalDialogOptions(this.user.get("forcedWorkspaceRemoval"));
            ShowMessageDialog(options);
            this.user.update({ forcedWorkspaceRemoval: null });
        }

        this.user.on("change:forcedWorkspaceRemoval", model => {
            if (model && model.changed && !!model.changed.forcedWorkspaceRemoval) {
                const options = createForcedRemovalDialogOptions(model.changed.forcedWorkspaceRemoval);
                options.onClick = () => window.location.reload();
                ShowMessageDialog(options);
                this.user.update({ forcedWorkspaceRemoval: null });
            }
        });

        this.user.on("change:workspaceDeleted", model => {
            if (model && model.changed && !!model.changed.workspaceDeleted) {
                ShowDialog(MandatoryUpdateDialog, {
                    message: `This browser tab will need to be refreshed since the ${model.changed.workspaceDeleted} workspace was deleted.`
                });
                this.user.update({ workspaceDeleted: null });
            }
        });
    }

    _stateDidUpdate(prevState: AppControllerState) {
        const { workspaceId } = this._state;
        if (prevState.workspaceId !== workspaceId) {
            if (!workspaceId) {
                // Empty workspaceId means we're not initialied yet
                return;
            }

            checkPastDue(workspaceId)
                .then(async res => await this._updateState({
                    workspaceIsPastDue: typeof res === "boolean" && res === true
                }))
                .catch(err => logger.error(err, "[AppController] _stateDidUpdate() checkPastDue() failed"));

            analytics.trackWorkspaceChange(this.firebaseUser)
                .catch(err => logger.error(err, "[AppController] _stateDidUpdate() analytics.trackWorkspaceChange() failed"));

            const workspace = this.user.workspaces[workspaceId];
            trackState({ ...workspace.getAnalytics() });

            try {
                localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, workspaceId);
            } catch (err) {
                logger.error(err, "[AppController] _stateDidUpdate() failed to set workspaceId in localStorage");
            }
        }
    }

    async switchWorkspace(workspaceId: string) {
        // Always show the library when switching workspaces
        this.showLibrary({ filter: { type: "recent", folderId: null, subFolderId: null } });

        await this._updateState({ workspaceId, isNewWorkspace: false });
    }

    handleWorkspaceRoleChanged(orgId: string) {
        this.user.update({ workspaceRoleChange: null });

        const role = ds.teams.defaultTeamForOrg(orgId).getUserRole();
        const orgName = ds.teams.defaultTeamForOrg(orgId).get("name");

        let message;
        switch (role) {
            case TEAM_USER_ROLES.OWNER:
                message = `Your role was changed to Owner in ${orgName}. Owners can manage members, Team Slides, and the Team Theme. Please refresh the browser to reflect the latest changes.`;
                break;
            case TEAM_USER_ROLES.LIBRARIAN:
                message = `Your role was changed to Librarian in ${orgName}. Librarians can manage Team Slides and the Team Theme. Please refresh the browser to reflect the latest changes.`;
                break;
            case TEAM_USER_ROLES.MEMBER_LICENSED:
                message = `Your role was changed to Pro Member in ${orgName}. Pro Members have access to Beautiful.ai Pro features. Please refresh the browser to reflect the latest changes.`;
                break;
            case TEAM_USER_ROLES.MEMBER:
                message = `Your role was changed to Free Team Member in ${orgName}. Free Team Members are limited to basic features. Please refresh the browser to reflect the latest changes.`;
                break;
        }

        ShowDialog(MandatoryUpdateDialog, { message });
    }

    async showTour() {
        this._updateState({ isTour: true });

        this.user.update({ hasTakenTour: true });

        // @ts-ignore
        const { Tour } = await import(/* webpackMode: "eager" */ "legacy-js/help/tour");
        app.tour = new Tour(this.workspaceId);
    }

    async showDebugInfo() {
        // @ts-ignore
        const { DebugInfoDialog } = await import(/* webpackMode: "eager" */ "legacy-js/react/components/Dialogs/DebugInfoDialog");
        ShowDialog(DebugInfoDialog);
    }

    async showInternalBugReport() {
        // @ts-ignore
        const { InternalBugReportDialog } = await import(/* webpackMode: "eager" */ "legacy-js/react/components/Dialogs/InternalBugReportDialog");
        ShowDialog(InternalBugReportDialog);
    }

    printWorkspacesDebugData(asFile = false) {
        Api.workspacesDebug.get({ id: this.workspaceId, asFile })
            .then(({ data, url }) => {
                if (!data && !url) {
                    // eslint-disable-next-line no-console
                    console.log("No data");
                    return;
                }

                if (asFile) {
                    window.open(url, "_blank");
                    return;
                }

                // eslint-disable-next-line no-console
                console.log(data);
            })
            .catch(err => {
                // eslint-disable-next-line no-console
                console.error(err);
            });
    }
}

// this is for backwards compatibilty with all the code that references app.mainView.editorView
if (!app.mainView) {
    app.mainView = {
        editorView: null
    };
}

if (!app.tourFuncs) {
    app.tourFuncs = {};
}

const appController = new AppController(initialState);

export default appController;
