import { SearchAssets, UnSplashSearchService } from "js/core/utilities/searchAssets";
import { uuid } from "js/core/utilities/utilities";
import {
    ASSET_FILETYPE,
    AssetType,
    AuthoringBlockType,
    BackgroundStyleType,
    ListStyleType,
    TextStyleType
} from "common/constants";
import {
    GeneratePresentationOutlineResponse,
    GenerateSlideResponse,
    GeneratedSlideModel,
    AiGeneratedSlideType
} from "common/aiConstants";
import * as geom from "js/core/utilities/geom";
import { iconSearch } from "js/core/services/iconSearch";
import { getCanvasBundle } from "js/canvas";
import { appVersion } from "js/config";
import { _, numeral } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";

type AssetTypeValue = typeof AssetType[keyof typeof AssetType];

interface ElementWithImageModel extends Object {
    content_type?: string,
    content_value?: string,
    __generateImage: {
        search: string,
        pickIndex?: number,
        aspectRatio?: number,
        minWidth?: number,
        minHeight?: number,
        assetType: AssetTypeValue,
        useStockLibrary?: boolean,
    }
}

const logger = getLogger(LogGroup.AI);

const searchAssets = new SearchAssets();
const headlinePositions = ["left", "right"];
const backgroundStyles = [BackgroundStyleType.LIGHT, BackgroundStyleType.DARK, BackgroundStyleType.ACCENT];
const backgroundColors = ["background_dark", "background_light", "background_accent", "background_light"];
const accentColors = ["theme", "accent1", "accent2", "accent3"];
const allBackgroundColors = backgroundColors.concat(accentColors);
const headerPositions = ["top", "left", "top"];
const fallbackIcons = ["fog-bridge", "map-marker", "people-network"];

function randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandomItem(list: any[]) {
    return list[randomIntFromInterval(0, list.length - 1)];
}

async function chooseRandomIcon(text: string): Promise<string> {
    const { results } = await iconSearch(text);
    return getRandomItem(results)?.ref ?? getRandomItem(fallbackIcons);
}

function getBlock(html, textStyle = TextStyleType.BODY, listStyle = undefined) {
    return {
        id: uuid(),
        html,
        type: AuthoringBlockType.TEXT,
        textStyle,
        listStyle
    };
}

function parseTitle(title: string) {
    let result = "";
    let startTag = true;
    for (let i = 0; i < title.length; i++) {
        if (title[i] == "*") {
            if (startTag) {
                result += `<font class="emphasized">`;
                startTag = false;
            } else {
                result += "</font>";
                startTag = true;
            }
        } else {
            result += title[i];
        }
    }
    return result;
}

function createHeader(slide) {
    if (slide.title) {
        const header = {
            header: {
                text: {
                    blocks: [getBlock(parseTitle(slide.title), TextStyleType.HEADING)]
                }
            }
        };

        if (slide.subtitle || slide.description) {
            header.header.text.blocks.push(getBlock(slide.subtitle ?? slide.description, TextStyleType.BODY));
        }

        return header;
    }
}

function createAttribution(slide) {
    if (slide.attribution) {
        return {
            elementAttribution: {
                elementAttribution: {
                    blocks: [getBlock("*" + slide.attribution, TextStyleType.BODY)]
                }
            }
        };
    } else {
        return null;
    }
}

async function createTitleSlide(slide) {
    const imagePosition = getRandomItem(["left", "top"]);

    let traySize = 600;
    if (imagePosition == "top") {
        traySize = 500;
    }

    return {
        template_id: "title",
        version: appVersion,
        layout: {
            backgroundColor: getRandomItem(backgroundColors),
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            primary: {
                text: {
                    blocks: [
                        {
                            id: uuid(),
                            html: parseTitle(slide.title),
                            type: "text",
                            textStyle: TextStyleType.HEADING,
                            indent: 0
                        }, {
                            id: uuid(),
                            html: parseTitle(slide.description),
                            type: "text",
                            textStyle: TextStyleType.BODY,
                            indent: 0
                        }
                    ]
                },
                textPosition: "left",
                image: {
                    content_type: "image",
                    __generateImage: {
                        search: "!presentationSubject!",
                        pickIndex: randomIntFromInterval(0, 2),
                        aspectRatio: 1280 / 720,
                        minWidth: 1000,
                        minHeight: 600,
                        useStockLibrary: true
                    },
                    position: imagePosition,
                    traySize: traySize
                }
            }
        }]
    };
}

async function createHeadlineSlide(slide) {
    const headlinePosition = getRandomItem(headlinePositions);
    const dividerSize = randomIntFromInterval(45, 70) / 100;

    const isOverview = ["introduction", "overview"].includes(slide.title.toLowerCase());

    const blocks = [];

    let bodyStyle = TextStyleType.BODY;

    if (!isOverview) {
        blocks.push(getBlock(parseTitle(slide.title), TextStyleType.HEADING));
    } else {
        bodyStyle = TextStyleType.TITLE;
    }

    if (Array.isArray(slide.body)) {
        blocks.push(...slide.body.map(body => (getBlock(body.body, bodyStyle))));
    } else {
        blocks.push(getBlock(slide.body, bodyStyle));
    }

    const image: any = {
        aspectRatio: dividerSize * 1280 / 700,
        minHeight: 600,
        pickIndex: randomIntFromInterval(0, 10),
        search: slide.aboutName ?? slide.search ?? slide.title
    };

    if (slide.about == "concept") {
        image.useStockLibrary = true;
    }

    return {
        template_id: "headline2",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            primary: {
                text: {
                    blocks
                },
                items: [
                    {
                        content_type: "image",
                        __generateImage: image,
                        cellColor: "auto",
                    }
                ],
                headlinePosition: headlinePosition,
                dividerSize: dividerSize
            }
        }]
    };
}

function createQuoteSlide(slide) {
    const quote = `“${slide.quote}”`;
    const blocks = [getBlock(parseTitle(quote), TextStyleType.HEADLINE)];
    if (slide.author && slide.author.toLowerCase() !== "anonymous") {
        blocks.push(getBlock(slide.author, TextStyleType.CAPTION));
    }

    return {
        template_id: "headline2",
        version: appVersion,
        layout: {
            backgroundColor: getRandomItem(allBackgroundColors),
            backgroundStyle: "backgroundColor",
        },

        states: [{
            primary: {
                text: {
                    blocks,
                    blockFontScales: {
                        caption: 1.5
                    }
                },
            }
        }]
    };
}

function createBigNumberSlide(slide) {
    const data = slide.data[0];
    const value = numeral(data.y).format("0,0");

    return {
        template_id: "big-numbers",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundColor: getRandomItem(allBackgroundColors),
            backgroundStyle: "backgroundColor",
        },

        states: [{
            ...createHeader(slide),
            primary: {
                items: [{
                    showLabel: true,
                    label: {
                        blocks: [getBlock(slide.yaxis, TextStyleType.BODY)]
                    },
                    value: {
                        blocks: [getBlock(value, "_use_styles")]
                    },
                    showChangeInValue: false,
                    showDescription: false
                }],
            }
        }]
    };
}

async function createChartSlide(slide) {
    if (slide.chartType == "pie") {
        return await createPieChartSlide(slide);
    }

    if (slide.data.length == 1) {
        return await createBigNumberSlide(slide);
    }

    if (slide.data.length < 6 && (slide.yaxis.toLowerCase().includes("rating") || slide.yaxis.toLowerCase().includes("score") || slide.yaxis.toLowerCase().includes("rank"))) {
        return await createChartCompareSlide(slide);
    }

    const { slideTemplates: { chartUtils } } = await getCanvasBundle(appVersion);

    let chartType = (slide.chartType ?? "column").toLowerCase();
    if (!["line", "area", "column", "spline"].includes(chartType)) {
        chartType = "column";
    }

    const chart = chartUtils.getDefaultChartModel(chartType);
    chart.showXAxis = true;
    chart.showYAxis = true;
    chart.chartData.xAxis.axisTitle = true;
    chart.chartData.xAxis.axisTitleText = slide.xaxis;
    chart.chartData.xAxis.dateFormatting = "none";
    chart.chartData.yAxis.axisTitle = "edge";
    chart.chartData.yAxis.axisTitleText = slide.yaxis;
    chart.chartData.xAxis.categories = slide.data.map(d => d.x);
    chart.chartData.series = [{
        colorName: "chart1",
        id: "series1",
        marker: "none",
        name: "Data",
        type: chartType,
        data: slide.data.map(d => ({ y: parseFloat(d.y) }))
    }];

    return {
        template_id: `chart_${chartType}`,
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles),
            elementTextBlockPosition: "tray",
            showElementAttribution: slide.attribution != null
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: [{
                    elementType: "chart",
                    chart: chart
                }]
            },
            elementTextBlock: {
                elementTextBlock: {
                    blocks: [{
                        id: uuid(),
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.TITLE,
                        html: slide.conclusion
                    }]
                }
            },
            ...createAttribution(slide)
        }]
    };
}

function createChartCompareSlide(slide) {
    let template;
    if (slide.data.length < 5) {
        template = getRandomItem(["compare_vertical_bars", "compare_radial_bars", "compare_horizontal_bars", "compare_bubbles"]);
    } else {
        template = getRandomItem(["compare_vertical_bars", "compare_horizontal_bars"]);
    }

    let maxValue = 100;
    if (slide.yaxis) {
        if (slide.yaxis.contains("1-100") || slide.yaxis.contains("1 - 100")) {
            maxValue = 100;
        } else if (slide.yaxis.contains("1-10") || slide.yaxis.contains("1 - 10")) {
            maxValue = 10;
        } else if (slide.yaxis.contains("1-5") || slide.yaxis.contains("1 - 5")) {
            maxValue = 5;
        } else {
            maxValue = _.max(slide.data.map(d => d.y));
        }
    }

    return {
        template_id: template,
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles),
            elementTextBlockPosition: "tray",
            showElementAttribution: slide.attribution != null
        },
        states: [{
            ...createHeader(slide),
            primary: {
                collectionColor: "colorful",
                scaling: "linear",
                format: "number",
                minValue: 0,
                maxValue: maxValue,
                items: slide.data.map(d => (
                    {
                        value: parseFloat(d.y),
                        text: {
                            blocks: [getBlock(d.x, TextStyleType.TITLE)]
                        }
                    }
                ))
            },
            elementTextBlock: {
                elementTextBlock: {
                    blocks: [{
                        id: uuid(),
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.TITLE,
                        html: slide.conclusion
                    }]
                }
            },
            ...createAttribution(slide)
        }]
    };
}

async function createPieChartSlide(slide) {
    slide.subtitle = slide.conclusion;

    return {
        template_id: "chart_pie",
        version: appVersion,
        layout: {
            headerPosition: "left",
            backgroundStyle: getRandomItem(backgroundStyles),
            showElementAttribution: slide.attribution != null
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: [{
                    elementType: "piechart",
                    piechart: {
                        format: "percent",
                        isDonut: false,
                        chartScale: 0.9,
                        data: slide.data.map(d => ({
                            id: uuid(),
                            color: "auto",
                            value: parseFloat(d.y),
                            label: { blocks: [getBlock(d.x, "_use_styles")] }
                        }))
                    }
                }]
            },
            ...createAttribution(slide)
        }]
    };
}

async function createTextListSlide(slide) {
    const cols = 2;
    const itemsInCol = Math.ceil(slide.items.length / cols);

    const listStyle = slide.type === AiGeneratedSlideType.ORDERED_LIST ? ListStyleType.NUMBERED : ListStyleType.BULLET;

    let col = {
        isMedia: false,
        text: {
            blocks: []
        }
    };
    const items = [col];
    for (let i = 0; i < slide.items.length; i++) {
        if (i >= itemsInCol && items.length < cols) {
            col = {
                isMedia: false,
                text: {
                    blocks: []
                }
            };
            items.push(col);
        }
        col.text.blocks.push(getBlock(slide.items[i].bullet || slide.items[i].title, TextStyleType.BULLET_LIST, listStyle));
        const details = getBlock(slide.items[i].details || slide.items[i].description, TextStyleType.BULLET_LIST, ListStyleType.TEXT) as any;
        details.indent = 1;
        col.text.blocks.push(details);
    }

    return {
        template_id: "textlist",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items
            },
        }]
    };
}

async function createAgendaSlide(slide) {
    const cols = 2;
    const itemsInCol = Math.ceil(slide.items.length / cols);

    const listStyle = ListStyleType.NUMBERED;

    let col = {
        isMedia: false,
        text: {
            blocks: []
        }
    };
    const items = [col];
    for (let i = 0; i < slide.items.length; i++) {
        if (i >= itemsInCol && items.length < cols) {
            col = {
                isMedia: false,
                text: {
                    blocks: []
                }
            };
            items.push(col);
        }
        if (slide.items[i].type == "break") {
            col.text.blocks.push(getBlock(slide.items[i].title, TextStyleType.SECTION));
        } else {
            col.text.blocks.push(getBlock(slide.items[i].title, TextStyleType.BULLET_LIST, listStyle));
        }
    }

    return {
        template_id: "agenda",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles),
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items
            },
        }]
    };
}

async function createIconListSlide(slide) {
    await Promise.all(slide.items.map(async item => {
        item.iconId = await chooseRandomIcon(item.icon ?? item.bullet);
    }));

    return {
        template_id: "textgrid_vertical_icons",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles),
            elementTextBlockPosition: "tray"
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: slide.items.map(item => ({
                    text: {
                        blocks: [
                            getBlock(item.bullet, TextStyleType.TITLE),
                            getBlock(item.details, TextStyleType.BODY)
                        ]
                    },
                    content_type: "icon",
                    content_value: item.iconId,
                    frameType: "theme"
                }))
            },
            elementTextBlock: {
                color: getRandomItem(accentColors),
                elementTextBlock: {
                    blocks: [{
                        id: uuid(),
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.TITLE,
                        html: slide.conclusion
                    }]
                }
            }
        }]
    };
}

function createTimelineSlide(slide) {
    const connectors = [];
    const items = [];

    let x = 0.1;
    const xDelta = 0.8 / slide.items.length;

    for (let i = 0; i < slide.items.length; i++) {
        const y = (i % 2 == 0) ? 0.25 : 0.65;

        const item = {
            id: uuid(),
            nodeType: "bullet-text",
            text: {
                blocks: [{
                    id: uuid(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.TITLE,
                    html: slide.items[i].date
                }, {
                    id: uuid(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BODY,
                    html: slide.items[i].text
                }]
            },
            textDirection: "right",
            hasBeenDragged: false,
            x, y
        };
        items.push(item);

        const connector = {
            id: "from-" + item.id,
            source: item.id,
            target: "/primary/timeline",
            endPointIsLocked: true,
            startAnchor: "anchor-left",
            endAnchor: "anchor-free",
            connectorType: "straight",
            canChangeConnectorType: false,
            startDecoration: "none",
            lineStyle: "solid",
            lineWeight: 2,
            color: "theme"
        };
        connectors.push(connector);

        x += xDelta;
    }

    return {
        template_id: "timeline",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                annotations: {
                    collectionColor: "theme",
                    connections: {
                        items: connectors
                    },
                    items: items
                }
            }
        }]
    };
}

function createVennSlide(slide) {
    const angleStep = 360 / slide.items.length;
    const zeroPoint = new geom.Point(0, 0);
    const startPoint = new geom.Point(0, 190);

    return {
        template_id: "vennDiagram",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                collectionColor: "colorful",
                items: slide.items.map((item, i) => ({
                    id: uuid(),
                    radius: 200,
                    x: startPoint.rotate(angleStep * i, zeroPoint).x,
                    y: startPoint.rotate(angleStep * i, zeroPoint).y,
                    text: {
                        blocks: [{
                            id: uuid(),
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.TITLE,
                            html: item.text
                        }]
                    }
                }))
            }
        }]
    };
}

async function createProcessDiagram(slide) {
    return {
        template_id: "step_chevrons",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: slide.steps.map((item, i) => ({
                    id: uuid(),
                    text: {
                        blocks: [
                            getBlock(item.title, TextStyleType.TITLE),
                        ]
                    },
                    body: {
                        blocks: [
                            getBlock(item.description, TextStyleType.BODY),
                        ]
                    },
                    hilited: i == (slide.steps.length - 1)
                })),
                textLayout: getRandomItem(["below"])
            }
        }]
    };
}

function getPersonHeadshot(name, title, isRealPerson = true) {
    if (isRealPerson) {
        return {
            search: `${name} ${title} headshot`,
            pickIndex: randomIntFromInterval(0, 1)
        };
    } else {
        let search;
        if (name.contains("John", "Joe", "Bob", "Peter", "Alex", "Mike", "George", "Brian")) {
            search = "male";
        } else if (name.contains("Jane", "Mary", "Jill", "Sarah", "Sara", "Karen", "Kim", "Emily", "Sarah")) {
            search = "female";
        } else {
            search = name;
        }

        return {
            search: `${search} headshot`,
            pickIndex: randomIntFromInterval(0, 30),
            useStockLibrary: true,
        };
    }
}

async function createTeamSlide(slide) {
    let template = "team";
    if (slide.items.length > 4) {
        template = "textgrid_photos";
    }

    return {
        template_id: template,
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: slide.items.map((item, i) => ({
                    id: uuid(),
                    color: "accent1",
                    content_type: "image",
                    __generateImage: getPersonHeadshot(item.name, item.title, item.isRealPerson),
                    text: {
                        blocks: [
                            getBlock(item.name, TextStyleType.TITLE),
                            getBlock(item.title || item.biography, TextStyleType.BODY)
                        ]
                    }
                }))
            }
        }]
    };
}

async function createPhotosWithTextSlide(slide) {
    return {
        template_id: "textgrid_photos",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: slide.items.map(item => ({
                    id: uuid(),
                    color: "accent1",
                    content_type: "image",
                    __generateImage: {
                        search: item.search || item.title,
                        pickIndex: randomIntFromInterval(0, 4)
                    },
                    text: {
                        blocks: [
                            getBlock(item.title, TextStyleType.TITLE),
                            getBlock(item.text, TextStyleType.BODY)
                        ]
                    }
                }))
            }
        }]
    };
}

function createTableSlide(slide) {
    const rows = [
        { index: 0, style: "headerRow", break: false, size: 30 },
        ...slide.cells.map((row, idx) => ({ index: idx + 1, style: "defaultRow", break: false, size: 40 }))
    ];

    const cols = slide.columnHeaders.map((header, index) => ({ index, style: "defaultRow", break: false, size: 50 }));

    const cells = [
        ...slide.columnHeaders.map((header, index) => ({
            col: index,
            row: 0,
            id: uuid(),
            format: "text",
            cellText: { text: _.toString(header) }
        })),
        ...slide.cells.map((row, rowIndex) => row.map((val, colIndex) => ({
            col: colIndex,
            row: rowIndex + 1,
            id: uuid(),
            format: "text",
            cellText: { text: _.toString(val) }
        }))).flat()
    ];

    for (const cell of cells) {
        if (cell.cellText.text.toLowerCase() === "yes") {
            cell.format = "icon";
            cell.content_type = "icon";
            cell.content_value = "check-yes";
        }
        if (cell.cellText.text.toLowerCase() === "no") {
            cell.format = "icon";
            cell.content_type = "icon";
            cell.content_value = "x";
        }
    }

    return {
        template_id: "table-v2",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: getRandomItem(backgroundStyles),
            showElementAttribution: slide.attribution != null
        },
        states: [{
            ...createHeader(slide),
            primary: {
                showBorder: true,
                showColGridLines: true,
                showTopLeftCell: true,
                showRowGridLines: true,
                showHeaderCol: true,
                showHeaderRow: true,
                tableWidth: 1,
                tableHeight: Math.min(1, rows.length * .2),
                cols,
                rows,
                cells,
                aiGeneratedData: slide,
            },
            ...createAttribution(slide)
        }]
    };
}

async function createPhotoCollageSlide(slide) {
    const items = [...new Array(randomIntFromInterval(4, 10)).keys()].map(pickIndex => ({
        content_type: "image",
        __generateImage: {
            search: slide.search || slide.title,
            assetType: AssetType.IMAGE,
            pickIndex
        }
    }));

    return {
        template_id: "collage",
        version: appVersion,
        layout: {
            headerPosition: "none",
        },
        states: [{
            primary: {
                aspectRatio: "fill",
                fullBleed: false,
                showGutter: true,
                layoutType: "grid",
                items
            }
        }]
    };
}

async function createLogoSlide(slide) {
    return {
        template_id: "logogrid",
        version: appVersion,
        layout: {
            headerPosition: "top",
            backgroundStyle: BackgroundStyleType.ACCENT,
            backgroundColor: "background_accent"
        },
        states: [{
            ...createHeader(slide),
            primary: {
                items: slide.companies.map(company => ({
                    content_type: "logo",
                    __generateImage: {
                        search: `${company} logo`,
                        assetType: AssetType.LOGO,
                        pickIndex: 0
                    }
                })),
                showFrame: true,
                backgroundColor: "white"
            }
        }]
    };
}

async function createBiographySlide(slide) {
    const headlinePosition = getRandomItem(headlinePositions);
    const dividerSize = 0.5;

    return {
        template_id: "headline2",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem(backgroundStyles)
        },
        states: [{
            primary: {
                text: {
                    blocks: [
                        getBlock(slide.name, TextStyleType.HEADING),
                        getBlock(slide.biography, TextStyleType.BODY)
                    ]
                },
                items: [
                    {
                        content_type: "image",
                        __generateImage: getPersonHeadshot(slide.search || slide.name, null, slide.isRealPerson),
                        cellColor: "auto",
                    }
                ],
                headlinePosition: headlinePosition,
                dividerSize: dividerSize
            }
        }]
    };
}

async function createCompanyInfoSlide(slide) {
    const headlinePosition = getRandomItem(headlinePositions);
    const dividerSize = 0.5;

    return {
        template_id: "headline2",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent"
        },
        states: [{
            primary: {
                text: {
                    blocks: [
                        getBlock(slide.company, TextStyleType.HEADING),
                        getBlock(slide.description, TextStyleType.BODY)
                    ]
                },
                items: [
                    {
                        content_type: "logo",
                        __generateImage: {
                            search: `${slide.company} logo`,
                            assetType: AssetType.LOGO,
                            pickIndex: 0
                        },
                        cellColor: "background_light",
                    }
                ],
                headlinePosition: headlinePosition,
                dividerSize: dividerSize,
                fullBleed: true
            }
        }]
    };
}

async function createComparisonSlide(slide) {
    const max = Math.max(...slide.items.map(({ value }) => value));
    const min = Math.min(...slide.items.map(({ value }) => value));

    let template = getRandomItem(["compare_horizontal_bars", "compare_vertical_bars", "compare_radial_bars", "compare_circles"]);
    if (slide.items.length > 4) {
        template = "compare_vertical_bars";
    }

    return {
        template_id: template,
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: "top"
        },
        states: [{
            header: {
                text: {
                    blocks: [
                        getBlock(slide.title, TextStyleType.HEADING),
                        getBlock(slide.subtitle, TextStyleType.BODY)
                    ]
                }
            },
            primary: {
                minValue: min - (max - min) * 0.2,
                maxValue: max,
                scaling: "linear",
                format: slide.format,
                items: slide.items.map(({ name, value }) => ({
                    id: uuid(),
                    text: {
                        blocks: [
                            getBlock(name, TextStyleType.TITLE)
                        ]
                    },
                    value
                }))
            }
        }]
    };
}

async function createTextBoxesSlide(slide) {
    return {
        template_id: "textBoxGrid",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: "top"
        },
        states: [{
            header: {
                text: {
                    blocks: [
                        getBlock(slide.title, TextStyleType.HEADING)
                    ]
                }
            },
            primary: {
                items: slide.items.map(({ title, body }) => ({
                    id: uuid(),
                    text: {
                        blocks: [
                            getBlock(title, TextStyleType.TITLE),
                            getBlock(body, TextStyleType.BODY)
                        ]
                    }
                }))
            }
        }]
    };
}

async function createArrowBarsSlide(slide) {
    const max = Math.max(...slide.items.map(({ value }) => parseFloat(value)));
    const min = Math.min(...slide.items.map(({ value }) => parseFloat(value)));

    const normalizeValue = value => (parseFloat(value) - min) / (max - min) * 70 + 30;

    return {
        template_id: "arrow_bars",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: "top"
        },
        states: [{
            header: {
                text: {
                    blocks: [
                        getBlock(slide.title, TextStyleType.HEADING)
                    ]
                }
            },
            primary: {
                showIndex: false,
                items: slide.items.map(({ title, value }) => ({
                    id: uuid(),
                    showArrowHead: true,
                    value: normalizeValue(value),
                    label: {
                        blocks: [
                            getBlock(title, TextStyleType.TITLE)
                        ]
                    }
                }))
            }
        }]
    };
}

async function createThermometerSlide(slide) {
    const gaugePosition = getRandomItem(["left", "right"]);

    const annotationItems = slide.callouts.map(({ title, value }) => ({
        hasBeenDragged: false,
        hideNodeConnectorWidget: true,
        id: uuid(),
        nodeType: "bullet-text",
        x: gaugePosition === "left" ? 0.7 : 0.2,
        y: parseFloat(value) / 100,
        text: {
            blocks: [
                getBlock(title, TextStyleType.TITLE)
            ]
        }
    }));

    return {
        template_id: "thermometer",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: "none",
            trayLayout: getRandomItem(["left_inline", "right_inline"])
        },
        states: [{
            tray: {
                items: [{
                    text: {
                        blocks: [
                            getBlock(slide.title, TextStyleType.HEADING),
                            getBlock(slide.conclusion, TextStyleType.BODY)
                        ]
                    }
                }]
            },
            primary: {
                annotations: {
                    items: annotationItems,
                    connections: {
                        items: annotationItems.map(({ id }) => ({
                            id: uuid(),
                            canChangeConnectorType: false,
                            connectorType: "straight",
                            endAnchor: "anchor-free",
                            endDecoration: "none",
                            endPointIsLocked: true,
                            labels: [],
                            lineStyle: "solid",
                            lineWeight: 2,
                            source: id,
                            startAnchor: "anchor-free",
                            startDecoration: "none",
                            target: "/primary/thermometerGauge"
                        }))
                    }
                },
                format: "percent",
                position: gaugePosition,
                showBulb: getRandomItem([true, false]),
                showTickValues: true,
                showValue: getRandomItem([true, false]),
                targetMax: 100,
                tickCount: 5,
                value: parseFloat(slide.value),
                valuePosition: "fluidTop"
            }
        }]
    };
}

async function createXYPlotSlide(slide) {
    const minX = Math.min(...slide.items.map(({ x }) => parseFloat(x)));
    const maxX = Math.max(...slide.items.map(({ x }) => parseFloat(x)));
    const minY = Math.min(...slide.items.map(({ y }) => parseFloat(y)));
    const maxY = Math.max(...slide.items.map(({ y }) => parseFloat(y)));

    const normalizeX = x => (parseFloat(x) - minX) / (maxX - minX) * 0.7 + 0.15;
    const normalizeY = y => (1 - (parseFloat(y) - minY) / (maxY - minY)) * 0.7 + 0.15;

    return {
        template_id: "xygraph",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: getRandomItem(["left", "top"])
        },
        states: [{
            header: {
                text: {
                    blocks: [
                        getBlock(slide.title, TextStyleType.HEADING)
                    ]
                }
            },
            primary: {
                xAxisLabel: {
                    blocks: [
                        getBlock(slide.xAxisLabel, "_use_styles_")
                    ]
                },
                yAxisLabel: {
                    blocks: [
                        getBlock(slide.yAxisLabel, "_use_styles_")
                    ]
                },
                showArrowHeads: true,
                showAxis: true,
                axisPosition: "left",
                showAxisLabels: true,
                showGrid: true,
                gridSize: 34,
                showQuadrants: false,
                showQuadrantLabels: false,
                items: slide.items.map(({ title, x, y }) => ({
                    id: uuid(),
                    x: normalizeX(x),
                    y: normalizeY(y),
                    size: 150,
                    color: "theme",
                    nodeType: "circle",
                    text: {
                        blocks: [
                            getBlock(title, TextStyleType.TITLE)
                        ]
                    }
                }))
            }
        }]
    };
}

async function createFunnelSlide(slide) {
    const { title, search, items } = slide;

    return {
        template_id: "funnel",
        version: appVersion,
        layout: {
            backgroundStyle: getRandomItem([BackgroundStyleType.DARK, BackgroundStyleType.ACCENT]),
            backgroundColor: "background_accent",
            headerPosition: "top",
        },
        states: [{
            header: {
                text: {
                    blocks: [
                        getBlock(title, TextStyleType.HEADING)
                    ]
                }
            },
            primary: {
                showDescription: true,
                capSize: 60,
                baseSize: 13,
                step: false,
                inverted: false,
                capAlign: "center",
                shadeColors: true,
                items: items.map(text => ({
                    titleTextStyle: "title",
                    text: {
                        blocks: [
                            getBlock(text, TextStyleType.TITLE)
                        ]
                    }
                })),
                style: "sequence"
            }
        }]
    };
}

export async function buildElementForCollection({ item, search }: { item: string, search?: string }) {
    const itemModel = {
        text: {
            blocks: [getBlock(item, TextStyleType.TITLE)]
        },
        content_type: undefined,
        content_value: undefined
    };

    if (search) {
        const images = await searchAssets.searchWebImages({ search, assetType: "photo", pickIndex: 0 });
        if (images.length > 0) {
            const asset = await searchAssets.createAssetFromGoogleImageDataUrl(item, images[0].url, AssetType.IMAGE);
            itemModel.content_type = AssetType.IMAGE;
            itemModel.content_value = asset.id;
        }
    }

    return itemModel;
}

export async function buildSlideModel(generateSlideResponse: GenerateSlideResponse, isRetry = false): Promise<GeneratedSlideModel> {
    const { slideType, slideTitle, slide } = generateSlideResponse;

    // Override title
    slide.title = slideTitle;

    try {
        const getSlideModel = () => {
            switch (slideType) {
                case AiGeneratedSlideType.TITLE:
                    return createTitleSlide(slide);
                case AiGeneratedSlideType.TEXT_WITH_IMAGE:
                    return createHeadlineSlide(slide);
                case AiGeneratedSlideType.CHART:
                    return createChartSlide(slide);
                case AiGeneratedSlideType.BULLET_LIST:
                    if (slide.items.length < 5) {
                        return createIconListSlide(slide);
                    } else {
                        return createTextListSlide(slide);
                    }
                case AiGeneratedSlideType.ORDERED_LIST:
                    return createTextListSlide(slide);
                case AiGeneratedSlideType.TIMELINE:
                    return createTimelineSlide(slide);
                case AiGeneratedSlideType.VENN_DIAGRAM:
                    return createVennSlide(slide);
                case AiGeneratedSlideType.TEAM_MEMBERS:
                    return createTeamSlide(slide);
                case AiGeneratedSlideType.QUOTE:
                    return createQuoteSlide(slide);
                case AiGeneratedSlideType.TABLE:
                    return createTableSlide(slide);
                case AiGeneratedSlideType.PHOTOS:
                    return createPhotoCollageSlide(slide);
                case AiGeneratedSlideType.COMPANY_LOGOS:
                    return createLogoSlide(slide);
                case AiGeneratedSlideType.IMAGES_WITH_TEXT:
                    return createPhotosWithTextSlide(slide);
                case AiGeneratedSlideType.PROCESS:
                    return createProcessDiagram(slide);
                case AiGeneratedSlideType.AGENDA:
                    return createAgendaSlide(slide);
                case AiGeneratedSlideType.BIOGRAPHY:
                    if (slide.items) {
                        // Fix request when a biography slide returns an array
                        for (const item of _.take<Record<string, any>>(slide.items, 6)) {
                            item.title = item.name;
                            item.text = item.biography ?? item.text ?? item.title;
                        }
                        return createPhotosWithTextSlide(slide);
                    } else {
                        return createBiographySlide(slide);
                    }
                case AiGeneratedSlideType.COMPANY_INFO:
                    return createCompanyInfoSlide(slide);
                case AiGeneratedSlideType.COMPARISON:
                    return createComparisonSlide(slide);
                case AiGeneratedSlideType.BOXES_WITH_TEXT:
                    return createTextBoxesSlide(slide);
                case AiGeneratedSlideType.ARROW_BARS:
                    return createArrowBarsSlide(slide);
                case AiGeneratedSlideType.THERMOMETER:
                    return createThermometerSlide(slide);
                case AiGeneratedSlideType.X_Y_PLOT:
                    return createXYPlotSlide(slide);
                case AiGeneratedSlideType.FUNNEL:
                    return createFunnelSlide(slide);
                default:
                    logger.error(new Error(`Unknown slide type: ${slideType}`), `[SlideGenerator] Unknown slide type: ${slideType}`, { slideType });
                    return createHeadlineSlide(slide);
            }
        };

        // @ts-ignore
        const slideModel = (await getSlideModel()) as GeneratedSlideModel;
        // Force v10
        slideModel.migrationVersion = 10;
        return slideModel;
    } catch (err) {
        if (!isRetry) {
            logger.warn(err, "[SlideGenerator] failed to generate slide, retrying...", { type: slideType });
            return buildSlideModel(generateSlideResponse, true);
        }

        throw err;
    }
}

export function findElementsWithImages(model: Object) {
    const imagesToGenerate: ElementWithImageModel[] = [];
    const findAssetsToGenerate = obj => {
        for (const [key, value] of Object.entries(obj)) {
            if (key === "__generateImage") {
                imagesToGenerate.push(obj as ElementWithImageModel);
            }
            if (value && typeof value === "object") {
                findAssetsToGenerate(value);
            }
        }
    };
    findAssetsToGenerate(model);
    return imagesToGenerate;
}

/**
 * Mutates the model to add the image asset
 */
export async function generateImagesForElement(
    item: ElementWithImageModel,
    generatePresentationOutlineResponse?: GeneratePresentationOutlineResponse,
) {
    let {
        search,
        assetType = AssetType.IMAGE,
        useStockLibrary = false,
    } = item.__generateImage;

    const stockSearch = async search => {
        const stockSearch = new UnSplashSearchService();

        let searchResults: any = await stockSearch.doSearch(search, null, 1);
        if (!searchResults?.results?.length) {
            searchResults = await stockSearch.doSearch("abstract", null, 1);
        }
        if (searchResults?.results?.length) {
            const imageProps = getRandomItem(searchResults.results);
            const asset = await ds.assets.getOrCreateImage({
                url: await stockSearch.getDownloadUrl(imageProps),
                name: search,
                fileType: ASSET_FILETYPE.JPEG,
                assetType,
                metadata: {
                    source: "google"
                },
            });
            item.content_value = asset.id;
        }
    };

    try {
        let isPresentationSubjectSpecific = generatePresentationOutlineResponse?.realWorldEntity;
        if (search == "!presentationSubject!") {
            search = generatePresentationOutlineResponse?.aboutName || generatePresentationOutlineResponse?.subject;
        }

        if (!search) {
            logger.warn("[SlideGenerator] generateImagesForElement() no search term provided", { ...item.__generateImage });
            // fall back to a generic search term
            search = "abstract";
            isPresentationSubjectSpecific = false;
        }

        if (assetType == AssetType.IMAGE && useStockLibrary && !isPresentationSubjectSpecific) {
            await stockSearch(search);
        } else {
            const images = await searchAssets.searchWebImages({ ...item.__generateImage, search, useTesseract: true });
            if (images.length > 0) {
                const asset = await searchAssets.createAssetFromGoogleImageDataUrl(search, images[0].url, assetType);
                item.content_value = asset.id;
            } else {
                // fall back in case of no search results
                await stockSearch(search);
            }
        }
    } catch (err) {
        logger.error(err, "[SlideGenerator] generateImagesForElement() error generating image for item", { ...item.__generateImage });
        throw err;
    } finally {
        delete item.__generateImage;
    }
}

