import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Icon } from "@material-ui/core";
import { BusinessCenterOutlined, FormatPaintOutlined, SchoolOutlined } from "@material-ui/icons";

import { headerContainerStyles, headerTextStyles, optionImageStyles, optionNameStyles, optionsButtonStyles, optionsContainerStyles, subHeaderTextStyles } from "legacy-js/react/views/Onboarding/MarketingSurveyStyles";

import Container from "./Components/Container";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const HeaderContainer = styled.div`
    ${headerContainerStyles}
`;

const HeaderText = styled.div`
    ${headerTextStyles}
`;

const SubHeaderText = styled.h3`
    ${subHeaderTextStyles}
`;

const OptionsContainer = styled.div`
    ${optionsContainerStyles}
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }

    & > :nth-child(4) {
        grid-column: 2 / 3;
    }
`;

const OptionButton = styled(Button)`
    ${optionsButtonStyles}
`;

const OptionImage = styled(Icon)`
    ${optionImageStyles}
`;

const OptionName = styled.span`
    ${optionNameStyles}

    /* Safari-specific styles */
    ${isSafari && `
        margin-top: 5px;
    `}
`;

const SkipSurveyButton = styled(Button)`
    &&& {
        display: none;
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: 3px;
        height: 3px;
        opacity: 0;
    }
`;

const UserType = ({ onNext, prevProgress, currProgress, handleChange, value, skipSurvey, paneIdx, prevPaneIdx }) => {
    const [selectedOption, setSelectedOption] = useState(value);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);

    const calculateDelay = index => {
        return 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 0);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (key, value) => {
        handleChange(key, value);
        setSelectedOption(value);
        setFadeOut(true);
        onNext();
    };

    return (
        <Container progress={progress} prevProgress={prevProgress} paneIdx={paneIdx} prevPaneIdx={prevPaneIdx}>
            <HeaderContainer>
                <HeaderText data-test-id="user-type-header" fadeOut={fadeOut ? fadeOut : undefined} delay={calculateDelay(0)}>What kind of work do you do?</HeaderText>
                <SubHeaderText fadeOut={fadeOut ? fadeOut : undefined} delay={calculateDelay(1)}>This helps us recommend templates</SubHeaderText>
            </HeaderContainer>
            <OptionsContainer>
                <OptionButton
                    onClick={() => handleOptionClick("userType", "sales")}
                    delay={calculateDelay(2)}
                    style={{
                        border: selectedOption === "sales" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    fadeOut={fadeOut}
                >
                    <OptionImage as={BusinessCenterOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Sales</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userType", "marketing")}
                    delay={calculateDelay(3)}
                    style={{
                        border: selectedOption === "marketing" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    fadeOut={fadeOut}
                >
                    <OptionImage as={FormatPaintOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Marketing</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userType", "educator-or-student")}
                    delay={calculateDelay(4)}
                    style={{
                        border: selectedOption === "educator-or-student" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    fadeOut={fadeOut}
                >
                    <OptionImage as={SchoolOutlined} style={{ fontSize: "20px" }}></OptionImage>
                    <OptionName>Educator or student</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("userType", "other")}
                    delay={calculateDelay(5)}
                    style={{
                        border: selectedOption === "other" ? "2px solid #11A9E2" : "0.5px solid #999"
                    }}
                    fadeOut={fadeOut}
                >
                    <OptionImage style={{ fontSize: "20px" }}>more_horiz</OptionImage>
                    <OptionName>Other</OptionName>
                </OptionButton>
            </OptionsContainer>
            <SkipSurveyButton
                id="skip-survey"
                variant="contained"
                onClick={skipSurvey}
            />
        </Container>
    );
};

export default UserType;
