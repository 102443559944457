import React, { useEffect, useState } from "react";

import { Section } from "js/react/components/UiComponents";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap10 } from "js/react/components/Gap";
import IconButton from "js/react/views/Editor/Components/IconButton";
import { ShowErrorDialog, ShowInputDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import getLogger, { LogGroup } from "js/core/logger";

import WorkspaceController, { type WorkspaceControllerState } from "js/controllers/WorkspaceController";

const logger = getLogger(LogGroup.WORKSPACES);

export default function CustomPlayerUrl({ workspace: { player: { settings: { customPlayerURLTemplate } } } }: WorkspaceControllerState) {
    const [customUrl, setCustomUrl] = useState(customPlayerURLTemplate);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (customUrl !== customPlayerURLTemplate) {
            setCustomUrl(customPlayerURLTemplate);
        }
    }, [customPlayerURLTemplate]);

    const handleChangeCustomPlayerUrl = async () => {
        const value = await ShowInputDialog({
            title: "Set Custom Player URL",
            message: "Enter the custom player URL template",
            value: customUrl
        });

        if (!value) return;

        if (!value.contains("linkId")) {
            ShowWarningDialog({
                title: "Invalid Custom Player URL",
                message: <>
                    <p>The URL must contain a 'linkId' placeholder.</p>For more information, <a href="https://support.beautiful.ai/hc/en-us/articles/26598025191565">click here.</a>
                </>,
                acceptCallback: () => { }
            });
            return;
        }

        setIsFetching(true);

        try {
            await WorkspaceController.updateWorkspace({ player: { settings: { customPlayerURLTemplate: value } } });
        } catch (err) {
            logger.error(err, "Failed to update custom player URL template");
            ShowErrorDialog({
                title: "Failed to update custom player URL template",
                message: "An error occurred while updating the custom player URL template. Please try again later.",
            });
        } finally {
            setIsFetching(false);
        }
    };

    const handleRemoveCustomPlayerUrl = async () => {
        setIsFetching(true);

        try {
            await WorkspaceController.updateWorkspace({ player: { settings: { customPlayerURLTemplate: null } } });
        } catch (err) {
            logger.error(err, "Failed to remove custom player URL template");
            ShowErrorDialog({
                title: "Failed to remove custom player URL template",
                message: "An error occurred while removing the custom player URL template. Please try again later.",
            });
        } finally {
            setIsFetching(false);
        }
    };

    return (// @ts-ignore
        <Section title="Custom Player URL" description={
            <p><strong>WARNING: </strong>This feature requires additional configuration on your website and incorrect use can prevent player links from working. For more information, <a href="https://support.beautiful.ai/hc/en-us/articles/26598025191565">click here.</a></p>
        }> {/* @ts-ignore */}
            <FlexBox left>
                <label><strong>Custom Player URL Template: </strong></label>
                <Gap10 />
                <label>{customUrl || "[use default]"}</label>
                <Gap10 /> {/* @ts-ignore */}
                <IconButton disabled={isFetching} icon="edit" onClick={handleChangeCustomPlayerUrl} /> {/* @ts-ignore */}
                {customUrl && <IconButton disabled={isFetching} icon="delete" onClick={handleRemoveCustomPlayerUrl} />}
            </FlexBox>
        </Section>
    );
}
