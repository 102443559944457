import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { getStaticUrl } from "legacy-js/config";

const UpdateBannerContainer = styled.div`
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    background: #FFF0E3;
    border-bottom: 2px solid #DDDDDD;
`;

const UpdateBannerLogo = styled.img`
    width: 32px;
    height: 33px;
`;

const UpdateBannerText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    color: #333;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    > p {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        padding: 0;

        > a {
            color: #11A9E2;
            text-decoration: none;
        }
    }

    .first-line {
        white-space: nowrap;
        display: block;
        
        > span {
            display: inline;
            color: #11a9e2;
            cursor: pointer;
            margin-left: 4px;
        }
    }
`;

const UpdateBannerButton = styled(Button)`
    &&& {
        background: #FFD3AD;
        color: #333;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
        
        &:hover {
            background: #FFB77A;
        }
    }
`;

const DismissButton = styled(Button)`
    &&&{
        width: 24px;
        height: 24px;
        min-width: 24px;

        &:hover {
            background: none;
        }
    }
`;

const LearnMoreButton = styled(Button)`
    &&&{
        color: #333;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:hover {
            background: #FFFFFF;
        }
    }
`;

export const UpdateBanner = ({
    hasTeams,
    isOwnerInATeam,
    onDismiss,
    onGetPersonalUpdate,
    onGetTeamUpdate
}) => {
    if (!hasTeams) {
        return (
            <UpdateBannerContainer>
                <UpdateBannerLogo src={getStaticUrl("/images/exclamation-mark.svg")} />
                <UpdateBannerText>
                    A new version of Beautiful.ai is now available!
                    <p>We've made the presentation editing experience more powerful, flexible and easier to navigate with a refreshed UI.
                        Update at your convenience by March 3.</p>
                </UpdateBannerText>
                <LearnMoreButton onClick={() => window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank")}>
                    Learn More
                </LearnMoreButton>
                <UpdateBannerButton onClick={onGetPersonalUpdate}>
                    Get the update!
                </UpdateBannerButton>
                <DismissButton onClick={onDismiss}>
                    <img src={getStaticUrl("/images/dissmiss-icon.svg")} />
                </DismissButton>
            </UpdateBannerContainer>
        );
    }

    if (hasTeams && !isOwnerInATeam) {
        return (
            <UpdateBannerContainer>
                <UpdateBannerLogo src={getStaticUrl("/images/exclamation-mark.svg")} />
                <UpdateBannerText>
                    <div className="first-line">A new version of Beautiful.ai is now available! Ask a team owner to upgrade your team.</div>
                    <p>We've made the presentation editing experience more powerful, flexible and easier to navigate with a refreshed UI. Your team will be automatically updated March 3.</p>
                </UpdateBannerText>
                <LearnMoreButton onClick={() => window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank")}>
                    Learn More
                </LearnMoreButton>
                <DismissButton onClick={onDismiss}>
                    <img src={getStaticUrl("/images/dissmiss-icon.svg")} />
                </DismissButton>
            </UpdateBannerContainer>
        );
    }

    if (hasTeams && isOwnerInATeam) {
        return (
            <UpdateBannerContainer>
                <UpdateBannerLogo src={getStaticUrl("/images/exclamation-mark.svg")} />
                <UpdateBannerText>
                    A new version of Beautiful.ai is now available for your team!
                    <p>We've made the presentation editing experience more powerful, flexible and easier to navigate with a refreshed UI. As a team Owner, updating your workspace will update everyone in your organization. You have until March 3 to update, at which point your team will be updated automatically.</p>
                </UpdateBannerText>
                <LearnMoreButton onClick={() => window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank")}>
                    Learn More
                </LearnMoreButton>
                <UpdateBannerButton onClick={onGetTeamUpdate}>
                    Update your team!
                </UpdateBannerButton>
                <DismissButton onClick={onDismiss}>
                    <img src={getStaticUrl("/images/dissmiss-icon.svg")} />
                </DismissButton>
            </UpdateBannerContainer>
        );
    }
};
