import { GlobalStateController } from "bai-react-global-state";
import firebase from "firebase/compat/app";
import _ from "lodash";

import { presentations as presentationsApi } from "apis/callables";
import removeDummyKeys from "common-backend/utils/removeDummyKeys";
import { PERMISSION_RESOURCE_TYPE, PresentationPrivacyType, PusherEventType, THUMBNAIL_SIZES, TaskState } from "common/constants";
import { IPresentation, IPresentationMetadata, ISlide, IUser } from "common/interfaces";
import { incUserProps } from "js/analytics";
import { appVersion } from "js/config";
import Api from "js/core/api";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { getPresentation, Presentation } from "js/core/models/presentation";
import { SharedTheme } from "js/core/models/sharedTheme";
import { Slide } from "js/core/models/slide";
import { Theme } from "js/core/models/theme";
import { FeatureType } from "js/core/models/features";
import Thumbnails from "js/core/models/thumbnails";
import { User } from "js/core/models/user";
import { search, SearchEngine, SearchResponseResult } from "js/core/services/appSearch";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import * as gDrive from "js/core/utilities/gDrive";
import uniqueName from "js/core/utilities/uniqueName";
import { trackActivity, downloadFromUrl, filenameForExport } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { ShowConfirmationDialog, ShowWarningDialog, ShowMessageDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { auth } from "js/firebase/auth";
import { requestExportCache } from "js/core/services/exportCache";
import { withMaxConcurrency } from "common/utils/withMaxConcurrency";

const logger = getLogger(LogGroup.LIBRARY);

function getPresentationIds(user?: IUser) {
    return Object.keys(removeDummyKeys(user?.presentations ?? {})).sort();
}

export interface PresentationLibraryControllerState {
    workspaceId: string;
    presentations: PresentationLibraryPresentation[];
    filteredPresentations: PresentationLibraryPresentation[];
    filter: PresentationLibraryFilter;
    sort: PresentationLibrarySort;
    viewType: "grid" | "list";
    isInitialized: boolean;
    initializeError: Error | null;
    searchQuery: string;
    searchResults: SearchResponseResult[];
    isPerformingSearch: boolean;
}

export interface PresentationLibraryPresentation extends IPresentationMetadata {
    getThumbnailUrl: (size?: "small" | "large") => Promise<string>;
    dirty?: boolean;
}

export interface PresentationLibraryFilter {
    type: PresentationFilterType;
    folderId?: string;
    subFolderId?: string;
}

export interface PresentationLibrarySort {
    field: string;
    reverse: boolean;
}

export enum PresentationFilterType {
    ALL_PRESENTATIONS = "all_presentations",
    RECENT = "recent",
    OWNED_BY_ME = "owned_by_me",
    SHARED_WITH_ME = "shared_with_me",
    VIEW_ONLY = "view_only",
    FOLDER = "folder",
    TEAM_FOLDER = "team_folder",
    TRASH = "trash"
}

const initialState: PresentationLibraryControllerState = {
    workspaceId: "personal",
    isInitialized: false,
    initializeError: null,
    presentations: [],
    filteredPresentations: [],
    filter: {
        type: PresentationFilterType.RECENT
    },
    sort: {
        field: "modifiedAt",
        reverse: true
    },
    viewType: "grid",
    searchQuery: "",
    searchResults: [],
    isPerformingSearch: false
};

class PresentationLibraryController extends GlobalStateController<PresentationLibraryControllerState> {
    protected _pusherChannel: ExtendedChannel;
    protected _firebaseUser: firebase.User;

    protected _asyncActionsPromiseChain: Promise<void> = Promise.resolve();

    public get isInitialized() {
        return this._state.isInitialized;
    }

    public get filter() {
        return this._state.filter;
    }

    constructor() {
        super(_.cloneDeep(initialState));
    }

    //// Internals and state management ////
    protected _stateDidUpdate(prevState: PresentationLibraryControllerState): void {
        const { sort, viewType, filter, presentations, searchResults, searchQuery, workspaceId, isInitialized } = this._state;

        if (!isInitialized) {
            return;
        }

        if (
            !_.isEqual(prevState.presentations, presentations) ||
            !_.isEqual(prevState.filter, filter) ||
            !_.isEqual(prevState.sort, sort) ||
            !_.isEqual(prevState.workspaceId, workspaceId) ||
            !_.isEqual(prevState.searchResults, searchResults)
        ) {
            this._setFilteredPresentations();
        }

        if (!_.isEqual(prevState.sort, sort) || !_.isEqual(prevState.viewType, viewType)) {
            this._updateUserLibrarySettings();
        }

        if (!_.isEqual(prevState.searchQuery, searchQuery)) {
            this._performSearch(prevState.searchQuery);
        }
    }

    protected async _initialize(initialStateValues: Partial<PresentationLibraryControllerState> & { workspaceId: string }) {
        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain.then(async () => {
                logger.info("[PresentationLibraryController] _initialize()");

                if (this._state.isInitialized) {
                    logger.info("[PresentationLibraryController] _initialize() resetting state");
                    await this._reset(false);
                } else {
                    // Subscribe to auth only once
                    auth().onAuthStateChanged(this._onAuthStateChanged);
                }

                await this._updateState(initialStateValues);

                await this._setPresentations(AppController.user.attributes as IUser, false);

                await this._setFilteredPresentations(false);

                AppController.user.on("change", this._onUserChange);
                ds.userFolders.on("change", this._onUserFoldersChange);
                ds.teams.on("change", this._onTeamsChange);

                await this._subscribeToPusherChannel();

                await this._updateState({ isInitialized: true });
            })
                .then(resolve)
                .catch(err => {
                    logger.error(err, "[PresentationLibraryController] initialize()");

                    this._updateState({ isInitialized: false, initializeError: err })
                        .then(() => reject(err));
                });
        });
    }

    protected async _updateUserLibrarySettings() {
        const { viewType, sort } = this._state;

        const user = await this._getUserModel();

        const librarySettings = _.cloneDeep(user.get("librarySettings") ?? {});
        librarySettings.viewType = viewType;
        if (sort.field !== "relevance") {
            librarySettings.sortField = sort.field;
            librarySettings.sortReverse = sort.reverse;
        }

        user.update({ librarySettings });
        await user.updatePromise;
    }

    protected _onAuthStateChanged = (user: firebase.User | null) => {
        const { isInitialized } = this._state;
        if (isInitialized && this._firebaseUser?.uid !== user?.uid) {
            // Force reset when user changes
            this._reset();
        }

        this._firebaseUser = user;
    }

    protected _onUserFoldersChange = () => {
        const { isInitialized } = this._state;
        if (!isInitialized) {
            return;
        }

        this._setFilteredPresentations();
    }

    protected _onTeamsChange = () => {
        const { isInitialized } = this._state;
        if (!isInitialized) {
            return;
        }

        this._setFilteredPresentations();
    }

    protected _onUserChange = (user: any) => {
        const { isInitialized } = this._state;
        if (!isInitialized) {
            return;
        }

        (async () => {
            if (user.changed.presentations) {
                await this._setPresentations(user.attributes as IUser);
            }

            if (user.changed.librarySettings || user.changed.userFolders) {
                await this._setFilteredPresentations();
            }
        })();
    }

    protected _setPresentations(user: IUser, syncActions: boolean = true) {
        const setUser = async () => {
            try {
                const { presentations: currentPresentations } = this._state;

                const currentPresentationIds = currentPresentations.map(p => p.id);

                const presentationIds = getPresentationIds(user);
                const addedPresentationIds = presentationIds.filter(id => !currentPresentationIds.includes(id));
                const existingPresentationIds = presentationIds.filter(id => currentPresentationIds.includes(id));

                const addedPresentationsMetadata = await presentationsApi.getPresentationsMetadata({ presentationIds: addedPresentationIds });

                const presentations: PresentationLibraryControllerState["presentations"] = [
                    ...currentPresentations.filter(p => existingPresentationIds.includes(p.id)),
                    ...addedPresentationsMetadata.map(metadata => this._getPresentationFromMetadata(metadata))
                ];

                await this._updateState({ presentations });
            } catch (err) {
                logger.error(err, "[PresentationLibraryController] _setPresentations()");

                throw err;
            }
        };

        if (!syncActions) {
            return setUser();
        }

        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(setUser)
                .then(resolve)
                .catch(reject);
        });
    }

    protected _reloadMetadata(presentationIds: string[]) {
        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(async () => {
                    const metadata = await presentationsApi.getPresentationsMetadata({ presentationIds });
                    await this._updateState(({ presentations, ...rest }) => ({
                        ...rest,
                        presentations: presentations.map(p => metadata.some(m => m.id === p.id) ? this._getPresentationFromMetadata(metadata.find(m => m.id === p.id)) : p)
                    }));
                })
                .then(resolve)
                .catch(reject);
        });
    }

    protected async _subscribeToPusherChannel() {
        this._pusherChannel = await pusher.subscribe(`private-user-presentation-updates-${auth().currentUser.uid}`);
        this._pusherChannel.bindChunked(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
    }

    protected _onPusherEvent = (payload: { presentationId: string, update: Partial<IPresentation>, permissions: { owner: boolean, write: boolean, read: boolean }, triggeredAt: number }) => {
        this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
            .then(async () => {
                const { presentationId, update, permissions, triggeredAt } = payload;
                const { presentations } = this._state;

                const index = presentations.findIndex(p => p.id === presentationId);
                if (index === -1) {
                    return;
                }

                const currentPresentation = presentations[index];

                if (triggeredAt < currentPresentation.metadataGeneratedAt) {
                    return;
                }

                const updatedMetadata: IPresentationMetadata = {
                    ..._.omit(currentPresentation, ["getThumbnailUrl"]),
                    permissions
                };

                const directlyTranslatedKeys = ["name", "createdAt", "modifiedAt", "trashedAt", "firstSlideModifiedAt", "isTemplate", "isPublished", "ratedByUsers"];
                directlyTranslatedKeys.forEach(key => {
                    if (key in update) {
                        updatedMetadata[key] = update[key];
                    }
                });
                if ("userId" in update) {
                    updatedMetadata.ownerUid = update.userId;
                }
                if ("softDeletedAt" in update) {
                    updatedMetadata.trashedAt = update.softDeletedAt;
                }
                if ("orgId" in update) {
                    updatedMetadata.workspaceId = update.orgId ? update.orgId : "personal";
                }
                if ("slideRefs" in update) {
                    const slideRefs = (update.slideRefs || {}) as Record<string, number>;
                    Object.entries(slideRefs).forEach(([slideId, slideIndex]) => {
                        if (slideIndex === null) {
                            delete slideRefs[slideId];
                        }
                    });
                    updatedMetadata.slideIds = Object.entries(slideRefs).sort(([slideIdA, slideIndexA], [slideIdB, slideIndexB]) => slideIndexA - slideIndexB).map(([slideId, slideIndex]) => slideId);
                    updatedMetadata.slideCount = updatedMetadata.slideIds.length;
                }

                const presentation = this._getPresentationFromMetadata(updatedMetadata);

                await this._updateState(state => ({
                    ...state,
                    presentations: state.presentations.map(p => p.id === presentation.id ? presentation : p)
                }));
            })
            .catch(err => logger.error(err, `[PresentationLibraryController] _onPusherEvent()`));
    };

    protected _getPresentationFromMetadata(metadata: IPresentationMetadata): PresentationLibraryPresentation {
        // Pin scalars to protect against mutations
        const firstSlideId = metadata.slideIds[0];
        const firstSlideModifiedAt = metadata.firstSlideModifiedAt;
        const presentationId = metadata.id;

        const getThumbnailUrl = (size: "small" | "large" = "small") => {
            return Thumbnails.getSignedUrlAndLoad(firstSlideId, firstSlideModifiedAt, presentationId, THUMBNAIL_SIZES[size].suffix, 0, false);
        };

        return {
            ...metadata,
            getThumbnailUrl
        };
    }

    protected _setFilteredPresentations(syncActions: boolean = true) {
        const setFiltered = async () => {
            const { presentations, filter, sort, workspaceId, searchQuery, searchResults, filteredPresentations: currentFilteredPresentations } = this._state;

            let filteredPresentations = presentations
                .filter(presentation => !presentation.isTemplate)
                .filter(presentation => {
                    // Looking for presentations in a non-personal workspace
                    if (workspaceId !== "personal") {
                        return presentation.workspaceId === workspaceId;
                    }

                    // Looking for presentations in a personal workspace
                    {
                        // Presentation is explicitly in personal workspace
                        if (presentation.workspaceId === "personal") {
                            return true;
                        }

                        // If the presentation is in a workspace where the user is not a member, then treat as personal
                        const workspaceIds = AppController.workspacesMetadata.map(({ id }) => id);
                        return !workspaceIds.includes(presentation.workspaceId);
                    }
                });

            filteredPresentations = (() => {
                if (filter.type === PresentationFilterType.TRASH) {
                    return filteredPresentations.filter(p => p.trashedAt);
                }

                filteredPresentations = filteredPresentations.filter(p => !p.trashedAt);

                if (filter.type === PresentationFilterType.ALL_PRESENTATIONS) {
                    return [...filteredPresentations];
                }

                if (filter.type === PresentationFilterType.RECENT) {
                    const recentPresentations = Object.values((AppController.user?.get("librarySettings") ?? {}).recentPresentations ?? []).filter(Boolean).reverse();

                    return recentPresentations.map(id => filteredPresentations.find(p => p.id === id))
                        .filter(Boolean)
                        // Max 12 recent presentations to display
                        .slice(0, 12);
                }

                if (filter.type === PresentationFilterType.OWNED_BY_ME) {
                    return filteredPresentations.filter(p => p.permissions.owner);
                }

                if (filter.type === PresentationFilterType.SHARED_WITH_ME) {
                    return filteredPresentations.filter(p => !p.permissions.owner);
                }

                if (filter.type === PresentationFilterType.VIEW_ONLY) {
                    return filteredPresentations.filter(p => p.permissions.read && !p.permissions.write);
                }

                if (filter.type === PresentationFilterType.FOLDER) {
                    const folderModel = ds.userFolders?.get(filter.folderId);
                    const presentationIds = folderModel?.get("presentations");
                    if (!presentationIds?.length) {
                        return [];
                    }

                    return filteredPresentations.filter(presentation => presentationIds.includes(presentation.id));
                }

                if (filter.type === PresentationFilterType.TEAM_FOLDER) {
                    const teamFolder = ds.teams?.get(filter.folderId);
                    if (!teamFolder || !teamFolder.get("sharedResources") || !teamFolder.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION]) {
                        return [];
                    }

                    const subfolders = teamFolder.get("subFolders");

                    if (!filter.subFolderId) {
                        const presentationIds = Object.keys(teamFolder.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION]);
                        const presentationsInSubfolders = (subfolders ?? []).reduce((acc, subfolder) => {
                            return [...acc, ...(subfolder.presentations ?? [])];
                        }, []);

                        return filteredPresentations
                            .filter(presentation => presentationIds.includes(presentation.id))
                            .filter(presentation => !presentationsInSubfolders.includes(presentation.id));
                    }

                    const subfolder = subfolders.find(sub => sub.id === filter.subFolderId) ?? { presentations: [] };
                    return filteredPresentations.filter(presentation => (subfolder.presentations ?? []).includes(presentation.id));
                }
            })();

            filteredPresentations = _.orderBy(filteredPresentations, "modifiedAt", "desc");

            if (searchQuery) {
                filteredPresentations = filteredPresentations
                    .filter(presentation => searchResults.some(r => r.presentation_id?.raw === presentation.id));

                if (sort.field === "relevance") {
                    filteredPresentations = filteredPresentations
                        .sort((a, b) => {
                            const scoreA: number = searchResults.find(r => r.presentation_id?.raw === a.id)?.presentation_id?.score ?? 0;
                            const scoreB: number = searchResults.find(r => r.presentation_id?.raw === b.id)?.presentation_id?.score ?? 0;

                            if (sort.reverse) {
                                return scoreB - scoreA;
                            }

                            return scoreA - scoreB;
                        });
                } else {
                    filteredPresentations = _.orderBy(filteredPresentations, sort.field, sort.reverse ? "desc" : "asc");
                }
            } else {
                filteredPresentations = _.orderBy(filteredPresentations, sort.field, sort.reverse ? "desc" : "asc");
            }

            if (_.isEqual(
                filteredPresentations.map(p => _.omit(p, ["getThumbnailUrl"])),
                currentFilteredPresentations.map(p => _.omit(p, ["getThumbnailUrl"])))
            ) {
                return;
            }

            await this._updateState({ filteredPresentations });
        };

        if (!syncActions) {
            return setFiltered();
        }

        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(setFiltered)
                .then(resolve)
                .catch(reject);
        });
    }

    protected _performSearch(prevSearchQuery: string) {
        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(async () => {
                    const { workspaceId, searchQuery, sort } = this._state;

                    if (!searchQuery) {
                        const stateUpdates = {
                            searchResults: [],
                            sort
                        };

                        if (sort.field === "relevance") {
                            // Ensure the sort is reset to modifiedAt when searchQuery is empty
                            stateUpdates.sort = {
                                field: "modifiedAt",
                                reverse: true
                            };
                        }

                        await this._updateState(stateUpdates);
                        return;
                    }

                    await this._updateState({ isPerformingSearch: true });

                    const { results: searchResults } = await search({
                        workspaceId,
                        searchEngine: SearchEngine.USER_SLIDES,
                        query: searchQuery,
                        page: { size: 500 },
                        resultFields: { presentation_id: { raw: {} } }
                    }).catch(err => {
                        logger.error(err, "[PresentationLibraryController] _performSearch()");

                        return { results: [] };
                    });

                    const stateUpdates = {
                        isPerformingSearch: false,
                        searchResults,
                        sort
                    };

                    if (!prevSearchQuery) {
                        // Switch to relevance sort when started searching
                        stateUpdates.sort = {
                            field: "relevance",
                            reverse: false
                        };
                    }

                    await this._updateState(stateUpdates);
                })
                .then(resolve)
                .catch(reject);
        });
    }

    protected _generatePresentationName() {
        const { presentations } = this._state;

        const maxNumber = presentations.reduce((max, presentation) => {
            if (presentation.name?.startsWith("Untitled")) {
                const numbers = presentation.name.match(/\d+/g);
                if (numbers) {
                    const number = parseInt(numbers[0]);
                    if (!isNaN(number)) {
                        return Math.max(max, number);
                    }
                }
            }

            return max;
        }, 0);

        return `Untitled #${maxNumber + 1}`;
    }

    protected async _createPresentation(options: {
        workspaceId: string;
        presentation: Partial<IPresentation>;
        theme?: typeof Theme | typeof SharedTheme;
        slides?: (Partial<ISlide> & { template_id: string })[];
    }) {
        const {
            workspaceId,
            presentation: initialData,
            theme,
            slides = [],
        } = options;

        if (slides.length === 0 && Object.keys(initialData.slideRefs || {}).length === 0) {
            throw new Error("At least one slide is required");
        }

        const uid = auth().currentUser.uid;

        const playerSettingsDefaults = {};
        if (workspaceId !== "personal") {
            const currentTeam = AppController.currentTeam;
            if (currentTeam?.get("workspaceSettings")?.playerSettingsDefaults) {
                Object.assign(playerSettingsDefaults, currentTeam.get("workspaceSettings").playerSettingsDefaults);
            }
        }

        const presentationData: Partial<IPresentation> = {
            ...playerSettingsDefaults,
            ...initialData,
            userId: uid
        };

        if (theme && theme instanceof SharedTheme) {
            presentationData.sharedThemeId = theme.id;
        }

        const presentation = new Presentation(presentationData, {
            userId: uid,
            permission: "write"
        });
        await presentation.load();

        if (theme && !(theme instanceof SharedTheme)) {
            await app.themeManager.saveThemeToPresentation(theme, presentation);
        }

        if (slides.length > 0) {
            const slideRefs: IPresentation["slideRefs"] = {};
            await Promise.all(slides.map(async (slideData, index) => {
                slideData = await presentation.getTemplateSlideData(slideData.template_id, {
                    ...slideData,
                    presentationId: presentation.id,
                    version: appVersion
                });

                const slide = new Slide(slideData, {
                    presentation,
                    autoSync: false
                });
                await slide.load();
                slide.disconnect();

                slideRefs[slide.id] = index;
            }));

            // Update the presentation with the slide refs
            presentation.update({ slideRefs });
            await presentation.updatePromise;
        }

        const user = await this._getUserModel();
        await user.addPresentation(presentation.id);

        const eventProps = {
            "presentation_id": presentation.id,
            "current_slide_count": this._getSlideCount(workspaceId) + slides.length,
            "slides_created": slides.length,
            "workspace_id": workspaceId
        };
        trackActivity("Presentation", "New", null, null, eventProps, { audit: true });
        incUserProps({ created_presentations: 1 });

        if (user.get("isGDriveEnabled")) {
            const gDriveFolderId = new URL(window.location.href).searchParams.get("gDriveFolderId");
            gDrive.savePresentation(presentation.id, gDriveFolderId)
                .catch(err => {
                    logger.error(err, "PresentationLibraryController gDrive.savePresentation() failed", { presentationId: presentation.id, gDriveFolderId });
                });
        }

        return presentation;
    }

    protected async _movePresentation(presentation: typeof Presentation, libraryFilter: PresentationLibraryFilter) {
        if (libraryFilter.type === PresentationFilterType.TEAM_FOLDER) {
            // @ts-ignore
            const { TeamFoldersController } = await import("js/core/dataServices/TeamFoldersDataService");

            await TeamFoldersController.addPresentationsToTeamFolder([presentation], libraryFilter.folderId, "write");

            if (libraryFilter.subFolderId) {
                await TeamFoldersController.addPresentationsToTeamSubFolder(
                    [presentation],
                    libraryFilter?.folderId,
                    libraryFilter?.subFolderId
                );
            }
            return;
        }

        if (libraryFilter.type === PresentationFilterType.FOLDER) {
            const folder = ds.userFolders.get(libraryFilter?.folderId);
            folder.addPresentationToFolder(presentation.id);
            await folder.updatePromise;
        }
    }

    protected async _getUserModel() {
        if (AppController.user?.isLoaded) {
            return AppController.user;
        }

        const uid = auth().currentUser.uid;
        const user = new User({ id: uid }, { autoLoad: false });
        await user.loadMinimum();
        return user;
    }

    protected _getSlideCount(workspaceId: string) {
        const { presentations } = this._state;

        return presentations.reduce((count, presentation) => {
            if (presentation.workspaceId === workspaceId) {
                return count + presentation.slideCount;
            }

            return count;
        }, 0);
    }

    protected async _reset(syncActions: boolean = true): Promise<void> {
        logger.info("[PresentationLibraryController] reset()");

        const reset = async () => {
            try {
                await this._updateState(_.cloneDeep(initialState));

                if (this.isInitialized) {
                    ds.userFolders.off("change", this._onUserFoldersChange);
                    ds.teams.off("change", this._onTeamsChange);
                    AppController.user.off("change", this._onUserChange);
                }

                if (this._pusherChannel) {
                    this._pusherChannel.unbind(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
                    if (!this._pusherChannel.isInUse) {
                        pusher.unsubscribe(this._pusherChannel.name);
                    }
                    this._pusherChannel = null;
                }
            } catch (err) {
                logger.error(err, "[PresentationLibraryController] _reset()");

                throw err;
            }
        };

        if (!syncActions) {
            return reset();
        }

        return new Promise<void>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(reset)
                .then(resolve)
                .catch(reject);
        });
    }

    //// Initialize and reset methods ////
    public initialize(initialStateValues: Partial<PresentationLibraryControllerState> & { workspaceId: string }) {
        return this._initialize(initialStateValues);
    }

    public reset() {
        return this._reset();
    }

    //// Dummy state getting methods ////
    public getSlideCount(workspaceId: string) {
        return this._getSlideCount(workspaceId);
    }

    public getPresentations(workspaceId: string) {
        return this._state.presentations.filter(p => p.workspaceId === workspaceId);
    }

    ////// Dummy state updating methods ////
    public async setFilter(filter: PresentationLibraryFilter) {
        await this._updateState({ filter });
    }

    public async setSort(sort: PresentationLibrarySort) {
        await this._updateState({ sort });
    }

    public async setViewType(viewType: "grid" | "list") {
        await this._updateState({ viewType });
    }

    public async setWorkspaceId(workspaceId: string) {
        await this._updateState({ workspaceId });
    }

    public async setSearchQuery(searchQuery: string) {
        await this._updateState({ searchQuery });
    }

    //// Helpers to trigger faster updates ////
    public async forceRefreshPresentations(presentationIds: string[]) {
        return this._reloadMetadata(presentationIds);
    }

    public async markPresentationsDirty(presentationIds: string[]) {
        await this._updateState(state => ({
            ...state,
            presentations: state.presentations.map(p => presentationIds.includes(p.id) ? { ...p, dirty: true } : p)
        }));
    }

    //// Logic-heavy methods ////
    public async createPresentation(options: {
        workspaceId: string;
        theme?: typeof Theme | typeof SharedTheme;
        name?: string;
        isTemplate?: boolean;
        slides?: (Partial<ISlide> & { template_id: string })[];
        metadata?: Record<string, any>;
        libraryFilter?: PresentationLibraryFilter;
    }) {
        const {
            workspaceId,
            theme = ds.builtInThemes.sample(),
            slides = [],
            name = this._generatePresentationName(),
            isTemplate = false,
            metadata = {},
            libraryFilter
        } = options;

        if (slides.length === 0) {
            slides.push({ template_id: "title" });
        }

        const initialData: Pick<IPresentation, "name" | "orgId" | "metadata" | "createdAt" | "modifiedAt" | "isTemplate"> = {
            name,
            orgId: workspaceId === "personal" ? null : workspaceId,
            metadata,
            createdAt: Date.now(),
            modifiedAt: Date.now(),
            isTemplate
        };

        const presentation = await this._createPresentation({
            workspaceId,
            presentation: initialData,
            theme,
            slides
        });

        if (libraryFilter) {
            await this._movePresentation(presentation, libraryFilter);
        }

        return presentation;
    }

    public async duplicatePresentation(options: {
        presentationId: string;
        libraryFilter?: PresentationLibraryFilter;
    }) {
        const { presentations } = this._state;
        const { presentationId, libraryFilter } = options;

        const presentation = await getPresentation(presentationId, { permission: "read", autoSync: false });

        const name = uniqueName(presentation.get("name"), presentations.map(p => p.name));
        const { presentationId: duplicatedPresentationId } = await presentationsApi.copyPresentation({
            id: presentationId,
            workspaceId: presentation.getWorkspaceId(),
            name
        });

        presentation.disconnect();

        const duplicatedPresentation = await getPresentation(duplicatedPresentationId, { permission: "write", autoSync: true });

        if (libraryFilter) {
            await this._movePresentation(duplicatedPresentation, libraryFilter);
        }

        return duplicatedPresentation;
    }

    public async createPresentationFromTemplate(options: {
        templatePresentationId: string;
        libraryFilter?: PresentationLibraryFilter;
    }) {
        const { templatePresentationId, libraryFilter } = options;

        const duplicatedPresentation = await this.duplicatePresentation({
            presentationId: templatePresentationId,
            libraryFilter
        });

        duplicatedPresentation.update({ isTemplate: false });
        await duplicatedPresentation.updatePromise;

        return duplicatedPresentation;
    }

    public async trashPresentations(presentationIds: string[]) {
        const presentations = await Promise.all(presentationIds.map(id => getPresentation(id, { permission: "write", autoSync: false })));

        const disconnect = () => presentations.forEach(p => p.disconnect());

        if (presentations.some(p => !p.permissions.owner)) {
            ShowWarningDialog({
                title: "Oops!",
                message: "Presentations that are being collaborated on can not move to trash!",
                acceptCallback: () => { }
            });
            disconnect();
            return;
        }

        // This will remove all collaborators from the presentations
        await Api.userPermissions.put({ permissionIds: presentationIds });

        //////////////////////////////////////////////////////
        //////////// Old logic, to be refactored /////////////
        const user = await this._getUserModel();
        if (user.get("teams")) {
            await Promise.all(presentationIds.map(async presentationId => {
                await Promise.all(ds.teams.map(async team => {
                    if (
                        team.has("sharedResources") &&
                        team.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION] &&
                        Boolean(team.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION][presentationId])
                    ) {
                        team.update({ "sharedResources": { [PERMISSION_RESOURCE_TYPE.PRESENTATION]: { [presentationId]: null } } });
                        await team.updatePromise;
                    }
                }));
            }));
        }

        await Promise.all(presentations.map(async presentation => {
            await Promise.all(ds.userFolders.map(async folderModel => {
                const folderPresentationIds = folderModel.get("presentations");
                if (folderPresentationIds?.contains(presentation.id)) {
                    folderModel.removePresentationFromFolder(presentation.id);
                    await folderModel.updatePromise;
                }
            }));

            await presentation.setPrivacySetting(PresentationPrivacyType.PRIVATE);

            presentation.update({ softDeletedAt: new Date().getTime() });
            await presentation.updatePromise;

            if (user.get("isGDriveEnabled")) {
                gDrive.removePresentation(presentation.id)
                    .catch(err => {
                        logger.error(err, "PresentationLibraryController gDrive.removePresentation() failed", { presentationId: presentation.id });
                    });
            }

            const eventProps = {
                "presentation_id": presentation.id,
                "workspace_id": presentation.getWorkspaceId(),
                "library_location": this._state.filter.type,
                "current_folder_id": this._state.filter.folderId
            };
            trackActivity("Presentation", "MoveToTrash", null, null, eventProps, { audit: true });
        }));
        //////////////////////////////////////////////////////

        disconnect();

        // Fire and forget
        this._reloadMetadata(presentationIds)
            .catch(err => {
                logger.error(err, "PresentationLibraryController _reloadMetadata() failed", { presentationIds });
            });
    }

    public async recoverPresentations(presentationIds: string[]) {
        await Promise.all(presentationIds.map(async id => {
            const presentation = await getPresentation(id, { permission: "write", autoSync: false });

            if (!presentation.get("softDeletedAt")) {
                presentation.disconnect();
                return;
            }

            presentation.update({ softDeletedAt: null });
            await presentation.updatePromise;

            const eventProps = {
                "presentation_id": presentation.id,
                "library_location": this._state.filter.type,
            };
            trackActivity("Presentation", "Recovered", null, null, eventProps, { audit: true });

            const user = await this._getUserModel();
            if (user.get("isGDriveEnabled")) {
                gDrive.savePresentation(presentation.id)
                    .catch(err => {
                        logger.error(err, "PresentationLibraryController gDrive.savePresentation() failed", { presentationId: presentation.id });
                    });
            }

            presentation.disconnect();
        }));

        // Fire and forget
        this._reloadMetadata(presentationIds)
            .catch(err => {
                logger.error(err, "PresentationLibraryController _reloadMetadata() failed", { presentationIds });
            });
    }

    public async deletePresentations(presentationIds: string[]) {
        await Promise.all(presentationIds.map(async id => {
            const presentation = await getPresentation(id, { permission: "write", autoSync: false });

            const isOwner = presentation.permissions.owner;
            const deletedSlideCount = -Object.keys(presentation.get("slideRefs") || {}).length;
            const workspaceId = presentation.getWorkspaceId();

            await presentation.destroy();

            if (isOwner) {
                incUserProps({
                    deleted_presentations: 1
                });
            }
            const eventProps = {
                "presentation_id": id,
                "library_location": this._state.filter.type,
                "current_slide_count": this._getSlideCount(workspaceId),
                "slides_created": deletedSlideCount,
            };
            trackActivity("Presentation", "Delete", null, null, eventProps, { audit: true });
        }));
    }

    public async playPresentation(presentationId: string) {
        const presentation = await getPresentation(presentationId, { permission: "read", autoSync: false });

        const entry_slide_index = 0;
        trackActivity("Presentation", "Present");
        const props = {
            initiated_via: "library",
            entry_slide_index: entry_slide_index + 1,
            presentation_id: presentation.id,
            presentation_slide_count: presentation.getSlideCount(),
            current_slide_count: this._getSlideCount(presentation.getWorkspaceId()),
            workspace_id: presentation.getWorkspaceId()
        };
        trackActivity("Presentation", "PresentOpen", null, null, props, { audit: true });

        if (window.roomID) {
            if (!presentation.get("public")) {
                if (await ShowConfirmationDialog({
                    title: "Do you want to set the presentation to public?",
                    message: "Your presentation is set to private but must be public in order to launch using remote control.",
                    okButtonLabel: "Set to public"
                })) {
                    await presentation.setPrivacySetting(PresentationPrivacyType.PUBLIC);
                }
            }
        }

        if (app.integrator) {
            app.integrator.registerPresentation(presentation);
            return;
        }

        if (app.isMobileOrTablet) {
            // To load the player UI to be compatible for mobile we need to switch the location to the player url instead
            // of playing the presentation from app.mainView.playPresentation
            let url = `/player/${presentation.id}`;
            if (window.roomID) {
                url += `?roomID=${window.roomID}&remoteRole=leader`;

                // for efficiency, trigger the registerLeader event on the pusher channel so that the clients can start loading their players immediately
                // instead of needing to wait for our player redirect to load
                const remoteSetupChannel = await pusher.subscribe(`presence-remote-control-${window.roomID}`);
                remoteSetupChannel.trigger("client-registerLeader", { presentationId: presentation.id });
            }
            window.location.href = url;
            return;
        }

        if (window.roomID) {
            // @ts-ignore
            window.remoteRole = "leader";
        }

        AppController.playPresentation({ presentationId: presentation.id, slideIndex: entry_slide_index }, {});
    }

    public async exportPresentations({
        assetType = "pdf",
        workspaceId = null,
        includeSkippedSlides = false,
    }: {
        assetType?: "pdf" | "pptx" | "zip";
        workspaceId?: string;
        includeSkippedSlides?: boolean;
    }) {
        const presentations = workspaceId ? this._state.presentations.filter(p => p.workspaceId === workspaceId) : this._state.presentations;
        if (presentations.length === 0) {
            ShowWarningDialog({
                title: "No presentations found",
                message: "Nothing to export",
                acceptCallback: () => { }
            });
            return;
        }

        const exportPresentation = (presentation: PresentationLibraryPresentation) => new Promise<string>((resolve, reject) => {
            const onTaskChanged = (task: { state: string, signedUrl: string, errorMessage: string, stateProgressPercents: number }) => {
                if (task.state === TaskState.ERROR) {
                    reject(new Error(`Export failed with message: ${task.errorMessage}`));
                    return;
                }

                if (task.state === TaskState.FINISHED) {
                    resolve(task.signedUrl);
                    return;
                }
            };

            const taskProps = {
                presentationId: presentation.id,
                assetType,
                hideBaiBranding: !!AppController.user.features?.isFeatureEnabled(FeatureType.REMOVE_BAI_BRANDING, presentation.workspaceId),
                hideSmartSlideWatermark: !!AppController.user.features?.isFeatureEnabled(FeatureType.SMART_SLIDES, presentation.workspaceId),
                isTeamUser: !!AppController.user.features?.isFeatureEnabled(FeatureType.TEAMS, presentation.workspaceId),
                forPrinting: false,
                includeSkippedSlides,
                pdfCompressionType: "none"
            };

            requestExportCache(onTaskChanged, taskProps)
                .catch(reject);
        });

        let shouldCancel = false;
        const progressDialog = ShowDialog(ProgressDialog, {
            title: `Exporting ${presentations.length} presentations`,
            onCancel: () => {
                shouldCancel = true;
                progressDialog.setTitle("Cancelling export...");
            }
        });

        let exportedCount = 0;
        let failedCount = 0;
        await withMaxConcurrency(presentations.map(presentation => async () => {
            if (shouldCancel) {
                return;
            }

            try {
                const signedUrl = await exportPresentation(presentation);
                if (shouldCancel) {
                    return;
                }
                const fileName = filenameForExport({ name: presentation.name, assetType });
                await downloadFromUrl(signedUrl, fileName);
                exportedCount++;
                progressDialog.setProgress(exportedCount / presentations.length * 100);
            } catch (err) {
                failedCount++;
                logger.error(err, "PresentationLibraryController exportPresentations() failed", { presentationId: presentation.id });
            }
        }), 10);

        progressDialog.props.closeDialog();

        ShowMessageDialog({
            title: "Export complete",
            message: `Exported ${exportedCount} presentations.${failedCount ? ` Failed to export ${failedCount} presentations.` : ""}${shouldCancel ? "  Export cancelled." : ""}`,
            acceptCallback: () => { }
        });
    }
}

const presentationLibraryController = new PresentationLibraryController();

// for lazy import and debug
app.presentationLibraryController = presentationLibraryController;

export default presentationLibraryController;
