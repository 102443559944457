import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";

import Container from "./Components/Container";

const IntroHeader = styled.h2`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.26px;
    text-wrap: nowrap;
`;

const IntroImage = styled.div`
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    background: url(${getStaticUrl("/images/bai-bot/bai-bot-shake.gif")});
    background-size: cover;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;

const Intro = ({ onNext, prevProgress, currProgress, paneIdx, prevPaneIdx }) => {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const delay = (600 + 1 / (1 + Math.exp(-.25 * 0)) * 800) + 100;
        setFadeOut(true);
        setTimeout(onNext, delay);
    }, []);

    return (
        <Container fadeIn contentFadeOut={fadeOut} progress={currProgress} prevProgress={prevProgress} paneIdx={paneIdx} prevPaneIdx={prevPaneIdx}>
            <IntroImage />
            <IntroHeader>Help us get to know you!</IntroHeader>
        </Container>
    );
};

export default Intro;
